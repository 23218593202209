import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from '../../../services/sites.service';
import { SurveyTypesService } from '../../../services/surveytypes.service';
import { ProductsService } from '../../../services/products.service';
import { DataTypesService } from '../../../services/datatypes.service';
import { XmlParse } from '../../../helper-classes/XmlParse';
import { MatSort, MatTableDataSource } from '@angular/material';
import { latLng, tileLayer, Map, LatLngBounds, LatLng } from 'leaflet';
import { LinkSitesProductsService } from '../../../services/link_sites_products.service';
import * as uuid from 'uuid';

import {
  FormBuilder,
  NgForm,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
declare var require: any;
var s3Service = require('../../../../../lib/s3Util');
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { take, mergeMap, startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoggingJson } from '../../../app/logging-json';
import { LoggingService } from '../../../services/logging.service';
var parser = require('fast-xml-parser');
var he = require('he');
import * as moment from 'moment';

import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { FileService } from '../../../services/FileService';
import { UsersService } from '../../../services/users.service';
import { Writable } from 'stream';
import { ExportXmlService } from '../../../services/export-to-new-product.service';

var options = {
  attributeNamePrefix: '@_',
  attrNodeName: 'attr', //default is 'false'
  textNodeName: '#text',
  ignoreAttributes: true,
  ignoreNameSpace: false,
  allowBooleanAttributes: false,
  parseNodeValue: true,
  parseAttributeValue: false,
  trimValues: true,
  cdataTagName: '__cdata', //default is 'false'
  cdataPositionChar: '\\c',
  localeRange: '', //To support non english character in tag/attribute values.
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  attrValueProcessor: (val, attrName) =>
    he.decode(val, { isAttributeValue: true }), //default is a=>a
  tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
  stopNodes: ['parse-me-as-string'],
};
//Global Declarations
declare let L;
@Component({
  selector: 'exported-data',
  templateUrl: './exported-data.component.html',
  styleUrls: ['./exported-data.component.css'],
})
// @Directive({
//   selector: '[appDragDrop]'
// })
export class ExportedDataComponent {
  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    ],
    zoom: 6,
    center: latLng(26.72, -89.68),
  };
  newSite: Object = {
    label: '',
  };

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('picker') pickerRef: ElementRef;
  @ViewChild('siteForm') siteForm: NgForm;
  @ViewChild('uploadForm') uploadForm: NgForm;
  @ViewChild('confirmationForm') confirmationForm: NgForm;

  metadataForm = new FormGroup({
    metadataFileName: new FormControl('', [Validators.required]),
  });
  confirmForm = new FormGroup({});
  existingSitesForm = new FormGroup({
    searchValue: new FormControl(''),
  });
  dataSource: any;
  matTableDataSource = new MatTableDataSource();

  xmlFileName: any;
  displayedColumnsFiles: string[] = ['site'];
  public files: NgxFileDropEntry[] = [];
  showError: boolean = false;
  existingSite: boolean = false;
  hasSubmitMetadataForm: boolean = false;
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<string[]>;
  siteIds = new Array();
  sitesAdded = new Array();
  dropZoneText: string;
  surveyTypes: any;
  IsChecked: boolean;
  singleFileDiv: boolean;
  fileDropDiv: boolean;
  dataTypes: any;
  existingSites: any;
  maxDate = new Date();
  s3Url = 'https://lidar-app-uploads.s3.amazonaws.com/';
  calculatedCentroid: any;
  calculatedBounds: any;
  /************** Site Form **************/
  isNewSite = true;
  // siteForm: NgForm;
  // uploadForm: NgForm;
  //confirmationForm: NgForm;
  siteName: string;
  siteDescription: string;
  siteLatitude: number;
  siteLongitude: number;
  productJsonXml: any;
  /***************************************/

  /************** Upload Form **************/
  surveyType: any;
  dataType: any;
  productFileName: string;
  metadataFileName: string;
  surveyDate: any;
  productDescription: string;
  /***************************************/

  /************** File Upload **************/
  showRenderer: boolean = false;
  percentComplete: number = 0;
  productFile: File;
  metadataFile: File;
  shapeFile: File;
  DBF: File;
  PRJ: File;
  productFilesStringArray: string[] = [];
  productFilesArray: File[] = [];
  productDirectory: string = '';
  productSurveyType: string = '';
  productDataType: string = '';
  loaded: boolean = false;
  _map: Map;
  show: boolean = false;
  layers = [];
  customMarker: any;
  showProgressBar: boolean = false;
  productUUID: string = '';
  metadataUUID: string = '';
  /*****************************************/
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    var fileList: FileList;
    console.log('DROPPED!');
    console.log(files);

    for (const droppedFile of files) {
      console.log(droppedFile);
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          // Here you can access the real file
          this.productDirectory = droppedFile.relativePath.substr(
            0,
            droppedFile.relativePath.indexOf('/')
          );
          if (
            this.productDirectory === null ||
            this.productDirectory === undefined ||
            this.productDirectory === ''
          ) {
            alert(
              'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
            );
          }
          //Assign file.type for ContentType upload later
          console.log(file);
          if (file.name.endsWith('.xml')) {
            //   file = "application/xml"
          }
          this.productFilesStringArray.push(file.name);
          this.productFilesArray.push(file);
          if (file.name.endsWith('.shp')) {
            //Store shapefile to send to server later
            this.shapeFile = file;
          } else if (file.name.endsWith('.dbf')) {
            this.DBF = file;
          } else if (file.name.endsWith('.prj')) {
            this.PRJ = file;
          }
          this.dropZoneText = droppedFile.relativePath.substr(
            0,
            droppedFile.relativePath.indexOf('/')
          );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public longest_common_starting_substring(inputArray) {
    const arr = inputArray.concat().sort();
    const a1 = arr[0];
    const a2 = arr[arr.length - 1];
    const L = a1.length;
    let i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
    return a1.substring(0, i);
  }
  public clicked(event) {
    // //var input = document.createElement('input');
    // input.type = 'file';
    // input.click();
    // console.log(input);
    // console.log(event);
    var input = document.getElementById('folderInput');
    input.click();
    var test = document.getElementById('dropZoneId');
    console.log(test);
  }
  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
  testable: any;
  constructor(
    private sitesService: SitesService,
    private productService: ProductsService,
    private surveyTypesServices: SurveyTypesService,
    private dataTypesServices: DataTypesService,
    private userService: UsersService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private linkSitesProductsService: LinkSitesProductsService,
    private loggingService: LoggingService,
    private fileservice: FileService,
    private exportService: ExportXmlService,
    private changeDetector: ChangeDetectorRef
  ) {
    // Populating survey types drop down
    this.IsChecked = false;
    this.surveyTypesServices
      .getSurveyTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.surveyTypes = result;
        },
        error => console.log(error)
      );

    // Populating data types drop down
    this.dataTypesServices
      .getDataTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.dataTypes = result;
        },
        error => console.log(error)
      );
  }
  OnChange($event) {
    this.IsChecked = $event.checked;
  }
  ngOnInit() {
    function filesPicked(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const path = file.webkitRelativePath.split('/');

        console.log(file);
        // upload file using path
      }
    }
    this.initializeMap();
    // Getting all the existing sites
    this.sitesService
      .getOnlySites()
      .pipe(take(1))
      .subscribe(
        result => {
          this.existingSites = result;
          this.dataSource = new MatTableDataSource(this.existingSites);
          console.log(this.existingSites);
        },
        error => {
          console.log(error);
        }
      );

    // Checking if user is logged in
    if (sessionStorage.getItem('email') !== null) {
    } else {
      console.log('Please login!');

      this.router.navigate(['/login', { continue: this.router.url }]).then(
        nav => {
          //console.log(nav);
        },
        err => {
          console.log(err);
        }
      );
    }
    this.dropZoneText = 'Drop Folder Here';
  }
  ngAfterViewInit() {
    console.log(this.siteForm);
    if (
      this.exportService.getXmlMetaDataObj().getValue() !== undefined ||
      this.exportService.getXmlMetaDataObj().getValue() !== null ||
      this.exportService.getXmlMetaDataObj().getValue() !== ''
    ) {
      var obj = this.exportService.getXmlMetaDataObj().getValue();
      var xml = new XmlParse(obj);
      var data = xml.schemaMatch(obj);
      this.changeDetector.detectChanges();
      this.xmlFileName = data['Product_Label'] + '_metadata.xml';
      setTimeout(() => {
        this.siteForm.controls['siteDescription'].setValue(
          data['Site_description']
        );
        this.siteForm.controls['siteName'].setValue(data['Product_Label']);
        this.uploadForm.controls['productDescription'].setValue(
          data['Product_Description']
        );
        var dateString = data['Product_Survey_Date'];
        var year = dateString.toString().substring(0, 4);
        var month = dateString.toString().substring(4, 6);
        var day = dateString.toString().substring(6, 8);

        if (moment(dateString, 'MM-DD-YYYY', true).isValid()) {
          //date is formatted as MM-DD-YYYY
          year = dateString.toString().substring(6, 10);
          month = dateString.toString().substring(0, 2);
          day = dateString.toString().substring(3, 5);
        }

        this.uploadForm.controls['surveyDate'].setValue(
          new Date(year, month - 1, day)
        );

        this.uploadForm.controls['surveyType'].setValue(
          data['Product_SurveyType']
        );
        this.uploadForm.controls['dataType'].setValue(data['Product_DataType']);

        var latlngCentroid = xml.calculateCentroid(obj);
        var latlngBounds = xml.calculateBounds(obj);
        this.calculatedCentroid = latlngCentroid;
        this.calculatedBounds = latlngBounds;
        this.confirmationForm.controls['latitude'].setValue(latlngCentroid.lat);
        this.confirmationForm.controls['longitude'].setValue(
          latlngCentroid.lng
        );
      }, 0);

      // confirmationForm.controls['metadataFileName'].setValue(
      //   this.metadataFile.name
      // );
      // var latlngCentroid = xml.calculateCentroid(result);
      // var latlngBounds = xml.calculateBounds(result);
      // this.calculatedCentroid = latlngCentroid;
      // this.calculatedBounds = latlngBounds;
      // confirmationForm.controls['latitude'].setValue(latlngCentroid.lat);
      // confirmationForm.controls['longitude'].setValue(latlngCentroid.lng);
    }
  }

  onChangeSelectedSites(selectedSites: any) {
    this.existingSite = true;
    this.sitesAdded = selectedSites;
  }

  initializeMap() {
    var myIcon = L.icon({
      iconUrl: '../../assets/img/marker-icon.png',
      iconSize: [24, 36],
      iconAnchor: [12, 36],
      popupAnchor: [-3, -76],
      shadowUrl: '../../assets/img/marker-shadow.png',
    });
    //define marker
    this.customMarker = L.marker([27, -99], {
      draggable: false,
      icon: myIcon,
      clickable: false,
    });

    this.loaded = true;
  }

  async metadataOnSubmit(uploadForm: NgForm, confirmationForm: NgForm) {
    //Create new XML variable from metadata

    this.hasSubmitMetadataForm = true;
    this.fileDropDiv = true;
    this.stepper.next();
    if (
      this.exportService.getXmlMetaData().getValue() !== undefined ||
      this.exportService.getXmlMetaData().getValue() !== null ||
      this.exportService.getXmlMetaData().getValue() !== ''
    ) {
      var savedXml = this.exportService.getXmlMetaData().getValue();
      var blob = new Blob([savedXml], {
        type: 'text/xml',
      });
      this.metadataFile = new File([blob], this.xmlFileName);
      setTimeout(() => {
        console.log(confirmationForm.controls);
        console.log(this.confirmationForm.controls);
        confirmationForm.controls['metadataFileName'].setValue(
          this.xmlFileName
        );
      }, 0);
    }
  }

  // Submit login form
  siteOnSubmit(siteForm: NgForm) {
    this.siteForm = siteForm;
    this.isNewSite = true;

    if (this.siteForm.valid) {
      this.stepper.next();
    }
  }

  // Submit upload form
  uploadOnSubmit(stepper: MatStepper, uploadForm: NgForm) {
    var scrubbedArray = [];

    if (this.productFilesArray.length == 0) {
      //LAS
    } else {
      //ORTHO
      for (var i = 0; i < this.productFilesArray.length; i++) {
        scrubbedArray.push(this.productFilesArray[i].name);
      }
      this.productDirectory = this.longest_common_starting_substring(
        scrubbedArray
      );
    }

    this.uploadForm = uploadForm;
    this.stepper.next();
  }

  // Select Product File
  selectProductFile(files: FileList) {
    // Checking to see if there are files
    if (files.length === 0) {
      return;
    }
    // Reset files in case user clicks it again
    this.productFile = null;
    this.productFileName = files[0].name;
    this.productFile = files[0];

    if (this.productFile.name.split('.').pop() == 'xml') {
      alert('.XML is not a valid product extension');
      this.productFile = null;
      this.productFileName = '';
    }
    console.log(this.productFile);
    // this.productDirectory = this.productFile.name.relativePath.substr(
    //   0,
    //   droppedFile.relativePath.indexOf('/')
    // );
    // if (
    //   this.productDirectory === null ||
    //   this.productDirectory === undefined ||
    //   this.productDirectory === ''
    // ) {
    //   alert(
    //     'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
    //   );
    // }
  }
  filesPicked(files, folder) {
    var scrubbedArray = [];
    for (var i = 0; i < files.length; i++) {
      scrubbedArray.push(files[i].name);
    }
    this.productDirectory = this.longest_common_starting_substring(
      scrubbedArray
    );
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.productFilesStringArray.push(file.name);
      this.productFilesArray.push(file);
      if (file.name.endsWith('.shp')) {
        //Store shapefile to send to server later
        this.shapeFile = file;
      } else if (file.name.endsWith('.dbf')) {
        this.DBF = file;
      } else if (file.name.endsWith('.prj')) {
        this.PRJ = file;
      }
      const path = file.webkitRelativePath.split('/');
      // upload file using path
      console.log(file);
      this.dropZoneText = this.productDirectory;
      if (
        this.productDirectory === null ||
        this.productDirectory === undefined ||
        this.productDirectory === ''
      ) {
        alert(
          'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
        );
      }
    }
  }
  async confirmationOnSubmit(
    stepper: MatStepper,
    siteForm: NgForm,
    uploadForm: NgForm,
    confirmationForm: NgForm
  ) {
    console.log(confirmationForm.controls['dataType'].value);
    console.log(confirmationForm.controls['surveyType'].value);

    this.showProgressBar = true;
    // Create our site
    if (this.siteIds.length !== 0) {
      this.createProductMultipleSites();
    } else {
      if (this.formValidation(confirmationForm) === false) {
        return;
      }
      var json = JSON.parse(JSON.stringify(confirmationForm.value));
      console.log(json);

      json.whoIssuedRoute = sessionStorage.getItem('email');
      json.sessionToken = sessionStorage.getItem('cookie');
      json.surveyType = +json.surveyType;
      json.dataType = +json.dataType;
      json.centroid = this.calculatedCentroid;
      json.geom = this.calculatedBounds;

      //  json.append('file', this.shapeFile,this.shapeFile.name);
      var jsonUser = {
        email: sessionStorage.getItem('email'),
        sessionToken: sessionStorage.getItem('cookie'),
        whoIssuedRoute: sessionStorage.getItem('email'),
      };
      this.userService.getUserType(jsonUser).subscribe(userTypeResult => {
        json.organization_id = userTypeResult[0].organization_id;
        this.sitesService.createSite(json).subscribe(
          site => {
            // Create a product once we have the site id
            var loggingData = new LoggingJson(this.loggingService);
            loggingData.user = sessionStorage.getItem('email');
            loggingData.action = LoggingJson.Actions.INSERT;
            loggingData.comments = LoggingJson.Comments.INSERT_SITE;
            loggingData.changes = site;
            loggingData.submit();

            // if (this.productFilesArray.length == 0)
            //  {
            //    this.createProduct(site);
            //  } else {
            this.createProductFromOrtho(site, confirmationForm);
            //  }
            // s3Service('lidar-app-files', `lidar-files/${this.file}`, this.file);
          },
          error => console.log(error)
        );
      });
    }
  }

  // Create a product on the db
  createProductFromOrtho(site: any, confirmationForm) {
    // Product file
    let uniqueId = uuid.v4();
    this.productUUID = uniqueId;
    let remotePath = '';

    if (
      this.calculatedBounds !== undefined &&
      this.calculatedCentroid !== undefined
    ) {
      for (var i = 0; i < this.surveyTypes.length; i++) {
        if (
          this.surveyTypes[i].survey_index_id.toString() ===
          confirmationForm.controls['surveyType'].value
        ) {
          this.productSurveyType = this.surveyTypes[i].survey_type;
        }
      }
      for (var i = 0; i < this.dataTypes.length; i++) {
        if (
          this.dataTypes[i].data_index_id.toString() ===
          confirmationForm.controls['dataType'].value
        ) {
          this.productDataType = this.dataTypes[i].data_type;
        }
      }
      var pathDate = moment(this.surveyDate).format('yyyy-mm-dd');
      remotePath = `lidar-files/${this.productSurveyType}/${this.productDataType}/${pathDate}/${this.productDirectory}/`;

      //Generate UUID's for each file
      var UUIDARRAY = [];
      for (var i = 0; i < this.productFilesArray.length; i++) {
        var generatedUUID = uuid.v4();
        UUIDARRAY.push(generatedUUID);
      }

      s3Service.s3Util
        .uploadDirectoryOfFiles(
          'lidar-app-uploads',
          remotePath,
          this.productFilesArray,
          null,
          uniqueId,
          UUIDARRAY
        )
        .then(productReturn => {
          console.log('RETURN!!!::');
          console.log(productReturn);
          //When this returns the product has been sucessfully uploaded
          let uniqueID = uuid.v4();
          this.metadataUUID = uniqueID;
          var pathDate = moment(this.surveyDate).format('yyyy-mm-dd');
          // Metadata file
          s3Service.s3Util
            .uploadFile(
              'lidar-app-uploads',
              `lidar-files/${this.productSurveyType}/${this.productDataType}/${pathDate}/${this.productDirectory}/${this.metadataFileName}`,
              this.metadataFile,
              null,
              uniqueID
            )
            .then(metadataReturn => {
              //When this returns the metadata has been sucessfully uploaded
              var jsonUser = {
                email: sessionStorage.getItem('email'),
                sessionToken: sessionStorage.getItem('cookie'),
                whoIssuedRoute: sessionStorage.getItem('email'),
              };
              this.userService
                .getUserType(jsonUser)
                .subscribe(userTypeResult => {
                  let formData = this.uploadForm.value;
                  formData.siteId = site.site_index_id;
                  formData.s3_path = this.s3Url + this.productDirectory;
                  formData.s3_path_metadata = this.s3Url + metadataReturn.Key;
                  formData.product_UUID = this.productUUID;
                  formData.product_metadata_UUID = this.metadataUUID;
                  formData.uuid_array = UUIDARRAY;

                  var json = JSON.parse(JSON.stringify(formData));
                  json.whoIssuedRoute = sessionStorage.getItem('email');
                  json.sessionToken = sessionStorage.getItem('cookie');
                  json.surveyType = +json.surveyType;
                  json.dataType = +json.dataType;
                  json.product_xml = this.productJsonXml;
                  json.centroid = this.calculatedCentroid;
                  json.geom = this.calculatedBounds;
                  json.files = this.productFilesStringArray;
                  json.organization_id = userTypeResult[0].organization_id;

                  json.productFileName = this.productDirectory; //+ "." + sessionStorage.getItem('email').substring(0, sessionStorage.getItem('email').lastIndexOf("@"));
                  // this.productService.createProductForSite(json).subscribe(
                  //   product => {
                  //     console.log(product);
                  //     // Establishing link
                  //     var loggingData = new LoggingJson(this.loggingService);
                  //     loggingData.user = sessionStorage.getItem('email');
                  //     loggingData.action = LoggingJson.Actions.INSERT;
                  //     loggingData.comments =
                  //       LoggingJson.Comments.INSERT_PRODUCT;
                  //     loggingData.changes = {
                  //       product_index_id: product.product_index_id,
                  //       product_label: product.product_label,
                  //     };
                  //     loggingData.submit();
                  //     console.log('product for site SUCCESSFUL');
                  //     this.createLinkSitesProducts(site, product);
                  //   },
                  //   error => console.log(error)
                  // );
                });
            });
        });
    } else {
      if (this.calculatedCentroid !== undefined) {
        alert(
          'Unable to calculate the centroid of the product. Please check the metadata file and try again.'
        );
      }
      if (this.calculatedBounds !== undefined) {
        alert(
          'Unable to calculate the bounds of the product. Please check the metadata file and try again.'
        );
      }

      this.showProgressBar = false;
    }
  }

  // Create a product on the db
  createProduct(site: any) {
    // Product file
    let uniqueId = uuid.v4();
    this.productUUID = uniqueId;
    if (
      this.calculatedBounds !== undefined &&
      this.calculatedCentroid !== undefined
    ) {
      let fileName = this.productFileName.substring(
        0,
        this.productFileName.length - 4
      );
      var pathDate = moment(this.surveyDate).format('yyyy-mm-dd');
      let remotePath = `lidar-files/${this.productSurveyType}/${this.productDataType}/${pathDate}/${fileName}/${this.productFileName}`;
      let remotePathMetaData = `lidar-files/${this.productSurveyType}/${this.productDataType}/${pathDate}/${fileName}/${this.metadataFileName}`;
      s3Service.s3Util
        .uploadFile(
          'lidar-app-uploads',
          remotePath,
          this.productFile,
          null,
          uniqueId
        )
        .then(productReturn => {
          //When this returns the product has been sucessfully uploaded
          let uniqueID = uuid.v4();
          this.metadataUUID = uniqueID;
          // Metadata file
          s3Service.s3Util
            .uploadFile(
              'lidar-app-uploads',
              remotePathMetaData,
              this.metadataFile,
              null,
              uniqueID
            )
            .then(metadataReturn => {
              //When this returns the metadata has been sucessfully uploaded

              let formData = this.uploadForm.value;
              formData.siteId = site.site_index_id;
              formData.s3_path = this.s3Url + productReturn.Key;
              formData.s3_path_metadata = this.s3Url + metadataReturn.Key;
              formData.product_UUID = this.productUUID;
              formData.product_metadata_UUID = this.metadataUUID;

              //get user organization to attach to product
              var jsonUser = {
                email: sessionStorage.getItem('email'),
                sessionToken: sessionStorage.getItem('cookie'),
                whoIssuedRoute: sessionStorage.getItem('email'),
              };
              this.userService
                .getUserType(jsonUser)
                .subscribe(userTypeResult => {
                  var json = JSON.parse(JSON.stringify(formData));
                  json.whoIssuedRoute = sessionStorage.getItem('email');
                  json.sessionToken = sessionStorage.getItem('cookie');
                  json.surveyType = +json.surveyType;
                  json.dataType = +json.dataType;
                  json.product_xml = this.productJsonXml;
                  json.centroid = this.calculatedCentroid;
                  json.geom = this.calculatedBounds;
                  json.files = this.productFilesStringArray;
                  json.organization_id = userTypeResult[0].organization_id;

                  // this.productService.createProductForSite(json).subscribe(
                  //   product => {
                  //     // Establishing link
                  //     var loggingData = new LoggingJson(this.loggingService);
                  //     loggingData.user = sessionStorage.getItem('email');
                  //     loggingData.action = LoggingJson.Actions.INSERT;
                  //     loggingData.comments =
                  //       LoggingJson.Comments.INSERT_PRODUCT;
                  //     loggingData.changes = {
                  //       product_index_id: product.product_index_id,
                  //       product_label: product.product_label,
                  //     };
                  //     loggingData.submit();
                  //     console.log('product for site SUCCESSFUL');
                  //     this.createLinkSitesProducts(site, product);
                  //   },
                  //   error => console.log(error)
                  // );
                });
            });
        });
    } else {
      if (this.calculatedCentroid !== undefined) {
        alert(
          'Unable to calculate the centroid of the product. Please check the metadata file and try again.'
        );
      }
      if (this.calculatedBounds !== undefined) {
        alert(
          'Unable to calculate the bounds of the product. Please check the metadata file and try again.'
        );
      }

      this.showProgressBar = false;
    }
  }

  uploadGeometryData() {
    console.log('HERE');
    const readerSF = new FileReader();
    const readerDBF = new FileReader();
    const readerPRJ = new FileReader();

    readerSF.readAsDataURL(this.shapeFile);

    readerSF.onload = () => {
      //this.fileservice.upload(this.shapeFile.name, readerSF.result.toString());

      readerDBF.readAsDataURL(this.DBF);
      readerDBF.onload = () => {
        readerPRJ.readAsDataURL(this.PRJ);
        {
          readerPRJ.onload = () => {
            var concatThreeFiles =
              readerSF.result.toString() +
              '-' +
              readerDBF.result.toString() +
              '-' +
              readerPRJ.result.toString();
            this.fileservice.upload(
              this.shapeFile.name,
              this.DBF.name,
              this.PRJ.name,
              concatThreeFiles.toString()
            );
          };
        }
      };
    };
  }

  // Connecting the site and product through a linking table on the db
  createLinkSitesProducts(site: any, product: any) {
    let siteProduct = {
      siteId: site.site_index_id,
      productId: product.product_index_id,
      whoIssuedRoute: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
    };

    this.testable = this.linkSitesProductsService
      .createLinkSiteProduct(siteProduct)
      .subscribe(
        async result => {
          alert('Product successfully created!');
          this.showProgressBar = false;
          if (this.productFilesArray.length == 0) {
            //LAS
          } else {
            //ortho
            console.log('UPLOADED');
            await this.uploadGeometryData();
            this.router.navigateByUrl('/');

            console.log('DONE');
          }

          //  window.location.reload();
        },
        error => console.log(error)
      );
  }
  ngOnDestroy() {
    try {
      this.testable.unsubscribe();
    } catch (e) {
      console.log('Unable to unsubscribe: ' + e);
    }
  }
  createProductMultipleSites() {
    // Product file
    let uniqueId = uuid.v4();
    this.productUUID = uniqueId;
    s3Service.s3Util
      .uploadFile(
        'lidar-app-uploads',
        `lidar-files/${this.productFileName}`,
        this.productFile,
        null,
        uniqueId
      )
      .then(productReturn => {
        //When this returns the product has been sucessfully uploaded
        let uniqueID = uuid.v4();
        this.metadataUUID = uniqueID;
        // Metadata file
        s3Service.s3Util
          .uploadFile(
            'lidar-app-uploads',
            `lidar-files/${this.metadataFileName}`,
            this.metadataFile,
            null,
            uniqueID
          )
          .then(metadataReturn => {
            let formData = this.uploadForm.value;
            formData.s3_path = this.s3Url + productReturn.Key;
            formData.s3_path_metadata = this.s3Url + metadataReturn.Key;
            formData.product_UUID = this.productUUID;
            formData.product_metadata_UUID = this.metadataUUID;

            var json = JSON.parse(JSON.stringify(formData));
            json.whoIssuedRoute = sessionStorage.getItem('email');
            json.sessionToken = sessionStorage.getItem('cookie');
            json.surveyType = +json.surveyType;
            json.dataType = +json.dataType;
            json.product_xml = this.productJsonXml;
            json.centroid = this.calculatedCentroid;
            json.geom = this.calculatedBounds;

            // this.productService
            //   .createProductForSite(json)
            //   .pipe(
            //     mergeMap((product: any) => {
            //       var jsoner = {
            //         productId: product.product_index_id,
            //         sites: this.siteIds,
            //         whoIssuedRoute: sessionStorage.getItem('email'),
            //         sessionToken: sessionStorage.getItem('cookie'),
            //       };

            //       return this.linkSitesProductsService.createMultipleLinksWithSites(
            //         jsoner
            //       );
            //     })
            //   )
            //   .subscribe(
            //     result => {
            //       alert('Product successfully created!');
            //       this.showProgressBar = false;
            //       window.location.reload();
            //     },
            //     error => console.log(error)
            //   );
          });
      });
  }
  onMapReady(map: L.Map) {
    this._map = map;
    map.removeControl(map.zoomControl);

    this.layers.push(this.customMarker);
    var markerBounds = [this.customMarker.getLatLng()];

    map.fitBounds(markerBounds);
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }

  onMapZoomEnd(map: L.Map) {}
  inputChanged(e) {
    if (this.siteLatitude >= -90 && this.siteLatitude <= 90) {
      if (this.siteLongitude >= -180 && this.siteLongitude <= 180) {
        this.customMarker.setLatLng(
          new L.LatLng(this.siteLatitude, this.siteLongitude)
        );
        var markerBounds = [this.customMarker.getLatLng()];

        this._map.fitBounds(markerBounds);
        this._map.setZoom(8);
        setTimeout(() => {
          this._map.invalidateSize();
        }, 100);
      }
    }
  }

  formValidation(confirmationForm: NgForm) {
    // Checking to make sure description is longer than 250
    if (confirmationForm.value.description.length < 10) {
      alert(
        'Cannot upload product, site Description has to be longer than at least 10 characters.'
      );
      this.showProgressBar = false;
      return false;
    }

    // Checking to make sure description isn't over 1000 characters
    if (confirmationForm.value.description.length > 1000) {
      alert(
        'Cannot upload product, site Description cannot be longer than 1000 characters.'
      );
      this.showProgressBar = false;
      return false;
    }

    // Checking to see if lat and long are correct
    if (
      confirmationForm.value.latitude < -90 ||
      confirmationForm.value.latitude > 90 ||
      confirmationForm.value.longitude < -180 ||
      confirmationForm.value.longitude > 180
    ) {
      alert(
        'Cannot upload product, latitude or longitude are not valid coordinates.'
      );
      this.showProgressBar = false;
      return false;
    }

    return true;
  }
}
