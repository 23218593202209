import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatCardModule,
} from '@angular/material';

import { AboutPageComponent } from './components/about-page.component';

@NgModule({
  declarations: [AboutPageComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    LeafletModule,
  ],
})
export class AboutPageModule {}
