import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) {}

  // file from event.target.files[0]
  uploadFile(files: FileList, siteId: number): Observable<HttpEvent<any>> {
    let formData = new FormData();
    formData.append('file', files[0], files[0].name);
    formData.set('enctype', 'multipart/form-data');

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `/api/data/products/${siteId}`,
      formData,
      options
    );
    return this.http.request(req);
  }
}
