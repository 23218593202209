import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { RouterModule } from '@angular/router';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
  MatProgressBarModule,
  MatIconModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatStepperModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatDialogModule,
  MatExpansionModule,
  MatTooltipModule,
  MatSortModule,
} from '@angular/material';

import { SitesService } from '../services/sites.service';
import { ProductsService } from '../services/products.service';
import { XmlCreatorComponent } from './components/xml-creator-page.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ComponentsModule } from '../components/components.module';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [XmlCreatorComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatCheckboxModule,
    ComponentsModule,
    MatButtonModule,
    MatDividerModule,
    LeafletModule,
    MatGridListModule,
    MatListModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    NgxUploaderModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatStepperModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSortModule,
  ],
  providers: [SitesService, ProductsService, DatePipe],
  entryComponents: [],
})
export class XmlCreatorModule {}
