import { Component, ChangeDetectorRef } from '@angular/core';
import { LinkSitesProductsService } from '../../services/link_sites_products.service';
import * as _ from 'lodash';
import { latLng, tileLayer, Map, polygon, layerGroup } from 'leaflet';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { decode } from 'punycode';
import { ProductsService } from '../../services/products.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

//Global Declarations

declare let L;
declare var require: any;
var mouseLat;
var mouseLon;
var geoJsonWithProperties;
const ctempLayers = [];
var HighlightIcon = L.Icon.extend({
  options: {
    iconUrl: '../../assets/img/marker-icon-highlight.png',
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [-3, -76],
    shadowUrl: '../../assets/img/marker-shadow.png',
  },
});
var StandardIcon = L.Icon.extend({
  options: {
    iconUrl: '../../assets/img/marker-icon.png',
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [-3, -76],
    shadowUrl: '../../assets/img/marker-shadow.png',
  },
});

@Component({
  selector: 'ortho-view-download-page',
  templateUrl: './ortho-view-download-page.component.html',
  styleUrls: ['./ortho-view-download-page.component.css'],
})
export class OrthoViewDownloadComponent {
  //Local variables
  productList = [];
  title = 'Blah';
  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    ],
    zoom: 6,
    center: latLng(26.72, -89.68),
  };
  selectedProduct;
  layers = [];
  dataLayers = [];
  jsonarray = [];
  tileLayerGroup = L.layerGroup();
  markers;
  hoverLayer: any;
  hoverLayerGroup: any;
  rectangleViewPortLayer: any;
  loaded: boolean = false;
  _map: Map;
  _container: any;
  _startLayerPoint: any;

  //Constructor
  constructor(
    private productservice: ProductsService,
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loaded = true;
  }
  ngAfterContentInit() {
    let string = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
    var productId = +string;
    var json = { productId: productId };

    console.log('JSON: ', json);

    this.productservice.getProduct(json).subscribe(product => {
      console.log(product);

      //Draw bounding box
      this.displayAllboundingBoxes(product[0]);
      //calculate tile bounding boxes

      //Populate download cart
      this.populateDownloadCart(json);

      this.generateLeafletRectangleControl(this._map);
      this.rectangleDrawEvent(this._map);
    });
  }
  populateDownloadCart(json) {
    //json is the product from the DB
    this.productservice.getFiles(json).subscribe(files => {
      this.calculateBoundingBoxEachTile(files);
      for (var i = 0; i < Object.keys(files).length; i++) {
        if (files[i].file_centroid !== null) {
          var copyOfFileArrayForDisplayPurposes = files[i];
          copyOfFileArrayForDisplayPurposes.file_centroid =
            parseFloat(JSON.parse(files[i].file_centroid).coordinates[1])
              .toPrecision(8)
              .toString() +
            ',' +
            parseFloat(JSON.parse(files[i].file_centroid).coordinates[0])
              .toPrecision(8)
              .toString();

          //  this.productList.push(copyOfFileArrayForDisplayPurposes);
        }
      }
    });
  }
  displayAllboundingBoxes(product) {
    var flag = true;
    if (this.hoverLayerGroup !== undefined)
      this._map.removeLayer(this.hoverLayerGroup);
    this.hoverLayerGroup = new L.layerGroup();

    flag = true;
    var geom = product.geom;
    var states = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: JSON.parse(geom).coordinates,
      },
    };
    var myLayer = L.geoJSON(states, {
      coordsToLatLng: function(coords) {
        return new L.LatLng(coords[1], coords[0]);
      },
    });
    this.hoverLayer = myLayer;
    this.hoverLayer.addTo(this._map);
    //this._map.fitBounds(this.hoverLayer.getBounds());
  }
  calculateBoundingBoxEachTile(files) {
    //Loop through file data sent from server
    for (var i = 0; i < files.length; i++) {
      //make sure each tile's geometry has been set
      if (files[i].file_geom !== null) {
        //build custom geojson
        geoJsonWithProperties = {
          type: JSON.parse(files[i].file_geom).type,
          coordinates: JSON.parse(files[i].file_geom).coordinates,
          properties: {
            name: files[i].filename,
            file: files[i],
          },
        };
        var THIS: OrthoViewDownloadComponent = this;
        //Create geoJSON layer using custom geojson from above
        var myGeoJSON = L.geoJSON(geoJsonWithProperties, {
          //Loop through each feature in the layer
          onEachFeature: function(feature, layer) {
            //Bind a tooltip to each polygon and define a custom propertie hierarchy called "definedProperties"
            layer.bindTooltip(feature.properties.name, { closeButton: false });

            var definedProperties = (layer.definedProperties =
              layer.definedProperties || {});
            definedProperties.type = definedProperties.type || 'Flag';
            var props = (definedProperties.properties =
              definedProperties.properties || {});

            //set definedProperties: property: _selected to false by default
            props._selected = false;

            //Each polygon needs mouse events to display tooltip and highlight
            layer.on('mouseover', function(e) {
              this.openPopup();
              //If the polygon is already selected keep it the selected color
              if (layer.definedProperties.properties._selected == true)
                this.setStyle({
                  fillColor: '#fff700',
                });
              //If the polygon is not selected keep it the highlight color
              else if (layer.definedProperties.properties._selected != true)
                this.setStyle({
                  fillColor: '#0000ff',
                });
            });

            //Mouse Out
            layer.on('mouseout', function(e) {
              this.closePopup();
              //If the polygon is not selected set to default color
              if (layer.definedProperties.properties._selected != true)
                this.setStyle({
                  fillColor: '#3388ff',
                });
            });

            //Add/remove download cart on click
            layer.on('click', function() {
              //Set color to selected
              this.setStyle({
                fillColor: '#fff700',
              });

              //Check if already exists in cart
              if (layer.definedProperties.properties._selected == true) {
                //set to default color
                this.setStyle({
                  fillColor: '#3388ff',
                });

                //unselect and remove from list
                layer.definedProperties.properties._selected = false;
                feature.properties.checked = false;
                THIS.productList.splice(
                  THIS.productList.indexOf(feature.properties),
                  1
                );
                THIS.changeDetector.detectChanges();
              } else {
                //add to list
                layer.definedProperties.properties._selected = true;
                feature.properties.checked = true;

                THIS.productList.push(feature.properties);
                THIS.changeDetector.detectChanges();
              }
              THIS.changeDetector.detectChanges();
            });
          },
        });

        this.tileLayerGroup.addLayer(myGeoJSON);
      }
    }
    this._map.addLayer(this.tileLayerGroup);
    //this._map.fitBounds(layerGroup.getBounds());
  }

  checkBoxChangeEvent(product) {
    this.productList.splice(this.productList.indexOf(product), 1);
    this.changeDetector.detectChanges();
    console.log(this.productList);
  }
  displayBoundingBox(product) {
    // //When hovering over it should display the bounding box of the current tile
    // var json = JSON.parse(product.geom);
    // var states = {
    //   type: 'Feature',
    //   properties: { party: 'Republican' },
    //   geometry: {
    //     type: 'Polygon',
    //     coordinates: json.coordinates,
    //   },
    // };
    // var myLayer = L.geoJSON(states, {
    //   coordsToLatLng: function(coords) {
    //     //                    latitude , longitude, altitude
    //     //return new L.LatLng(coords[1], coords[0], coords[2]); //Normal behavior
    //     return new L.LatLng(coords[1], coords[0]);
    //   },
    // });
    // this.hoverLayer = myLayer;
    // this.hoverLayer.addTo(this._map);
    // // var geometry = wkx.Geometry.parse(product.geom);
    // // console.log(geometry);
    // //   this.hoverPolygon = L.polygon([
    // //     [51.509, -0.08],
    // //     [51.503, -0.06],
    // //     [51.51, -0.047]
    // // ]);
    // // this.hoverPolygon.addTo(this._map);
  }
  removeAllBoundingBox(map, viewportlayer) {
    map.removeLayer(this.hoverLayerGroup);
    map.removeLayer(viewportlayer);
  }
  removeBoundingBox(product) {
    this._map.removeLayer(this.hoverLayer);
  }
  generateLeafletRectangleControl(map) {
    //Define leaflet draw control
    new L.Control.Draw({
      draw: {
        marker: false,
        // polygon: {
        //   shapeOptions: {
        //     color: '#ffff00',
        //   },
        // },
        polygon: false,
        polyline: false,
        rectangle: {
          shapeOptions: {
            color: '#ffff00',
          },
        },
        circle: false,
        circlemarker: false,
      },
      edit: false,
    }).addTo(map);
  }
  rectangleDrawEvent(map) {
    var drawnBounds;
    var THIS: OrthoViewDownloadComponent = this;

    //Set rectangle bounds on draw create
    map.on(L.Draw.Event.CREATED, function(e: any) {
      //Define the bounds of the rectangle drawn on the map

      //When we draw on the map
      if (THIS.rectangleViewPortLayer !== undefined)
        map.removeLayer(THIS.rectangleViewPortLayer);

      //Get bounds of drawn rectangle
      var topleft = e.layer.getBounds()._northEast;
      var bottomright = e.layer.getBounds()._southWest;
      //sets the rectangle bounds locally
      drawnBounds = new L.LatLngBounds(topleft, bottomright);
    });

    //Calculate once draw is complete
    map.on(L.Draw.Event.DRAWSTOP, function(e: any) {
      //The following section of code ADDS a visual rectangle to the map
      //************************************************************* */
      // this.rectangleViewPortLayer = new L.geoJSON();
      // var rectangle = L.rectangle(bounds, {
      //   color: '#ff7800',
      //   weight: 1,
      // }).addTo(this.rectangleViewPortLayer);
      // this.rectangleViewPortLayer.addTo(map);
      //************************************************************* */

      //Loop through each shapefile polygon layer
      THIS.tileLayerGroup.eachLayer(function(layer) {
        //Loop through each feature in the layer
        layer.eachLayer(function(feature) {
          //check if the drawn rectangle (calcualted above) intersects the visual polygon feature
          if (drawnBounds.intersects(layer.getBounds())) {
            //If the polygon (tile) has already been selected
            if (feature.definedProperties.properties._selected == true) {
              //  //remove from list
              //  layer.setStyle({
              //   'fillColor': '#3388ff'
              // });
              //  feature.definedProperties.properties._selected = false;
              //  THIS.productList.splice(THIS.productList.indexOf(feature.feature.geometry.properties.file),1);
              //  THIS.changeDetector.detectChanges();
            } else {
              //add to list
              feature.definedProperties.properties._selected = true;
              layer.setStyle({
                fillColor: '#fff700',
              });
              feature.feature.geometry.properties.checked = true;
              THIS.productList.push(feature.feature.geometry.properties);
              THIS.changeDetector.detectChanges();
            }
          }
        });
      });
    });
  }
  onMapReady(map: L.Map) {
    // //Define constraint on rectangle (not used)
    // L.Rectangle.include({
    //   contains: function(latLng) {
    //     return this.getBounds().contains(latLng);
    //   },
    // });

    //Change the scoping

    //Draw event fired when toolbar selected

    //Once map is loaded add text control and custom box select control to map
    this._map = map;

    this._container = map.getContainer();
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
    var popup = L.popup().setContent(
      '<p>Hello world!<br />This is a nice popup.</p>'
    );
    map.on('contextmenu', function() {
      //THIS.removeAllBoundingBox(map, this.rectangleViewPortLayer);
    });
  }

  onMapZoomEnd(map) {
    //after zoom event, restructure the product list
  }

  //When a product is selected (clicked) in the visible products
  //This function navigates to the product on the ALL-DATA page.
  onSelect(product): void {
    console.log(product);
    //selecting a product will take you to that product page
    this.selectedProduct = product;
    var latlngArray = product.file_centroid.split(',');
    var latln = L.latLng(latlngArray[0], latlngArray[1]);
    this._map.setView(latln, 16);
    // Calculate the offset
    // var offset = this._map.getSize().x*-0.10;
    // // Then move the map
    // this._map.panBy(new L.Point(-offset, 0), {animate: false});
  }
  centerOnTile(product) {}

  onCheck(product): void {
    //selecting a product will take you to that product page
    console.log(this.tileLayerGroup);
    this.tileLayerGroup.eachLayer(function(tlLayer) {
      tlLayer.eachLayer(function(feature) {
        if (feature.feature.geometry.properties.name == product.filename) {
          //names match
          console.log('Matched Name');
          console.log(product);
        }
      });
    });

    //Loop through each feature in the layer

    //     //Set color to selected
    //   this.setStyle({
    //     'fillColor': '#ff0000'
    //   });

    //   //Check if already exists in cart
    //   if(layer.definedProperties.properties._selected == true)
    //   {
    //     //set to default color
    //     this.setStyle({
    //       'fillColor': '#3388ff'
    //     });
    //     //unselect and remove from list
    //     layer.definedProperties.properties._selected = false;
    //     THIS.productList.splice(THIS.productList.indexOf(feature.properties.file),1);
    //     THIS.changeDetector.detectChanges();
    //   }
    //   else
    //   {
    //     //add to list
    //     layer.definedProperties.properties._selected = true;
    //     THIS.productList.push(feature.properties.file);
    //     THIS.changeDetector.detectChanges();
    //   }

    // });
  }
  downloadFiles() {
    document.getElementById('abc').style.display = 'block';
  }
  checkForLoggedIn(product): void {
    //Check if user is logged in
    //Prompt User to login / create account
  }
}
