import { Component, OnInit, Input } from '@angular/core';
import { SitesService } from '../../services/sites.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.css'],
})
export class SiteTableComponent implements OnInit {
  @Input() sites: any;
  columnsToDisplay = [];
  constructor(private sitesService: SitesService) {}

  ngOnInit() {
    if (this.sites === null || this.sites === undefined) {
      console.log('No sites recieved from parent component');
      //this.getSites();
    }
  }

  /**
   * (Used to get sites from the database)
   *
   * @memberof SiteTableComponent
   */
  getSites() {
    this.sitesService
      .getOnlySites()
      .pipe(take(1))
      .subscribe(
        sites => {
          this.sites = sites;
          //console.log(this.sites);
        },
        error => {
          console.log(error);
        }
      );
  }
}
