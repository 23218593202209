import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TriggerService {
  constructor() {}
  private _trigger = new BehaviorSubject<string>('');
  private _triggerPushpinHoverEvent = new BehaviorSubject<any>('');
  private _triggerProductHoverEvent = new BehaviorSubject<any>('');
  private _triggerProductLeaveEvent = new BehaviorSubject<any>('');
  private _triggerFiltersApplied = new BehaviorSubject<string>('');
  private _triggerApplyProductFilter = new BehaviorSubject<string>('');
  private _triggerOpenDialog = new BehaviorSubject<string>('');
  private _triggerDownloadProducts = new BehaviorSubject<string>('');
  private _triggetSortingApplied = new BehaviorSubject<string>('');
  private _triggerResetProductCounter = new BehaviorSubject<string>('');
  private _triggerNotificationColorBar = new BehaviorSubject<string>('');

  //Cast trigger triggers geographic search by default
  castTrigger = this._trigger.asObservable();
  //Triggers the product list item to highligh when hovering over pushpin
  listenForPushpinMouseOver = this._triggerPushpinHoverEvent.asObservable();
  //Indicates the state if filteres have been applied to the map
  listenForFiltersApplied = this._triggerFiltersApplied.asObservable();
  //Triggers mouse over hover event to color the pushpin if the product is hovered over
  listenForProductMouseOver = this._triggerProductHoverEvent.asObservable();
  //Triggers the mouse out event to change the color of the pushpin back to standard
  listenForProductMouseOut = this._triggerProductLeaveEvent.asObservable();
  //Product list listens for landing-page to tell it to apply filter on map move.
  listenForApplyFilterInstruction = this._triggerApplyProductFilter.asObservable();
  //Product list listens for landing-page to tell it to call the openDialog function
  listenForOpenDialogInstruction = this._triggerOpenDialog.asObservable();
  //search-menu-view-chart listens for landing-page to tell it to call the downloadProducts function
  listenForDownloadProductsInstruction = this._triggerDownloadProducts.asObservable();
  listenForApplySortingInstruction = this._triggetSortingApplied.asObservable();
  //listener defined in product list listens for landing-page to tell it to reset the product-counter typically on mapmoveend
  listenForResetProductCounter = this._triggerResetProductCounter.asObservable();
  //Listens for landing page to tell the product-list to display the notificationColorBar
  listenForNotificationColorBar = this._triggerNotificationColorBar.asObservable();

  trigger() {
    this._trigger.next('');
  }
  triggerPushpinHoverEvent(data) {
    this._triggerPushpinHoverEvent.next(data);
  }
  triggerProductHoverEvent(data) {
    this._triggerProductHoverEvent.next(data);
  }
  triggerProductLeaveEvent(data) {
    this._triggerProductLeaveEvent.next(data);
  }

  triggerFiltersApplied(data) {
    this._triggerFiltersApplied.next(data);
  }

  triggerApplyFilterInstruction(data) {
    //console.log(data);
    this._triggerApplyProductFilter.next(data);
    data = undefined;
  }
  triggerOpenDialogInstruction(data) {
    //console.log(data);
    this._triggerOpenDialog.next(data);
  }
  triggerDownloadProductsInstruction(data) {
    //console.log(data);
    this._triggerDownloadProducts.next(data);
  }
  triggerApplySortingInstruction(data) {
    this._triggetSortingApplied.next(data);
  }
  triggerResetProductCounter(data) {
    this._triggerResetProductCounter.next(data);
  }
  triggerNotificationColorBar(data) {
    this._triggerNotificationColorBar.next(data);
  }
  // sendPushpinId()
  // {

  // }
}
