import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
1;
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  MatCardModule,
  MatTableModule,
  MatTabsModule,
  MatButtonModule,
} from '@angular/material';

import { MatSortModule } from '@angular/material';
import {
  AllDataComponent,
  LoadableMapComponent,
} from './components/all-data.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

@NgModule({
  declarations: [AllDataComponent, LoadableMapComponent],
  imports: [
    MatSortModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatExpansionModule,
    RouterModule,
    MatTableModule,
    MatCardModule,
    LeafletModule,
    MatTabsModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [MatSortModule, MatInputModule, MatFormFieldModule],
  providers: [],
  entryComponents: [],
})
// change
export class AllDataModule {}
