import { LatLngBounds, latLng } from 'leaflet';
import { Alert } from 'selenium-webdriver';
declare var require: any;
var parser = require('fast-xml-parser');
var he = require('he');
export class XmlParse {
  MatchedJsonSchema: object = {};
  MetaData: any;
  JsonData: any;
  options = {
    attributeNamePrefix: '@_',
    attrNodeName: 'attr', //default is 'false'
    textNodeName: '#text',
    ignoreAttributes: true,
    ignoreNameSpace: false,
    allowBooleanAttributes: false,
    parseNodeValue: true,
    parseAttributeValue: false,
    trimValues: true,
    cdataTagName: '__cdata', //default is 'false'
    cdataPositionChar: '\\c',
    localeRange: '', //To support non english character in tag/attribute values.
    parseTrueNumberOnly: false,
    arrayMode: false, //"strict"S
    attrValueProcessor: (val, attrName) =>
      he.decode(val, { isAttributeValue: true }), //default is a=>a
    tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
    stopNodes: ['parse-me-as-string'],
  };
  constructor(obj) {
    this.MetaData = obj;
    // return new Promise((resolve, reject) =>
    // {
    //     this.parse(this.MetaData).then(result =>
    //         {
    //         var bar = this.schemaMatch(result);
    //         console.log(bar);
    //         return "HELLO!!";
    //         });
    // });
  }

  getMetaData() {
    return this.JsonData;
  }
  async parse() {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        var result = parser.validate(reader.result);
        if (result !== true) {
          console.log(result.err);
        }
        var jsonObj = parser.parse(reader.result, this.options);
        this.JsonData = jsonObj;
        resolve(jsonObj);
      };
      reader.readAsText(this.MetaData);
    });
  }

  //getNested will return the value at the reduced object level
  // ie: Level1:{Level2:{Level3}}
  // if Level3 exist it will return the value
  getNested(obj, ...args) {
    if (args.reduce((obj, level) => obj && obj[level], obj) == '') {
      console.log('Found Blank Value In XML');
      return undefined;
    } else {
      return args.reduce((obj, level) => obj && obj[level], obj);
    }
  }
  schemaMatch(json) {
    //Site Matching
    //Site Name = Placekey
    //Site Description = indspref
    console.log(json);
    console.log(typeof json);

    //SITE DESCRIPTION
    console.log(this.getNested(json, 'metadata', 'spdoinfo', 'indspref'));
    var errorArray = [];
    var tempJson = {};
    errorArray.push('ERROR');
    if (
      this.getNested(json, 'metadata', 'spdoinfo', 'indspref') !== undefined
    ) {
      this.MatchedJsonSchema['Site_description'] =
        json.metadata.spdoinfo.indspref;
    } else {
      tempJson = {
        Site_Description: 'metadata.spdoinfo.indspref',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT LABEL
    if (
      this.getNested(
        json,
        'metadata',
        'idinfo',
        'citation',
        'citeinfo',
        'title'
      ) !== undefined
    ) {
      this.MatchedJsonSchema['Product_Label'] =
        json.metadata.idinfo.citation.citeinfo.title;
    } else {
      tempJson = {
        Product_Label: 'metadata.idinfo.citation.citeinfo.title',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT DESCRIPTION
    if (
      this.getNested(json, 'metadata', 'idinfo', 'descript', 'abstract') !==
      undefined
    ) {
      this.MatchedJsonSchema['Product_Description'] =
        json.metadata.idinfo.descript.abstract;
    } else {
      tempJson = {
        Product_Description: 'metadata.idinfo.descript.abstract',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT SURVEY DATE
    if (
      this.getNested(
        json,
        'metadata',
        'idinfo',
        'timeperd',
        'timeinfo',
        'rngdates',
        'begdate'
      ) !== undefined
    ) {
      this.MatchedJsonSchema['Product_Survey_Date'] =
        json.metadata.idinfo.timeperd.timeinfo.rngdates.begdate;
    } else {
      tempJson = {
        Product_Survey_Date:
          'metadata.idinfo.timeperd.timeinfo.rngdates.begdate',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT GEOMETRY
    if (
      this.getNested(
        json,
        'metadata',
        'idinfo',
        'spdom',
        'bounding',
        'westbc'
      ) !== undefined &&
      this.getNested(
        json,
        'metadata',
        'idinfo',
        'spdom',
        'bounding',
        'eastbc'
      ) !== undefined &&
      this.getNested(
        json,
        'metadata',
        'idinfo',
        'spdom',
        'bounding',
        'northbc'
      ) !== undefined &&
      this.getNested(
        json,
        'metadata',
        'idinfo',
        'spdom',
        'bounding',
        'southbc'
      ) !== undefined
    ) {
      this.MatchedJsonSchema['Product_Geometry'] = [
        json.metadata.idinfo.spdom.bounding.westbc,
        json.metadata.idinfo.spdom.bounding.eastbc,
        json.metadata.idinfo.spdom.bounding.northbc,
        json.metadata.idinfo.spdom.bounding.southbc,
      ];
    } else {
      tempJson = {
        Product_Geometry: 'metadata.idinfo.spdom.bounding',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT SURVEY TYPE
    if (
      this.getNested(json, 'metadata', 'idinfo', 'descript', 'supplinf') !==
      undefined
    ) {
      this.MatchedJsonSchema['Product_SurveyType'] =
        json.metadata.idinfo.descript.supplinf;
    } else {
      tempJson = {
        Product_SurveyType: 'metadata.idinfo.descript.supplinf',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT DATA TYPE
    if (this.getNested(json, 'metadata', 'spdoinfo', 'direct') !== undefined) {
      this.MatchedJsonSchema['Product_DataType'] =
        json.metadata.spdoinfo.direct;
    } else {
      tempJson = {
        Product_DataType: 'metadata.spdoinfo.direct',
      };
      errorArray.push(tempJson);
    }
    //PRODUCT FILE TYPE
    console.log(
      this.getNested(
        json,
        'metadata',
        'distinfo',
        'stdorder',
        'digform',
        'digtinfo',
        'formname'
      )
    );
    if (
      this.getNested(
        json,
        'metadata',
        'distinfo',
        'stdorder',
        'digform',
        'digtinfo',
        'formname'
      ) !== undefined
    ) {
      this.MatchedJsonSchema['Product_FileType'] =
        json.metadata.distinfo.stdorder.digform.digtinfo.formname;
    } else {
      tempJson = {
        Product_FileType:
          'metadata.distinfo.stdorder.digform.digtinfo.formname',
      };
      errorArray.push(tempJson);
    }

    this.MatchedJsonSchema['Site_Location'] = {
      Latitude: this.calculateCentroid(json).lat,
      Longitude: this.calculateCentroid(json).lng,
    };
    this.MatchedJsonSchema['Product_Centroid'] = this.calculateCentroid(json);

    //Product Matching

    console.log(errorArray);
    if (errorArray.length > 1) {
      return errorArray;
    } else {
      let returnData = this.MatchedJsonSchema;
      return returnData;
    }
  }

  calculateCentroid(json) {
    console.log(json);
    var NW = {
      lat: json.metadata.idinfo.spdom.bounding.northbc,
      lon: json.metadata.idinfo.spdom.bounding.westbc,
    };
    var NE = {
      lat: json.metadata.idinfo.spdom.bounding.northbc,
      lon: json.metadata.idinfo.spdom.bounding.eastbc,
    };
    var SW = {
      lat: json.metadata.idinfo.spdom.bounding.southbc,
      lon: json.metadata.idinfo.spdom.bounding.westbc,
    };
    var SE = {
      lat: json.metadata.idinfo.spdom.bounding.southbc,
      lon: json.metadata.idinfo.spdom.bounding.eastbc,
    };
    var points = [NW, NE, SW, SE];

    var bounds = new LatLngBounds(
      latLng(SW.lat, SW.lon),
      latLng(NE.lat, NE.lon)
    );
    return bounds.getCenter();
  }
  calculateBounds(json) {
    var NW = {
      lat: json.metadata.idinfo.spdom.bounding.northbc,
      lon: json.metadata.idinfo.spdom.bounding.westbc,
    };
    var NE = {
      lat: json.metadata.idinfo.spdom.bounding.northbc,
      lon: json.metadata.idinfo.spdom.bounding.eastbc,
    };
    var SW = {
      lat: json.metadata.idinfo.spdom.bounding.southbc,
      lon: json.metadata.idinfo.spdom.bounding.westbc,
    };
    var SE = {
      lat: json.metadata.idinfo.spdom.bounding.southbc,
      lon: json.metadata.idinfo.spdom.bounding.eastbc,
    };
    var points = [NW, NE, SW, SE];

    var bounds = new LatLngBounds(
      latLng(SW.lat, SW.lon),
      latLng(NE.lat, NE.lon)
    );
    var boundsJson = {
      southWest: bounds.getSouthWest(),
      northEast: bounds.getNorthEast(),
    };
    return boundsJson;
  }
}
