import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatCardModule,
} from '@angular/material';

import { LogoutPageComponent } from './components/logout-page.component';

@NgModule({
  declarations: [LogoutPageComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    LeafletModule,
  ],
})
export class LogoutPageModule {}
