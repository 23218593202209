import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
} from '@angular/material';

import { SitesService } from '../services/sites.service';
import { ProductsService } from '../services/products.service';
import { ComponentsModule } from '../components/components.module';
import { ProductViewComponent } from './components/product-view/product-view.component';

@NgModule({
  declarations: [ProductViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    MatButtonModule,
    MatDividerModule,
    MatGridListModule,
    MatListModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [SitesService, ProductsService],
})
export class ProductsModule {}
