import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CameraControl } from '../../../../lib/lidar-reader/CameraControl';
import { XYZRenderer } from '../../../../lib/lidar-reader/XYZRenderer';
import { vertexShader } from '../../../../lib/lidar-reader/constants/vertexShader';
import { fragmentShader } from '../../../../lib/lidar-reader/constants/fragmentShader';
import { SocketService } from '../../services/socket.service';
import { ProductsService } from '../../services/products.service';
import * as THREE from 'three';
import { ConvexGeometry } from 'three';
import { times, map, chunk } from 'lodash';
import CameraControls from 'camera-controls';
const WIDTH = 500;
const HEIGHT = 500;
const VIEW_ANGLE = 45;
const ASPECT = WIDTH / HEIGHT;
const NEAR = 0.1;
const FAR = 100;
let test: number = 1;
CameraControls.install({ THREE: THREE });

@Component({
  selector: 'app-point-cloud-viewer',
  templateUrl: './point-cloud-viewer.component.html',
  styleUrls: ['./point-cloud-viewer.component.css'],
})
export class PointCloudViewerComponent implements OnInit {
  renderer;
  camera;
  render;
  animate;
  cameraControl;
  simpleCamera;
  controls;
  simpleCamrea;
  xyzRenderer;
  ioConnection: any;
  cameraControls;
  isZoomed: boolean = false;
  @Input() scene;
  @Input() particleBatcher;
  @Input() productId;

  constructor(
    private socketService: SocketService,
    private productService: ProductsService
  ) {}

  ngOnInit() {
    //get the bytes before we can get the service
    this.initIoConnection();
    const renderContainer = '#scene';
    const container = document.querySelector(renderContainer);
    const h = container.clientHeight;
    const w = container.clientWidth;
    this.scene = new THREE.Scene();
    //this.scene.add(light);

    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.scene.background = new THREE.Color(0x000000);
    container.appendChild(this.renderer.domElement);
    const clock = new THREE.Clock();
    this.simpleCamera = new THREE.PerspectiveCamera(60, w / h, 0.1, 1000000);

    this.renderer.setSize(w, h);

    const lambertMat = new THREE.MeshLambertMaterial({
      color: 0xcc0000,
    });

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, material);
    const sphere = new THREE.Mesh(
      new THREE.SphereGeometry(50, 16, 16),
      lambertMat
    );

    sphere.position.z = 0;
    //this.scene.add(cube);
    this.simpleCamera.position.z = 5;

    const pointLight = new THREE.PointLight(0xffffff);
    pointLight.position.x = 10;
    pointLight.position.y = 50;
    pointLight.position.z = 130;

    const ambientLight = new THREE.AmbientLight(0xffffff);
    this.scene.add(ambientLight);
    this.scene.add(this.simpleCamera);

    this.simpleCamera.lookAt(sphere.position);
    this.renderer.render(this.scene, this.simpleCamera);

    this.cameraControls = new CameraControls(
      this.simpleCamera,
      this.renderer.domElement
    );

    this.render = () => {
      this.renderer.render(this.scene, this.simpleCamera);
    };

    this.animate = () => {
      const delta = clock.getDelta();

      const isControlsUpdated = this.cameraControls.update(delta);
      requestAnimationFrame(this.animate);

      if (isControlsUpdated) {
        //console.log('rendering')
        this.renderer.render(this.scene, this.simpleCamera);
      }
    };
    this.animate();
  }

  hello() {
    console.log('hi');
    //console.log(this.cameraControls.target);
    //// console.log(this.simpleCamera.position);
    const data = {
      cameraTarget: this.cameraControls.target,
      cameraPosition: this.simpleCamera.position,
    };
    this.productService
      .updateProductCameraPosition(this.productId, data)
      .subscribe(
        result => console.log(result),
        error => console.log(error),
        () => console.log('SOME SORT OF TEXT IN THERE')
      );
  }

  async initIoConnection() {
    const bytes = await this.productService
      .getProductBytes(this.productId)
      .toPromise();
    console.log('bytes', bytes);
    this.socketService.initSocket().subscribe(() => {
      //done initializing
      console.log('socket initted');
      const reductionFactor = 10;
      const chunks = Math.floor(parseInt(bytes) / 3048);
      console.log('we have this many chunkgs', chunks);
      //we don't neeed to send tons of messages. Just invoke the lambda once and listen for events
      this.socketService.send(
        `{"action": "readpoints", "data": { "product_id": ${this.productId}}}`
      );
    });

    this.ioConnection = this.socketService.onMessage().subscribe(message => {
      //send message for all chunks - normalize?
      //process and then add particlesystem
      const attributes = {
        color: {
          type: 'c',
          value: null,
        },
        intensity: {
          type: 'f',
          value: null,
        },
        classification: {
          type: 'f',
          value: null,
        },
      };

      let particleGeometry = new THREE.BufferGeometry({ attributes });

      const points = JSON.parse(message.data);
      if (!points.forEach) {
        console.log('invalid', message);
      }

      //make a particle system from the geometry and add to the scene
      let positions = [];
      let colors = [];
      let intensities = [];
      const scale = 1000;

      points.forEach(point => {
        positions = [
          ...positions,
          point.x * scale,
          point.y * scale,
          point.z * scale,
        ];

        colors = [
          ...colors,
          point.R / 255 / 100,
          point.G / 255 / 100,
          point.B / 255 / 100,
        ];

        const intenseColor = parseInt(point.i) / 1000;
        // console.log(intenseColor);
        intensities = [...intensities, intenseColor];
      });

      particleGeometry.addAttribute(
        'position',
        new THREE.Float32BufferAttribute(positions, 3)
      );
      particleGeometry.addAttribute(
        'color',
        new THREE.Float32BufferAttribute(colors, 3)
      );
      particleGeometry.addAttribute(
        'intensity',
        new THREE.Float32BufferAttribute(intensities, 1)
      );
      const simpleParticleMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.5,
      });

      const complexPointMaterial = getComplexMaterial();

      const particleSystem = new THREE.Points(
        particleGeometry,
        //simpleParticleMaterial,
        complexPointMaterial
      );

      //debug code to check only 1 partical
      // if(test == 1)
      // {
      //   console.log(particleGeometry.attributes.position);
      //   // console.log(complexPointMaterial);
      // }

      this.scene.add(particleSystem);

      if (!this.isZoomed) {
        this.cameraControls.fitTo(particleSystem);

        this.isZoomed = true;
      }
    });
  }
}

function getComplexMaterial() {
  const uniforms = {
    pointSize: { type: 'f', value: 1 },
    intensityBlend: { type: 'f', value: 50 },
    maxColorComponent: { type: 'f', value: 255 },

    // colors
    rgb_f: { type: 'f', value: 1.0 },
    class_f: { type: 'f', value: 0.0 },
    map_f: { type: 'f', value: 0.0 },
    imap_f: { type: 'f', value: 0.0 },

    // intensity
    intensity_f: { type: 'f', value: 1.0 },
    height_f: { type: 'f', value: 0.0 },
    iheight_f: { type: 'f', value: 0.0 },

    xyzScale: { type: 'v3', value: new THREE.Vector3(1, 1, 1) },

    clampLower: { type: 'f', value: 0 },
    clampHigher: { type: 'f', value: 255 },

    colorClampLower: { type: 'f', value: 0 },
    colorClampHigher: { type: 'f', value: 255 },

    zrange: { type: 'v2', value: new THREE.Vector2(0, 0) },
    offsets: { type: 'v3', value: new THREE.Vector3(0, 0, 0) },
    klassRange: { type: 'v2', value: new THREE.Vector2(0, 0) },

    //clipping controls
    do_plane_clipping: { type: 'i', value: 0 },
    planes: {
      type: 'v4v',
      value: times(6, function() {
        return new THREE.Vector4();
      }),
    },
  };

  return new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms,
  });
}
