import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { LinkSitesProductsService } from '../../services/link_sites_products.service';
import * as _ from 'lodash';
import { latLng, tileLayer, Map, polygon, layerGroup, map } from 'leaflet';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { decode } from 'punycode';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SearchMenuGeoSearchComponent } from '../../components/search-menu-geo-search/search-menu-geo-search.component';
import { ProductsService } from '../../services/products.service';
import { JoyrideService } from 'ngx-joyride';
import { TriggerService } from '../../services/trigger.service';
import { ProductListComponent } from '../../components/product-list/product-list.component';
import * as moment from 'moment';
import * as underscore from 'underscore';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import 'leaflet-easybutton/src/easy-button';
import { Console } from 'console';
import { OrganizationsService } from '../../services/organizations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { C } from '@angular/cdk/keycodes';
import { _getComponentHostLElementNode } from '@angular/core/src/render3/instructions';

// import statesData from '../../assets/leaflet/geojson/us-states.json'
//Global Declarations
declare let L;
declare var require: any;
var statesData = require('../../assets/leaflet/geojson/us-states.json');
/// <reference types='leaflet-sidebar-v2' />

const globalMarkerDataLayers = [];
var HighlightIcon = L.Icon.extend({
  options: {
    iconUrl: '../../assets/img/marker-icon-highlight.png',
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [-3, -76],
    shadowUrl: '../../assets/img/marker-shadow.png',
  },
});
var StandardIcon = L.Icon.extend({
  options: {
    iconUrl: '../../assets/img/marker-icon.png',
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [-3, -76],
    shadowUrl: '../../assets/img/marker-shadow.png',
  },
});
export interface DialogData {
  response: string;
}
//console.log = function() {};
@Component({
  selector: 'dialog-tour-overview',
  templateUrl: 'dialog-tour-overview.html',
})
export class DialogTourOverview {
  response: string;
  constructor(
    public dialogRef: MatDialogRef<DialogTourOverview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    let wantsTour = sessionStorage.getItem('wantsTour');
    console.log(wantsTour);
    //If visited is set, the user has been to the website in this session
    if (wantsTour != null) {
    } else {
      //set visited to true and show tutorial modal
      sessionStorage.setItem('wantsTour', 'false');
      // make sure to unsubscribe on ngDestroy
    }
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close('startTour');
  }
}
@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent {
  @ViewChild('mapContainer') private mapContainer: HTMLElement;
  //Local variables
  productList = [];
  geoSearchList = [];
  selectRegionList = [];
  SearchList = [];
  readyToRenderProductList = false;
  title = 'Blah';
  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: '© Google',
      }),
    ],
    zoom: 6,
    center: latLng(26.72, -89.68),
  };
  stateChangingButton: any;
  selectedProduct;
  isFiltersApplied: boolean = true;
  markerLayers = [];
  dataLayers = [];
  tester: boolean = true;
  jsonarray = [];
  statesGeoJsonLayer: any;
  providerTypes = [
    // {
    //   organization_index_id: 1,
    //   organization: "All Providers"
    // }
  ];
  selectRegionjsonarray = [];
  markers;
  marker: any;
  hoverLayer: any;
  lblCartCount: any;
  hoverLayerGroup: L.LayerGroup;
  toggleGeometryLayerGroup: L.LayerGroup;
  rectangleViewPortLayer: any;
  isRectangleBeingDrawn: boolean = false;
  loaded: boolean = false;
  mapControlStopFlag: boolean = true;
  isFilterZoom: boolean = false;
  _map: Map;
  _container: any;
  _startLayerPoint: any;
  selectedIndex: any = 0; // Identifies the selected tab of the search menu
  drawBox: any; // Variable holds draw box icon to add and remove to map
  mapEvent: any;
  currentYear = new Date().getFullYear();
  minYear = this.currentYear;
  examples: any[] = [];
  layerTrackingDict = {};
  markerDictionary = {};
  screenHeight: number;
  screenWidth: number;
  screenVH: string;
  baseMaps: { Sattelite: any; Streets: any; Terrain: any; Hybrid: any };
  //Constructor
  constructor(
    private productsService: ProductsService,
    private triggerService: TriggerService,
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private readonly joyrideService: JoyrideService,
    private organizationService: OrganizationsService,
    private snackBar: MatSnackBar
  ) {
    // console.log("Landing")
  }

  ngOnInit() {
    //Store this scope
    var THIS: LandingPageComponent = this;
    //OnInit
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.setScalingDimension(this.screenWidth, this.screenHeight);

    this.selectedIndex = 0;
    this.productList = [];
    this.geoSearchList = [];
    this.selectRegionList = [];
    this.loaded = true;
    //defines all service listeners that the landing page needs to listen for
    this.defineServiceListeners(THIS);
    this.organizationService.getOrganizations().subscribe(data => {
      const indexes = Object.keys(data);
      const objectValues = Object.values(data);

      for (var i = 0; i < indexes.length; i++) {
        //offset index so All Providers can be first
        // objectValues[indexes[i]]['organizations_index_id'] += 1;
        this.providerTypes.push(objectValues[indexes[i]]);
      }
      // console.log(this.providerTypes);
    });

    let visited = sessionStorage.getItem('visited');

    //If visited is set, the user has been to the website in this session
    if (visited != null) {
    } else {
      //set visited to true and show tutorial modal
      sessionStorage.setItem('visited', 'true');
      // make sure to unsubscribe on ngDestroy
    }

    this.productsService.getAllProducts().subscribe(sites => {
      //Define cluster group for markers
      this.markers = L.markerClusterGroup();
      //dataLayers is an empty array used to store the sites
      this.dataLayers = _(sites)
        .map(s => {
          //The item is visible in the list
          s['isHidden'] = false;
          s['isMaxZoom'] = true;
          //The item's checkbox's state
          s['isChecked'] = false;
          //The user's selection state
          s['isSelected'] = false;
          s['isBeingViewed'] = false;
          //State indicates if the pushpin is currently visible on the map
          s['isVisibleOnMap'] = true;
          s['formatted_product_survey_date'] = moment(
            s.product_survey_date
          ).format('YYYY-MM-DD');
          //console.log(s);
          //defined custom icon (default one)
          var myIcon = L.icon({
            iconUrl: '../../assets/img/marker-icon.png',
            iconSize: [24, 36],
            iconAnchor: [12, 36],
            popupAnchor: [-3, -76],
            shadowUrl: '../../assets/img/marker-shadow.png',
          });
          //define marker
          try {
            let geoJsonObj = JSON.parse(s.centroid);
            let customMarker = L.marker(
              [geoJsonObj.coordinates[0], geoJsonObj.coordinates[1]],
              {
                draggable: false,
                icon: myIcon,
                clickable: true,
                data: JSON.stringify(s),
              }
            );
            // var states = {
            //   type: 'Feature',
            //   geometry: {
            //     type: 'MultiPolygon',
            //     coordinates: JSON.parse(s.geom).coordinates,
            //   },
            // };

            // var myLayer = L.geoJSON(
            //   states,

            //   {
            //     coordsToLatLng: function(coords) {
            //       //  console.log(coords);

            //       //                    latitude , longitude, altitude
            //       return new L.LatLng(coords[1], coords[0]); //Normal behavior
            //     },
            //   }
            // );

            // this.hoverLayer = myLayer;
            // this.hoverLayer.addTo(this._map);
            //Bind popup to each unique marker

            let newRelativeUrl = this.router.createUrlTree([
              'details/' +
                JSON.parse(customMarker.options.data).product_index_id,
            ]);
            var newRelative = newRelativeUrl.toString().substring(1);

            // window.open('#' + newRelativeUrl, '_blank');
            let org = JSON.parse(customMarker.options.data);
            let orgName = '';
            this.providerTypes.forEach(function(provider) {
              // console.log(provider);
              // console.log(JSON.parse(customMarker.options.data));
              if (provider.organizations_index_id == org.organization_id) {
                orgName = provider.organization;
              }
            });
            customMarker
              .bindPopup(
                '<b>' +
                  JSON.parse(customMarker.options.data).product_label +
                  '</b>' +
                  '<li>' +
                  'Data Type: ' +
                  JSON.parse(customMarker.options.data).data_type +
                  '</li>' +
                  '<li>' +
                  'Survey Type: ' +
                  JSON.parse(customMarker.options.data).survey_type +
                  '</li>' +
                  '<li>' +
                  'Survey Date: ' +
                  JSON.parse(customMarker.options.data)
                    .formatted_product_survey_date +
                  '</li>' +
                  '<hr>' +
                  orgName +
                  ' <a style="font-size:20px" href="#/' +
                  newRelative +
                  '" target="_blank" class="fa fa-info-circle" title="See more details."></a>' +
                  '<br>'
              )
              .openPopup();
            return customMarker;
          } catch (e) {
            console.log(e);
            console.log('Null values');
          }
          console.log('Finished Mapping');
        })
        .value();

      //**markerDataArray */ local array contains all marker data from the above datalayers
      var markerDataArray = [];
      //**productDataArray */ local array contains all product data from the markers above
      var productDataArray = [];

      //Copy dataLayers into new array markerDataArray
      markerDataArray = this.dataLayers;

      //Loop through all markers
      for (var i = 0; i < markerDataArray.length; i++) {
        //Grab product information from marker
        var product = JSON.parse(markerDataArray[i].options.data);
        //Create new global dict element with the product_index_id as the key; and the marker as the value
        this.markerDictionary[product.product_index_id] = markerDataArray[i];
        //Add just the product to the productDataArray
        productDataArray.push(product);
        //Add the marker to a global list of markers for manipulation later
        globalMarkerDataLayers.push(markerDataArray[i]);
        markerDataArray[i].id = product.product_label;
        //markers is a clustergroup that we add each marker too
        this.markers.addLayer(markerDataArray[i]);
        this.markerLayers.push(markerDataArray[i]);
        // console.log(markerDataArray[i]);
      }
      //Adds mouse,move,click events to markers
      this.defineMarkerClusterGroupEvents(THIS);

      //Add all markers to markerLayers which is binded to leafletLayers in the HTML

      this.markers.id = 'markers';
      this._map.addLayer(this.markers);
      // console.log(this._map);
      //Copy all Products to master list
      this.productList = productDataArray;
      //Prepopulate the productList for child components
      //this.geographicSearchInit();
      this.geoSearchList = this.productList;
      this.SearchList = this.productList;
      this.readyToRenderProductList = true;
      this.mapControlStopFlag = false;
      this.triggerService.trigger();
    });
  }
  setScalingDimension(screenWidth: number, screenHeight: number) {
    //Sets the scale of the map-container and map classes according to screen resolution
    if (screenWidth == 1600 || screenWidth == 1440) this.screenVH = '91.5vh';
    else if (screenWidth == 3440) this.screenVH = '95vh';
    else if (screenWidth == 2560) this.screenVH = '95vh';
    else if (screenWidth == 1680) this.screenVH = '93vh';
    else this.screenVH = '93vh';
  }
  onMapReady(map: L.Map) {
    var style = {
      weight: 4,
      opacity: 0.4,
      color: '#fff', //getColor(feature.properties.name),
      dashArray: '3',
      fillOpacity: 0.1,
    };
    this.statesGeoJsonLayer = L.geoJson(statesData, { style: style });
    //Store LandingPageComponent scoping
    var THIS: LandingPageComponent = this;
    //function used to add event listeners to the map
    this.defineMapEventListeners(THIS, map);
    //function used to add leaflet controls to the map
    this.defineMapLeafletControls(map);

    //Once map is loaded add text control and custom box select control to map
    this._map = map;
    setTimeout(() => {
      let snackBarRef = this.snackBar.open(
        'Zoom in futher to start loading products.',
        'Dismiss',
        {
          duration: 5000,
          verticalPosition: 'top',
        }
      );
    });
    this._map.options.minZoom = 4;

    let wantsTour = sessionStorage.getItem('wantsTour');

    if (wantsTour == 'true' || wantsTour == null) this.openDialog();
    // this.joyrideService.startTour({steps:['firstStep']});
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogTourOverview, {
      width: '250px',
      data: { response: 'foo' },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result == 'startTour') {
        this.joyrideService.startTour({
          steps: [
            '1stStep',
            '2ndStep',
            '3rdStep',
            '4thStep',
            '5thStep',
            '6thStep',
            '7thStep',
            '8thStep',
            '9thStep',
            '10thStep',
            '11thStep',
          ],
        });
      }
    });
  }
  /**onMapZoomEnd()
   * function called when map is panned or zoomed
   * @param map is the map variable to be manipulated
   * @returns void
   */
  onMapZoomEnd(map) {
    if (this.isFilterZoom) {
      console.log('isFilterZoom');
      //this is true when we call the function map.fitBounds from the listenforFiltersApplied function.
      //If this is fired we want to bypass normal functionality to avoid a loop
    } else {
      this.isFilterZoom = false;
      console.log(this._map.getZoom());
      if (this._map.getZoom() > 7) {
        // console.log('Zoom > 7');
        //console.log("here9");
        //mapControlStopFlag prevents zoomEnd and other map events from firing on init
        if (this.mapControlStopFlag) {
          //console.log('mapControlStopFlag');
          return;
        } else {
          // console.log('!mapControlStopFlag');
          // console.log("here2") ;
          if (
            !this._map.hasLayer(this.hoverLayerGroup) &&
            this.selectedIndex === 0
          ) {
            // console.log('no hoverLayerGroup');
            // console.log("here3");
            this.jsonarray = [];
            //SearchList should contain every product
            for (var i = 0; i < this.SearchList.length; i++) {
              //some error checking
              if (this.SearchList[i] === undefined) {
                // console.log('searchlist undefined');
                continue;
              }
              this.SearchList[i].isMaxZoom = false;
              //only apply geosearch rules if rectangle is not on map
              if (this.isRectangleBeingDrawn == false) {
                // console.log('isRectangleBeingDrawn is false');
                //Grab point lat long from json object in the SearchList
                var searchListObject = JSON.parse(this.SearchList[i].centroid);
                //Convert object to a leflet latLng
                var searchListlatLng = L.latLng(searchListObject.coordinates);
                //Check if map contains product
                if (map.target.getBounds().contains(searchListlatLng)) {
                  /**NOTE: These flags are the default settings for if a product should be visible, they are later refined by calling the applyFilter an applySort trigger services */
                  //Set product flag that tells us if the pushpin is visible within the map bounds
                  this.SearchList[i].isVisibleOnMap = true;
                  this.SearchList[i].isHidden = false;
                } else {
                  //Set product flag that tells us if the pushpin is visible within the map bounds
                  this.SearchList[i].isVisibleOnMap = false;
                  this.SearchList[i].isHidden = true;
                  //console.log("PRODUCT DOES NOT EXIST IN MAP BOUNDS");
                }
              }
            }

            this.triggerService.trigger();
            // console.log('telling to apply filter');
            this.triggerService.triggerApplyFilterInstruction('applyFilter');
            this.triggerService.triggerApplySortingInstruction('applySorting');
            this.triggerService.triggerNotificationColorBar('open');
          } else {
            console.log('map Has hoverlayergroup');
            //console.log("No hover layer group");
            if (this.isRectangleBeingDrawn) {
              console.log('isRectangleBeingDrawn is true');
              for (var i = 0; i < this.SearchList.length; i++) {
                //Flag above zoomlevel
                this.SearchList[i].isMaxZoom = false;
                this.triggerService.triggerApplyFilterInstruction(
                  'applyFilter'
                );
                this.triggerService.triggerApplySortingInstruction(
                  'applySorting'
                );
                this.triggerService.triggerResetProductCounter('resetCounter');
              }
            }
          }

          this._map.invalidateSize();
        }
      } else {
        console.log('zoom is < 7');
        //console.log("here1");
        if (this.isRectangleBeingDrawn == false) {
          console.log('isRectangleBeingDrawn is false');
          for (var i = 0; i < this.SearchList.length; i++) {
            //Flag above zoomlevel
            this.SearchList[i].isMaxZoom = true;
          }
          this.triggerService.triggerResetProductCounter('resetCounter');
        } else {
          console.log('isRectangleBeingDrawn is true');
          for (var i = 0; i < this.SearchList.length; i++) {
            //Flag above zoomlevel
            this.SearchList[i].isMaxZoom = false;

            // this.triggerService.triggerResetProductCounter('resetCounter');
          }
        }
      }
    }

    //trigger product-list triggerNgOnChanges function
  }

  defineProductPolygonToBePutOnMap(product) {
    var json = JSON.parse(product.geom);
    //define shape json for polygon
    var shape = {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: json.coordinates,
      },
    };
    //stylize polygon
    var exteriorStyle = {
      color: '#0000FF',
      fillOpacity: 0.1,
    };
    //Turn json polygon into geoJson for implanting into map
    var layer = L.geoJSON(
      shape,
      { style: exteriorStyle },
      {
        coordsToLatLng: function(coords) {
          //  console.log(coords);

          //                    latitude , longitude, altitude
          return new L.LatLng(coords[1], coords[0]); //Normal behavior
        },
      }
    );

    return layer;
  }

  checkDrawnRectangleAndPolygonOverlap(rectangleBounds, polygonBounds) {
    if (rectangleBounds.intersects(polygonBounds)) {
      return true;
    } else if (rectangleBounds.overlaps(polygonBounds)) {
      return true;
    } else {
      return false;
    }
  }
  checkHoverLayerGroupContainsLayerID(id) {
    var flag: boolean = false;
    if (this._map.hasLayer(this.hoverLayerGroup)) {
      this.hoverLayerGroup.eachLayer(function(layer: any) {
        if (id == layer.layerID) {
          flag = true;
        }
      });
    }

    return flag;
  }
  displayBoundingBoxForAllProductsWithinRectangle(
    rectangleViewPortBounds,
    THIS
  ) {
    THIS.selectRegionjsonarray = [];
    //We need to loop through all products, determine if the products geometry overlaps the rectangle, if it does show it on map
    for (var i = 0; i < this.productList.length; i++) {
      var layerContainingProductPolygon = this.defineProductPolygonToBePutOnMap(
        this.productList[i]
      );
      var topleft = layerContainingProductPolygon.getBounds()._northEast;
      var bottomright = layerContainingProductPolygon.getBounds()._southWest;
      var polygonSquareBounds = new L.LatLngBounds(topleft, bottomright);

      var isIntersecting = this.checkDrawnRectangleAndPolygonOverlap(
        rectangleViewPortBounds,
        polygonSquareBounds
      );

      if (isIntersecting) {
        this.hoverLayer = layerContainingProductPolygon;
        this.hoverLayer.layerID = this.productList[i].product_index_id;
        if (!THIS._map.hasLayer(THIS.hoverLayerGroup)) {
          THIS.hoverLayerGroup = L.layerGroup()
            .addLayer(this.hoverLayer)
            .addTo(THIS._map);
          // console.log(THIS._map);
        } else {
          THIS.hoverLayerGroup.addLayer(this.hoverLayer);
          //console.log(THIS._map);
        }

        //this.hoverLayer.addTo(this._map);
        console.log(this.productList[i]);
        THIS.selectRegionjsonarray.push(this.productList[i]);
        // THIS.marker.setIcon(new HighlightIcon());
      }
    }

    console.log(THIS.selectRegionjsonarray);

    // var geometry = wkx.Geometry.parse(product.geom);
    // console.log(geometry);
    //   this.hoverPolygon = L.polygon([
    //     [51.509, -0.08],
    //     [51.503, -0.06],
    //     [51.51, -0.047]
    // ]);
    // this.hoverPolygon.addTo(this._map);
  }

  toggleBoundingBoxForAllProducts(THIS) {
    console.log(THIS.hoverLayerGroup);
    console.log(THIS.toggleGeometryLayerGroup);
    THIS.selectRegionjsonarray = [];
    //We need to loop through all products, determine if the products geometry overlaps the rectangle, if it does show it on map
    for (var i = 0; i < this.productList.length; i++) {
      //console.log(this.productList[i]);
      //build polygon to be placed in a layer on map
      var layerContainingProductPolygon = this.defineProductPolygonToBePutOnMap(
        this.productList[i]
      );
      //get bounds of new polygon to be placed on map
      var topleft = layerContainingProductPolygon.getBounds()._northEast;
      var bottomright = layerContainingProductPolygon.getBounds()._southWest;
      var polygonSquareBounds = new L.LatLngBounds(topleft, bottomright);

      //Check if new polygon layer intersects in anyway with what is on the map
      var isIntersecting = this.checkDrawnRectangleAndPolygonOverlap(
        THIS._map.getBounds(),
        polygonSquareBounds
      );
      //force every product to be "intersecting" to allow other products to show
      isIntersecting = true;
      if (isIntersecting) {
        //condition to verify the product is visible on the map
        if (!this.productList[i].isHidden) {
          this.hoverLayer = layerContainingProductPolygon;
          //Assign ID to layer so we can check if layer is already on the map
          this.hoverLayer.layerID = this.productList[i].product_index_id;

          //Hoverlayergroup does not contain the given ID means the layer showing the geometry should not be on the map currently
          if (
            !this.checkHoverLayerGroupContainsLayerID(this.hoverLayer.layerID)
          ) {
            if (!THIS._map.hasLayer(THIS.toggleGeometryLayerGroup)) {
              //first iteration adds TGLG group to the map
              THIS.toggleGeometryLayerGroup = L.layerGroup()
                .addLayer(THIS.hoverLayer)
                .addTo(THIS._map);
            } else {
              //first iteration adds TGLG group to the map
              THIS.toggleGeometryLayerGroup.addLayer(THIS.hoverLayer);
            }
          }
        }
      }
    }
  }
  boundingBoxMarkerLocator(e: any, map: any, THIS: any) {
    //Show shapes that touch rectangle
    if (THIS.rectangleViewPortLayer !== undefined)
      map.removeLayer(THIS.rectangleViewPortLayer);

    var topleft = e.layer.getBounds()._northEast;
    var bottomright = e.layer.getBounds()._southWest;
    THIS.bounds = new L.LatLngBounds(topleft, bottomright);
    this.displayBoundingBoxForAllProductsWithinRectangle(THIS.bounds, THIS);
    THIS.rectangleViewPortLayer = new L.geoJSON();

    var rectangle = L.rectangle(THIS.bounds, {
      color: '#ff7800',
      weight: 1,
    }).addTo(THIS.rectangleViewPortLayer);
    THIS.rectangleViewPortLayer.addTo(map);
    THIS.layerTrackingDict['rectangleViewPortLayer'] =
      THIS.rectangleViewPortLayer;

    var zoom = map.getBoundsZoom(THIS.bounds);
    console.log(zoom);
    map.fitBounds(THIS.bounds);

    //Global jasonarray and productList reference
    // THIS.selectRegionjsonarray = [];
    // THIS.productList = [];

    //Search for markers in shape

    // for (var i = 0; i < globalMarkerDataLayers.length; i++) {
    //   THIS.marker = globalMarkerDataLayers[i];

    //   var Latitude = THIS.marker.getLatLng().lat;
    //   var Longitude = THIS.marker.getLatLng().lng;

    //   var markerPointLatLong = new L.LatLng(Latitude, Longitude);

    //   var json = JSON.parse(globalMarkerDataLayers[i].options.data);
    //   var dataCentroid = JSON.parse(json.centroid);

    //   // var geoJsonLayer = L.geoJSON(json,
    //   //   {
    //   //   coordsToLatLng: function(coords) {
    //   //     return new L.LatLng(coords[1], coords[0]);
    //   //   },
    //   // });
    //   // console.log(geoJsonLayer);

    //   // CHECKS FOR THE PUSHPIN
    //   // if (bounds.contains(markerPointLatLong) === true) {
    //   //   THIS.selectRegionjsonarray.push(JSON.parse(ctempLayers[i].options.data));
    //   //   marker.setIcon(new HighlightIcon());
    //   // }
    //   // CHECKS THE GEOMETRY
    //   //console.log(json.coordinates);
    //   if (
    //     THIS.bounds.contains([
    //       dataCentroid['coordinates'][0],
    //       dataCentroid['coordinates'][1],
    //     ])
    //   ) {
    //     // Checking for duplicates before pushing
    //     if (
    //       !THIS.selectRegionjsonarray.some(
    //         value =>
    //           value.product_index_id ===
    //           JSON.parse(globalMarkerDataLayers[i].options.data)
    //             .product_index_id
    //       )
    //     ) {
    //       THIS.selectRegionjsonarray.push(
    //         JSON.parse(globalMarkerDataLayers[i].options.data)
    //       );
    //       THIS.marker.setIcon(new HighlightIcon());
    //     }
    //   } else {
    //     THIS.marker.options.icon.iconUrl = '../../assets/img/marker-icon.png';
    //     THIS.marker.setIcon(new StandardIcon());
    //   }
    // }
    //Update product list
    // console.log('ARRAY: ', THIS.selectRegionjsonarray);
    console.log(THIS.selectRegionjsonarray);
    THIS.selectRegionList = THIS.selectRegionjsonarray;
    THIS.SearchList = THIS.selectRegionjsonarray;
    //THIS.displayAllboundingBoxes(ctempLayers, THIS.bounds);
    THIS.changeDetector.detectChanges();
  }

  bounds: any;
  filteredList = [];
  event: any;

  colorMarkersFromBoundingBox(boundingBox: any) {
    console.log(this.geoSearchList);
    this.jsonarray = [];
    this.geoSearchList = [];

    for (var i = 0; i < this.dataLayers.length; i++) {
      if (this.dataLayers[i] === undefined) {
        continue;
      }
      var JSONMAPDATA = JSON.parse(this.dataLayers[i].options.data);
      if (boundingBox.contains(this.dataLayers[i].getLatLng())) {
        //this.dataLayers[i].setIcon(new HighlightIcon());
        // JSONMAPDATA.isHidden = false;
        // if(JSONMAPDATA.isHidden)
        // {

        // }
        // JSONMAPDATA.isChecked = true;
        this.jsonarray.push(JSONMAPDATA);
      }
    }

    this.geoSearchList = this.jsonarray;
  }

  removeMarkerColor(product: any) {
    if (product === 'remove all') {
      // removes highlighted pins when tabs are changed
      for (var i = 0; i < globalMarkerDataLayers.length; i++) {
        globalMarkerDataLayers[i].options.icon.iconUrl =
          '../../assets/img/marker-icon.png';
        globalMarkerDataLayers[i].setIcon(new StandardIcon());
      }
    } else {
      for (var i = 0; i < this.dataLayers.length; i++) {
        if (this.dataLayers[i] === undefined) {
          continue;
        }
        var tempJSON = JSON.parse(this.dataLayers[i].options.data);
        if (product.product_index_id === tempJSON.product_index_id) {
          this.dataLayers[i].setIcon(new StandardIcon());
        }
      }
    }
  }

  onChange(ev: any) {
    this.selectedIndex = ev.index;

    // removes layer if there is an active one so layers arent shared throughout tabs
    // if (this.hoverLayerGroup !== undefined)
    // {
    //   console.log("Here");
    //   this._map.removeLayer(this.hoverLayerGroup);
    //   this.changeDetector.detectChanges();

    // }

    //Leaflet doesn't allow you to label/track/id LAYERGROUPS. layerTrackingDict is two-way binded to grandchildren
    //This allows me to keep track of what layergroups are added to the map in the grandchildren so I can remove them here if required.
    if (this.layerTrackingDict['tileLayerGroup'] !== undefined) {
      this._map.removeLayer(this.layerTrackingDict['tileLayerGroup']);
      this.layerTrackingDict['tileLayerGroup'] == undefined;
    }

    // removes highlighted pins when tabs are changed
    for (var i = 0; i < globalMarkerDataLayers.length; i++) {
      globalMarkerDataLayers[i].options.icon.iconUrl =
        '../../assets/img/marker-icon.png';
      globalMarkerDataLayers[i].setIcon(new StandardIcon());
    }

    // Removes the drawn rectangle for different tabs
    this.triggerRemoveDrawnBox();
    console.log(this.drawBox);
    console.log(this.selectedIndex);
    /////////////////////////// Detects active tab ///////////////////////////
    if (this.selectedIndex === 0) {
      this.drawBox = new L.Control.Draw({
        draw: {
          marker: false,
          // polygon: {
          //   shapeOptions: {
          //     color: '#ffff00',
          //   },
          // },
          polygon: false,
          polyline: false,
          rectangle: {
            shapeOptions: {
              color: '#ffff00',
            },
          },
          circle: false,
          circlemarker: false,
        },
        edit: false,
      });
      this.drawBox.prop;
      this.drawBox.addTo(this._map);
      //  this.drawBox._toolbars.draw._modes.rectangle.handler.enable();
      // this.productList = [];
      this.triggerMapZoomEnd(this._map);
      this.changeDetector.detectChanges();
      // console.log(this.rectangleViewPortLayer);
      this.colorMarkersFromBoundingBox(this._map.getBounds());
      // this._map.removeLayer(this.layerTrackingDict['rectangleViewPortLayer']);
    }
    if (this.selectedIndex === 1) {
      if (this.drawBox !== undefined) {
        this.drawBox.remove();
      }
    }
    // if (this.selectedIndex === 1) {
    //   // Declaring and Adding rectangle draw option to left side of map
    //   this.drawBox = new L.Control.Draw({
    //     draw: {
    //       marker: false,
    //       // polygon: {
    //       //   shapeOptions: {
    //       //     color: '#ffff00',
    //       //   },
    //       // },
    //       polygon: false,
    //       polyline: false,
    //       rectangle: {
    //         shapeOptions: {
    //           color: '#ffff00',
    //         },
    //       },
    //       circle: false,
    //       circlemarker: false,
    //     },
    //     edit: false,
    //   });
    //   this.drawBox.prop;
    //   this.drawBox.addTo(this._map);
    //   // Color pins inside of drawn box
    //   if (this.bounds !== undefined) {
    //     this.colorMarkersFromBoundingBox(this.bounds);
    //   }

    //   if (this.rectangleViewPortLayer !== undefined) {
    //     // Add back drawn box once user comes back to draw rectangle tab
    //     this.rectangleViewPortLayer.addTo(this._map);
    //     this.boundingBoxMarkerLocator(this.event, this._map, this); // repopulates the products once the user comes back to this tab
    //   } else {
    //     this.drawBox._toolbars.draw._modes.rectangle.handler.enable();
    //   }
    //   // this.drawBox._toolbars.draw._modes.rectangle.handler.enable();
    // } else {
    // if (this.drawBox !== undefined) {
    //   this.drawBox.remove();
    // }
    // }
  }
  /**triggerRemoveDrawnBox(map)
   * called from product-list to trigger the removal of the drawn rectangle layer
   * @returns void
   */
  triggerRemoveDrawnBox() {
    // Removes the drawn rectangle for different tabs
    if (this.rectangleViewPortLayer !== undefined) {
      this.isRectangleBeingDrawn = false;
      this._map.removeLayer(this.rectangleViewPortLayer);
    }
  }
  /**triggerRemoveHoverLayer(map)
   * called from product-list to trigger the removal of the hoverLayer
   * @returns void
   */
  triggerRemoveHoverLayer() {
    if (this._map.hasLayer(this.hoverLayerGroup))
      this._map.removeLayer(this.hoverLayerGroup);
  }
  /**triggerRemoveToggleGeometryLayerGroup(map)
   * called from product-list to trigger the removal of the hoverLayer
   * @returns void
   */
  triggerRemoveToggleGeometryLayerGroup() {
    if (this._map.hasLayer(this.toggleGeometryLayerGroup))
      this._map.removeLayer(this.toggleGeometryLayerGroup);
  }
  /**triggerMapZoomEnd(map)
   * called from product-list to trigger a similar function to what happens when the map finishes panning. Resets rectangle product list to geosearch list
   * @returns void
   */
  triggerMapZoomEnd(map) {
    //mapControlStopFlag prevents zoomEnd and other map events from firing on init
    if (this._map.getZoom() > 7) {
      if (this.mapControlStopFlag) {
        return;
      } else {
        if (
          !this._map.hasLayer(this.hoverLayerGroup) &&
          this.selectedIndex === 0
        ) {
          this.jsonarray = [];
          //SearchList should contain every product
          for (var i = 0; i < this.productList.length; i++) {
            //some error checking
            if (this.productList[i] === undefined) {
              continue;
            }
            //only apply geosearch rules if rectangle is not on map
            if (this.isRectangleBeingDrawn == false) {
              //Grab point lat long from json object in the SearchList
              var searchListObject = JSON.parse(this.productList[i].centroid);
              //Convert object to a leflet latLng
              var searchListlatLng = L.latLng(searchListObject.coordinates);
              //Check if map contains product
              console.log(this._map.getBounds().contains(searchListlatLng));
              if (this._map.getBounds().contains(searchListlatLng)) {
                /**NOTE: These flags are the default settings for if a product should be visible, they are later refined by calling the applyFilter an applySort trigger services */
                //Set product flag that tells us if the pushpin is visible within the map bounds
                this.productList[i].isVisibleOnMap = true;
                this.productList[i].isHidden = false;
              } else {
                //Set product flag that tells us if the pushpin is visible within the map bounds
                this.productList[i].isVisibleOnMap = false;
                this.productList[i].isHidden = true;
                //console.log("PRODUCT DOES NOT EXIST IN MAP BOUNDS");
              }
            }
          }

          this.SearchList = this.productList;
          this.triggerService.trigger();
          this.triggerService.triggerApplyFilterInstruction('applyFilter');
          this.triggerService.triggerApplySortingInstruction('applySorting');
        }

        this._map.invalidateSize();
      }
    }

    // if (this.mapControlStopFlag)
    // {
    //   return;
    // }
    // else
    // {
    //   //after zoom event, restructure the product list
    //   if (!this._map.hasLayer(this.hoverLayerGroup) && this.selectedIndex === 0)
    //   {
    //     this.jsonarray = [];
    //     for (var i = 0; i < this.dataLayers.length; i++) {
    //       //Datalayers is the original array of markers returned by the DB
    //       // Checks if dataLayers function is undefined, if so the loop is skipped
    //       if (this.dataLayers[i] === undefined) {
    //         continue;
    //       }
    //       //JSONMAPDATA contains a product as a json. such that JSONMAPDATA.survey_id is a valid json
    //       var JSONMAPDATA = JSON.parse(this.dataLayers[i].options.data);

    //       //console.log(this.isRectangleBeingDrawn);
    //       //If the rectangle search is note currently applied or on the map
    //       if (this.isRectangleBeingDrawn == false) {
    //         //PUSHPIN FOUND WITHIN MAP BOUNDS
    //         //the datalayer is the map pushpin, we check the location of the pushpin which
    //         //correlates to the jsonmapdata to see if it lies within the bounds of the map
    //         if (map.getBounds().contains(this.dataLayers[i].getLatLng())) {
    //           //Check if the geoSearchList is empty
    //           if (
    //             this.SearchList.length === 0 ||
    //             this.SearchList === undefined
    //           ) {
    //             //If this pushpin lies within the bounds of the map, and is NOT in the geosearch list
    //             //(Because its empty)
    //             //Change the pushpin to highlighted and add the pushpin to the geosearch list
    //             JSONMAPDATA.isHidden = false;
    //             //JSONMAPDATA.isChecked = false;
    //             //ADD PUSHPIN TO GEOSEARCH
    //             this.geoSearchList.push(JSONMAPDATA); // we loop throught the products
    //             this.SearchList.push(JSONMAPDATA); // we loop throught the products
    //           } else {
    //             //If the geoSearchList already contains some pushpins
    //             //Loop through those pushpins
    //             for (var j = 0; j < this.SearchList.length; j++) {
    //               //Check if current index product/pushpin already exists in the geoSearchList
    //               if (
    //                 this.SearchList[j].product_index_id ===
    //                 JSONMAPDATA.product_index_id
    //               ) {
    //                 //The pushpin already exists in the geoSearchList
    //                 //So we ensure the pushpin is visible and selected

    //                 this.SearchList[j].isHidden = false;
    //                 this.SearchList[j].isChecked = this.SearchList[
    //                   j
    //                 ].isSelected;
    //                 //this.dataLayers[i].setIcon(new HighlightIcon());
    //               } //PUSHPIN DOES NOT EXIST IN GEOSEARCH ITEMS
    //               else {
    //                 if (
    //                   this.SearchList.filter(function(e) {
    //                     return (
    //                       e.product_index_id === JSONMAPDATA.product_index_id
    //                     );
    //                   }).length === 0
    //                 ) {
    //                   //   console.log('DIFFERENT')

    //                   // this.dataLayers[i].setIcon(new HighlightIcon()); // does it already exist in the product list??
    //                   this.SearchList.push(JSONMAPDATA);
    //                 }
    //               }
    //             }
    //           }
    //         } //PUSHPIN NOT FOUND WITHIN MAP BOUNDS
    //         else {
    //           //console.log('here' + i);
    //           //LOOP THROUGH ALL GEOSEARCH ITEMS
    //           for (var k = 0; k < this.SearchList.length; k++) {
    //             //CHECK IF PUSHPIN EXISTS IN GEOSEARCH ITEMS
    //             if (
    //               this.SearchList[k].product_index_id ===
    //               JSONMAPDATA.product_index_id
    //             ) {
    //               //UPDATE GEOSEARCH LIST TO MAKE PUSHPIN INVISIBLE
    //               this.SearchList[k].isHidden = true;
    //               this.SearchList[k].isChecked = false;
    //               this.dataLayers[i].setIcon(new StandardIcon());
    //             } else {
    //               //we ignore it
    //               continue;
    //             }
    //           }
    //         }
    //       }
    //       // console.log(this.SearchList);
    //     }
    //     this.triggerService.trigger();
    //     // console.log("apply filter instruction");
    //     this.triggerService.triggerApplyFilterInstruction('applyFilter');
    //   }

    //   this._map.invalidateSize();
    // }
  }

  /**triggerOpenDialog(THIS)
   * tells product-list to call the function openDialog
   * @returns void
   */
  triggerOpenDialog() {
    this.triggerService.triggerOpenDialogInstruction('openDialog');
  }

  /**triggerDownloadProducts()
   * tells search menu view cart to begin rendering the download cart
   * @returns void
   */
  triggerDownloadProducts() {
    this.triggerService.triggerDownloadProductsInstruction('downloadProducts');
  }

  /**defineServiceListeners(THIS)
   * Subscribes to services for the input/output of data
   * @param THIS the scope for LandingPageComponent
   * @returns void
   */
  defineServiceListeners(THIS) {
    //This service acts as a trigger for when the user MOUSEOUTS of a product in the productlist in the drawer
    this.triggerService.listenForProductMouseOut.subscribe(data => {
      if (data !== undefined && data !== '') {
        //Identify which marker belongs to the product we are mousingout from
        THIS.marker = THIS.markerDictionary[data.product_index_id];
        //Set markers icon back to standard
        THIS.marker.setIcon(new StandardIcon());

        //THIS.markers is the markerClusterGroup.
        //Looping through each feature group allows us to loop through all CLUSTERS on the map, which allows us to identify
        //Which cluster a product belongs too. And eventually highlight that cluster.
        THIS.markers._featureGroup.eachLayer(function(x) {
          //Get all markers belonging to X cluster
          if (typeof x.getAllChildMarkers === 'function')
            var children = x.getAllChildMarkers();
          else return;
          //Check ID of each marker within cluster to see if it matches the given MARKER we are looking for THIS.marker.id
          var result = children.find(obj => {
            return obj.id === THIS.marker.id;
          });

          //This cluster contains the product we are highlighting
          if (result !== undefined) {
            //Remove Highlight from the cluster
            L.DomUtil.removeClass(x._icon, 'marker-cluster-highlight');
          }
        });
        //
      }
    });
    //This service acts as a trigger for when the user MOUSEOVER of a product in the productlist in the drawer
    this.triggerService.listenForProductMouseOver.subscribe(data => {
      if (data !== undefined && data !== '') {
        //Identify which marker belongs to the product we are mousingout from
        THIS.marker = THIS.markerDictionary[data.product_index_id];
        //Set markers icon back to highlighted
        THIS.marker.setIcon(new HighlightIcon());

        //THIS.markers is the markerClusterGroup.
        //Looping through each feature group allows us to loop through all CLUSTERS on the map, which allows us to identify
        //Which cluster a product belongs too. And eventually highlight that cluster.
        try {
          THIS.markers._featureGroup.eachLayer(function(x) {
            //Get all markers belonging to X cluster

            if (typeof x.getAllChildMarkers === 'function')
              var children = x.getAllChildMarkers();
            else return;
            //Check ID of each marker within cluster to see if it matches the given MARKER we are looking for THIS.marker.id
            var result = children.find(obj => {
              return obj.id === THIS.marker.id;
            });
            //console.log(x);
            //This cluster contains the product we are highlighting
            if (result !== undefined) {
              //Highlight the cluster
              //console.log(x);
              L.DomUtil.addClass(x._icon, 'marker-cluster-highlight');
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
    //This service acts as a trigger for when the user applies a filter in the productList which should  trigger a notification on the map
    this.triggerService.listenForFiltersApplied.subscribe(data => {
      var dataJson = JSON.parse(JSON.stringify(data));
      if (dataJson.message === 'filtersApplied') {
        THIS.isFiltersApplied = false;
        console.log(data);
        if (
          (this.stateChangingButton.button.title =
            'Toggle product geometry to be OFF')
        ) {
          this.stateChangingButton.button.click();
          this.stateChangingButton.button.click();
        }
        //
        if (dataJson.list.length > 0) {
          console.log('setting isFilterZoom = true');
          this.isFilterZoom = true;
          console.log(this.markers);
          this._map.fitBounds(this.markers.getBounds().pad(0.2));

          //console.log(this.stateChangingButton.button);
          // console.log(THIS.stateChangingButton);
          // THIS.toggleBoundingBoxForAllProducts(THIS);
          // btn.state('toggle-off-geometry'); // change state on click!
        }
      }
      if (data === 'filtersRemoved') {
        THIS.isFiltersApplied = true;
        THIS.isFilterZoom = false;
      }
    });
  }

  /**defineMapEventListeners(THIS)
   * Adds event Listeners to the map
   * @param THIS the scope for LandingPageComponent
   * @returns void
   */
  defineMapEventListeners(THIS, map) {
    //Draw event fired when toolbar selected
    map.on(L.Draw.Event.CREATED, function(e: any) {
      THIS.event = e;
      THIS.isRectangleBeingDrawn = true;
      THIS.boundingBoxMarkerLocator(e, map, THIS);
    });
    //Draw event fired when toolbar selected

    //Prevents context menu on right-click
    try {
      map.on('contextmenu', function() {
        // THIS.removeAllBoundingBox(map, THIS.rectangleViewPortLayer);
      });
    } catch (Exception) {}
  }

  handleTileError(evt) {
    console.log(evt);
    if (evt.tile._hasError) return;
  }
  /**defineMapLeafletControls(THIS)
   * Defines controls for the leaflet map
   * @param THIS the scope for LandingPageComponent
   * @returns void
   */
  defineMapLeafletControls(map) {
    var THIS: LandingPageComponent = this;
    this._container = map.getContainer();
    //Define new ZOOM HOME EXTEND button
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topleft',

        zoomHomeText:
          '<i class="fa fa-home" style="line-height:1.65; font-size:18px"></i>',
        zoomHomeTitle: 'Reset Map Extent',
      },
      onAdd: function(map) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options;

        this._zoomHomeButton = this._createButton(
          options.zoomHomeText,
          options.zoomHomeTitle,
          controlName + '-home',
          container,
          this._zoomHome
        );

        this._updateDisabled();
        map.on('zoomend zoomlevelschange', this._updateDisabled, this);

        return container;
      },

      onRemove: function(map) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this);
      },

      _zoomIn: function(e) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function(e) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: function(e) {
        // zoom: 6,
        // center: latLng(26.72, -89.68),
        map.setView([26.72, -89.68], 6);
      },

      _createButton: function(html, title, className, container, fn) {
        var link = L.DomUtil.create('a', className, container);
        link.innerHTML = html;
        link.href = '#';
        link.title = title;

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function() {
        var map = this._map,
          className = 'leaflet-disabled';

        if (map._zoom === map.getMinZoom()) {
          if (this._zoomOutButton != undefined)
            L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          if (this._zoomInButton != undefined)
            L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });
    var zoomHome = new L.Control.zoomHome();
    zoomHome.addTo(map);

    // var osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // maxZoom: 19,
    // attribution: '© OpenStreetMap'
    // });
    var googleStreets = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: '© Google',
      }
    );
    var googleHybrid = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: '© Google',
      }
    );
    var googleSat = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: '© Google',
      }
    );
    var googleTerrain = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: '© Google',
      }
    );

    console.log(this.productList);

    // var streets = L.tileLayer(mapboxUrl, {id: 'mapbox/streets-v11', tileSize: 512, zoomOffset: -1, attribution: mapboxAttribution});

    this.baseMaps = {
      Sattelite: googleSat,
      Streets: googleStreets,
      Terrain: googleTerrain,
      Hybrid: googleHybrid,
      //Layer: lyr
    };
    var overlayMaps = {
      States: this.statesGeoJsonLayer,
    };

    var layerControl = L.control.layers(this.baseMaps, overlayMaps).addTo(map);

    // lyr.on('tileerror', this.handleTileError)
    // L.Control.stateButton = L.Control.extend({
    //   options: {
    //     position: 'topright',
    //     stateButtonText:
    //       '<i class="fa fa-home" style="line-height:1.65; font-size:18px"></i>',
    //     stateButtonTitle: 'State',
    //   },
    //   onAdd: function(map) {
    //     var controlName = 'gin-control-zoom',
    //       container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
    //       options = this.options;

    //     this._stateButtonButton = this._createButton(
    //       options.stateButtonText,
    //       options.stateButtonTitle,
    //       controlName + '-state',
    //       container,
    //       this._stateButton
    //     );

    //     this._updateDisabled();
    //     map.on('zoomend zoomlevelschange', this._updateDisabled, this);

    //     return container;
    //   },

    //   onRemove: function(map) {
    //     map.off('zoomend zoomlevelschange', this._updateDisabled, this);
    //   },

    //   _zoomIn: function(e) {
    //     this._map.zoomIn(e.shiftKey ? 3 : 1);
    //   },

    //   _zoomOut: function(e) {
    //     this._map.zoomOut(e.shiftKey ? 3 : 1);
    //   },

    //   _stateButton: function(e)
    //   {

    //       var style = {
    //         weight: 4,
    //         opacity: .4,
    //         color: '#fff',//getColor(feature.properties.name),
    //         dashArray: '3',
    //         fillOpacity: 0.1
    //       }

    //     if(!this._map.hasLayer(this.statesGeoJsonLayer))
    //     {
    //       this.statesGeoJsonLayer = L.geoJson(statesData, {style: style});
    //       this.statesGeoJsonLayer.addTo(this._map);
    //     }
    //     else
    //     {
    //       this._map.removeLayer( this.statesGeoJsonLayer);
    //     }

    //   },

    //   _createButton: function(html, title, className, container, fn) {
    //     var link = L.DomUtil.create('a', className, container);
    //     link.innerHTML = html;
    //     link.href = '#';
    //     link.title = title;

    //     L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
    //       .on(link, 'click', L.DomEvent.stop)
    //       .on(link, 'click', fn, this)
    //       .on(link, 'click', this._refocusOnMap, this);

    //     return link;
    //   },

    //   _updateDisabled: function() {
    //     var map = this._map,
    //       className = 'leaflet-disabled';

    //     if (map._zoom === map.getMinZoom()) {
    //       if (this._zoomOutButton != undefined)
    //         L.DomUtil.addClass(this._zoomOutButton, className);
    //     }
    //     if (map._zoom === map.getMaxZoom()) {
    //       if (this._zoomInButton != undefined)
    //         L.DomUtil.addClass(this._zoomInButton, className);
    //     }
    //   },
    // });
    // var stateButton = new L.Control.stateButton();
    // stateButton.addTo(map);
    this.stateChangingButton = L.easyButton({
      position: 'bottomleft',
      states: [
        {
          stateName: 'toggle-on-geometry', // name the state
          icon:
            '<div style="display: flex; justify-content: space-between; width:20px; padding-top:10px">' +
            '<p class="fa fa-toggle-off" style="font-size:24px; line-height:1.65px;" aria-hidden="false"></p>' +
            '<p style="font-size:16px; margin-left: 5px; line-height:1.65px;">Off</p>' +
            '</div>',
          title: 'Toggle product geometry to be ON',
          id: 'MY-BUTTON-ID', // like its title
          onClick: function(btn, map) {
            // and its callback
            // map.setView([46.25,-121.8],10);
            THIS.toggleBoundingBoxForAllProducts(THIS);
            btn.state('toggle-off-geometry'); // change state on click!
          },
        },
        {
          stateName: 'toggle-off-geometry',
          icon:
            '<div style="display: flex; justify-content: space-between; width:20px; padding-top:10px">' +
            '<p class="fa fa-toggle-on" style="font-size:24px; line-height:1.65px;" aria-hidden="false"></p>' +
            '<p style="font-size:16px; margin-left: 5px; line-height:1.65px;">On</p>' +
            '</div>',
          title: 'Toggle product geometry to be OFF',
          onClick: function(btn, map) {
            // map.setView([42.3748204,-71.1161913],16);
            THIS.triggerRemoveToggleGeometryLayerGroup();
            btn.state('toggle-on-geometry');
          },
        },
      ],
    });
    this.stateChangingButton.button.style.height = '26px';
    this.stateChangingButton.button.style.width = '70px';
    this.stateChangingButton.button.style.borderRadius = '5px';
    this.stateChangingButton.button.style.border = 'none';
    this.stateChangingButton.button.style.fontSize = '25px';
    this.stateChangingButton.button.style.color = '#242323';
    this.stateChangingButton.button.style.fontFamily = 'Roboto';
    this.stateChangingButton.button.style.paddingBottom = '2px';
    this.stateChangingButton.addTo(map);

    //Define new rectangle draw button
    this.drawBox = new L.Control.Draw({
      draw: {
        marker: false,
        // polygon: {
        //   shapeOptions: {
        //     color: '#ffff00',
        //   },
        // },
        polygon: false,
        polyline: false,
        rectangle: {
          shapeOptions: {
            color: '#ffff00',
            title: 'BOOP',
          },
        },
        circle: false,
        circlemarker: false,
      },
      edit: false,
    });
    this.drawBox.prop;
    this.drawBox.addTo(map);
  }

  /**defineMarkerClusterGroupEvents(THIS)
   * Defines events for markers on the map
   * @param THIS the scope for LandingPageComponent
   * @returns void
   */
  defineMarkerClusterGroupEvents(THIS) {
    /**THIS is the LandingPageComponent Scope, it is passed here to maintain correct scoping */

    //mouseover marker event
    this.markers.on('clustermouseover', function(event) {
      // your custom L.MarkerCluster extended with function highlight()
      // event.layer.highlight();
      // console.log(event.layer);
      L.DomUtil.addClass(event.layer._icon, 'marker-cluster-highlight');
    });

    this.markers.on('clustermouseout', function(event) {
      // your custom L.MarkerCluster extended with function resetHighlight()
      //event.layer.resetHighlight();
      L.DomUtil.removeClass(event.layer._icon, 'marker-cluster-highlight');
    });
    this.markers.on('mouseover', function(a) {
      THIS.triggerService.triggerPushpinHoverEvent(
        JSON.parse(a.layer.options.data)
      );
    });
    //mouseout marker event
    this.markers.on('mouseout', function(a) {
      THIS.triggerService.triggerPushpinHoverEvent(-1);
    });
    //click marker event
    this.markers.on('click', function(a) {
      //Each marker onClick function
      // console.log(a.layer);
      // console.log(a.layer.);
      //  a.layer.bindPopup("HIya").openPopup();
    });
    //clusterclick marker eventa
    // this.markers.on('clusterclick', function(a) {
    //   //Each clusterMarker onClick function
    //   //console.log('clustermarker ' + a.layer);
    // });

    // this.markers.on('clustermouseover', function(a) {
    //     ////Get all child markers of the hovered clustermarker
    //     var m = a.layer.getAllChildMarkers();
    //     var content = '';
    //     //Loop through each marker and grab the content from that markers popup
    //     for (var i = 0; i < m.length; i++) {
    //       content += m[i]._popup._content;
    //     }
    //     //Save the location of the first pop up and give that location to the markercluster
    //     while (!m[0]._icon) {
    //       m[0] = m[0].__parent;
    //     }
    //     m[0].bindPopup(content).openPopup();
    //   });
  }

  removeAllBoundingBox(map, viewportlayer) {
    map.removeLayer(this.hoverLayerGroup);
    map.removeLayer(viewportlayer);
  }

  removeBoundingBox(product) {
    this._map.removeLayer(this.hoverLayer);
  }
}
