import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../services/users.service';
@Component({
  selector: 'forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.css'],
})
export class ForgotPasswordComponent {
  constructor(private Auth: AuthService, private usersService: UsersService) {}
  siteForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });
  hasSubmitted: any;
  ngOnInit() {}
  reset() {
    this.hasSubmitted = true;
    let json = { email: this.siteForm.controls['email'].value };
    this.usersService.getUserForgotPW(json).subscribe(result => {
      console.log(result);
      let resetJson = {
        email: result[0].email,
        sessionToken: sessionStorage.getItem('cookie'),
        whoIssuedRoute: sessionStorage.getItem('email'),
      };
      this.usersService
        .resendUserVerificationKey(resetJson)
        .subscribe(resetResult => {
          //console.log(resetResult);
        });
    });
  }
}
