import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from '../../services/sites.service';
import { SurveyTypesService } from '../../services/surveytypes.service';
import { ProductsService } from '../../services/products.service';
import { DataTypesService } from '../../services/datatypes.service';
import { XmlParse } from '../../helper-classes/XmlParse';
import { MatSort, MatTableDataSource } from '@angular/material';
import { latLng, tileLayer, Map, LatLngBounds, LatLng } from 'leaflet';
import { LinkSitesProductsService } from '../../services/link_sites_products.service';
import * as uuid from 'uuid';

import {
  FormBuilder,
  NgForm,
  FormGroup,
  FormControl,
  Validators,
  Form,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
declare var require: any;
var s3Service = require('../../../../lib/s3Util');
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { take, mergeMap, startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoggingJson } from '../../app/logging-json';
import { LoggingService } from '../../services/logging.service';
var parser = require('fast-xml-parser');
var he = require('he');

import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { FileService } from '../../services/FileService';
import { UsersService } from '../../services/users.service';
import * as moment from 'moment';

var options = {
  attributeNamePrefix: '@_',
  attrNodeName: 'attr', //default is 'false'
  textNodeName: '#text',
  ignoreAttributes: true,
  ignoreNameSpace: false,
  allowBooleanAttributes: false,
  parseNodeValue: true,
  parseAttributeValue: false,
  trimValues: true,
  cdataTagName: '__cdata', //default is 'false'
  cdataPositionChar: '\\c',
  localeRange: '', //To support non english character in tag/attribute values.
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  attrValueProcessor: (val, attrName) =>
    he.decode(val, { isAttributeValue: true }), //default is a=>a
  tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
  stopNodes: ['parse-me-as-string'],
};
//Global Declarations
declare let L;
@Component({
  selector: 'details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.css'],
})
// @Directive({
//   selector: '[appDragDrop]'
// })
export class DetailsPageComponent {
  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    ],
    zoom: 6,
    center: latLng(26.72, -89.68),
  };
  newSite: Object = {
    label: '',
  };

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('picker') pickerRef: ElementRef;
  @ViewChild('dataForm') dataForm: NgForm;

  metadataForm = new FormGroup({
    metadataFileName: new FormControl('', [Validators.required]),
  });
  confirmForm = new FormGroup({});
  existingSitesForm = new FormGroup({
    searchValue: new FormControl(''),
  });
  dataSource: any;
  matTableDataSource = new MatTableDataSource();
  fDataSource: any;
  displayedColumnsFiles: string[] = ['files'];
  public files: NgxFileDropEntry[] = [];
  showError: boolean = false;
  existingSite: boolean = false;
  hasSubmitMetadataForm: boolean = false;
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<string[]>;
  siteIds = new Array();
  sitesAdded = new Array();
  dropZoneText: string;
  surveyTypes: any;
  IsChecked: boolean;
  singleFileDiv: boolean;
  fileDropDiv: boolean;
  dataTypes: any;
  existingSites: any;
  maxDate = new Date();
  s3Url = 'https://lidar-app-uploads.s3.amazonaws.com/';
  calculatedCentroid: any;
  calculatedBounds: any;
  /************** Site Form **************/
  isNewSite = true;
  siteForm: NgForm;
  uploadForm: NgForm;
  siteName: string;
  siteDescription: string;
  siteLatitude: number;
  siteLongitude: number;
  productJsonXml: any;
  /***************************************/

  /************** Upload Form **************/
  surveyType: any;
  dataType: any;
  productFileName: string;
  metadataFileName: string;
  surveyDate: any;
  productDescription: string;
  /***************************************/

  /************** File Upload **************/
  showRenderer: boolean = false;
  percentComplete: number = 0;
  productFile: File;
  metadataFile: File;
  shapeFile: File;
  DBF: File;
  PRJ: File;
  products: any;
  product: any;
  productArrayOfObjects: any;
  productsLinks: any;
  productFilesStringArray: string[] = [];
  productFilesArray: File[] = [];
  productDirectory: string = '';
  productSurveyType: string = '';
  productDataType: string = '';
  loaded: boolean = false;
  _map: Map;
  show: boolean = false;
  layers = [];
  customMarker: any;
  showProgressBar: boolean = false;
  productUUID: string = '';
  metadataUUID: string = '';
  confirmationForm: any;
  thumbSource: string = '';
  showThumbnail: boolean = false;
  /*****************************************/
  // public dropped(files: NgxFileDropEntry[]) {
  //   this.files = files;
  //   var fileList: FileList;
  //   console.log('DROPPED!');
  //   console.log(files);

  //   for (const droppedFile of files) {
  //     console.log(droppedFile);
  //     // Is it a file?
  //     if (droppedFile.fileEntry.isFile) {
  //       const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

  //       fileEntry.file((file: File) => {
  //         // Here you can access the real file
  //         this.productDirectory = droppedFile.relativePath.substr(
  //           0,
  //           droppedFile.relativePath.indexOf('/')
  //         );
  //         if (
  //           this.productDirectory === null ||
  //           this.productDirectory === undefined ||
  //           this.productDirectory === ''
  //         ) {
  //           alert(
  //             'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
  //           );
  //         }
  //         this.productFilesStringArray.push(file.name);
  //         this.productFilesArray.push(file);
  //         if (file.name.endsWith('.shp')) {
  //           //Store shapefile to send to server later
  //           this.shapeFile = file;
  //         } else if (file.name.endsWith('.dbf')) {
  //           this.DBF = file;
  //         } else if (file.name.endsWith('.prj')) {
  //           this.PRJ = file;
  //         }
  //         this.dropZoneText = droppedFile.relativePath.substr(
  //           0,
  //           droppedFile.relativePath.indexOf('/')
  //         );
  //       });
  //     } else {
  //       // It was a directory (empty directories are added, otherwise only files)
  //       const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
  //       console.log(droppedFile.relativePath, fileEntry);
  //     }
  //   }
  // }

  public longest_common_starting_substring(inputArray) {
    const arr = inputArray.concat().sort();
    const a1 = arr[0];
    const a2 = arr[arr.length - 1];
    const L = a1.length;
    let i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
    return a1.substring(0, i);
  }
  public clicked(event) {
    // //var input = document.createElement('input');
    // input.type = 'file';
    // input.click();
    // console.log(input);
    // console.log(event);
    var input = document.getElementById('folderInput');
    input.click();
    var test = document.getElementById('dropZoneId');
    console.log(test);
  }
  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
  testable: any;
  constructor(
    private sitesService: SitesService,
    private productService: ProductsService,
    private surveyTypesServices: SurveyTypesService,
    private dataTypesServices: DataTypesService,
    private userService: UsersService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private linkSitesProductsService: LinkSitesProductsService,
    private loggingService: LoggingService,
    private fileservice: FileService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.dataSource = [];
    // Populating survey types drop down
    this.IsChecked = false;
  }
  OnChange($event) {
    this.IsChecked = $event.checked;
  }
  ngOnInit() {
    this.surveyTypesServices
      .getSurveyTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.surveyTypes = result;
          // Populating data types drop down
          this.dataTypesServices
            .getDataTypes()
            .pipe(take(1))
            .subscribe(
              result => {
                this.dataTypes = result;
                var routeSub = this.route.params.subscribe(params => {
                  this.getProduct(params.id);
                });
              },
              error => console.log(error)
            );
        },
        error => console.log(error)
      );

    // // Getting all the existing sites
    // this.sitesService
    //   .getOnlySites()
    //   .pipe(take(1))
    //   .subscribe(
    //     result => {
    //       this.existingSites = result;
    //       this.dataForm.controls['siteName'].setValue(
    //         this.existingSites[0].site_label
    //       );
    //       this.dataForm.controls['siteDescription'].setValue(
    //         this.existingSites[0].site_description
    //       );
    //       this.dataForm.controls['siteCentroid'].setValue(
    //         this.existingSites[0].site_location.latitude.toString() +
    //           ', ' +
    //           this.existingSites[0].site_location.longitude.toString()
    //       );
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   );

    // Checking if user is logged in
    // if (sessionStorage.getItem('email') !== null)
    // {
    // } else {
    //   console.log('Please login!');

    //   this.router.navigate(['/login', { continue: this.router.url }]).then(
    //     nav => {
    //       //console.log(nav);
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    // }
    this.dropZoneText = 'Drop Folder Here';
  }

  onChangeSelectedSites(selectedSites: any) {
    this.existingSite = true;
    this.sitesAdded = selectedSites;
  }

  viewProduct(product) {
    console.log(product);
    if (product[0].data_index_id == 1) {
      //If the product is a pointcloud navigate to lidar.tamucc
      window.open('http://lidar.tamucc.edu/pv/' + product[0].product_uuid);
      //terrestrial lidar
    } else {
      alert('Visualization for this product type is not supported. Yet!');
      // this.router
      //   .navigate(['/ortho-view-download', product[0].product_index_id])
      //   .then(
      //     nav => {
      //       //  console.log(nav);
      //     },
      //     err => {
      //       console.log(err);
      //     }
      //   );
    }
    // window.open(
    //   'http://lidar.tamucc.edu/pv/7b18a491-c89d-4594-863d-319af49356fe/'
    // );
  }

  // To prevent duplicate products showing, the tables datasource will just be products
  getProduct(id) {
    var productId = { productId: id };
    this.productService
      .getProduct(productId)
      .pipe(take(1))
      .subscribe(
        product => {
          console.log(product);
          // console.log(product[0]);
          this.product = product[0];
          window.document.title = this.product.product_label;
          this.changeDetector.detectChanges();
          this.product.filesWExt = [];

          for (var i = 0; i < product[0].files.length; i++) {
            var tmpJson = { filename: this.product.files[i] };
            this.product.filesWExt.push(tmpJson);
          }
          for (var i = 0; i < this.surveyTypes.length; i++) {
            if (
              this.surveyTypes[i].survey_index_id.toString() ===
              product[0].survey_id.toString()
            ) {
              this.dataForm.controls['surveyType'].setValue(
                this.surveyTypes[i].survey_type
              );
            }
          }
          for (var i = 0; i < this.dataTypes.length; i++) {
            if (
              this.dataTypes[i].data_index_id.toString() ===
              product[0].data_id.toString()
            ) {
              this.dataForm.controls['dataType'].setValue(
                this.dataTypes[i].data_type
              );
            }
          }

          this.dataForm.controls['productDescription'].setValue(
            product[0].product_description
          );
          this.dataForm.controls['metadataFileName'].setValue(
            /[^/]*$/.exec(product[0].s3_path_metadata)[0]
          );
          this.dataForm.controls['productLabel'].setValue(
            product[0].product_label
          );

          this.dataForm.controls['surveyDate'].setValue(
            moment(product[0].product_survey_date).format('YYYY-MM-DD')
          );
          this.dataForm.controls['productUUID'].setValue(
            product[0].product_uuid
          );
          //var t = "https://lidar-app-uploads.s3.amazonaws.com/lidar-files/Other/DSM/EXAMPLE_DATA_BOB_HALL_CAMPGROUND_DSM/EXAMPLE_DATA_BOB_HALL_CAMPGROUND_DSM.xml";
          if (product[0].data_type == 'Point Cloud') {
            this.showThumbnail = false;
          } else {
            this.showThumbnail = true;
          }
          var t =
            'https://lidar-app-uploads.s3.amazonaws.com/lidar-files/' +
            product[0].survey_type +
            '/' +
            product[0].data_type +
            '/' +
            moment(product[0].product_survey_date).format('YYYY-MM-DD') +
            '/' +
            product[0].product_label +
            '/';
          var thumbString = '';
          // console.log('edsdf');
          for (var i = 0; i < product[0].files.length; i++) {
            //is the file a potential thumbnail?

            var fileString: String = product[0].files[i];
            // console.log(fileString);
            if (fileString.includes('thumb'.toUpperCase())) {
              //verify product is a thumbnail image type
              var tempString = product[0].files[i];
              // console.log(tempString);
              if (
                tempString.toUpperCase().endsWith('.JPG') ||
                tempString.toUpperCase().endsWith('.JPEG')
              ) {
                //product is a thumbnail add to end of thumbString
                thumbString = product[0].files[i];
              }
            }
          }
          console.log(thumbString);
          console.log(t);
          // t = t.substr(0, t.lastIndexOf("/")+1);
          this.thumbSource = t + thumbString;
        },
        error => {
          console.log(error);
        }
      );
  }

  ngOnDestroy() {
    try {
      this.testable.unsubscribe();
    } catch (e) {
      console.log('Unable to unsubscribe: ' + e);
    }
  }
}
