import { Component } from '@angular/core';
import { tileLayer, latLng } from 'leaflet';

const MAP_OPTIONS = {
  layers: [
    tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: '...',
    }),
  ],
  zoom: 8,
  center: latLng(27.183007, -90.068012),
};

@Component({
  selector: 'main-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent {
  leafletOptions = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    ],
    zoom: 8,
    doubleClickZoom: true,
    center: latLng(28.38, -93.5),
  };

  constructor() {}

  ngOnInit() {
    //console.log('init map', this.leafletOptions);
  }
}
