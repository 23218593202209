import { Component } from '@angular/core';
import {
  NgForm,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { control } from 'leaflet';
import { AuthService } from '../../services/auth.service';

//declare var require: any
//const bcrypt = require('bcrypt');
@Component({
  selector: 'change-password',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.css'],
})

//declare var require: any;
export class ChangePasswordComponent {
  param1: any;
  // siteForm: NgForm;
  siteForm = new FormGroup({
    ffname: new FormControl('', [Validators.required]),
    llname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})/
      ),
    ]),
    confirmpassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})/
      ),
    ]),
  });
  constructor(
    private activatedroute: ActivatedRoute,
    private usersService: UsersService,
    private router: Router,
    private Auth: AuthService
  ) {}
  ngOnInit() {
    //On init, grab supplied ID and query the database for a user with that key
    //If Key finds a user, auto supply the forms

    if (this.activatedroute.snapshot.params.firstname) {
      //process as change password
      this.siteForm.setValue({
        ffname: this.activatedroute.snapshot.params.firstname,
        llname: this.activatedroute.snapshot.params.lastname,
        email: sessionStorage.getItem('email'),
        password: '',
        confirmpassword: '',
      });
    } else {
      //process as 1st time change password
      this.activatedroute.queryParams.subscribe(params => {
        var id = params['id'];
        var json = { validationkey: id };
        //console.log(json);
        this.usersService.getUserByValidationKey(json).subscribe(result => {
          var json = result;
          this.siteForm.setValue({
            ffname: json[0].firstname,
            llname: json[0].lastname,
            email: json[0].email,
            password: '',
            confirmpassword: '',
          });
        });
      });
    }
  }

  login() {
    //this if statement allows us to manually change our OWN password ( not verify code)
    if (this.activatedroute.snapshot.params.firstname) {
      if (sessionStorage.getItem('email') === this.siteForm.value.email) {
        let json = { email: this.siteForm.value.email };
        this.usersService.getUser(json).subscribe(result => {
          if (result) {
            var changePassJson = {
              email: this.siteForm.value.email,
              password: encodeURIComponent(this.siteForm.value.password),
              validationkey: '-999',
              sessionToken: sessionStorage.getItem('cookie'),
              whoIssuedRoute: sessionStorage.getItem('email'),
            };

            if (
              this.siteForm.value.password ===
              this.siteForm.value.confirmpassword
            ) {
              this.usersService
                .changeUserPassword(changePassJson)
                .subscribe(result => {
                  //Remove Validation Key
                  var updateJson = {
                    email: this.siteForm.value.email,
                    validation: 1,
                    validationkey: '',
                    sessionToken: sessionStorage.getItem('cookie'),
                    whoIssuedRoute: sessionStorage.getItem('email'),
                  };
                  this.usersService
                    .updateUserValidationKeyWithKey(updateJson)
                    .subscribe(result => {
                      localStorage.clear();
                      sessionStorage.clear();
                      this.Auth.setLoggedIn(false);
                      this.router.navigate(['/login']);
                    });
                });
            } else {
              alert('Please make sure your passwords match.');
            }
          }
        });
      }
    } else {
      this.activatedroute.queryParams.subscribe(params => {
        var id = params['id'];
        var json = { validationkey: id };
        this.usersService.getUserByValidationKey(json).subscribe(result => {
          var json = result;
          let email = json[0].email;
          if (email === json[0].email) {
            //Email matches the key in the URL
            // console.log("EMAIL MATCHES. VALID KEY");

            var changePassJson = {
              email: email,
              password: encodeURIComponent(this.siteForm.value.password),
              validationkey: id,
            };

            if (
              this.siteForm.value.password ===
              this.siteForm.value.confirmpassword
            ) {
              this.usersService
                .changeUserPasswordWithVKey(changePassJson)
                .subscribe(result => {
                  //Remove Validation Key
                  var updateJson = {
                    email: email,
                    validation: 1,
                    validationkey: '',
                  };
                  this.usersService
                    .updateUserValidationKey(updateJson)
                    .subscribe(result => {
                      this.router.navigate(['/login']);
                    });
                });
            } else {
              alert('Please make sure your passwords match.');
            }
          }
        });
      });
    }
    //Verify that the ID matches the email supplied and exists in the DB
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }
  hasLetters(myString) {
    return myString.match(/[a-z]/i);
  }
  validation(NgForm) {
    if (!this.passwordMatches(NgForm)) {
      alert('Your password does not match.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.password)) {
      alert('Please enter a password.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.retypepassword)) {
      alert('Please retype your password.');

      return false;
    }
    if (NgForm.value.password.length < 6) {
      alert('Your password must be at least 6 characters long.');
      return false;
    }
    return true;
  }

  inputIsNotNull(input) {
    if (input === null || input === undefined || input === '') {
      return false;
    } else {
      return true;
    }
  }

  passwordMatches(form) {
    if (form.value.password === form.value.retypepassword) {
      return true;
    } else {
      return false;
    }
  }
}
