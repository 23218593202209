import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import {
  MatButtonModule,
  MatInputModule,
  MatIconModule,
  MatSidenavModule,
  MatTableModule,
  MatTooltipModule,
  MatToolbarModule,
  MatCardModule,
  MatFormFieldModule,
  MatListModule,
} from '@angular/material';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { LandingPageModule } from '../landing-page/landing-page.module';
import { AboutPageModule } from '../about-page/about-page.module';
import { SitesModule } from '../sites/sites.module';
import { ProductsModule } from '../products/products.module';
import { LoginPageModule } from '../login-page/login-page.module';
import { LogoutPageModule } from '../logout-page/logout-page.module';
import { DetailsPageModule } from '../details-page/details-page.module';
import { EditProductPageModule } from '../edit-product-page/edit-product-page.module';
import { AllDataModule } from '../all-data/all-data.module';
import { RegistrationModule } from '../register/registration-page.module';
import { ProfileModule } from '../profile-page/profile-page.module';
import { ChangePasswordModule } from '../change-password-page/change-password-page.module';
import { XmlCreatorModule } from '../xml-creator-page/xml-creator-page.module';
import { UserManagementModule } from '../user-management-page/user-management-page.module';
import { ForgotPasswordModule } from '../forgot-password-page/forgot-password-page.module';
import { MatMenuModule } from '@angular/material/menu';

import { AppComponent } from './app.component';
import { SitesService } from '../services/sites.service';
import { ExportXmlService } from '../services/export-to-new-product.service';
import { LoggingService } from '../services/logging.service';
import { FeedbackService } from '../services/feedback.service';
import { RegistrationService } from '../services/registration.service';
import { SocketService } from '../services/socket.service';
import { SurveyTypesService } from '../services/surveytypes.service';
import { OrganizationsService } from '../services/organizations.service';
import { DataTypesService } from '../services/datatypes.service';
import { FileService } from '../services/FileService';
import { LinkSitesProductsService } from '../services/link_sites_products.service';
import { ComponentsModule } from '../components/components.module';
import { UploadService } from '../services/upload.service';
import { AuthGuard } from './auth.guard';
import { appRoutes } from './routes';
import { AuthService } from '../services/auth.service';
import { FeedbackDialogComponent } from '../components/feedback-dialog/feedback-dialog.component';
import { FeedbackDialogModule } from '../components/feedback-dialog/feedback-dialog.module';
import { SiteTableModule } from '../components/site-table/site-table.module';
import { SiteAutocompleteModule } from '../components/site-autocomplete/site-autocomplete.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { httpSetHeaders } from '../../src/httpSetHeaders.interceptor';
import { OrthoViewDownloadModule } from '../ortho-view-download-page/ortho-view-download-page.module';
import { JoyrideModule } from 'ngx-joyride';
import { TriggerService } from '../services/trigger.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
    }),

    JoyrideModule.forRoot(),
    BrowserModule,
    DetailsPageModule,
    NgxSliderModule,
    EditProductPageModule,
    ForgotPasswordModule,
    ProfileModule,
    ChangePasswordModule,
    XmlCreatorModule,
    UserManagementModule,
    LoadingBarHttpClientModule,
    ComponentsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatTooltipModule,
    MatButtonModule,
    MatSidenavModule,
    MatInputModule,
    MatTableModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatListModule,
    LandingPageModule,
    OrthoViewDownloadModule,
    AboutPageModule,
    LoginPageModule,
    LogoutPageModule,
    LeafletModule.forRoot(),
    FlexLayoutModule,
    SitesModule,
    ProductsModule,
    AllDataModule,
    RegistrationModule,
    FeedbackDialogModule,
    SiteTableModule,
    SiteAutocompleteModule,
  ],
  exports: [MatFormFieldModule, MatInputModule],
  providers: [
    ExportXmlService,
    SitesService,
    FileService,
    LoggingService,
    SocketService,
    RegistrationService,
    UploadService,
    FeedbackService,
    SurveyTypesService,
    OrganizationsService,
    DataTypesService,
    LinkSitesProductsService,
    AuthGuard,
    AuthService,
    TriggerService,
    { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [FeedbackDialogComponent],
})
export class AppModule {}
