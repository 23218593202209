import {
  Component,
  Inject,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { SitesService } from '../../../services/sites.service';
import { SurveyTypesService } from '../../../services/surveytypes.service';
import { DataTypesService } from '../../../services/datatypes.service';
import { ProductsService } from '../../../services/products.service';
import { LinkSitesProductsService } from '../../../services/link_sites_products.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSort,
  MatTable,
  MatSnackBar,
} from '@angular/material';

import { MatTableDataSource } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import * as uuid from 'uuid';
import {
  UploadOutput,
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploaderOptions,
} from 'ngx-uploader';
import { Router } from '@angular/router';
import { LoggingJson } from '../../../app/logging-json';
import { LoggingService } from '../../../services/logging.service';
import { AuthService } from '../../../services/auth.service';
import { UsersService } from '../../../services/users.service';
import { take } from 'rxjs/operators';
import { deleteAllObjects } from '../../../../../lib/s3Util';
import { t } from '@angular/core/src/render3';
import * as moment from 'moment';

declare var require: any;
var s3Service = require('../../../../../lib/s3Util');

@Component({
  selector: 'existing-data',
  templateUrl: './existing-data.component.html',
  styleUrls: ['./existing-data.component.scss'],
})
export class ExistingDataComponent implements OnInit {
  displayedColumns = [
    'product_label',
    'survey_type',
    'data_type',
    'survey_date',
    'product_display_name',
    'updated_at',
    'view',
  ];

  // For Add New Product Dialog
  surveyTypes: any;
  dataTypes: any;
  user: any;
  products: any;
  dataSource: any;
  sites: any;
  s3Url = 'https://lidar-app-uploads.s3.amazonaws.com/';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('productTable') productTable: MatTable<any>;
  productArrayOfObjects: any[];

  constructor(
    private sitesService: SitesService,
    private Auth: AuthService,
    public productDialog: MatDialog,
    private dataTypesService: DataTypesService,
    private surveyTypesService: SurveyTypesService,
    private linkSitesProductsService: LinkSitesProductsService,
    private ProductService: ProductsService,
    private router: Router,
    private userService: UsersService,
    private loggingService: LoggingService,
    private changeDetector: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {
    // Get datatypes and survey types
    this.dataTypesService.getDataTypes().subscribe(
      dataTypes => {
        this.dataTypes = dataTypes;
      },
      error => {
        console.log('Error, cannot get datatypes.');
      }
    );

    this.surveyTypesService.getSurveyTypes().subscribe(
      surveyTypes => {
        this.surveyTypes = surveyTypes;
      },
      error => {
        console.log('Error, cannot get surveytypes.');
      }
    );

    // Getting existing products for dialog
    // this.getProducts();
  }

  ngOnInit() {
    //check if user has a valid session
    var json = {
      email: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };
    var user;
    this.userService.getUserType(json).subscribe(result => {
      console.log(result);
      user = result;
      this.user = user;
      if (sessionStorage.getItem('email') === result[0].email) {
        //Session matches DB//Valid User
        console.log('TEST');
        if (result[0].usertype < 2) {
          //user must be admin.
          window.alert(
            'You do not have permission to access this page. \nPlease contact your administrator.'
          );
          this.router.navigate(['/']);
        }
      } else {
        window.alert(
          'We could not verify you as a valid user. \nPlease try logging in again or contact your administrator.'
        );
        this.router.navigate(['/login']);
      }
      this.getProducts(user);
    });
  }
  ngAfterInit() {
    // this.dataSource.sort = this.sort;
  }
  getProducts(user: any) {
    this.ProductService.getAllProducts().subscribe(products => {
      console.log(products);
      this.products = products;
      // console.log(this.products);
      for (var i = this.products.length - 1; i >= 0; i--) {
        this.products[i].filesWithoutExt = this.products[i].files.filter(
          function(file) {
            return (
              file.indexOf('las', 'tif', 'TIF', 'LAS', 'TIFF', 'tiff') !== -1
            );
          }
        );
        // console.log(user[0]);
        if (this.products[i].organization_id !== user[0].organization_id) {
          //Check if Super Admin
          if (user[0].usertype === 3) {
            //User is super admin, load everything
          } else {
            //User is not a super admin and doesn't match organization_ID
            this.products.splice(i, 1);
          }
        }
      }
      console.log(this.products);
      this.dataSource = new MatTableDataSource(this.products);
      this.dataSource.sort = this.sort;
      // this.getLinks();

      this.getFiles(products);
    });
  }
  doFilter(value: string) {
    console.log(this.dataSource);
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(this.dataSource);
    this.productTable.renderRows();
  }
  getFiles(products) {
    this.productArrayOfObjects = [];
    this.ProductService.getAllFiles().subscribe(results => {
      for (var i = 0; i < products.length; i++) {
        products[i].filesWExt = [];
        for (var key in results) {
          var obj = results[key];
          var extPieces = obj.filename.split('.');
          var ext = extPieces[extPieces.length - 1];
          if (ext === 'las' || ext === 'LAS') {
            if (products[i].product_index_id === obj.product_id) {
              products[i].filesWExt.push(obj);
            }
          }
          // } else {
          //   this.notVisible = true;
          // }
        }
      }
    });
    // this.productArrayOfObjects = [];
    // for (var i = 0; i < products.length; i++) {
    //   var productId = { productId: products[i].product_index_id };
    //   this.ProductService.getFiles(productId)
    //     .pipe(take(1))
    //     .subscribe(
    //       results => {
    //         console.log(results);

    //         for (var i = 0; i < products.length; i++) {
    //           products[i].filesWExt = [];
    //           for (var key in results) {
    //             var obj = results[key];
    //             // console.log(obj.filename);
    //             var extPieces = obj.filename.split('.');
    //             var ext = extPieces[extPieces.length - 1];
    //             if (ext === 'las') {
    //               console.log(obj.filename);
    //               products[i].filesWExt.push(obj);
    //             }
    //           }
    //         }
    //         console.log(this.products[0]);

    //         for (var i = 0; i < this.products.length; i++) {
    //           console.log(this.productArrayOfObjects);
    //           this.productArrayOfObjects.push(this.products[i]);
    //         }
    //         //this.productArrayOfObjects = this.products.filesWithoutExt;
    //         console.log(this.productArrayOfObjects);

    //         // this.products[i].filesWithoutExt = results[i].files.filter(function(file)
    //         // {
    //         //   return file.indexOf('las','tif') !== -1;
    //         // });
    //       },
    //       error => {
    //         console.log(error);
    //       }
    //     );
    // }
    // console.log(this.products[0]);
  }
  generateTiles(productId: number, product: any) {
    if (
      window.confirm(
        'Are you sure you want to re-generate the aerial imagery tileset for this product?'
      )
    ) {
      var pathDate = moment(product.product_survey_date).format('YYYY-MM-DD');
      var results = s3Service.s3Util
        .deleteAllObjects(
          'lidar-app-uploads',
          `lidar-files/${product.survey_type}/${product.data_type}/${pathDate}/${product.product_label}/output_tiles_256/`
        )
        .then(result => {
          console.log('Delete Tile Directory Response: ', result);
          var TIF = product.s3_path + product.product_label + '.tif';
          var TFW = product.s3_path + product.product_label + '.tfw';
          var S3PATH = product.s3_path;
          var FILE = product.product_label;
          console.log(TIF, TFW, S3PATH, FILE);
          console.log(S3PATH.substring(0, 5));
          if (S3PATH.substring(0, 5) === 'https') {
            const parts = S3PATH.split('/');
            const slicedParts = parts.slice(3);
            const result = slicedParts.join('/');
            S3PATH = result;
            console.log(S3PATH);
          }
          if (TIF.substring(0, 5) === 'https') {
            const parts = TIF.split('/');
            const slicedParts = parts.slice(3);
            const result = slicedParts.join('/');
            TIF = result;
            console.log(TIF);
          }
          if (TFW.substring(0, 5) === 'https') {
            const parts = TFW.split('/');
            const slicedParts = parts.slice(3);
            const result = slicedParts.join('/');
            TFW = result;
            console.log(TFW);
          }
          s3Service.s3Util
            .invokeLambdaRaster2Tiles(TIF, TFW, FILE, S3PATH)
            .then(result => {
              console.log(result);
              console.log('Asked S3 To Generate Tiles');
            });
        });
    }
  }
  generateDSMTiles(productId: number, product: any) {
    if (
      window.confirm(
        'Are you sure you want to re-generate the DSM tileset for this product?'
      )
    ) {
      var pathDate = moment(product.product_survey_date).format('YYYY-MM-DD');
      var results = s3Service.s3Util
        .deleteAllObjects(
          'lidar-app-uploads',
          `lidar-files/${product.survey_type}/${product.data_type}/${pathDate}/${product.product_label}/output_tiles_256/`
        )
        .then(result => {
          console.log('Delete Tile Directory Response: ', result);
          var TIF = product.s3_path + product.product_label + '.tif';
          //var TFW = product.s3_path + product.product_label + '.tfw';
          var S3PATH = product.s3_path;
          var FILE = product.product_label;
          console.log(TIF, S3PATH, FILE);
          console.log(S3PATH.substring(0, 5));
          if (S3PATH.substring(0, 5) === 'https') {
            const parts = S3PATH.split('/');
            const slicedParts = parts.slice(3);
            const result = slicedParts.join('/');
            S3PATH = result;
            console.log(S3PATH);
          }
          if (TIF.substring(0, 5) === 'https') {
            const parts = TIF.split('/');
            const slicedParts = parts.slice(3);
            const result = slicedParts.join('/');
            TIF = result;
            console.log(TIF);
          }

          s3Service.s3Util
            .invokeLambdaGdalDem(TIF, FILE, S3PATH)
            .then(result => {
              console.log(result);
              console.log('Asked S3 To Generate Tiles');
            });
        });
    }
  }
  async deleteProduct(productId: number, product: any) {
    if (
      window.confirm(
        'Are you sure you want to delete this product permanently? '
      )
    ) {
      var json = JSON.parse(JSON.stringify(product));
      json.whoIssuedRoute = sessionStorage.getItem('email');
      json.sessionToken = sessionStorage.getItem('cookie');
      console.log(json);
      this.ProductService.deleteProduct(productId, json).subscribe(
        async result => {
          var loggingData = new LoggingJson(this.loggingService);
          loggingData.user = sessionStorage.getItem('email');
          loggingData.action = LoggingJson.Actions.DELETE;
          loggingData.comments = LoggingJson.Comments.DELETE_PRODUCT;
          loggingData.changes = {
            product_index_id: product.product_index_id,
            product_label: product.product_label,
          };
          loggingData.submit();
          var pathDate = moment(product.product_survey_date).format(
            'YYYY-MM-DD'
          );
          // `lidar-files/${this.productSurveyType}/${this.productDataType}/${this.productDirectory}/${this.metadataFileName}`
          var results = s3Service.s3Util
            .deleteAllObjects(
              'lidar-app-uploads',
              `lidar-files/${product.survey_type}/${product.data_type}/${pathDate}/${product.product_label}/`
            )
            .then(result => {
              if (
                result.Errors.length === 0 &&
                result.$response.httpResponse.statusCode == 200
              ) {
                //successfully deleted
                alert('This product has been deleted sucessfully!');
              } else {
                alert(
                  'Error! Something went wrong, please contact your administrator.'
                );
              }
              this.getProducts(this.user);
              this.changeDetector.detectChanges();
            })
            .catch(e => {
              console.log(e);
            });
          this.changeDetector;
          //this.getProducts(this.siteId);
        },
        error => {
          console.log(error);
          alert('Error, cannot delete the proudct.');
        }
      );
    }
  }

  // deleteSite(siteId: number, site: any) {
  //   if (
  //     window.confirm(
  //       'Are you sure you want to delete this site permanently?\nAll products associate with this site will still exist. '
  //     )
  //   ) {
  //     var json = JSON.parse(JSON.stringify(site));
  //     json.whoIssuedRoute = sessionStorage.getItem('email');
  //     json.sessionToken = sessionStorage.getItem('cookie');

  //     this.sitesService.deleteSite(siteId, json).subscribe(
  //       result => {
  //         var loggingData = new LoggingJson(this.loggingService);
  //         loggingData.user = sessionStorage.getItem('email');
  //         loggingData.action = LoggingJson.Actions.DELETE;
  //         loggingData.comments = LoggingJson.Comments.DELETE_SITE;
  //         loggingData.changes = site;
  //         loggingData.submit();
  //         alert('The site was deleted sucessfully!');
  //         this.getSites();
  //       },
  //       error => {
  //         alert('Error, the site could not be deleted!');
  //       }
  //     );
  //   }
  // }

  // Open the add new product dialog
  openProductDialog(product: any) {
    this.router.navigate(['/edit-product/', product.product_index_id]).then(
      nav => {
        //  console.log(nav);
        window.location.reload();
      },
      err => {
        console.log(err);
      }
    );
  }
  openDialogAddNewProduct(site: any) {
    const dialogRef = this.productDialog.open(DialogAddNewProduct, {
      maxWidth: '800px',
      minWidth: '500px',
      // maxHeight: '800px',
      // minHeight: '250px',
      position: { top: '0' },
      data: {
        site: site,
        surveyTypes: this.surveyTypes,
        dataTypes: this.dataTypes,
        existingProducts: this.products,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result === '') {
        return;
      }

      if (result.products instanceof Array) {
        this.createMultipleLinks(site.site_index_id, result);
      } else {
        // Create Proudct
        this.createProduct(result, site.site_index_id);
      }
    });
  }

  // Creating a new Product
  createProduct(productData: any, siteId: number) {
    // File upload first
    // Product file
    let uniqueId = uuid.v4();
    let s3ReturnData = s3Service.s3Util.uploadFile(
      'lidar-app-uploads',
      `lidar-files/${productData.productFile.name}`,
      productData.productFile,
      null,
      uniqueId
    );

    let uniqueID = uuid.v4();
    // Metadata file
    let metadataS3ReturnData = s3Service.s3Util.uploadFile(
      'lidar-app-uploads',
      `lidar-files/${productData.metadataFileName}`,
      productData.metadataFile,
      null,
      uniqueID
    );

    //  Bucket: "lidar-app-uploads", Key: "lidar-files/mountain_wallpaper.jpg", Body: File, ContentType: "image/jpeg", Location: null}
    productData.s3_path = this.s3Url + s3ReturnData.Key;
    productData.s3_path_metadata = this.s3Url + metadataS3ReturnData.Key;
    productData.product_UUID = uniqueId;
    productData.product_metadata_UUID = uniqueID;

    var json = JSON.parse(JSON.stringify(productData));
    json.whoIssuedRoute = sessionStorage.getItem('email');
    json.sessionToken = sessionStorage.getItem('cookie');

    // this.productsService.createProductForSite(json).subscribe(
    //   product => {
    //     var loggingData = new LoggingJson(this.loggingService);
    //     loggingData.user = sessionStorage.getItem('email');
    //     loggingData.action = LoggingJson.Actions.INSERT;
    //     loggingData.comments = LoggingJson.Comments.INSERT_PRODUCT;
    //     loggingData.changes = {
    //       product_index_id: product.product_index_id,
    //       product_label: product.product_label,
    //     };
    //     loggingData.submit();
    //     this.createSiteProductLink(siteId, product.product_index_id);
    //   },
    //   error => {
    //     console.log('Error, cannot create the product');
    //   }
    // );
  }

  createSiteProductLink(siteId: number, productId: number) {
    // Formating data before sending it to create the link
    let link = {
      siteId: siteId,
      productId: productId,
      whoIssuedRoute: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
    };

    this.linkSitesProductsService.createLinkSiteProduct(link).subscribe(
      result => {
        alert('The product has been sucessfully added to the site!');
        this.getProducts(this.user); // Refresh products select statement
      },
      error => {
        alert(
          'Error, the product could not be added to the site. Something is wrong with the server.'
        );
        console.log(error);
      }
    );
  }

  createMultipleLinks(siteId: number, products: Array<any>) {
    let data = {
      siteId: siteId,
      products: products,
      whoIssuedRoute: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
    };
    this.linkSitesProductsService.createMultipleLinks(data).subscribe(
      (links: any) => {
        let message = '';

        // If any links were added
        if (links.queryResult.length !== 0) {
          for (let i = 0; i < links.queryResult.length; i++) {
            message += `Product ${links.queryResult[i].product_id} has been added sucessfully!\n`;
          }
        }

        // Checking if the any rows already exist in the database
        if (links.alreadyExists.length !== 0) {
          for (let i = 0; i < links.alreadyExists.length; i++) {
            message += `Product ${links.alreadyExists[i]} already exists on this site\n`;
          }
        }

        alert(message);
      },
      error => {
        alert(
          'Error, cannot add the products to the sites. Something is wrong with the server.'
        );
        console.log(error);
      }
    );
  }

  getSites() {
    // Getting sites
    this.linkSitesProductsService.getAllSitesAndProducts().subscribe(sites => {
      //FILTER BY O
      this.sites = sites;
      // Have to cast sites to any
      this.dataSource = new MatTableDataSource(this.sites);
      this.dataSource.sort = this.sort;
    });
    // this.sitesService.getOnlySites().subscribe(
    //   sites => {
    //     //FILTER BY O
    //     this.sites = sites;
    //     // Have to cast sites to any
    //     this.dataSource = new MatTableDataSource(this.sites);
    //     this.dataSource.sort = this.sort;
    //     console.log(this.sites);

    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

// Product Table Dialog
@Component({
  selector: 'dialog-add-new-product',
  templateUrl: 'dialog-add-new-product.component.html',
  styleUrls: ['./existing-data.component.scss'],
})
export class DialogAddNewProduct {
  dataTypes: any;
  surveyTypes: any;
  existingProducts: any;
  productFile: File;
  metadataFile: File;
  metadataFileName: string;
  productFileName: string;
  maxDate = new Date();
  productDescription: string;

  constructor(
    private ProductDialog: MatDialogRef<DialogAddNewProduct>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Populating the drop downs
    this.dataTypes = data.dataTypes;
    this.surveyTypes = data.surveyTypes;
    this.existingProducts = data.existingProducts;
  }

  // Select Product File
  selectProductFile(files: FileList) {
    // Checking to see if there are files
    if (files.length === 0) {
      return;
    }
    // Reset files in case user clicks it again
    this.productFile = null;
    this.productFileName = files[0].name;
    this.productFile = files[0];
  }

  selectMetadataFile(files: FileList) {
    // Checking to see if there are files
    if (files.length === 0) {
      return;
    }
    // Reset files in case user clicks it again
    this.metadataFile = null;
    this.metadataFileName = files[0].name;
    let metadataExt = this.metadataFileName.substr(
      this.metadataFileName.lastIndexOf('.')
    );
    this.metadataFileName = this.metadataFileName.replace(
      metadataExt,
      `-metadata${metadataExt}`
    );
    this.metadataFile = files[0];
  }

  addProduct(productForm: NgForm) {
    if (productForm.valid) {
      // Adding files to product form then sending it to parent component
      productForm.value.productFile = this.productFile;
      productForm.value.metadataFile = this.metadataFile;
      this.ProductDialog.close(productForm.value);
    }
  }

  addExistingProduct(existingProductForm: NgForm) {
    if (existingProductForm.valid) {
      this.ProductDialog.close(existingProductForm.value);
    }
  }
}
