import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SocketService } from '../../services/socket.service';
import { RegistrationService } from '../../services/registration.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SurveyTypesService } from '../../services/surveytypes.service';
import { Validators } from '@angular/forms';
import { OrganizationsService } from '../../services/organizations.service';
import { LoggingJson } from '../../app/logging-json';
import { LoggingService } from '../../services/logging.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.css'],
})
//declare var require: any;
export class RegistrationComponent {
  organizationTypes: any;
  comparisonNumber: any;

  constructor(
    private registrationService: RegistrationService,
    private organizationsServices: OrganizationsService,
    private loggingService: LoggingService,
    private userService: UsersService,
    private router: Router
  ) {
    // Populating survey types drop down
    this.organizationsServices.getOrganizations().subscribe(
      result => {
        this.organizationTypes = result;
      },
      error => console.log(error)
    );
  }
  ngOnInit() {
    //check if user has a valid session
    var json = {
      email: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };
    this.userService.getUserType(json).subscribe(result => {
      console.log(result);

      if (sessionStorage.getItem('email') === result[0].email) {
        //Session matches DB//Valid User

        if (result[0].usertype < 2) {
          //user must be admin.
          window.alert(
            'You do not have permission to access this page. \nPlease contact your administrator.'
          );
          this.router.navigate(['/']);
        }
      } else {
        window.alert(
          'We could not verify you as a valid user. \nPlease try logging in again or contact your administrator.'
        );
        this.router.navigate(['/login']);
      }
    });
  }

  register(NgForm: NgForm) {
    let flag = this.validation(NgForm);
    if (flag === true) {
      //conver form to json string, then parse to create json object
      var json = JSON.parse(JSON.stringify(NgForm.value));
      //add new attribute to json object
      json.sessionToken = sessionStorage.getItem('cookie');
      json.whoIssuedRoute = sessionStorage.getItem('email');
      //submit form
      this.registrationService.insertNewUser(json).subscribe(
        result => {
          //console.log('Result: ' + result);
          if (result == false) {
            alert('User already exists in the database.');
          } else {
            var loggingData = new LoggingJson(this.loggingService);
            loggingData.user = sessionStorage.getItem('email');
            loggingData.action = LoggingJson.Actions.REGISTER;
            loggingData.comments = LoggingJson.Comments.REGISTER;
            loggingData.submit();

            alert('Validation sent to their email!');
            window.location.reload();
          }
        },
        error => console.log(error),
        () => console.log('text')
      );
    }
  }
  validation(NgForm) {
    if (!this.emailIsValid(NgForm.value.email)) {
      alert('Please enter a valid email address.');

      return false;
    }
    if (!this.passwordMatches(NgForm)) {
      alert('Your password does not match.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.fname)) {
      alert('Please enter your first name.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.lname)) {
      alert('Please enter your last name.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.registrationType)) {
      alert('Please select a user type.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.password)) {
      alert('Please enter a password.');

      return false;
    }
    if (!this.inputIsNotNull(NgForm.value.retypepassword)) {
      alert('Please retype your password.');

      return false;
    }

    if (NgForm.value.password.length < 6) {
      alert('Your password must be at least 6 characters long.');
      return false;
    }
    return true;
  }

  inputIsNotNull(input) {
    if (input === null || input === undefined || input === '') {
      return false;
    } else {
      return true;
    }
  }
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  passwordMatches(form) {
    if (form.value.password === form.value.retypepassword) {
      return true;
    } else {
      return false;
    }
  }
}
