import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../services/products.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css'],
})
export class ProductViewComponent implements OnInit {
  sub;
  product;
  loading: boolean = true;
  id: number;

  constructor(
    private productsService: ProductsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    //also start subscribing to the websocket that sends points for the product. add to scene as needed.
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.productsService.getProduct(this.id).subscribe(product => {
        //console.log('got product', product);
        this.product = product[0];
        this.loading = false;
      });
    });
  }
}
