import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { latLng, tileLayer, Map, polygon, layerGroup } from 'leaflet';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { LandingPageComponent } from '../../landing-page/components/landing-page.component';

//Global Declarations
declare let L;

@Component({
  selector: 'app-search-menu-select-region',
  templateUrl: './search-menu-select-region.component.html',
  styleUrls: ['./search-menu-select-region.component.css'],
})
export class SearchMenuSelectRegionComponent implements OnInit {
  constructor(
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private dataService: DataService,
    public landingPage: LandingPageComponent
  ) {}

  @Input() productList;
  @Input() layerTrackingDict;
  @Input() _map;
  @Input() tab;

  selectedProduct;
  hoverLayer: any;
  hoverLayerGroup: any;
  selectedIndex: any; // Identifies the selected tab of the search menu
  drawBox: any; // Variable holds draw box icon to add and remove to map

  ngOnInit() {
    // console.log('REGION/ProductList: ', this.productList);
  }

  clear() {
    this.productList = [];
    this.landingPage.triggerRemoveDrawnBox();
    this.landingPage.bounds = undefined;
    this.landingPage.rectangleViewPortLayer = undefined;
    this.changeDetector.detectChanges();
    this.landingPage.removeMarkerColor('remove all');
  }
}
