import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { CheckoutDialogComponent } from '../../components/checkout-dialog/checkout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { LandingPageComponent } from '../../landing-page/components/landing-page.component';
import * as _ from 'underscore';

//Global Declarations
declare let L;

@Component({
  selector: 'app-search-menu-search-bar',
  templateUrl: './search-menu-search-bar.component.html',
  styleUrls: ['./search-menu-search-bar.component.css'],
})
export class SearchMenuSearchBarComponent implements OnInit {
  constructor(
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private dataService: DataService,
    private landingPage: LandingPageComponent
  ) {}

  @Input() productList;
  @Input() _map;
  @Input() tab;
  @Input() layerTrackingDict;

  searchText: any;
  myArrayFiltered = [];

  selectedProduct;
  hoverLayer: any;

  ngOnInit() {}

  showResults() {
    this.myArrayFiltered = [];

    if (this.searchText !== undefined && this.searchText !== '') {
      for (var i = 0; i < this.productList.length; i++) {
        // Compare and filter for year
        var productYear = new Date(this.productList[i].product_survey_date);
        if (productYear.getFullYear() === parseInt(this.searchText)) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }

        // Compare and filter for survey type
        if (
          this.productList[i].survey_type
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        ) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }

        // Compare and filter for data type
        if (
          this.productList[i].data_type
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        ) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }

        // Compare and filter for site description
        if (
          this.productList[i].site_description
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        ) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }

        // Compare and filter for site label
        if (
          this.productList[i].site_label
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        ) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }

        // Compare and filter for product label
        if (
          this.productList[i].product_label
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        ) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }
      }
    }

    // console.log('FILTER: ', this.myArrayFiltered);
  }

  //When a product is selected (clicked) in the visible products
  //This function navigates to the product on the ALL-DATA page.
  onSelect(product): void {
    //selecting a product will take you to that product page
    this.selectedProduct = product;
    this.router
      .navigate(['/all-data', this.selectedProduct.product_index_id])
      .then(
        nav => {
          //  console.log(nav);
        },
        err => {
          console.log(err);
        }
      );
  }

  displayBoundingBox(product) {
    var json = JSON.parse(product.geom);
    var states = {
      type: 'Feature',
      properties: { party: 'Republican' },
      geometry: {
        type: 'Polygon',
        coordinates: json.coordinates,
      },
    };

    var myLayer = L.geoJSON(states, {
      coordsToLatLng: function(coords) {
        //                    latitude , longitude, altitude
        //return new L.LatLng(coords[1], coords[0], coords[2]); //Normal behavior
        return new L.LatLng(coords[1], coords[0]);
      },
    });
    this.hoverLayer = myLayer;
    this.hoverLayer.addTo(this._map);

    // var geometry = wkx.Geometry.parse(product.geom);
    // console.log(geometry);
    //   this.hoverPolygon = L.polygon([
    //     [51.509, -0.08],
    //     [51.503, -0.06],
    //     [51.51, -0.047]
    // ]);
    // this.hoverPolygon.addTo(this._map);
  }
  removeBoundingBox(product) {
    this._map.removeLayer(this.hoverLayer);
  }

  checkBoxChangeEvent(product) {
    this.productList.splice(this.productList.indexOf(product), 1);
    this.changeDetector.detectChanges();
    console.log('JSON FROM SELECTREGION', this.landingPage.jsonarray);
    this.landingPage.removeMarkerColor(product);
  }

  clear() {
    this.myArrayFiltered = [];
    this.searchText = '';
  }

  openDialog() {
    this.dataService.changeMessage(this.productList);

    const dialogRef = this.dialog.open(CheckoutDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result: ', this.productList);
    });
  }
}
