import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-download-message',
  templateUrl: './success-download-message.component.html',
  styleUrls: ['./success-download-message.component.css'],
})
export class SuccessDownloadMessageComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  minResponse: boolean = false;
  maxResponse: boolean = false;
  ngOnInit() {
    console.log(this.data);

    const minConditions = ['KB', 'MB'];
    const maxConditions = ['GB', 'TB'];

    const containsMinSubstring = minConditions.some(substring =>
      this.data.tSize.includes(substring)
    );
    const containsMaxSubstring = maxConditions.some(substring =>
      this.data.tSize.includes(substring)
    );

    if (containsMinSubstring) {
      this.minResponse = true;
    }
    if (containsMaxSubstring) {
      this.maxResponse = true;
    }
    // for (var i = 0; i < this.data.length; i++)
    //  {
    //   tempTotalSize += this.convertToBytes(this.data[i].totalSize);
    // }
    // let testValue = this.convertToFormattedSize(tempTotalSize);
    // if (minConditions.some(el => testValue.includes(el))) {
    //   console.log(testValue + 'MIN CONDITIONS MET');
    //   this.minResponse = true;
    //   //console.log(this.convertToBytes(testValue));
    // } else if (maxConditions.some(el => testValue.includes(el))) {
    //   console.log(testValue + 'MAX CONDITIONS MET');
    //   this.maxResponse = true;
    // }
  }
  convertToBytes(x) {
    //Stack overflow answer
    //Define units
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    var bytes = 0;
    if (x.includes('bytes')) {
      let value = parseInt(x, 10) || 0;
      bytes += value;
    } else if (x.includes('KB')) {
      x.replace(/KB/g, '');

      let value = parseInt(x, 10) || 0;

      value = value * 1024;
      console.log(value);
      bytes += value;
    } else if (x.includes('MB')) {
      let value = parseInt(x, 10) || 0;

      value = value * 1024 * 1024;
      console.log(value);
      bytes += value;
    } else if (x.includes('GB')) {
      let value = parseInt(x, 10) || 0;

      value = value * 1024 * 1024 * 1024;
      console.log(value);
      bytes += value;
    }
    console.log(bytes);
    return bytes;
    // let l = 0,
    //   n = parseInt(x, 10) || 0;

    // while (n >= 1024 && ++l) {
    //   n = n / 1024;
    // }

    // return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  convertToFormattedSize(bytes) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let l = 0;
    while (bytes >= 1024 && ++l) {
      bytes = bytes / 1024;
    }
    return bytes.toFixed(bytes < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  closeModal() {
    const dialogRef = this.dialog.closeAll();
    window.location.reload();
  }
}
