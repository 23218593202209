import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { CheckoutDialogComponent } from '../../components/checkout-dialog/checkout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckbox, MatExpansionPanel, MatSnackBar } from '@angular/material';
import { DataService } from '../../services/data.service';
import { LandingPageComponent } from '../../landing-page/components/landing-page.component';
import { ProductsService } from '../../services/products.service';
import { Observable, of } from 'rxjs';
import { ValueTransformer } from '@angular/compiler/src/util';
import { first } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { TriggerService } from '../../services/trigger.service';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { Options } from '@angular-slider/ngx-slider';

import * as _ from 'underscore';
import { OrganizationsService } from '../../services/organizations.service';
import * as moment from 'moment';

//Global Declarations
declare let L;
var geoJsonWithProperties;
var s3Service = require('../../../../lib/s3Util.js');
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit {
  screenVH: string;
  screenHeight: number;
  screenWidth: number;
  _COUNTER: number = 0;
  constructor(
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private dataService: DataService,
    private landingPage: LandingPageComponent,
    private productservice: ProductsService,
    private snackBar: MatSnackBar,
    private triggerService: TriggerService,
    private organizationService: OrganizationsService
  ) {
    //console.log('Product Page Constructor');
  }

  @Input() productList;
  @Input() _map;
  @Input() viewingCart;
  @Input() tab;
  @Input() layerTrackingDict;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  @ViewChild('sortingSelect') sortingSelect;
  @ViewChild('providerSelect') providerSelect;
  @ViewChild('notificationColorBar') notificationColorBar: MatExpansionPanel;

  selectedProduct;
  minInitial: any;
  filterChkBox: any;
  maxInitial: any;
  layers = [];
  dataLayers = [];
  productArray = [];
  markers;
  productCount: number = 0;
  hoverLayer: any;
  hoverLayerGroup: any;
  rectangleViewPortLayer: any;
  loaded: boolean = false;
  selectAll: boolean = false;
  _container: any;
  _startLayerPoint: any;
  selectedIndex: any; // Identifies the selected tab of the search menu
  drawBox: any; // Variable holds draw box icon to add and remove to map
  detailedView = {};
  checkBoxArray = [];
  displayLabelTextChkBox = false;
  zooming = false;
  years = [];
  currentYear = new Date().getFullYear();
  minYear = this.currentYear;
  singleYear = false;
  theYear: any;
  filterLowValue: any;
  filterHighValue: any;
  yearsChanged = false;
  tileLayerOrthoStorage = {};
  tileLayerGroup = L.layerGroup();

  private _value: string;
  public get value(): string {
    return this._value;
  }
  public set value(v: string) {
    this._value = v;
  }

  hoverID: any = -1;
  productId: any;
  tileSubscription: any;
  thatLayer: any;
  fileCounter = 0;
  productCounter = 0;
  tempTab: any;
  testArray: any;
  isHidden: any;
  layerContainer = [];
  featureContainer = [];
  testChangesL: any;
  initCounter = 0;
  surveyTypes = [
    {
      key: 'survey_type',
      value: 'UAS SFM',
    },
    {
      key: 'survey_type',
      value: 'Terrestrial LiDAR',
    },
    {
      key: 'survey_type',
      value: 'UAS LiDAR',
    },
    {
      key: 'survey_type',
      value: 'Mobile LiDAR',
    },
    {
      key: 'survey_type',
      value: 'Other',
    },
  ];
  filterChecks = [];
  sortTypes = [
    {
      id: 1,
      name: 'Clustered Products',
    },
    {
      id: 2,
      name: 'Product Name (A-Z)',
    },
    {
      id: 3,
      name: 'Product Name (Z-A)',
    },
    {
      id: 4,
      name: 'Survey Date (ASC)',
    },
    {
      id: 5,
      name: 'Survey Date (DESC)',
    },
  ];
  providerTypes = [
    // {
    //   organization_index_id: 1,
    //   organization: "All Providers"
    // }
  ];

  dataTypes = [
    {
      key: 'data_type',
      value: 'Point Cloud',
    },
    {
      key: 'data_type',
      value: 'DSM',
    },
    {
      key: 'data_type',
      value: 'DEM',
    },
    {
      key: 'data_type',
      value: 'Aerial Imagery',
    },
    {
      key: 'data_type',
      value: 'Other',
    },
  ];
  panelOpen = false;
  minValue: number = 0;
  storeMinYear: number = 0;
  storeMaxYear: number = 0;
  storePreviousMinYear: number = 0;
  storePreviousMaxYear: number = 0;
  providerSelected: any;
  prevName: string = null;
  prevDate: string = null;
  maxValue: number = 250;
  options: Options = {
    floor: 0,
    ceil: 250,
  };
  async ngOnInit() {
    //console.log(this.productList);
    var THIS: ProductListComponent = this;
    this.initCounter = this.initCounter + 1;
    this.testChangesL = this;
    //change
    //this.productList is filled based on which tab is selected on the landing page
    //I.E. If geosearch is selected then on init the landing page queries the db for all products
    //ONMAPMOVEEND (inlandingpage) the landingpage calculates if any of those products reside within the map bounds
    //IF they do, they are added via double binded parameters to geosearch which passes to the product-list
    this.organizationService.getOrganizations().subscribe(data => {
      console.log(data);
      const indexes = Object.keys(data);
      const objectValues = Object.values(data);
      for (var i = 0; i < indexes.length; i++) {
        //offset index so All Providers can be first
        objectValues[indexes[i]]['organizations_index_id'] += 1;
        this.providerTypes.push(objectValues[indexes[i]]);
      }
      this.providerSelected = 1;
    });
    if (this.productList === undefined) {
      //If the product list is undefined
      this.productList = [];
    }
    this.triggerService.listenForPushpinMouseOver.subscribe(incomingData => {
      if (incomingData !== '') {
        this.hoverID = incomingData.product_index_id;
        this.changeDetector.detectChanges();
      }

      // console.log(JSON.parse(incomingData.options));
    });
    this.triggerService.listenForApplyFilterInstruction.subscribe(data => {
      //console.log("apply filter instruction issued");
      // console.log(data);
      if (data === 'applyFilter') {
        this.showResults();
        data = undefined;
      }
    });
    this.triggerService.listenForApplySortingInstruction.subscribe(data => {
      if (data === 'applySorting') {
        this.applySort();
      }
    });
    this.triggerService.castTrigger.subscribe(data => {
      // console.log('TRIGGERNGONCHANGES');
      this.triggerNgOnChanges();
      //this.updateYear();
    });
    this.triggerService.listenForOpenDialogInstruction.subscribe(data => {
      if (data === 'openDialog') {
        this.openDialog();
      }
    });
    this.triggerService.listenForResetProductCounter.subscribe(data => {
      if (data === 'resetCounter') {
        console.log('resetting counter');
        this.notificationColorBar.close();
        this.productCount = 0;
      }
    });
    this.triggerService.listenForNotificationColorBar.subscribe(data => {
      // console.log(data);
      // if(data === 'open')
      // {
      //   setTimeout(() =>
      //   {
      //     this.notificationColorBar.open();
      //     setTimeout(() =>
      //     {
      //       this.notificationColorBar.close();
      //     },8000)
      //   },2000)
      // }
    });
    if (this.productList.length > 0) this.populateYear();
    this.sortingSelect.nativeElement.value = 1;
    this.applySort();

    for (var i = 0; i < this.productList.length; i++) {
      // let geoJsonObj = JSON.parse(this.productList[i].centroid);
      // // console.log(geoJsonObj);
      // const latlng = L.latLng(
      //   geoJsonObj.coordinates[0],
      //   geoJsonObj.coordinates[1]
      // );
      // //console.log(latlng);
      // var url =
      //   'https://nominatim.openstreetmap.org/search?format=json&limit=3&q=' +
      //   latlng.lat +
      //   ',' +
      //   latlng.lng;
      // var xmlhttp = new XMLHttpRequest();
      // xmlhttp.onreadystatechange = function() {
      //   if (this.readyState == 4 && this.status == 200) {
      //     var myArr = JSON.parse(this.responseText);
      //     return myArr;
      //   }
      // };
      // xmlhttp.open('GET', url, false);
      // xmlhttp.send();
      // //console.log(JSON.parse(xmlhttp.responseText));
      // this.productList[i].state = JSON.parse(
      //   xmlhttp.responseText
      // )[0].display_name;

      var pathDate = moment(this.productList[i].product_survey_date).format(
        'YYYY-MM-DD'
      );

      if (
        this.productList[i].totalSize === 0 ||
        this.productList[i].totalSize === null ||
        this.productList[i].totalSize === undefined
      ) {
        console.log('Product Does Not have a total size.', this.productList[i]);
        this.productList[i]['totalSize'] = await s3Service.s3Util
          .sizeOf(
            'lidar-app-uploads',
            `lidar-files/${this.productList[i].survey_type}/${this.productList[i].data_type}/${pathDate}/${this.productList[i].product_label}/`
          )
          .then(res => {
            return this.convertBytes(res);
          });

        this.productservice
          .updateProductTotalSize(this.productList[i])
          .subscribe(data => {
            console.log(data);
          });
      }

      //console.log(this.productList[i]);
    }
    console.log(this.providerTypes);
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.setScalingDimension(this.screenWidth, this.screenHeight);

    var elements = document.getElementsByClassName('product-card');
    //Set default sort to ASC

    // for(var i = 0; i < elements.length; i++)
    // {
    //   elements[i].addEventListener('click', evt =>
    //   {
    //     const link = evt.target.closest('a')
    //   });
    // }
  }

  setScalingDimension(screenWidth: number, screenHeight: number) {
    console.log(screenWidth);
    //Sets the scale of the map-container and map classes according to screen resolution
    if (screenWidth == 1600 || screenWidth == 1440) this.screenVH = '53.5vh';
    else if (screenWidth == 3440) this.screenVH = '72.5vh';
    else if (screenWidth == 2560) this.screenVH = '72vh';
    else if (screenWidth == 1680) this.screenVH = '60vh';
    else this.screenVH = '62vh';
  }
  onNext() {
    this._map.setView(new L.LatLng(27, -97), 8);
  }
  addOverlay(product) {
    event.stopPropagation();
    this.changeDetector.detectChanges();
    var TIF = product.s3_path + product.product_label + '.tif';
    var TFW = product.s3_path + product.product_label + '.tfw';
    var S3PATH = product.s3_path;
    var FILE = product.product_label;

    product.isBeingViewed = true;
    // console.log(TIF);
    // console.log(TFW);
    // console.log(S3PATH);
    //console.log(FILE);
    // var test = s3Service.s3Util.invokeLambdaRaster2Tiles(TIF,TFW,FILE,S3PATH);

    // console.log(product);
    console.log(this._map.getZoom());
    // googlemaps.html
    var MP = L.geoJson(JSON.parse(product.geom));
    var LAYERBOUNDS = MP.getBounds();
    if (S3PATH.includes('https')) {
      // console.log("The string contains 'https'.");
      var lyr = L.tileLayer(S3PATH + 'output_tiles_256/{z}/{x}/{y}.png', {
        tms: 1,
        opacity: 1.0,
        attribution: '',
        minZoom: 15,
        maxZoom: 20,
        reuseTiles: true,
        tileSize: 256,
        zoomOffset: 0,
        errorTileUrl: '',
        bounds: LAYERBOUNDS,
      });
    } else {
      // console.log("The string does not contain 'https'.");
      var lyr = L.tileLayer(
        'https://lidar-app-uploads.s3.amazonaws.com/' +
          S3PATH +
          'output_tiles_256/{z}/{x}/{y}.png',
        {
          tms: 1,
          opacity: 1.0,
          attribution: '',
          minZoom: 15,
          maxZoom: 20,
          reuseTiles: true,
          tileSize: 256,
          zoomOffset: 0,
          errorTileUrl: '',
          bounds: LAYERBOUNDS,
        }
      );
    }

    lyr.on('tileerror', () => {
      this._COUNTER += 1;
      console.log(this._COUNTER);
    });
    this.tileLayerOrthoStorage[product.product_label] = lyr;
    this.landingPage._map.addLayer(lyr);
    this._map.fitBounds(LAYERBOUNDS);
  }
  removeOverlay(product) {
    event.stopPropagation();
    this.changeDetector.detectChanges();
    product.isBeingViewed = false;
    if (product.product_label in this.tileLayerOrthoStorage) {
      this._map.removeLayer(this.tileLayerOrthoStorage[product.product_label]);
      delete this.tileLayerOrthoStorage[product.product_label];
    }
    //this.landingPage._map.removeLayer(lyr);
    // this._map.fitBounds(LAYERBOUNDS);
  }
  addDSMOverlay(product) {
    event.stopPropagation();
    this.changeDetector.detectChanges();
    var S3PATH = product.s3_path;
    product.isBeingViewed = true;

    // console.log(product);
    console.log(this._map.getZoom());
    // googlemaps.html
    var MP = L.geoJson(JSON.parse(product.geom));
    var LAYERBOUNDS = MP.getBounds();

    if (S3PATH.includes('https')) {
      // console.log("The string contains 'https'.");
      var lyr = L.tileLayer(S3PATH + 'output_tiles_256/{z}/{x}/{y}.png', {
        tms: 1,
        opacity: 1.0,
        attribution: '',
        minZoom: 15,
        maxZoom: 20,
        reuseTiles: true,
        tileSize: 256,
        zoomOffset: 0,
        errorTileUrl: '',
        bounds: LAYERBOUNDS,
      });
    } else {
      // console.log("The string does not contain 'https'.");
      var lyr = L.tileLayer(
        'https://lidar-app-uploads.s3.amazonaws.com/' +
          S3PATH +
          'output_tiles_256/{z}/{x}/{y}.png',
        {
          tms: 1,
          opacity: 1.0,
          attribution: '',
          minZoom: 15,
          maxZoom: 20,
          reuseTiles: true,
          tileSize: 256,
          zoomOffset: 0,
          errorTileUrl: '',
          bounds: LAYERBOUNDS,
        }
      );
    }

    lyr.on('tileerror', () => {
      this._COUNTER += 1;
      console.log(this._COUNTER);
    });
    this.tileLayerOrthoStorage[product.product_label] = lyr;
    this.landingPage._map.addLayer(lyr);
    this._map.fitBounds(LAYERBOUNDS);
  }
  removeDSMOverlay(product) {
    event.stopPropagation();
    this.changeDetector.detectChanges();
    product.isBeingViewed = false;
    if (product.product_label in this.tileLayerOrthoStorage) {
      this._map.removeLayer(this.tileLayerOrthoStorage[product.product_label]);
      delete this.tileLayerOrthoStorage[product.product_label];
    }
  }
  convertBytes(x) {
    //Stack overflow answer
    //Define units
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  clear() {
    //this.productList = [];

    this.landingPage.triggerRemoveDrawnBox();
    this.landingPage.triggerRemoveHoverLayer();
    this.landingPage.bounds = undefined;
    this.landingPage.rectangleViewPortLayer = undefined;
    this.landingPage.triggerMapZoomEnd(this._map);
    this.changeDetector.detectChanges();
    this.landingPage.removeMarkerColor('remove all');
  }
  test() {
    this.changeDetector.detectChanges();
    this.detailedView = [];
    if (this.tempTab !== undefined && this.tileSubscription !== undefined) {
      if (this.tempTab !== this.tab) {
        this.resetAll();
      }
    }

    // re-initializing our users current tab selected on change
    this.tempTab = this.tab;
  }
  applySort() {
    //get value of ddl
    let value: number = this.sortingSelect.nativeElement.value;
    //console.log(value);
    //Sort Results
    if (value == 1) {
      // console.log(this.productList);

      this.productList.sort((a, b) => {
        if (
          a.product_display_name === null &&
          b.product_display_name === null
        ) {
          // If both product_display_name values are null, sort by formatted_product_survey_date
          return a.formatted_product_survey_date >
            b.formatted_product_survey_date
            ? -1
            : 1;
        } else if (a.product_display_name === null) {
          // If a.product_display_name is null, sort it lower
          return 1;
        } else if (b.product_display_name === null) {
          // If b.product_display_name is null, sort it lower
          return -1;
        } else {
          // Otherwise, sort by product_display_name and formatted_product_survey_date
          return (
            //a.product_display_name.localeCompare(b.product_display_name) || (a.formatted_product_survey_date > b.formatted_product_survey_date ? -1 : 1)
            a.product_display_name.localeCompare(b.product_display_name) ||
            (a.formatted_product_survey_date > b.formatted_product_survey_date
              ? -1
              : 1) ||
            (a.survey_type === b.survey_type
              ? 0
              : a.survey_type > b.survey_type
              ? 1
              : -1)
          );
        }
      });

      const colors = ['#648FFF', '#785EF0', '#DC267F', '#FF8235', '#FFC544'];
      let prevProductName = '';
      let prevSurveyDate = '';
      let colorIndex = 0;

      for (var i = 0; i < this.productList.length; i++) {
        if (
          this.productList[i].product_display_name === prevProductName &&
          this.productList[i].formatted_product_survey_date === prevSurveyDate
        ) {
          // Assign the same color as the previous product

          this.productList[i].color = this.productList[i - 1].color;
        } else {
          // Iterate to the next color and assign it to the current product
          this.productList[i].color = colors[colorIndex];
          colorIndex = (colorIndex + 1) % colors.length;
        }

        // Update the previous product name and survey date
        prevProductName = this.productList[i].product_display_name;
        prevSurveyDate = this.productList[i].formatted_product_survey_date;
      }
      // console.log(JSON.parse(JSON.stringify(this.productList)));
    } else if (value == 2) {
      //Product Name A-Z
      this.productList.sort((a, b) =>
        a.product_label > b.product_label ? 1 : -1
      );
    } else if (value == 3) {
      //Product Name Z-A
      this.productList.sort((a, b) =>
        a.product_label < b.product_label ? 1 : -1
      );
    } else if (value == 4) {
      //Survey Date (ASC)
      this.productList.sort((a, b) =>
        a.formatted_product_survey_date > b.formatted_product_survey_date
          ? 1
          : -1
      );
    } else if (value == 5) {
      //Survey Date (DESC)
      this.productList.sort((a, b) =>
        a.formatted_product_survey_date < b.formatted_product_survey_date
          ? 1
          : -1
      );
    } else {
      console.log('Error');
    }
  }
  onChangeProvider() {
    var THIS: ProductListComponent = this;
    let value: number = this.providerSelected;
    //console.log(value);

    // console.log(this.providerTypes);
    // console.log(this.providerSelected);
    //console.log(this.filterChecks);
    //Sort Results
    if (value == 1) {
      for (var i = 0; i < this.filterChecks.length; i++) {
        //if key is survey_type
        if (Object.keys(this.filterChecks[i])[0] === 'organization_id') {
          this.filterChecks.splice(i, 1);
        }
      }
      return;
      //this.showResults();
    } else {
      var organizationObj = {};
      //organization_id: X : 2
      organizationObj['organization_id'] = +value - 1;

      var organizationFilterFound =
        this.filterChecks.filter(function(o) {
          return o.hasOwnProperty('organization_id');
        }).length > 0;

      //  console.log(organizationFilterFound);
      if (organizationFilterFound) {
        for (var i = 0; i < this.filterChecks.length; i++) {
          //if key is survey_type
          if (Object.keys(this.filterChecks[i])[0] === 'organization_id') {
            this.filterChecks[i] = organizationObj;
          }
        }
      } else {
        this.filterChecks.push(organizationObj);
      }
      // console.log(this.filterChecks);
      //this.showResults();
      return;
    }
  }
  onChangeSort(incValue) {
    //insure incoming value is a number
    let value: number = incValue;

    //Sort Results
    if (value === 1) {
      return;
    } else if (value == 2) {
      //Product Name A-Z
      this.productList.sort((a, b) =>
        a.product_label > b.product_label ? 1 : -1
      );
    } else if (value == 3) {
      //Product Name Z-A
      this.productList.sort((a, b) =>
        a.product_label < b.product_label ? 1 : -1
      );
    } else if (value == 4) {
      //Survey Date (ASC)
      this.productList.sort((a, b) =>
        a.formatted_product_survey_date > b.formatted_product_survey_date
          ? 1
          : -1
      );
    } else if (value == 5) {
      //Survey Date (DESC)
      this.productList.sort((a, b) =>
        a.formatted_product_survey_date < b.formatted_product_survey_date
          ? 1
          : -1
      );
    } else {
      console.log('Error');
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log('NGONCHANGES');
    //console.log(this.productList);
    //This function fires anytime someone changes a tab
    //Reset productArray
    this.productArray = [];

    this.detailedView = [];

    //If no product is within a search parameter from the landing page (geosearch, rectangle, filter, etc...)
    if (this.productList !== undefined && this.productList.length > 0) {
      //Loop through the product list which is structured to match the data egress from the database (JSON)
      for (let i = 0; i < this.productList.length; i++) {
        //Separate the files from the product by assigning the product and its file array to a JSONobj we can reference

        let tempJson = { theProduct: null, files: [] };
        tempJson.theProduct = this.productList[i]; //isHidden isChecked isSelected

        //If the current product has files
        if (
          this.productList[i].files !== null &&
          this.productList[i].files !== undefined
        ) {
          //loop through current products files
          for (let x = 0; x < this.productList[i].files.length; x++) {
            var endingArray = ['.tif', '.tiff', '.las'];
            var currentFile = this.productList[i].files[x].toLowerCase();

            //if the current file ends with any of the above endings (.tiff,.tif,.las)
            if (endingArray.some(s => currentFile.endsWith(s))) {
              //If the product is hidden OR not checked add file to tempJson with isChecked/isSelected flags set to false
              if (
                this.productList[i].isHidden === true ||
                this.productList[i].isChecked === false
              )
                tempJson.files.push({
                  fileName: this.productList[i].files[x],
                  isChecked: false,
                  isSelected: false,
                });
              //If the product is checked add the file to tempJSON as isChecked/isSelected
              if (this.productList[i].isChecked === true)
                tempJson.files.push({
                  fileName: this.productList[i].files[x],
                  isChecked: true,
                  isSelected: true,
                });
            }
          }
        }
        //product array should contain a product and a files array. Each file in the files array is a tempjson which contains the file and whether or not
        //the file isChecked or isSelected
        this.productArray.push(tempJson);
        this.detailedView[this.productArray.length - 1] = false;

        if (this.landingPage.selectedIndex == 4) {
          //The user has selected to view the cart
          this.productList[i].isBeingViewed = false;
        }

        //console.log('SET DET AILED VIEW TO FALSE');
      }
    }
    // this.showResults();
    // console.log('CHECKBOXARRAY', this.checkBoxArray);
  }

  public triggerNgOnChanges() {
    this.productArray = [];

    this.detailedView = [];

    //If no product is within a search parameter from the landing page (geosearch, rectangle, filter, etc...)
    if (this.productList !== undefined && this.productList.length > 0) {
      //console.log('HERE');
      //Loop through the product list which is structured to match the data egress from the database (JSON)
      for (let i = 0; i < this.productList.length; i++) {
        //Separate the files from the product by assigning the product and its file array to a JSONobj we can reference

        let tempJson = { theProduct: null, files: [] };
        tempJson.theProduct = this.productList[i]; //isHidden isChecked isSelected

        //If the current product has files
        if (
          this.productList[i].files !== null &&
          this.productList[i].files !== undefined
        ) {
          //loop through current products files
          for (let x = 0; x < this.productList[i].files.length; x++) {
            var endingArray = ['.tif', '.tiff', '.las'];
            var currentFile = this.productList[i].files[x].toLowerCase();

            //if the current file ends with any of the above endings (.tiff,.tif,.las)
            if (endingArray.some(s => currentFile.endsWith(s))) {
              //If the product is hidden OR not checked add file to tempJson with isChecked/isSelected flags set to false
              if (
                this.productList[i].isHidden === true ||
                this.productList[i].isChecked === false
              )
                tempJson.files.push({
                  fileName: this.productList[i].files[x],
                  isChecked: false,
                  isSelected: false,
                });
              //If the product is checked add the file to tempJSON as isChecked/isSelected
              if (this.productList[i].isChecked === true)
                tempJson.files.push({
                  fileName: this.productList[i].files[x],
                  isChecked: true,
                  isSelected: true,
                });
            }
          }
        }
        //product array should contain a product and a files array. Each file in the files array is a tempjson which contains the file and whether or not
        //the file isChecked or isSelected
        this.productArray.push(tempJson);
        this.detailedView[this.productArray.length - 1] = false;

        if (this.landingPage.selectedIndex == 1) {
          //The user has selected to view the cart
          this.productList[i].isBeingViewed = false;
        }

        // console.log('SET DET AILED VIEW TO FALSE');
      }
    }
  }
  populateDownloadCart(json, prodCount = null) {
    //json is the product from the DB
    this.productservice.getFiles(json).subscribe(files => {
      console.log(files);
      //Get Geometry for each tile of selected product
      if (this.viewingCart !== true) this.calculateBoundingBoxEachTile(files);

      for (var i = 0; i < Object.keys(files).length; i++) {
        if (files[i].file_centroid !== null) {
          var copyOfFileArrayForDisplayPurposes = files[i];
          copyOfFileArrayForDisplayPurposes.file_centroid =
            parseFloat(JSON.parse(files[i].file_centroid).coordinates[1])
              .toPrecision(8)
              .toString() +
            ',' +
            parseFloat(JSON.parse(files[i].file_centroid).coordinates[0])
              .toPrecision(8)
              .toString();

          //  this.productList.push(copyOfFileArrayForDisplayPurposes);
        }
      }
    });
  }

  calculateBoundingBoxEachTile(files) {
    console.log(files);
    //If the tilelayergroup doesn't exist (NULL)
    if (this.tileLayerGroup !== undefined) {
      //If the tilelayergroup currently has tiles
      if (this.tileLayerGroup.getLayers().length !== 0) {
        //remove tiles and assign as new layergroup()
        this.tileLayerGroup = L.layerGroup();
      }
    } //If the tileLayerGroup is undefined
    else {
      this.tileLayerGroup = L.layerGroup();
    }

    //Loop through file data sent from server
    for (var i = 0; i < files.length; i++) {
      //make sure each tile's geometry has been set
      if (files[i].file_geom !== null) {
        //build custom geojson
        geoJsonWithProperties = {
          type: JSON.parse(files[i].file_geom).type,
          coordinates: JSON.parse(files[i].file_geom).coordinates,
          properties: {
            name: files[i].filename,
            file: files[i],
          },
        };
        var THIS: ProductListComponent = this;
        //Create geoJSON layer using custom geojson from above
        var myGeoJSON = L.geoJSON(geoJsonWithProperties, {
          //Loop through each feature in the layer
          onEachFeature: function(feature, layer) {
            console.log(layer);
            //console.log('THE STUFF: ', feature, layer);

            //Bind a tooltip to each polygon and define a custom propertie hierarchy called "definedProperties"
            layer.bindTooltip(feature.properties.name, {
              closeButton: false,
            });

            var definedProperties = (layer.definedProperties =
              layer.definedProperties || {});
            definedProperties.type = definedProperties.type || 'Flag';
            definedProperties.name = definedProperties.name || 'polygonTile';
            var props = (definedProperties.properties =
              definedProperties.properties || {});

            console.log(THIS.productArray);
            if (
              THIS.productArray != undefined &&
              THIS.productArray.length > 0
            ) {
              for (var i = 0; i < THIS.productArray.length; i++) {
                for (var j = 0; j < THIS.productArray[i].files.length; j++) {
                  if (THIS.productArray[i].files[j].isChecked == false) {
                    // console.log("Not checked")
                    feature.properties.checked = false;
                  } else {
                    //  console.log("checked")
                    feature.properties.checked = true;
                  }
                  if (THIS.productArray[i].files[j].isSelected == false) {
                    //   console.log("Not selected")
                    layer.setStyle({
                      fillColor: '#3388ff',
                    });
                    feature.properties.selected = false;
                    props._selected = false;
                    layer.definedProperties.properties._selected = false;
                  } else {
                    // console.log("selected")
                    layer.setStyle({
                      fillColor: '#fff700',
                    });
                    props._selected = true;
                    feature.properties.selected = true;
                    layer.definedProperties.properties._selected = true;
                  }
                }
              }
            } else {
              // console.log("HEEEEEEEEE");
              //set definedProperties: property: _selected to false by default
              props._selected = false;
              layer.definedProperties.properties._selected = true;
              feature.properties.checked = true;
            }

            if (layer !== undefined) {
              THIS.layerContainer.push(layer);
              // THIS.layerContainer[i] = layer;
            }
            if (feature !== undefined) {
              THIS.featureContainer.push(feature);
            }

            // if (layer.definedProperties.properties._selected == true)
            //  {
            //     console.log("YAER!!" + layer);
            //     layer.setStyle({
            //         fillColor: '#fff700'
            //     });
            // }
            // if (layer.feature.properties.selected == true)
            //  {
            //     console.log("Setting Layer Style: " + layer);
            //     layer.setStyle({
            //         fillColor: '#fff700'
            //     });
            // }

            //Each polygon needs mouse events to display tooltip and highlight
            // layer.on('mouseover', function (e) {
            //   this.openPopup();
            //   //If the polygon is already selected keep it the selected color
            //   if (layer.definedProperties.properties._selected == true)
            //     this.setStyle({
            //       fillColor: '#fff700',
            //     });
            //   //If the polygon is not selected keep it the highlight color
            //   else if (layer.definedProperties.properties._selected != true)
            //     this.setStyle({
            //       fillColor: '#0000ff',
            //     });
            // });

            // //Mouse Out
            // layer.on('mouseout', function (e) {
            //   this.closePopup();
            //   //If the polygon is not selected set to default color
            //   if (layer.definedProperties.properties._selected != true)
            //     this.setStyle({
            //       fillColor: '#3388ff',
            //     });
            // });

            //Add/remove download cart on click
            layer.on('click', function() {
              // console.log("HJERE");
              //Loop through productArray ( SELECTED (CheckedFiles))
              for (
                var prodCount = 0;
                prodCount < THIS.productArray.length;
                prodCount++
              ) {
                for (
                  var fileCount = 0;
                  fileCount < THIS.productArray[prodCount].files.length;
                  fileCount++
                ) {
                  if (
                    layer._tooltip._content ===
                    THIS.productArray[prodCount].files[fileCount].fileName
                  ) {
                    console.log(
                      THIS.productArray[prodCount].files[fileCount].fileName
                    );
                    THIS.checkBoxChangeEvent(
                      THIS.productList[prodCount],
                      'file',
                      !THIS.productArray[prodCount].files[fileCount].isChecked,
                      prodCount,
                      fileCount,
                      THIS.productArray[prodCount].files[fileCount].fileName
                    );
                  }
                }
              }

              // console.log('LAYER: ', THIS.checked);

              // THIS.checked[0].files[i] = !THIS.checked[0].files[i];
              //Set color to selected
              // this.setStyle({
              //     fillColor: '#fff700'
              // });

              //Check if already exists in cart
              // if (layer.definedProperties.properties._selected == true) {
              //     //set to default color
              //     this.setStyle({
              //         fillColor: '#3388ff'
              //     });

              //     //unselect and remove from list
              //     layer.definedProperties.properties._selected = false;
              //     feature.properties.checked = false;
              //     // THIS.productList.splice(
              //     //   THIS.productList.indexOf(feature.properties),
              //     //   1
              //     // );
              //     // THIS.changeDetector.detectChanges();
              // } else {
              //     //add to list
              //     layer.definedProperties.properties._selected = true;
              //     feature.properties.checked = true;

              //     // THIS.productList.push(feature.properties);
              //     // THIS.changeDetector.detectChanges();
              // }
              THIS.changeDetector.detectChanges();
            });
          },
        });

        // console.log(myGeoJSON);
        // if (this.tileLayerGroup.getLayers().length !== 0)
        // {
        //   this.tileLayerGroup.eachLayer(function(layer)
        //   {
        //     layer.eachLayer(function(layerValues)
        //     {
        //       if(layerValues.definedProperties.properties._selected == false)
        //       {
        //         console.log("FALSE!");
        //       }
        //       console.log(layerValues.definedProperties.properties._selected);
        //     })

        //   })
        // }

        this.tileLayerGroup.addLayer(myGeoJSON);
      }
    }
    console.log(this.tileLayerGroup);
    console.log(this.layerTrackingDict);
    var customDict = {};
    this.layerTrackingDict['tileLayerGroup'] = this.tileLayerGroup;
    this._map.addLayer(this.tileLayerGroup);
    // console.log(THIS.layerContainer);

    //this.watchThisBradThisIsMagic();
    //this._map.fitBounds(layerGroup.getBounds());
  }

  rectangleDrawEvent(map) {
    var drawnBounds;
    var THIS: ProductListComponent = this;

    //Set rectangle bounds on draw create
    map.on(L.Draw.Event.CREATED, function(e: any) {
      //Define the bounds of the rectangle drawn on the map

      //When we draw on the map
      if (THIS.rectangleViewPortLayer !== undefined)
        map.removeLayer(THIS.rectangleViewPortLayer);

      //Get bounds of drawn rectangle
      var topleft = e.layer.getBounds()._northEast;
      var bottomright = e.layer.getBounds()._southWest;
      //sets the rectangle bounds locally
      drawnBounds = new L.LatLngBounds(topleft, bottomright);
    });

    //Calculate once draw is complete
    map.on(L.Draw.Event.DRAWSTOP, function(e: any) {
      //The following section of code ADDS a visual rectangle to the map
      //************************************************************* */
      // this.rectangleViewPortLayer = new L.geoJSON();
      // var rectangle = L.rectangle(bounds, {
      //   color: '#ff7800',
      //   weight: 1,
      // }).addTo(this.rectangleViewPortLayer);
      // this.rectangleViewPortLayer.addTo(map);
      //************************************************************* */

      //Loop through each shapefile polygon layer
      THIS.tileLayerGroup.eachLayer(function(layer) {
        //Loop through each feature in the layer
        layer.eachLayer(function(feature) {
          //check if the drawn rectangle (calcualted above) intersects the visual polygon feature
          if (drawnBounds.intersects(layer.getBounds())) {
            //If the polygon (tile) has already been selected
            if (feature.definedProperties.properties._selected == true) {
              //  //remove from list
              //  layer.setStyle({
              //   'fillColor': '#3388ff'
              // });
              //  feature.definedProperties.properties._selected = false;
              //  THIS.productList.splice(THIS.productList.indexOf(feature.feature.geometry.properties.file),1);
              //  THIS.changeDetector.detectChanges();
            } else {
              //add to list
              feature.definedProperties.properties._selected = true;
              layer.setStyle({
                fillColor: '#fff700',
              });
              feature.feature.geometry.properties.checked = true;
              THIS.productList.push(feature.feature.geometry.properties);
              THIS.changeDetector.detectChanges();
            }
          }
        });
      });
    });
  }

  openInNewTab(router: Router, namedRoute, selectedProduct) {
    let newRelativeUrl = namedRoute;
    let baseUrl = window.location.href.replace(router.url, '');
    newRelativeUrl = newRelativeUrl.toString().substring(1);
    console.log(baseUrl + newRelativeUrl);
    var newWindow = window.open('#' + newRelativeUrl, 'test');
    setTimeout(function() {
      newWindow.document.title = selectedProduct.product_label;
      self.focus();
    }, 1000);

    //newWindow.document.title = selectedProduct.product_label
  }
  onCheck(ev: any, type: any): void {
    console.log(type);
    console.log(type.value);
    if (type.organization !== undefined) {
      if (ev.checked === true) {
        var obj = {};
        obj[type.organizations_index_id] = type.organization;
        this.filterChecks.push(obj);
      } else {
        var obj = {};
        obj[type.organizations_index_id] = type.organization;
        this.filterChecks = _.reject(this.filterChecks, obj);
      }
    } else {
      if (ev.checked === true) {
        var obj = {};
        obj[type.key] = type.value;
        this.filterChecks.push(obj);
      } else {
        var obj = {};
        obj[type.key] = type.value;
        this.filterChecks = _.reject(this.filterChecks, obj);
      }
    }

    console.log('SURVERY CHECKS: ', this.filterChecks);
  }

  removeFilters() {
    this.minValue = this.storeMinYear;
    this.maxValue = this.storeMaxYear;

    // let opts: Options = {
    //   floor: this.storeMinYear,
    //   ceil: this.storeMaxYear,
    //   // getPointerColor: ()=>{return 'gray'},
    //   // getSelectionBarColor: () => {return 'gray'}
    // };

    // this.options = opts;

    for (var i = 0; i < this.productList.length; i++) {
      if (this.productList[i].isVisibleOnMap)
        this.productList[i].isHidden = false;

      //If we are at max zoom and removeFilter was pressed, we need to check if there are any
      //products who are not flagged for maxzoom.
      if (this._map.getZoom() < 8) {
        if (this.productList[i].isMaxZoom == false) {
          this.productList[i].isMaxZoom = true;
        }
      }

      this.filterHighValue = undefined;
      this.filterLowValue = undefined;
      this.filterChecks = [];
    }
    this.checkboxes.forEach(element => {
      //  console.log(element);
      element['checked'] = false;
    });

    this.providerSelected = 1;
    this.addMarkerLayer(this.productList);
    // this.providerSelect.selectedValue = {organization_index_id: 1, organization:'All Providers'};
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }

    this.triggerService.triggerFiltersApplied('filtersRemoved');
    this.applySort();
  }
  changeEnd(ev: any, startOrEnd: any) {
    console.log('CHANGE END: ', ev);
    //this.yearsChanged = true;

    if (startOrEnd === 'START') {
      this.filterLowValue = ev;
    } else if (startOrEnd === 'END') {
      this.filterHighValue = ev;
    }
  }
  populateYear() {
    console.log(this.productList);
    for (var i = 0; i < this.productList.length; i++) {
      var tempYear = new Date(this.productList[i].product_survey_date);

      if (this.minYear !== undefined && this.minYear > tempYear.getFullYear()) {
        this.minYear = tempYear.getFullYear();
      }
    }
    for (var x = this.currentYear; x >= this.minYear; x--) {
      //If the value doesn't exist
      if (this.years.indexOf(x) === -1) {
        this.years.push(x);
      }
    }

    var minNum: number = this.years[this.years.length - 1];
    var maxNum: number = this.years[0];

    //Keep max and min year (outer range) updated
    this.storeMaxYear = maxNum;
    this.storeMinYear = minNum;
    //Set current min and current max
    this.minValue = minNum;
    this.maxValue = maxNum;

    let opts: Options = {
      floor: minNum,
      ceil: maxNum,
      // getPointerColor: ()=>{return 'gray'},
      // getSelectionBarColor: () => {return 'gray'}
    };

    this.options = opts;
    this.changeDetector.detectChanges();
  }
  updateYear() {
    console.log('CURRENT MIN: ' + this.minValue);
    console.log('PREVIOUS MIN: ' + this.storePreviousMinYear);
    console.log('TOTAL MIN: ' + this.storeMinYear);
    console.log('CURRENT MAX: ' + this.maxValue);
    console.log('PREVIOUS MAX: ' + this.storePreviousMaxYear);
    console.log('TOTAL MAX: ' + this.storeMaxYear);

    // //Keep max and min year (outer range) updated
    // this.storeMaxYear = maxNum;
    // this.storeMinYear = minNum;
    // //Store where the slider range is currently at

    // console.log(this.maxValue)
    // console.log(this.minValue)
    // this.storePreviousMaxYear = this.maxValue
    // this.storePreviousMinYear = this.minValue
    // //Check if we need to update the slider range
    // if(this.storePreviousMaxYear <= maxNum && this.storePreviousMinYear >= minNum)
    // {
    //   //Slider has moved
    //   //keep slider at same value
    //     this.minValue = this.storePreviousMinYear;
    //     this.maxValue = this.storePreviousMaxYear;

    // }
    // else if(this.storePreviousMaxYear <= maxNum && this.storePreviousMinYear == minNum)
    // {
    //     this.maxValue = this.storePreviousMaxYear;
    // }
    // else if(this.storePreviousMaxYear == maxNum && this.storePreviousMinYear >= minNum)
    // {
    //     this.minValue = this.storePreviousMinYear;
    // }
    // else
    // {
    //   //keep min and max num the same
    //   // this.minValue = minNum;
    //   // this.maxValue = maxNum;
    // }

    // let opts: Options = {
    //   floor: minNum,
    //   ceil: maxNum,
    //   // getPointerColor: ()=>{return 'gray'},
    //   // getSelectionBarColor: () => {return 'gray'}
    // };

    // this.options = opts;
    // this.changeDetector.detectChanges();
  }
  removeMarkerLayer(list) {
    var arrayOfLayersToRemove = [];
    this.landingPage.markers.eachLayer(function(layer) {
      if (
        _.where(list, {
          product_label: layer.id,
        }).length > 0
      ) {
        //ID EXISTS DO NOTHING
      } else {
        //Layer does not exist, needs to be added to array
        arrayOfLayersToRemove.push(layer);
      }
    });
    //loop through layers identified to remove from master list
    for (var i = 0; i < arrayOfLayersToRemove.length; i++) {
      this.landingPage.markers.removeLayer(arrayOfLayersToRemove[i]);
    }
  }
  addMarkerLayer(productList) {
    //Array to hold layer ID's
    var markerLayerId = [];
    //Loop through each layer on map to identify which markers are on the map
    this.landingPage.markers.eachLayer(function(layer) {
      markerLayerId.push(layer.id);
    });

    //productList should contain products we NEED on the map
    for (var i = 0; i < productList.length; i++) {
      var markerAlreadyExists = _.contains(
        markerLayerId,
        productList[i].product_label
      );

      if (markerAlreadyExists) {
        //do nothing
      } else {
        //add to layer
        var freshLayerFromUnEditedList = _.where(
          this.landingPage.markerLayers,
          {
            id: productList[i].product_label,
          }
        )[0];
        this.landingPage.markers.addLayer(freshLayerFromUnEditedList);
      }
    }
  }
  changeDisplayName(event: any) {
    if (event.checked) {
      console.log('box is checked');
      this.displayLabelTextChkBox = true;
    } else {
      console.log('box is not checked');
      this.displayLabelTextChkBox = false;
    }
  }
  getDisplayText(product) {
    if (
      product.product_display_name !== undefined &&
      product.product_display_name !== '' &&
      product.product_display_name !== null
    )
      return this.displayLabelTextChkBox
        ? product.product_display_name
        : product.product_label;
    else return product.product_label;
  }
  showResults() {
    //define organization filters if any
    this.onChangeProvider();
    this.addMarkerLayer(this.productList);
    // console.log(this.filterChecks);
    //console.log(this.productList);
    this.productCount = 0;
    // console.log(this.filterChecks["organization_id"]);

    // console.log(this.storeMinYear);
    // console.log(this.maxValue);
    // console.log(this.storePreviousMaxYear);
    if (
      this.filterChecks.length == 0 &&
      (this.minValue === this.storeMinYear &&
        this.maxValue === this.storePreviousMaxYear)
    ) {
      if (this.productList.length > 0) {
        // this.productCount = 0;
        for (var i = 0; i < this.productList.length; i++) {
          if (this.productList[i].isVisibleOnMap) {
            this.productCount += 1;
          }
        }
        // this.productCount = this.productList.length;
        this.removeFilters();
      }
      return;
    } else {
      //Sorts the filterchecks such that the survey_types are sorted together at the beginning of the array
      //I.E:
      //survey_type
      //survey_type
      //data_type
      //data_typ
      if (this.filterChecks.length == 1) {
        if ('organization_id' in this.filterChecks[0]) {
          if (
            this.filterChecks[0]['organization_id'] === undefined ||
            isNaN(this.filterChecks[0]['organization_id'])
          ) {
            return;
          }
        } else {
          console.log('organization not found', this.filterChecks);
        }
      }
      var filteredList = this.processFilters();
      // console.log('PROCESS FILTERED LIST: ', filteredList);
      var dateFilteredList = this.processDateFilter(filteredList);
      // console.log('DATE FILTERED LIST: ', dateFilteredList);
      var organizationFilteredList = this.processOrganizationFilter(
        dateFilteredList
      );

      this.removeMarkerLayer(organizationFilteredList);
      _.each(this.productList, product => {
        if (
          _.where(organizationFilteredList, {
            product_index_id: product.product_index_id,
          }).length > 0
        ) {
          //Product exists in both lists
          //use "product" to change flag
          //Check if product is visible on the map (within bounds)
          if (product.isVisibleOnMap) {
            product.isHidden = false;
          } else {
            this.productCount += 1;
          }

          //Check if bounds are at max zoom level
          if (product.isMaxZoom) {
            //bypass maxzoom check
            product.isMaxZoom = false;
          }
          // console.log(product);
        } else {
          product.isHidden = true;
        }
      });
      // console.log(organizationFilteredList);
      for (var i = 0; i < organizationFilteredList.length; i++) {
        if (organizationFilteredList[i].isVisibleOnMap == true) {
          this.productCount += 1;
        }
      }
      //  console.log(this.productCount);
      if (organizationFilteredList.length <= 0) {
        let snackBarRef = this.snackBar.open(
          'No products found matching filter results.',
          'Dismiss',
          {
            duration: 5000,
            verticalPosition: 'top',
          }
        );
      }
      // this.productCount = organizationFilteredList.length;
      if (this.filterChecks.length > 0) {
        var data = {
          message: 'filtersApplied',
          list: organizationFilteredList,
        };
        this.triggerService.triggerFiltersApplied(data);
      }

      filteredList = [];
      dateFilteredList = [];
      organizationFilteredList = [];
    }
  }
  processFilters() {
    //Sort filters by key
    this.filterChecks.sort((a, b) =>
      Object.keys(a)[0] < Object.keys(b)[0]
        ? 1
        : Object.keys(a)[0] > Object.keys(b)[0]
        ? -1
        : 0
    );
    console.log(this.filterChecks);
    //Verify that atleast one survey filter has been found
    var surveyFilterfound =
      this.filterChecks.filter(function(o) {
        return o.hasOwnProperty('survey_type');
      }).length > 0;
    //Verify that atleast one data filter has been found
    var dataFilterfound =
      this.filterChecks.filter(function(o) {
        return o.hasOwnProperty('data_type');
      }).length > 0;

    var tempListOrganization = [];
    var tempListGather = [];
    var tempListData = [];

    if (surveyFilterfound === true && dataFilterfound === true) {
      //loop through array of objects
      for (var i = 0; i < this.filterChecks.length; i++) {
        //if key is survey_type
        if (Object.keys(this.filterChecks[i])[0] === 'survey_type') {
          //Add all products that contain this survey_type to a temp list
          tempListGather = tempListGather.concat(
            _.where(this.productList, this.filterChecks[i])
          );
        } else {
          if (Object.keys(this.filterChecks[i])[0] === 'data_type') {
            //add all products that contain this data_type to a temp list
            tempListData = tempListData.concat(
              _.where(tempListGather, this.filterChecks[i])
            );
          }
        }
      }
      //tell the landing page filters have been applied
      // this.triggerService.triggerFiltersApplied('filtersApplied');
    }
    //handle case where only one filter is applied from either category
    else if (surveyFilterfound === true || dataFilterfound === true) {
      //If a filter is applied on one property type OR the other
      for (var i = 0; i < this.filterChecks.length; i++) {
        if (
          Object.keys(this.filterChecks[i])[0] === 'survey_type' ||
          Object.keys(this.filterChecks[i])[0] === 'data_type'
        ) {
          tempListData = tempListData.concat(
            _.where(this.productList, this.filterChecks[i])
          );
        }
      }
      //tell the landing page filters have been applied
      // this.triggerService.triggerFiltersApplied('filtersApplied');
    } else {
      //IF NOTHING IS SELECTED

      //console.log('NOTHING IS SELECTED INGORING FILTERS');
      return this.productList;
    }

    //Contains the final list of filtered files
    return tempListData;
  }
  processDateFilter(list) {
    this.storePreviousMaxYear = this.maxValue;
    this.storePreviousMinYear = this.minValue;
    // this.minValue = this.storeMinYear;
    // this.maxValue = this.storeMaxYear;

    // let opts: Options = {
    //   floor: this.storeMinYear,
    //   ceil: this.storeMaxYear,
    //   // getPointerColor: ()=>{return 'gray'},
    //   // getSelectionBarColor: () => {return 'gray'}
    // };
    //takes in filteredList
    var dateList = list.filter(product => {
      var productYear = new Date(product.product_survey_date).getFullYear();
      return productYear >= this.minValue && productYear <= this.maxValue;
    });
    //returns list filtered by date
    return dateList;
  }
  processOrganizationFilter(list) {
    // console.log(list);
    // console.log(this._map);
    //console.log(this.landingPage.markers);

    if (this._map) var organizationFilteredList = [];
    var organizationFilterFound =
      this.filterChecks.filter(function(o) {
        return o.hasOwnProperty('organization_id');
      }).length > 0;

    if (organizationFilterFound) {
      for (var i = 0; i < this.filterChecks.length; i++) {
        //if key is survey_type
        if (Object.keys(this.filterChecks[i])[0] === 'organization_id') {
          //Add all products that contain this survey_type to a temp list
          organizationFilteredList = organizationFilteredList.concat(
            _.where(list, this.filterChecks[i])
          );
        }
      }
    } else {
      return list;
    }
    console.log(organizationFilteredList);

    return organizationFilteredList;
  }
  onCheckSelectAll() {}
  checkBoxSelectAllEvent() {
    this.selectAll = !this.selectAll;
    //console.log(this.selectAll)
    for (var i = 0; i < this.productList.length; i++) {
      if (this.selectAll === true) {
        if (this.productList[i].isHidden === false)
          this.productList[i].isChecked = true;
      } else {
        if (this.productList[i].isHidden === false)
          this.productList[i].isChecked = false;
      }
      //  console.log(this.productList[i]);
    }
  }
  //When a product is selected (clicked) in the visible products
  //This function navigates to the product on the ALL-DATA page.
  onSelect(product, event): void {
    //When we click the card (opens details)
    // console.log(event);
    event.stopPropagation();

    //selecting a product will take you to that product page
    this.selectedProduct = product;
    // this.openInNewTab(
    //   this.router,
    //   this.router.createUrlTree([
    //     'details/' + this.selectedProduct.product_index_id,
    //   ]), product
    // );

    // const url = this.router.serializeUrl(this.router.createUrlTree(['/details/' + this.selectedProduct.product_index_id], { queryParams: {   } }));
    // console.log(url);
    // window.open(url, '_blank');
    // this.router
    //   .navigate(['/details/', this.selectedProduct.product_index_id])
    //   .then(
    //     nav => {
    //       //  console.log(nav);
    //       //window.location.reload();
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
  }

  displayBoundingBox(product) {
    this.triggerService.triggerProductHoverEvent(product);
    var json = JSON.parse(product.geom);

    var states = {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: json.coordinates,
      },
    };
    var exteriorStyle = {
      color: '#a1d99b',
      fillOpacity: 0.1,
    };
    var myLayer = L.geoJSON(
      states,
      { style: exteriorStyle },
      {
        coordsToLatLng: function(coords) {
          //  console.log(coords);

          //                    latitude , longitude, altitude
          return new L.LatLng(coords[1], coords[0]); //Normal behavior
        },
      }
    );

    this.hoverLayer = myLayer;
    this.hoverLayer.addTo(this._map);

    // var geometry = wkx.Geometry.parse(product.geom);
    // console.log(geometry);
    //   this.hoverPolygon = L.polygon([
    //     [51.509, -0.08],
    //     [51.503, -0.06],
    //     [51.51, -0.047]
    // ]);
    // this.hoverPolygon.addTo(this._map);
  }
  removeBoundingBox(product) {
    this.triggerService.triggerProductLeaveEvent(product);
    this._map.removeLayer(this.hoverLayer);
  }

  openDialog() {
    this.updateCart();

    // for (var count = 0; count < message.length; count++)
    // {
    //   message[count].isHidden = false;
    //   if (!this.message.some(item => item.product_id == message[count].product_id))
    //   {
    //     this.message.push(message[count]);
    //   } else {
    //     // Message displays if the user trys to add duplicate products to their cart
    //     let snackBarRef = this.snackBar.open(
    //       'WARNING: Dupplicates WERE NOT added to your cart!',
    //       'Dismiss',
    //       {
    //         duration: 5000,
    //         verticalPosition: 'top',
    //       }
    //     );
    //   }
    // }

    try {
      // console.log(this.productList);
      // console.log(this.testArray);
      //  if (this.testArray.length > 3) {
      // Message displays if the user trys to add duplicate products to their cart
      // let snackBarRef = this.snackBar.open(
      //   'WARNING: You have reached your limit of 3 products in your download cart at once! Adding the first 3 products.',
      //   'Dismiss',
      //   {
      //     duration: 5000,
      //     verticalPosition: 'bottom',
      //   }
      // );

      //  snackBarRef.afterDismissed().subscribe(result => {
      console.log(this.testArray);
      if (this.testArray.length > 0) {
        console.log(this.testArray);
        console.log(this.landingPage.lblCartCount);

        // const dialogRef = this.dialog.open(CheckoutDialogComponent, {
        //   maxWidth: '90vh',
        //   maxHeight: '90vh',
        // });
        // this.testArray = this.testArray.slice(0, 3);
        this.dataService.changeMessage(this.testArray);
        for (var i = 0; i < this.productList.length; i++) {
          this.productList[i].isChecked = false;
        }
        // dialogRef.afterClosed().subscribe(() => {
        //   // modal closes
        // });
      } else {
        let snackBarRef = this.snackBar.open(
          'Notice: Please select a product to add to the download cart.',
          'Dismiss',
          {
            duration: 10000,
            verticalPosition: 'top',
          }
        );
      }

      // });
      //}
    } catch (Exception) {
      console.log(Exception);
    }
    // this.ngOnChanges(this.productList);
  }
  updateCart() {
    // console.log('checked: ', this.checked);
    var tempArray = [];
    var tmpFilesArray = [];
    this.testArray = [];

    console.log(JSON.parse(JSON.stringify(this.productArray)));
    console.log(JSON.parse(JSON.stringify(this.productList)));
    for (var i = 0; i < this.productList.length; i++) {
      if (this.productList[i].isChecked) {
        this.testArray.push(this.productList[i]);
      }
    }
    //this.productArray looks like this :
    //[x],[x],[x],[x] ... where x is a JSON containing two properties (files, and "theProduct")
    //files: contains an array of JSONS [JSON, JSON, JSON ...] where JSON contains 3 properties (fileName, isChecked, isSelected)
    //theProduct: contains the product information as seen from the DB

    // //Loop through all products in productArray
    // for (let prodCount = 0; prodCount < this.productArray.length; prodCount++)
    // {

    //   //Defined temp json to store data for passing
    //   var productAndFilesJson = {
    //     theProduct: null,
    //     files: [],
    //   };

    //   //If the product is checked and not hidden
    //   if (this.productArray[prodCount].theProduct.isChecked === true && this.productArray[prodCount].theProduct.isHidden === false)
    //   {
    //     console.log(JSON.parse(JSON.stringify(this.productArray[prodCount].theProduct)));

    //     //At this point the product should always contain files
    //     if (this.productArray[prodCount].files !== null)
    //     {
    //       //Copy all currently searched files for the given product to a mutable temporary array.
    //       tmpFilesArray = this.productArray[prodCount].files;
    //       console.log("tmpFilesArray: ", tmpFilesArray);
    //       //loop through current product's files
    //       for (let fileCount = 0;fileCount < this.productArray[prodCount].files.length;fileCount++)
    //       {
    //         //If the current file is NOT checked
    //         if (!this.productArray[prodCount].files[fileCount].isChecked) {
    //           //If the current file is unchecked remove it from the mutable temporary array
    //           tmpFilesArray = tmpFilesArray.filter(
    //             value =>
    //               !value.fileName.includes(
    //                 this.productArray[prodCount].files[
    //                   fileCount
    //                 ].fileName.substring(
    //                   0,
    //                   this.productArray[prodCount].files[fileCount].fileName
    //                     .length - 4
    //                 )
    //               )
    //           );

    //           // console.log('After Filter: ', tmpFilesArray);
    //         } else {
    //           //  console.log(this.productArray[prodCount].files[fileCount]);
    //           //dont add the file
    //           //flag file as hidden
    //         }
    //       }
    //       //  this.productArray[prodCount].theProduct.files = this.productArray[prodCount].files;

    //       // console.log(this.productArray);
    //       //Grabs ALL files before we wipe the productArray
    //       var productArrayProductFilesB4Wipe = this.productArray[prodCount]
    //         .theProduct.files;

    //       //At this point we should have a "filtered" set of files for the given product stored in tmpFilesArray
    //       //We can now set the productArray.theproduct.files to empty so we can assign the filtered array
    //       this.productArray[prodCount].theProduct.files = [];
    //       //console.log(this.productArray);
    //       //Loop through the filtered array adding each file back to the productArray
    //       //Each file in this case should only represent what has been selected.
    //       for (var i = 0; i < tmpFilesArray.length; i++) {
    //         this.productArray[prodCount].theProduct.files[i] =
    //           tmpFilesArray[i].fileName;
    //       }

    //       var metaDataFiles = this.getMetaDataFiles(
    //         productArrayProductFilesB4Wipe,
    //         this.productArray[prodCount].theProduct.files
    //       );
    //       // console.log(metaDataFiles);

    //       // console.log('Before: ');
    //       // console.log(this.productArray[prodCount].theProduct);
    //       // console.log('After: ');

    //       //Product array product files should only contain the actual survey files at this point
    //       //We need to concat the above calculated METADATA files (.shp,.cfg, etc).. to the list of files
    //       var filesAndMetadata = this.productArray[
    //         prodCount
    //       ].theProduct.files.concat(metaDataFiles);
    //       this.productArray[prodCount].theProduct.files = filesAndMetadata;
    //       //console.log(this.productArray[prodCount].theProduct);
    //       tempArray.push(this.productArray[prodCount].theProduct);
    //       //tempArray.push(filesAndMetadata);
    //     } //LAS has no files
    //     else {
    //       productAndFilesJson.theProduct = this.productArray[
    //         prodCount
    //       ].theProduct;
    //       productAndFilesJson.files = [];
    //       tempArray.push(productAndFilesJson.theProduct);
    //     }
    //     /*Add product to tempArray*/
    //   } else if (
    //     this.productList[prodCount].isChecked === true &&
    //     this.productList[prodCount].isHidden === true
    //   ) {
    //     // handle weird case where something is checked but hidden
    //     console.log('Product is Checked AND Hidden');
    //     this.productList[prodCount].isHidden = false;
    //   } else if (
    //     this.productList[prodCount].isChecked === false &&
    //     this.productList[prodCount].isHidden === false
    //   ) {
    //     // handle case where item is not checked but still needs to be hidden
    //     // this.productList[prodCount].isHidden = true;
    //   } //If isChecked is false && isHidden is true
    //   else {
    //   }
    // }
    // Loop through tempArray to remove unchecked products from productList
    for (let y = 0; y < tempArray.length; y++) {
      // console.log(tempArray[y]);
      this.testArray.push(tempArray[y]);
    }

    this.changeDetector.detectChanges();
    //  console.log(this.testArray);
    // this.test();
  }

  checkBoxChangeEvent(
    product,
    fileOrProduct,
    ischecked,
    prodCount,
    fileCount,
    productFileName
  ) {
    var THIS: ProductListComponent = this;
    //console.log(product);
    //console.log(ischecked);
    //console.log(fileOrProduct);
    //console.log("BOOP");
    // console.log(product);
    if (fileOrProduct === 'product') {
      //this.checkBoxArray[prodCount].product = ischecked;
      product.isChecked = ischecked;
      product.isSelected = ischecked;

      // console.log(product);
      // console.log(ischecked);
      if (product.isChecked === false) {
        //  console.log("hereee");
        //console.log(this.productArray[prodCount]);
        //console.log(this.productList[prodCount]);

        for (var i = 0; i < this.productArray[prodCount].files.length; i++) {
          this.productArray[prodCount].files[i].isChecked = ischecked;
          this.productArray[prodCount].files[i].isSelected = ischecked;
        }
        // console.log(this.productArray[prodCount].files)
        // this.productArray[prodCount].isChecked = ischecked;
      }
    }

    if (fileOrProduct === 'file') {
      //  console.log(ischecked);

      //  console.log(this.productArray[prodCount].files);
      // console.log(this.productArray[prodCount].files[fileCount]);
      this.productArray[prodCount].files[fileCount].isChecked = ischecked;
      this.productArray[prodCount].files[fileCount].isSelected = ischecked;
      // console.log(this.productArray[prodCount].files[fileCount]);
      //this.checkBoxArray[prodCount].files[fileCount].trueOrFalse = ischecked;
      //  console.log('filecount: ', fileCount);

      // console.log('CONTAINERS: ', THIS.layerContainer, THIS.featureContainer);

      //Loop through layer containers

      for (var i = 0; i < THIS.layerContainer.length; i++) {
        //console.log(THIS.layerContainer[i]);
        if (THIS.layerContainer[i] != undefined) {
          //console.log("here!");

          if (THIS.layerContainer[i]._tooltip._content === productFileName) {
            // console.log(i);
            THIS.layerContainer[
              i
            ].definedProperties.properties._selected = ischecked;
            THIS.featureContainer[i].properties.checked = ischecked;
            //If the polygon is already selected keep it the selected color
            // console.log(
            //  'LayerContainer: ' +
            //    THIS.layerContainer[i].definedProperties.properties._selected,
            //  THIS.featureContainer[i].properties.checked
            // );
            if (
              THIS.layerContainer[i].definedProperties.properties._selected ==
              true
            ) {
              //Polygons are yellow
              THIS.layerContainer[i].setStyle({ fillColor: '#fff700' });
              //Change it to not selected anymore
              THIS.layerContainer[
                i
              ].definedProperties.properties._selected = false;
              THIS.featureContainer[i].properties.checked = false;
            }
            //If the polygon is not selected set to default color
            else if (
              THIS.layerContainer[i].definedProperties.properties._selected !=
              true
            ) {
              THIS.layerContainer[i].setStyle({ fillColor: '#3388ff' });

              THIS.layerContainer[
                i
              ].definedProperties.properties._selected = true;
              THIS.featureContainer[i].properties.checked = true;
            }
          }
        }
      }
    }

    this.changeDetector.detectChanges();
    // console.log('PRODUCT: ', product, fileOrProduct, ischecked, prodCount, fileCount);
  }

  getMetaDataFiles(listOfAllFiles, listOfRequestedFiles) {
    //listOfAllFiles - contains the unfiltered list of FILES for the selected product this should contain ALL files
    //listOfRequestedFiles - contains the FILTERED list of FILES for the selected product this should contain only selected products
    var metaDataFiles = [];
    for (var i = 0; i < listOfAllFiles.length; i++) {
      if (
        listOfAllFiles[i].toUpperCase().endsWith('.TIF') ||
        listOfAllFiles[i].toUpperCase().endsWith('.TIFF') ||
        listOfAllFiles[i].toUpperCase().endsWith('.LAS') ||
        listOfAllFiles[i].toUpperCase().endsWith('.TFW')
      ) {
        if (listOfRequestedFiles.includes(listOfAllFiles[i])) {
          //Found the product add to list (this is added later)
          //  metaDataFiles.push(listOfAllFiles[i])
          if (
            listOfAllFiles.includes(
              listOfAllFiles[i].replace(/\.[^/.]+$/, '.TFW')
            ) ||
            listOfAllFiles.includes(
              listOfAllFiles[i].replace(/\.[^/.]+$/, '.tfw')
            )
          ) {
            metaDataFiles.push(listOfAllFiles[i].replace(/\.[^/.]+$/, '.tfw'));
          }
        }
      } else if (
        listOfAllFiles[i].toUpperCase().endsWith('.CPG') ||
        listOfAllFiles[i].toUpperCase().endsWith('.DBF') ||
        listOfAllFiles[i].toUpperCase().endsWith('.PRJ') ||
        listOfAllFiles[i].toUpperCase().endsWith('.SBN') ||
        listOfAllFiles[i].toUpperCase().endsWith('.SBX') ||
        listOfAllFiles[i].toUpperCase().endsWith('.SHP') ||
        listOfAllFiles[i].toUpperCase().endsWith('.SHX')
      ) {
        metaDataFiles.push(listOfAllFiles[i]);
      }
    }

    //This code ignores TIF, TIFF, LAS files... change to only add specific
    // for (var i = 0; i < listOfFiles.length; i++)
    // {
    //   //If file ends in tif
    //   if (listOfFiles[i].toUpperCase().endsWith('.TIF')) {
    //     //ignore
    //   } else if (listOfFiles[i].toUpperCase().endsWith('.TIFF')) {
    //     //ignore
    //   } else if (listOfFiles[i].toUpperCase().endsWith('.LAS')) {
    //     //ignore
    //   } else {
    //     metaDataFiles.push(listOfFiles[i]);
    //   }
    // }
    // for (var i = 0; i < listOfFiles.length; i++)
    // {
    //   //If file ends in tif
    //   if (listOfFiles[i].toUpperCase().endsWith('.CPG') || listOfFiles[i].toUpperCase().endsWith('.DBF') || listOfFiles[i].toUpperCase().endsWith('.PRJ') || listOfFiles[i].toUpperCase().endsWith('.SBN') || listOfFiles[i].toUpperCase().endsWith('.SBX') || listOfFiles[i].toUpperCase().endsWith('.SHP') || listOfFiles[i].toUpperCase().endsWith('.SHX') || listOfFiles[i].toUpperCase().endsWith('.TFW')) {
    //     metaDataFiles.push(listOfFiles[i]);
    //   }
    // }
    //console.log(metaDataFiles);
    return metaDataFiles;
  }

  zoomTo(product, count, eyeState, event) {
    event.stopPropagation();
    this.changeDetector.detectChanges();

    // if (product.data_type === 'DSM')
    // {
    //   //console.log('PRODUCT IS A DSM');
    //   return;
    // }

    //ResetAll loops through all products to determine if they are open/being viewed. If they are then it swaps its state
    //this.resetAll(product);
    var centroid = JSON.parse(product.centroid);
    console.log(eyeState);
    console.log(product);
    if (this._map.getZoom() > 15) {
      // if we are zoomed in futher than 15
      this._map.setView(centroid['coordinates'], this._map.getZoom());
    } else {
      this._map.setView(centroid['coordinates'], 15);
    }
    //If eyeState is 0 we are closing the file view
    // if (eyeState == 0) {
    //   product.isBeingViewed = false;
    //   var THIS: ProductListComponent = this;
    //   // this.detailedView[count] = !this.detailedView[count];
    //   this.changeDetector.detectChanges();
    // } else {
    //   this.productId = { productId: product.product_index_id };
    //   this.zooming = true;
    //   product.isBeingViewed = true;

    //   if (this._map.getZoom() > 15) {
    //     // if we are zoomed in futher than 15
    //     this._map.setView(centroid['coordinates'], this._map.getZoom());
    //   } else {
    //     this._map.setView(centroid['coordinates'], 15);
    //   }

    //   // this.tileSubscription = this.productservice
    //   //   .getProduct(this.productId)
    //   //   .pipe(first())
    //   //   .subscribe(product => {
    //   //     //Populate download cart
    //   //     //console.log(product);
    //   //     this.populateDownloadCart(this.productId, count);

    //   //     this.changeDetector.detectChanges();
    //   //   });
    // }
    //this.changeDetector.detectChanges();
    this.zooming = false;
    //100ms timeout for change detector
    this.timedDetectChanges(100);
  }

  timedDetectChanges(time) {
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, time);
  }
  resetAll(product = undefined) {
    // console.log(product);
    if (product != undefined) {
      for (var i = 0; i < this.productList.length; i++) {
        //If we find a product that is open and it doesn't match the passed product
        //close it
        if (
          this.productList[i].isBeingViewed == true &&
          this.productList[i] != product
        ) {
          this.productList[i].isBeingViewed = false;
        }
      }
    }

    if (this.tileSubscription !== undefined) {
      this.tileSubscription.unsubscribe();
      this.tileSubscription = undefined;
    }
    if (this.hoverLayer !== undefined) {
      //Responsible for displayboundingbox of entire product (on hover)
      this.hoverLayer.removeFrom(this._map);
      this.hoverLayer = undefined;
    }
    if (this.tileLayerGroup !== undefined) {
      //The layer group responsible for holding all tile geometry
      this._map.removeLayer(this.tileLayerGroup);
      this.tileLayerGroup = undefined;
    }

    this.layerContainer = [];
    this.featureContainer = [];
    geoJsonWithProperties = undefined;
    this.changeDetector.detectChanges();
  }
}
