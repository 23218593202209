import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable()
export class LinkSitesProductsService {
  constructor(private http: HttpClient) {}

  createLinkSiteProduct(data): Observable<any> {
    return this.http.post(`/api/data/link_site_product/create`, data);
  }

  createMultipleLinks(data) {
    return this.http.post(`/api/data/link_site_product/createMultiple`, data);
  }

  /**
   * (Inserts links into the link_sites_products database. You must have multiple sites)
   *
   * @param {*} data - ex: {produceId: 1, sites: ARRAY OF SITES}
   * @returns Observable
   * @memberof LinkSitesProductsService
   */
  createMultipleLinksWithSites(data: any) {
    return this.http.post(
      `/api/data/link_site_product/createMultipleLinksWithSites`,
      data
    );
  }

  getProductsBySite(siteId) {
    return this.http.post(
      `/api/data/link_site_product/products/`,
      siteId,
      httpOptions
    );
  }
  getAllSitesAndProductsByArrayOfSiteID(siteId): Observable<any> {
    //var test = Object.assign({}, siteId);
    // var test2 = JSON.stringify(test);
    return this.http.post(`/api/data/link_site_product/getAllArray`, siteId);
  }

  getAllSitesAndProducts() {
    return this.http.get('/api/data/link_site_product/getAll');
  }

  deleteLink(link: any) {
    return this.http.post('/api/data/link_site_product/delete/', link);
  }
}
