import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.css'],
})
export class CheckoutDialogComponent implements OnInit {
  constructor(public dialog: MatDialog, private ngZone: NgZone) {}

  ngOnInit() {}

  closeModal(hello) {
    //hello.closeAll();
    this.ngZone.run(() => {
      this.dialog.closeAll();
    });
    this.dialog.closeAll();
    const dialogRef = this.dialog.closeAll();
  }
}
