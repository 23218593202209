import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable()
export class LoggingService {
  constructor(private http: HttpClient) {}
  insertNewLog(data) {
    return this.http.post(`/api/data/logging/`, data, httpOptions);
  }
}
