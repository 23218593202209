import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from '../../../services/sites.service';
import { SurveyTypesService } from '../../../services/surveytypes.service';
import { ProductsService } from '../../../services/products.service';
import { DataTypesService } from '../../../services/datatypes.service';
import { XmlParse } from '../../../helper-classes/XmlParse';
import {
  MatDialog,
  MatDialogRef,
  MatSort,
  MatTableDataSource,
  MAT_DIALOG_DATA,
  MatSnackBar,
} from '@angular/material';
import { latLng, tileLayer, Map, LatLngBounds, LatLng } from 'leaflet';
import { LinkSitesProductsService } from '../../../services/link_sites_products.service';
import * as uuid from 'uuid';

import {
  FormBuilder,
  NgForm,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
declare var require: any;
var s3Service = require('../../../../../lib/s3Util');
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { take, mergeMap, startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoggingJson } from '../../../app/logging-json';
import { LoggingService } from '../../../services/logging.service';
var parser = require('fast-xml-parser');
var he = require('he');
import * as moment from 'moment';

import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { FileService } from '../../../services/FileService';
import { UsersService } from '../../../services/users.service';

import { ExportXmlService } from '../../../services/export-to-new-product.service';
import Swal from 'sweetalert2';
import { DialogTourOverview } from '../../../landing-page/components/landing-page.component';
import { JoyrideService } from 'ngx-joyride';
var options = {
  attributeNamePrefix: '@_',
  attrNodeName: 'attr', //default is 'false'
  textNodeName: '#text',
  ignoreAttributes: true,
  ignoreNameSpace: false,
  allowBooleanAttributes: false,
  parseNodeValue: true,
  parseAttributeValue: false,
  trimValues: true,
  cdataTagName: '__cdata', //default is 'false'
  cdataPositionChar: '\\c',
  localeRange: '', //To support non english character in tag/attribute values.
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  attrValueProcessor: (val, attrName) =>
    he.decode(val, { isAttributeValue: true }), //default is a=>a
  tagValueProcessor: (val, tagName) => he.decode(val), //default is a=>a
  stopNodes: ['parse-me-as-string'],
};
//Global Declarations
declare let L;
export interface DialogProviderData {
  response: string;
}
//console.log = function() {};
@Component({
  selector: 'dialog-provider-tour-overview',
  templateUrl: 'dialog-provider-tour-overview.html',
})
export class DialogProviderTourOverview {
  response: string;
  constructor(
    public dialogRef: MatDialogRef<DialogProviderTourOverview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogProviderData
  ) {}

  onNoClick(): void {
    let wantsProviderTour = sessionStorage.getItem('wantsProviderTour');
    console.log(wantsProviderTour);
    //If visited is set, the user has been to the website in this session
    if (wantsProviderTour != null) {
    } else {
      //set visited to true and show tutorial modal
      sessionStorage.setItem('wantsProviderTour', 'false');
      // make sure to unsubscribe on ngDestroy
    }
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close('startProviderTour');
  }
}
@Component({
  selector: 'new-data',
  templateUrl: './new-data.component.html',
  styleUrls: ['./new-data.component.css'],
})
// @Directive({
//   selector: '[appDragDrop]'
// })
export class NewDataComponent {
  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    ],
    zoom: 6,
    center: latLng(26.72, -89.68),
  };
  newSite: Object = {
    label: '',
  };

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('picker') pickerRef: ElementRef;
  @ViewChild('dragdrop') ngxDragDrop: any;
  @ViewChild('folderInput') folderInputSelector: any;
  // @ViewChild('siteName', {static: false}) siteNameElementRef: ElementRef;
  @ContentChild('siteName') img: ElementRef;

  // @Viewchild('content', {static: false}) innerConttent: TemplateRef<any>;

  //Form Groups
  metadataForm = new FormGroup({
    metadataFileName: new FormControl('', [Validators.required]),
  });
  confirmForm = new FormGroup({});
  existingSitesForm = new FormGroup({
    searchValue: new FormControl(''),
  });

  //Sites
  sites: any;
  dataSource: any;
  matTableDataSource = new MatTableDataSource();

  fileExtensions = [];
  displayedColumnsFiles: string[] = ['site'];
  public files: NgxFileDropEntry[] = [];
  showError: boolean = false;
  existingSite: boolean = false;
  hasSubmitMetadataForm: boolean = false;
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<string[]>;
  siteIds = new Array();
  sitesAdded = new Array();
  dropZoneText: string;
  surveyTypes: any;
  existingSiteCtrl: any;
  IsChecked: boolean;
  singleFileDiv: boolean;
  submitClicked: boolean = false;
  fileDropDiv: boolean;
  dataTypes: any;
  existingSites: any;
  maxDate = new Date();
  s3Url = 'https://lidar-app-uploads.s3.amazonaws.com/';
  calculatedCentroid: any;
  calculatedBounds: any;
  /************** Site Form **************/
  isNewSite = true;
  dataForm: NgForm;
  uploadForm: NgForm;
  confirmationForm: NgForm;
  siteDescription: string;
  siteLatitude: number;
  siteLongitude: number;
  productJsonXml: any;
  /***************************************/

  /************** Upload Form **************/
  surveyType: any;
  dataType: any;
  productFileName: string;
  metadataFileName: string;
  surveyDate: any;
  cfmSurveyDate: any;
  productDescription: string;
  productDisplayName: string;
  /***************************************/

  /************** File Upload **************/
  showRenderer: boolean = false;
  percentComplete: number = 0;
  productFile: File;
  metadataFile: File;
  shapeFile: File;
  DBF: File;
  PRJ: File;
  productFilesStringArray: string[] = [];
  productFilesArray: File[] = [];
  productDirectory: string = '';
  productSurveyType: string = '';
  productDataType: string = '';
  loaded: boolean = false;
  _map: Map;
  show: boolean = false;
  layers = [];
  customMarker: any;
  showProgressBar: boolean = false;
  productUUID: string = '';
  metadataUUID: string = '';
  keyword: string = 'site_label';
  confirmedCalculatedFeatures: any;
  pathSurveyDate: string;
  totalSizeNum: number = 0;
  totalSize: any;
  /*****************************************/
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    var fileList: FileList;
    console.log('DROPPED!');
    console.log(files);
    if (files.length == 1) {
      let snackBarRef = this.snackbar.open(
        'Please drag and drop a folder containing your product.',
        'Dismiss',
        {
          duration: 5000,
          verticalPosition: 'bottom',
        }
      );
    } else {
      for (const droppedFile of files) {
        console.log(droppedFile);
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

          fileEntry.file((file: File) => {
            console.log('IN FILE ENTRY');

            // Here you can access the real file
            this.productDirectory = droppedFile.relativePath.substr(
              0,
              droppedFile.relativePath.indexOf('/')
            );
            // console.log(droppedFile.relativePath);
            // console.log(this.productDirectory);
            this.folderInputSelector.title = this.productDirectory;

            if (
              this.productDirectory === null ||
              this.productDirectory === undefined ||
              this.productDirectory === ''
            ) {
              alert(
                'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
              );
            }
            //Assign file.type for ContentType upload later
            // console.log(file.size);
            // console.log(this.totalSizeNum)

            this.totalSizeNum += file.size;
            console.log(this.totalSizeNum);
            if (file.name.toLowerCase().endsWith('.xml')) {
              //   file = "application/xml"
            }
            if (
              file.name.toLowerCase().endsWith('.db') ||
              file.name.toLowerCase().endsWith('.DB')
            ) {
              //ignore
            } else {
              //continues only if the file is not the windows .db/.DB file
              this.productFilesStringArray.push(file.name);
              this.productFilesArray.push(file);
              if (file.name.toLowerCase().endsWith('.shp')) {
                //Store shapefile to send to server later
                this.shapeFile = file;
              } else if (file.name.toLowerCase().endsWith('.dbf')) {
                this.DBF = file;
              } else if (file.name.toLowerCase().endsWith('.prj')) {
                this.PRJ = file;
              }
              this.dropZoneText = droppedFile.relativePath.substr(
                0,
                droppedFile.relativePath.indexOf('/')
              );
            }
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          console.log(droppedFile.relativePath, fileEntry);
        }
      }

      console.log(this.totalSize);
      this.changeDetector.detectChanges();
    }
  }

  public longest_common_starting_substring(inputArray) {
    const arr = inputArray.concat().sort();
    const a1 = arr[0];
    const a2 = arr[arr.length - 1];
    const L = a1.length;
    let i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
    return a1.substring(0, i);
  }
  public clicked(event) {
    // //var input = document.createElement('input');
    // input.type = 'file';
    // input.click();
    // console.log(input);
    // console.log(event);
    var input = document.getElementById('folderInput');
    input.click();
    var test = document.getElementById('dropZoneId');
    console.log(test);
  }
  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
  testable: any;
  constructor(
    private sitesService: SitesService,
    private productService: ProductsService,
    private surveyTypesServices: SurveyTypesService,
    private dataTypesServices: DataTypesService,
    private userService: UsersService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private linkSitesProductsService: LinkSitesProductsService,
    private loggingService: LoggingService,
    private fileservice: FileService,
    private exportService: ExportXmlService,
    public dialog: MatDialog,
    private readonly joyrideService: JoyrideService,
    private changeDetector: ChangeDetectorRef,
    private snackbar: MatSnackBar
  ) {
    this.metadataForm = new FormGroup({
      metadataFileName: new FormControl('', Validators.required),
    });
    console.log(exportService.getXmlMetaData().getValue());

    // Populating survey types drop down
    this.IsChecked = false;
    this.surveyTypesServices
      .getSurveyTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.surveyTypes = result;
        },
        error => console.log(error)
      );

    // Populating data types drop down
    this.dataTypesServices
      .getDataTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.dataTypes = result;
        },
        error => console.log(error)
      );
  }
  OnChange($event) {
    this.IsChecked = $event.checked;
  }
  ngOnInit() {
    function filesPicked(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const path = file.webkitRelativePath.split('/');

        console.log(file);
        // upload file using path
      }
    }
    this.initializeMap();
    // Getting all the existing sites
    this.sitesService
      .getOnlySites()
      .pipe(take(1))
      .subscribe(
        result => {
          this.existingSites = result;
          this.sites = result;
          console.log(this.sites);
          this.dataSource = new MatTableDataSource(this.existingSites);
          console.log(this.existingSites);
        },
        error => {
          console.log(error);
        }
      );

    // Checking if user is logged in
    if (sessionStorage.getItem('email') !== null) {
    } else {
      console.log('Please login!');

      this.router.navigate(['/login', { continue: this.router.url }]).then(
        nav => {
          //console.log(nav);
        },
        err => {
          console.log(err);
        }
      );
    }
    this.dropZoneText = 'Drop Folder Here';
    this.keyword = 'site_label';

    let wantsProviderTour = sessionStorage.getItem('wantsProviderTour');

    // if (wantsProviderTour == 'true' || wantsProviderTour == null) this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogProviderTourOverview, {
      width: '250px',
      data: { response: 'foo' },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      if (result == 'startProviderTour') {
        console.log('starting tour');
        this.joyrideService.startTour({
          steps: ['1stProviderStep'],
        });
      }
    });
  }
  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }
  onChangeSelectedSites(selectedSites: any) {
    this.existingSite = true;
    this.sitesAdded = selectedSites;
  }

  initializeMap() {
    var myIcon = L.icon({
      iconUrl: '../../assets/img/marker-icon.png',
      iconSize: [24, 36],
      iconAnchor: [12, 36],
      popupAnchor: [-3, -76],
      shadowUrl: '../../assets/img/marker-shadow.png',
    });
    //define marker
    this.customMarker = L.marker([27, -99], {
      draggable: false,
      icon: myIcon,
      clickable: false,
    });

    this.loaded = true;
  }

  async metadataOnSubmit(dataForm: NgForm, confirmationForm: NgForm) {
    console.log('HERE!');
    console.log(dataForm);
    console.log(this.img);
    console.log(this.productDisplayName);
    //Create new XML variable from metadata
    var xml = new XmlParse(this.metadataFile);
    //Convert into JSON
    this.productJsonXml = JSON.stringify(xml);

    await xml.parse().then(result => {
      console.log(result);
      var data = xml.schemaMatch(result);
      console.log(data);
      // uploadForm.controls['productDescription'].setValue(
      //   data['Product_Description']
      // );
      // confirmationForm.controls['metadataFileName'].setValue(
      //   this.metadataFile.name
      // );

      var dateString = data['Product_Survey_Date'];
      var year = dateString.toString().substring(0, 4);
      var month = dateString.toString().substring(4, 6);
      var day = dateString.toString().substring(6, 8);

      if (moment(dateString, 'MM-DD-YYYY', true).isValid()) {
        //date is formatted as MM-DD-YYYY
        year = dateString.toString().substring(6, 10);
        month = dateString.toString().substring(0, 2);
        day = dateString.toString().substring(3, 5);
      }

      this.surveyDate = new Date(year, month - 1, day);
      dataForm.controls['surveyDate'].setValue(this.surveyDate);
      dataForm.controls['productDisplayName'].setValue(this.productDisplayName);
      this.pathSurveyDate = moment(this.surveyDate).format('YYYY-MM-DD');

      for (var i = 0; i < this.surveyTypes.length; i++) {
        if (
          this.surveyTypes[i].survey_type.toLowerCase() ===
          data['Product_SurveyType'].toLowerCase()
        ) {
          // uploadForm.controls['surveyType'].setValue(this.surveyTypes[i].survey_index_id.toString());
          // this.productSurveyType = this.surveyTypes[i].survey_type;
        }
      }
      for (var i = 0; i < this.dataTypes.length; i++) {
        if (
          this.dataTypes[i].data_type.toLowerCase() ===
          data['Product_DataType'].toLowerCase()
        ) {
          // uploadForm.controls['dataType'].setValue(this.dataTypes[i].data_index_id.toString());
          // this.productDataType = data['Product_DataType'];

          this.fileDropDiv = true;
          // if (data['Product_DataType'].toLowerCase() == 'aerial imagery') {
          //   this.fileDropDiv = true;
          //   this.singleFileDiv = false;
          // } else {
          //   this.fileDropDiv = false;
          //   this.singleFileDiv = true;
          // }
        }
      }

      //  uploadForm.controls['dataType'].setValue(data.Product_DataType);
      var latlngCentroid = xml.calculateCentroid(result);
      var latlngBounds = xml.calculateBounds(result);
      this.calculatedCentroid = latlngCentroid;
      this.calculatedBounds = latlngBounds;
      // console.log(dataForm.controls['existingSite'].value);
      // confirmationForm.controls['siteName'].setValue(dataForm.controls['existingSite'].value);
      //console.log(confirmationForm.controls['dataType'].value);
      //this.productDataType = confirmationForm.controls['dataType'].value;
      // confirmationForm.controls['latitude'].setValue(latlngCentroid.lat);
      // confirmationForm.controls['longitude'].setValue(latlngCentroid.lng);
      // if (this.existingSite == false) {
      //   this.siteForm.controls['latitude'].setValue(latlngCentroid.lat);
      //   this.siteForm.controls['longitude'].setValue(latlngCentroid.lng);
      // }
    });

    // if (this.existingSite == false) {
    //   this.calculatedCentroid = new LatLng(
    //     this.siteForm.controls['latitude'].value,
    //     this.siteForm.controls['longitude'].value
    //   );
    // }
    // confirmationForm.controls['metadataFileName'].setValue(
    //   this.metadataFile.name
    // );
    this.hasSubmitMetadataForm = true;
    // if (this.metadataForm.valid) {
    this.stepper.next();
    //}
  }

  existingSitesOnSubmit() {
    this.stepper.next();
  }

  // Submit login form
  siteOnSubmit(dataForm: NgForm) {
    this.dataForm = dataForm;
    // this.isNewSite = true;
    console.log('Site Submit');

    if (this.dataForm.valid) {
      this.stepper.next();
    }
  }

  // Submit upload form
  async uploadOnSubmit(
    stepper: MatStepper,
    uploadForm: NgForm,
    dataForm: NgForm
  ) {
    this.totalSize = this.convertBytes(this.totalSizeNum);
    console.log(this.totalSize);
    console.log(uploadForm);
    console.log(this.productFilesArray);
    console.log(this.ngxDragDrop);
    console.log(this.metadataForm.controls['metadataFileName']);
    var scrubbedArray = [];

    if (this.productFilesArray.length == 0) {
      //There must be files
      console.log('NO FILES FOUND');
      this.ngxDragDrop.dropZoneClassName = 'filedropError';
    } else if (this.metadataFile == undefined) {
      var htmlelement = document.getElementsByName(
        'metadataFileNameFormField'
      )[0] as HTMLElement;
      htmlelement.classList.add('invalid-field');
    } else {
      //Calculate the longest common substring for product labeling
      for (var i = 0; i < this.productFilesArray.length; i++) {
        //get filename
        var fileExt = this.productFilesArray[i].name.split('.').pop();
        console.log(fileExt);
        if (fileExt.toUpperCase() == 'DB') {
          console.log('Ignoreing DB file');
          //continue
          //Ignore the automatic generation of windows10 icon files
        } else {
          scrubbedArray.push(this.productFilesArray[i].name);
        }
      }
      console.log(scrubbedArray);
      this.productDirectory = this.longest_common_starting_substring(
        scrubbedArray
      );
      console.log(this.productDirectory);
      console.log(
        'PRODUCT DIRECTORY IN UPLOAD ON SUBMIT: ',
        this.productDirectory
      );
      if (this.IsChecked) {
        var xml = new XmlParse(this.metadataFile);
        await xml.parse().then(result => {
          var data = xml.schemaMatch(result);
          console.log(dataForm);
          console.log(data);
          const list = document.createElement('ul');
          const listItem = document.createElement('li');

          if (data[0] == 'ERROR') {
            var templateString = '';
            for (const i of Object.keys(data)) {
              if (data[i] != 'ERROR') {
                //console.log(Object.keys(data[i]));
                var key = Object.keys(data[i]);
                var value = Object.values(data[i]);

                templateString += `<b>${key}:</b> ${value}\n`;
              }
            }
            console.log(templateString);
            listItem.innerHTML = templateString;
            list.appendChild(listItem);
            Swal.fire({
              title: 'Oops...XML Parse Error',
              type: 'error',
              html: `<ul style="text-align:left">${templateString}</ul>`,
              footer:
                '<a href="https://www.fgdc.gov/standards/projects/metadata/base-metadata/v2_0698.pdf">Why do I have this issue?</a>',
            }).then(result => {
              /* Read more about isConfirmed, isDenied below */
              if (result) {
                console.log('CONFIRMED!');
                window.location.reload();
              }
            });
          }
          // if(data == -1)
          // {
          //
          // }
          dataForm.controls['productDescription'].setValue(
            data['Product_Description']
          );

          var dateString = data['Product_Survey_Date'];
          var year = dateString.toString().substring(0, 4);
          var month = dateString.toString().substring(4, 6);
          var day = dateString.toString().substring(6, 8);

          if (moment(dateString, 'MM-DD-YYYY', true).isValid()) {
            //date is formatted as MM-DD-YYYY
            year = dateString.toString().substring(6, 10);
            month = dateString.toString().substring(0, 2);
            day = dateString.toString().substring(3, 5);
          }

          this.surveyDate = new Date(year, month - 1, day);
          this.pathSurveyDate = moment(this.surveyDate).format('YYYY-MM-DD');
          dataForm.controls['surveyDate'].setValue(this.surveyDate);

          dataForm.controls['surveyType'].setValue(data['Product_SurveyType']); //NEED ERROR CHECKING
          dataForm.controls['dataType'].setValue(data['Product_DataType']); //NEED ERROR CHECKING
          // for (var i = 0; i < this.surveyTypes.length; i++) {
          //   if (
          //     this.surveyTypes[i].survey_type.toLowerCase() ===
          //     data['Product_SurveyType'].toLowerCase()
          //   ) {
          //     dataForm.controls['surveyType'].setValue(
          //       this.surveyTypes[i].survey_index_id.toString()
          //     );
          //   } else {
          //     alert(
          //       'The surveyType attribute in the metadata does not match a known surveyType! Please check your metadata syntax at the below attribute URL. \n\nmetadata.idinfo.descript.supplinf'
          //     );
          //   }
          // }

          // for (var i = 0; i < this.dataTypes.length; i++) {
          //   if (
          //     this.dataTypes[i].data_type.toLowerCase() ===
          //     data['Product_DataType'].toLowerCase()
          //   ) {
          //     dataForm.controls['dataType'].setValue(
          //       this.dataTypes[i].data_index_id.toString()
          //     );
          //   } else {
          //     alert(
          //       'The dataType attribute in the metadata does not match a known dataType! Please check your metadata syntax at the below attribute URL. \n\nmetadata.spdoinfo.direct'
          //     );
          //   }
          // }

          //  uploadForm.controls['dataType'].setValue(data.Product_DataType);
          // var latlngCentroid = xml.calculateCentroid(result);
          // var latlngBounds = xml.calculateBounds(result);

          // dataForm.controls['latitude'].setValue(latlngCentroid.lat);
          // dataForm.controls['longitude'].setValue(latlngCentroid.lng);
        });
      }
      let uploadDataResult = this.uploadGeometryData().then(result => {
        console.log(result);
        this.confirmedCalculatedFeatures = result;
        var json = {
          email: sessionStorage.getItem('email'),
          sessionToken: sessionStorage.getItem('cookie'),
          whoIssuedRoute: sessionStorage.getItem('email'),
          calculatedFeatures: result,
        };
        // this.sitesService.getSitesBasedOnDistance(json).subscribe( res =>
        //   {
        //     var obj = res;

        //     for(var i in obj)
        //     {
        //       console.log(obj[i]);
        //       obj[i].bestDistance.val = Math.round(obj[i].bestDistance.val * 100) / 100
        //       obj[i].calcDistance = obj[i].bestDistance.val
        //       obj[i].unit = obj[i].bestDistance.unit;
        //     }

        //     this.sites = obj;
        //     console.log(this.sites);
        //     this.changeDetector.detectChanges();
        //   });
      });

      this.ngxDragDrop.dropZoneClassName = 'filedrop';
      this.changeDetector.detectChanges();
      this.uploadForm = uploadForm;
      this.stepper.next();
    }
  }

  async metadataSelectFile(
    files: FileList,
    dataForm: NgForm,
    uploadForm: NgForm,
    confirmForm: NgForm
  ) {
    console.log(this.productDisplayName);
    console.log(dataForm);

    // Checking to see if there are files
    if (files.length === 0) {
      return;
    }

    this.metadataFile = files[0];
    console.log('HERE');
    console.log(this.metadataFile);
    if (this.metadataFile.name.split('.').pop() != 'xml') {
      alert('.XML is the only accepted file extension.');
      this.metadataFile = null;
      this.metadataFileName = '';
      return;
    }
    // this.metadataFileName = files[0].name;
    // this.metadataForm.setValue({ metadataFileName: files[0].name });
    console.log('SETTING FILENAME');
    this.metadataFileName = files[0].name;
  }
  // Select Product File
  selectProductFile(files: FileList) {
    // Checking to see if there are files
    if (files.length === 0) {
      return;
    }
    // Reset files in case user clicks it again
    this.productFile = null;
    this.productFileName = files[0].name;
    this.productFile = files[0];

    if (this.productFile.name.split('.').pop() == 'xml') {
      alert('.XML is not a valid product extension');
      this.productFile = null;
      this.productFileName = '';
    }
    console.log(this.productFile);
    // this.productDirectory = this.productFile.name.relativePath.substr(
    //   0,
    //   droppedFile.relativePath.indexOf('/')
    // );
    // if (
    //   this.productDirectory === null ||
    //   this.productDirectory === undefined ||
    //   this.productDirectory === ''
    // ) {
    //   alert(
    //     'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
    //   );
    // }
  }
  filesPicked(files, folder) {
    var scrubbedArray = [];
    for (var i = 0; i < files.length; i++) {
      var fileExt = files[i].name.split('.').pop();
      console.log(fileExt);
      if (fileExt.toUpperCase() == 'DB') {
        console.log('Ignoreing DB file');
        //continue
        //Ignore the automatic generation of windows10 icon files
      } else {
        scrubbedArray.push(files[i].name);
      }
    }
    console.log(this.productDirectory);
    this.productDirectory = this.longest_common_starting_substring(
      scrubbedArray
    );
    console.log('PRODUCT DIRECTORY IN FILES PICKED: ', this.productDirectory);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      var fileExt = files[i].name.split('.').pop();
      console.log(fileExt);
      if (fileExt.toUpperCase() == 'DB') {
      } else {
        this.productFilesStringArray.push(file.name);
        this.productFilesArray.push(file);
        if (file.name.toLowerCase().endsWith('.shp')) {
          //Store shapefile to send to server later
          this.shapeFile = file;
        } else if (
          file.name
            .toLowerCase()
            .endsWith('.dbf')
            .toLowerCase()
        ) {
          this.DBF = file;
        } else if (
          file.name
            .toLowerCase()
            .endsWith('.prj')
            .toLowerCase()
        ) {
          this.PRJ = file;
        }
        const path = file.webkitRelativePath.split('/');
        // upload file using path
        console.log(file);
        this.dropZoneText = this.productDirectory;
        if (
          this.productDirectory === null ||
          this.productDirectory === undefined ||
          this.productDirectory === ''
        ) {
          alert(
            'Unable to find directory of dropped files. Please ensure the files are in the following format:\n\n exampleDirectory/exampleFile.las'
          );
        }
      }
    }
  }
  async confirmationOnSubmit(
    stepper: MatStepper,
    siteForm: NgForm,
    uploadForm: NgForm,
    confirmationForm: NgForm
  ) {
    this.submitClicked = true;
    this.changeDetector.detectChanges();

    this.showProgressBar = true;
    // Create our site
    if (this.siteIds.length !== 0) {
      //this.createProductMultipleSites();
    } else {
      if (this.formValidation(confirmationForm) === false) {
        return;
      }

      // let uploadDataResult = this.uploadGeometryData().then( result  =>
      //   {
      //     console.log("HERE IS THE TRANSFORMED DATA: ")
      //     console.log(result);
      var json = JSON.parse(JSON.stringify(confirmationForm.value));
      console.log(json);

      json.whoIssuedRoute = sessionStorage.getItem('email');
      json.sessionToken = sessionStorage.getItem('cookie');
      json.surveyType = +json.surveyType;
      json.dataType = +json.dataType;
      json.totalSize = this.totalSize;

      //json.centroid = this.calculatedCentroid;
      //json.geom = this.calculatedBounds;
      json.calculatedFeatures = this.confirmedCalculatedFeatures;
      //  json.append('file', this.shapeFile,this.shapeFile.name);
      var jsonUser = {
        email: sessionStorage.getItem('email'),
        sessionToken: sessionStorage.getItem('cookie'),
        whoIssuedRoute: sessionStorage.getItem('email'),
      };

      this.userService.getUserType(jsonUser).subscribe(userTypeResult => {
        json.organization_id = userTypeResult[0].organization_id;

        this.createProduct(json, confirmationForm);

        // this.sitesService.createSite(json).subscribe(
        //   site => {
        //     // Create a product once we have the site id
        //     var loggingData = new LoggingJson(this.loggingService);
        //     loggingData.user = sessionStorage.getItem('email');
        //     loggingData.action = LoggingJson.Actions.INSERT;
        //     loggingData.comments = LoggingJson.Comments.INSERT_SITE;
        //     loggingData.changes = site;
        //     loggingData.submit();

        //     this.createProductFromOrtho(site, confirmationForm);

        //   },
        //   error => console.log(error)
        // );
      });

      // result.subscribe(event => console.log(event));
      //   });
    }
  }

  createProduct(productJson, confirmationForm) {
    let uniqueId = uuid.v4();
    this.productUUID = uniqueId;
    let remotePath = '';
    let UUIDFILEARRAY = [];
    //Get Correct surveytype and datatype so its not 0,1,2,3 but rather string... etc..
    for (var i = 0; i < this.surveyTypes.length; i++) {
      if (
        this.surveyTypes[i].survey_index_id.toString() ===
        confirmationForm.controls['surveyType'].value
      ) {
        this.productSurveyType = this.surveyTypes[i].survey_type;
      }
    }
    for (var i = 0; i < this.dataTypes.length; i++) {
      if (
        this.dataTypes[i].data_index_id.toString() ===
        confirmationForm.controls['dataType'].value
      ) {
        this.productDataType = this.dataTypes[i].data_type;
      }
    }
    //Include datatype and surveytype into string template

    remotePath = `lidar-files/${this.productSurveyType}/${this.productDataType}/${this.pathSurveyDate}/${this.productDirectory}/`;

    //Loop through this.productFilesArray (contains products files) to generate a UUID for each file.
    for (var i = 0; i < this.productFilesArray.length; i++) {
      var generatedUUID = uuid.v4();
      UUIDFILEARRAY.push(generatedUUID);
    }

    //Upload files to amazon to generate object url for DB
    s3Service.s3Util
      .uploadDirectoryOfFiles(
        'lidar-app-uploads',
        remotePath,
        this.productFilesArray,
        null,
        uniqueId,
        UUIDFILEARRAY
      )
      .then(productReturn => {
        //generate a UUID for the metadatafile (XML)
        let uniqueID = uuid.v4();
        this.metadataUUID = uniqueID;
        s3Service.s3Util
          .uploadFile(
            'lidar-app-uploads',
            `lidar-files/${this.productSurveyType}/${this.productDataType}/${this.pathSurveyDate}/${this.productDirectory}/${this.metadataFileName}`,
            this.metadataFile,
            null,
            uniqueID
          )
          .then(metadataReturn => {
            var jsonUser = {
              email: sessionStorage.getItem('email'),
              sessionToken: sessionStorage.getItem('cookie'),
              whoIssuedRoute: sessionStorage.getItem('email'),
            };

            var TIF = remotePath + this.productDirectory + '.tif';
            var TFW = remotePath + this.productDirectory + '.tfw';
            var S3PATH = remotePath;
            var FILE = this.productDirectory;

            s3Service.s3Util
              .invokeLambdaRaster2Tiles(TIF, TFW, FILE, S3PATH)
              .then(result => {
                console.log(result);
                console.log('Asked S3 To Generate Tiles');

                this.userService
                  .getUserType(jsonUser)
                  .subscribe(userTypeResult => {
                    //?????????????????
                    this.fileExtensions = this.calculateExtensions(
                      this.productFilesStringArray
                    );
                    var trueFileExtention = this.getProductTrueFileExtension(
                      this.fileExtensions
                    );
                    //????????????????

                    var json = {
                      whoIssuedRoute: sessionStorage.getItem('email'),
                      sessionToken: sessionStorage.getItem('cookie'),
                      s3_path: remotePath,
                      s3_path_metadata: metadataReturn.Key,
                      product_UUID: this.productUUID,
                      product_metadata_UUID: this.metadataUUID,
                      product_display_name: this.productDisplayName,
                      productDescription:
                        confirmationForm.controls['productDescription'].value,
                      uuid_array: UUIDFILEARRAY,
                      surveyType: confirmationForm.controls['surveyType'].value,
                      dataType: confirmationForm.controls['dataType'].value,
                      product_xml: this.productJsonXml,
                      files: this.productFilesStringArray,
                      organization_id: userTypeResult[0].organization_id,
                      directoryName: this.productDirectory,
                      productFileExt: trueFileExtention,
                      calculatedFeatures: this.confirmedCalculatedFeatures,
                      product_survey_date: this.surveyDate,
                      // siteLabel: confirmationForm.controls['siteName'].value,
                      // siteDescription: confirmationForm.controls['siteDescription'].value,
                    };

                    console.log(json);

                    this.productService.createProduct(json).subscribe(
                      product => {
                        console.log(product);
                        // Establishing link
                        var loggingData = new LoggingJson(this.loggingService);
                        loggingData.user = sessionStorage.getItem('email');
                        loggingData.action = LoggingJson.Actions.INSERT;
                        loggingData.comments =
                          LoggingJson.Comments.INSERT_PRODUCT;
                        loggingData.changes = {
                          product_index_id: product.product_index_id,
                          product_label: product.product_label,
                        };
                        loggingData.submit();
                        console.log('product for site SUCCESSFUL');
                        window.location.reload();
                        // CREATE SITE BASED ON THIS PRODUCT.
                        // this.createLinkSitesProducts(site, product);
                      },
                      error => console.log(error)
                    );
                  });
              });
          });
      });
  }
  convertBytes(x) {
    //Stack overflow answer
    //Define units
    console.log(x);
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  calculateExtensions(data: any) {
    var fileExtArray = [];
    for (var i = 0; i < data.length; i++) {
      var item = data[i].split('.').pop();
      fileExtArray.indexOf(item) === -1
        ? fileExtArray.push(item)
        : console.log('This item already exists');
    }
    console.log(fileExtArray);
    return fileExtArray;
  }
  getProductTrueFileExtension(fileExtArray: any) {
    var ext = '';
    for (var i = 0; i < fileExtArray.length; i++) {
      if (fileExtArray[i].toUpperCase().indexOf('TIF') !== -1) {
        //if we find some version of TIF
        ext = '.tif';
      } else if (fileExtArray[i].toUpperCase().indexOf('TIFF') !== -1) {
        //if we find some version of TIFF // hardcode to .tif to keep things the same.
        ext = '.tif';
      } else if (fileExtArray[i].toUpperCase().indexOf('LAS') !== -1) {
        ext = '.las';
      }
      // fileExtArray[i].toUpperCase().indexOf("TIF") === -1 ? ext = ".tif"
      // fileExtArray[i].toUpperCase().indexOf("TIFF") === -1 ? ext = ".tif" ;
      // if (fileExtArray[i].toUpperCase().indexOf("LAS") === -1) { };
    }
    return ext;
  }

  uploadGeometryData() {
    console.log('HERE');
    const readerSF = new FileReader();
    const readerDBF = new FileReader();
    const readerPRJ = new FileReader();
    var promiseReturn = new Promise((resolve, reject) => {
      readerSF.readAsDataURL(this.shapeFile);

      readerSF.onload = () => {
        //this.fileservice.upload(this.shapeFile.name, readerSF.result.toString());

        readerDBF.readAsDataURL(this.DBF);
        readerDBF.onload = () => {
          readerPRJ.readAsDataURL(this.PRJ);
          {
            readerPRJ.onload = () => {
              var concatThreeFiles =
                readerSF.result.toString() +
                '-' +
                readerDBF.result.toString() +
                '-' +
                readerPRJ.result.toString();
              var fileServiceReturn = this.fileservice.upload(
                this.shapeFile.name,
                this.DBF.name,
                this.PRJ.name,
                concatThreeFiles.toString()
              );
              console.log(fileServiceReturn);
              fileServiceReturn.subscribe(res => {
                console.log(res);
                resolve(res);
              });
            };
          }
        };
      };
    });

    console.log(promiseReturn);
    return promiseReturn;
  }

  // Connecting the site and product through a linking table on the db
  createLinkSitesProducts(site: any, product: any) {
    let siteProduct = {
      siteId: site.site_index_id,
      productId: product.product_index_id,
      whoIssuedRoute: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
    };

    this.testable = this.linkSitesProductsService
      .createLinkSiteProduct(siteProduct)
      .subscribe(
        async result => {
          alert('Product successfully created!');
          this.showProgressBar = false;
          if (this.productFilesArray.length == 0) {
            //LAS
          } else {
            //ortho
            console.log('UPLOADED');
            // await this.uploadGeometryData();
            this.router.navigateByUrl('/');

            console.log('DONE');
          }

          //  window.location.reload();
        },
        error => console.log(error)
      );
  }
  ngOnDestroy() {
    try {
      this.testable.unsubscribe();
    } catch (e) {
      console.log('Unable to unsubscribe: ' + e);
    }
  }
  // createProductMultipleSites() {
  //   // Product file
  //   let uniqueId = uuid.v4();
  //   this.productUUID = uniqueId;
  //   s3Service.s3Util
  //     .uploadFile(
  //       'lidar-app-uploads',
  //       `lidar-files/${this.productFileName}`,
  //       this.productFile,
  //       null,
  //       uniqueId
  //     )
  //     .then(productReturn => {
  //       //When this returns the product has been sucessfully uploaded
  //       let uniqueID = uuid.v4();
  //       this.metadataUUID = uniqueID;
  //       // Metadata file
  //       s3Service.s3Util
  //         .uploadFile(
  //           'lidar-app-uploads',
  //           `lidar-files/${this.metadataFileName}`,
  //           this.metadataFile,
  //           null,
  //           uniqueID
  //         )
  //         .then(metadataReturn => {
  //           let formData = this.uploadForm.value;
  //           formData.s3_path = this.s3Url + productReturn.Key;
  //           formData.s3_path_metadata = this.s3Url + metadataReturn.Key;
  //           formData.product_UUID = this.productUUID;
  //           formData.product_metadata_UUID = this.metadataUUID;

  //           var json = JSON.parse(JSON.stringify(formData));
  //           json.whoIssuedRoute = sessionStorage.getItem('email');
  //           json.sessionToken = sessionStorage.getItem('cookie');
  //           json.surveyType = +json.surveyType;
  //           json.dataType = +json.dataType;
  //           json.product_xml = this.productJsonXml;
  //           json.centroid = this.calculatedCentroid;
  //           json.geom = this.calculatedBounds;

  //           this.productService
  //             .createProductForSite(json)
  //             .pipe(
  //               mergeMap((product: any) => {
  //                 var jsoner = {
  //                   productId: product.product_index_id,
  //                   sites: this.siteIds,
  //                   whoIssuedRoute: sessionStorage.getItem('email'),
  //                   sessionToken: sessionStorage.getItem('cookie'),
  //                 };

  //                 return this.linkSitesProductsService.createMultipleLinksWithSites(
  //                   jsoner
  //                 );
  //               })
  //             )
  //             .subscribe(
  //               result => {
  //                 alert('Product successfully created!');
  //                 this.showProgressBar = false;
  //                 window.location.reload();
  //               },
  //               error => console.log(error)
  //             );
  //         });
  //     });
  // }
  onMapReady(map: L.Map) {
    this._map = map;
    map.removeControl(map.zoomControl);

    this.layers.push(this.customMarker);
    var markerBounds = [this.customMarker.getLatLng()];

    map.fitBounds(markerBounds);
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }

  onMapZoomEnd(map: L.Map) {}
  inputChanged(e) {
    if (this.siteLatitude >= -90 && this.siteLatitude <= 90) {
      if (this.siteLongitude >= -180 && this.siteLongitude <= 180) {
        this.customMarker.setLatLng(
          new L.LatLng(this.siteLatitude, this.siteLongitude)
        );
        var markerBounds = [this.customMarker.getLatLng()];

        this._map.fitBounds(markerBounds);
        this._map.setZoom(8);
        setTimeout(() => {
          this._map.invalidateSize();
        }, 100);
      }
    }
  }

  iHaveLostFocusDoSomethingWithIt(searchValue: string) {
    console.log(searchValue);
  }
  formValidation(confirmationForm: NgForm) {
    // Checking to make sure description is longer than 250
    // if (confirmationForm.value.siteDescription.length < 10) {
    //   alert(
    //     'Cannot upload product, site Description has to be longer than at least 10 characters.'
    //   );
    //   this.showProgressBar = false;
    //   return false;
    // }

    // // Checking to make sure description isn't over 1000 characters
    // if (confirmationForm.value.siteDescription.length > 1000) {
    //   alert(
    //     'Cannot upload product, site Description cannot be longer than 1000 characters.'
    //   );
    //   this.showProgressBar = false;
    //   return false;
    // }

    // Checking to see if lat and long are correct
    if (
      confirmationForm.value.latitude < -90 ||
      confirmationForm.value.latitude > 90 ||
      confirmationForm.value.longitude < -180 ||
      confirmationForm.value.longitude > 180
    ) {
      alert(
        'Cannot upload product, latitude or longitude are not valid coordinates.'
      );
      this.showProgressBar = false;
      return false;
    }

    return true;
  }
}
