export const vertexShader = `
  varying vec3 vColor;
  attribute vec3 color;
  void main() {
    vColor = color;
    gl_PointSize = 2.;
    vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0 );
    gl_Position = projectionMatrix * modelViewPosition;

   
  }

`;
