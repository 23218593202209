import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatCardModule,
  MatExpansionModule,
  MatDialogModule,
} from '@angular/material';

import { OrthoViewDownloadComponent } from './components/ortho-view-download-page.component';
@NgModule({
  declarations: [OrthoViewDownloadComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    LeafletModule,
    MatExpansionModule,
    //LeafletDrawModule.forRoot()
  ],
})
export class OrthoViewDownloadModule {}
