import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class OrganizationsService {
  constructor(private http: HttpClient) {}

  getOrganizations() {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.get(`/api/data/organizations/`);
  }
}
