import { Component, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SocketService } from '../../services/socket.service';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoggingService } from '../../services/logging.service';
import { LoggingJson } from '../../app/logging-json';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent {
  constructor(
    private socketService: SocketService,
    private usersService: UsersService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private Auth: AuthService,
    private changeDetector: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {}

  private continueRoute;
  ngOnInit() {
    this.activatedroute.params.subscribe(params => {
      this.continueRoute = params.continue;
    });
  }
  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  // Submit login form
  loginOnSubmit(loginForm: NgForm) {
    if (loginForm.value != null) {
      encodeURIComponent(loginForm.value.password);
      var logingInJson = {
        email: loginForm.value.email,
        password: encodeURIComponent(loginForm.value.password),
      };
      this.usersService.getUserLogin(JSON.stringify(logingInJson)).subscribe(
        resultEmail => {
          if (resultEmail != false) {
            this.usersService
              .getUserIsValidated(JSON.stringify(logingInJson))
              .subscribe(resultValidated => {
                if (resultValidated[0].isvalidated != 1) {
                  alert('User is not validated!');
                } else {
                  var loggingData = new LoggingJson(this.loggingService);
                  loggingData.user = loginForm.value.email;
                  loggingData.action = LoggingJson.Actions.LOGIN;
                  loggingData.comments = LoggingJson.Comments.LOGIN;
                  loggingData.submit();
                  console.log(resultValidated);
                  sessionStorage.setItem('email', resultEmail.toString());
                  sessionStorage.setItem('cookie', resultValidated[1]);
                  // sessionStorage.setItem('timer', new Date().getTime().toString());
                  var userInfo = { email: sessionStorage.getItem('email') };
                  this.usersService
                    .getUserOrganization(userInfo)
                    .subscribe(resultUser => {
                      sessionStorage.setItem(
                        'oID',
                        resultUser[0].organization_id
                      );

                      this.Auth.setLoggedIn(true);
                      if (this.continueRoute == null) {
                        this.router.navigate(['/']);
                      } else {
                        this.router.navigate([this.continueRoute]);
                      }
                    });
                }
              });
          } else if (resultEmail == false) {
            alert('Username or password is not correct.');
          }
        },
        error => console.log(error)
      );
    }
  }
}
