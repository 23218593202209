import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable()
export class RegistrationService {
  constructor(private http: HttpClient) {}

  insertPendingUser(user) {
    return this.http.post(`/api/data/users/pending_user/`, user, httpOptions);
  }
  insertNewUser(user) {
    return this.http.post(`/api/data/users/new_user/`, user, httpOptions);
  }
}
