import {
  Component,
  EventEmitter,
  ViewChild,
  Input,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { SitesService } from '../../../services/sites.service';
import { SurveyTypesService } from '../../../services/surveytypes.service';
import { ProductsService } from '../../../services/products.service';
import { DataTypesService } from '../../../services/datatypes.service';
import { LinkSitesProductsService } from '../../../services/link_sites_products.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { LoggingJson } from '../../../app/logging-json';
import { LoggingService } from '../../../services/logging.service';
import { UsersService } from '../../../services/users.service';
declare var require: any;
var s3Service = require('../../../../../lib/s3Util.js');
@Component({
  selector: 'site-product-table-editor',
  templateUrl: './site-product-table-editor.component.html',
  styleUrls: ['./site-product-table-editor.component.css'],
})
export class SiteTableEdit {
  newSite: Object = {
    label: '',
  };

  surveyTypes: any;
  dataTypes: any;
  isLoading: boolean = false;
  existingSites: any;
  displayedColumns = [
    'product_index_id',
    'product_label',
    'SurveyType',
    'DataType',
    'survey_date',
    'updated_at',
    'view',
  ];

  /************** Site Form **************/
  siteId: number;
  siteName: string;
  siteDescription: string;
  siteLatitude: number;
  siteLongitude: number;
  adminAllowedToEdit: boolean = false;
  /***************************************/

  /************** Product Form **************/
  products: any;
  isProductsLoaded: boolean = false;
  /***************************************/

  user: any;
  constructor(
    private sitesService: SitesService,
    private productService: ProductsService,
    private surveyTypesServices: SurveyTypesService,
    private dataTypesServices: DataTypesService,
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private userService: UsersService,
    private linkSitesProductsService: LinkSitesProductsService,
    public productDialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {
    this.route.params.subscribe(params => {
      this.siteId = params['id'];
      this.getSite();
    });
    // Populating survey types drop down
    this.surveyTypesServices.getSurveyTypes().subscribe(
      result => {
        this.surveyTypes = result;
      },
      error => console.log(error)
    );

    // Populating data types drop down
    this.dataTypesServices.getDataTypes().subscribe(
      result => {
        this.dataTypes = result;
      },
      error => console.log(error)
    );

    // Getting all the existing sites
    this.sitesService.getOnlySites().subscribe(
      result => {
        this.existingSites = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  ngOnInit() {
    //check if user has a valid session
    var json = {
      email: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };
    this.userService.getUserType(json).subscribe(result => {
      this.user = result[0];

      for (var i = 0; i < this.existingSites.length; i++) {
        if (result[0].organization_id !== this.existingSites.organization_id) {
          console.log("Organization ID's don't match");
          this.adminAllowedToEdit = false;
        } else {
          console.log('');
          this.adminAllowedToEdit = true;
          break;
        }
      }

      if (sessionStorage.getItem('email') === result[0].email) {
        //Session matches DB//Valid User
        if (result[0].usertype < 2) {
          //user must be admin.
          window.alert(
            'You do not have permission to access this page. \nPlease contact your administrator.'
          );
          this.router.navigate(['/']);
        }
      } else {
        window.alert(
          'We could not verify you as a valid user. \nPlease try logging in again or contact your administrator.'
        );
        this.router.navigate(['/login']);
      }
    });
  }

  // Dialog function
  openProductDialog(productRow: any) {
    const dialogRef = this.productDialog.open(DialogContentProductsTable, {
      maxWidth: '800px',
      minWidth: '500px',
      position: { top: '0' },
      data: {
        product: productRow,
        surveyTypes: this.surveyTypes,
        dataTypes: this.dataTypes,
        exisitingSites: this.existingSites,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      //console.log(result);
      if (result === undefined || result === '') {
        return;
      }

      // Formatting result to a product
      let product = {
        product_index_id: result.product_index_id,
        product_survey_date: result.product_survey_date,
        survey_id: result.surveytype,
        data_id: result.datatype,
        site_ids: result.siteIds,
        product_description: result.productDescription,
      };

      this.updateProduct(product);
    });
  }

  // Submit login form
  siteOnSubmit(siteForm: NgForm) {
    // Formating lat and lon
    let updateValues = siteForm.value;

    // Checking validation
    if (this.formValidation(siteForm) === false) {
      return;
    }

    updateValues.site_location = {
      latitude: updateValues.latitude,
      longitude: updateValues.longitude,
    };
    updateValues.site_index_id = this.siteId;
    delete updateValues['latitude'];
    delete updateValues['longitude'];

    var json = JSON.parse(JSON.stringify(siteForm.value));
    json.whoIssuedRoute = sessionStorage.getItem('email');
    json.sessionToken = sessionStorage.getItem('cookie');

    this.sitesService.updateSite(json).subscribe(
      result => {
        var loggingData = new LoggingJson(this.loggingService);
        loggingData.user = sessionStorage.getItem('email');
        loggingData.action = LoggingJson.Actions.UPDATE;
        loggingData.comments = LoggingJson.Comments.UPDATE_SITE;
        loggingData.changes = siteForm.value;
        loggingData.submit();

        alert('Updated the site sucessfully!');
      },
      error => {
        alert('Error, could not update the site!');
        console.log(error);
      }
    );
  }

  // Getting the site from the database
  getSite() {
    var json = { siteID: this.siteId };
    this.sitesService.getSite(json).subscribe(
      site => {
        this.setSite(
          site[0].site_index_id,
          site[0].site_label,
          site[0].site_location.latitude,
          site[0].site_location.longitude,
          site[0].site_description
        );
        this.getProducts(site[0].site_index_id);
      },
      error => {
        console.log(error);
      }
    );
  }

  setSite(
    id: number,
    label: string,
    latitude: number,
    longitude: number,
    description: string
  ) {
    // Setting form values
    this.siteId = id;
    this.siteName = label;
    this.siteLatitude = latitude;
    this.siteLongitude = longitude;
    this.siteDescription = description;
  }

  // Getting the products from the database, based on the site
  getProducts(siteId: number) {
    var json = { siteID: siteId };
    this.linkSitesProductsService.getProductsBySite(json).subscribe(
      products => {
        this.products = products;
        console.log(this.products);
        this.isProductsLoaded = true;
      },
      error => {
        console.log(error);
      }
    );
  }

  // Updating the product
  updateProduct(product: any) {
    var json = JSON.parse(JSON.stringify(product));
    json.whoIssuedRoute = sessionStorage.getItem('email');
    json.sessionToken = sessionStorage.getItem('cookie');

    this.productService.updateProduct(json).subscribe(
      result => {
        var loggingData = new LoggingJson(this.loggingService);
        loggingData.user = sessionStorage.getItem('email');
        loggingData.action = LoggingJson.Actions.UPDATE;
        loggingData.comments = LoggingJson.Comments.UPDATE_PRODUCT;
        loggingData.changes = {
          product_index_id: result[0].product_index_id,
          product_label: result[0].product_label,
        };
        loggingData.submit();
        alert('This product has been updated sucessfully!');
        this.getProducts(this.siteId);
      },
      error => {
        console.log(error);
        alert('Error, cannot update the proudct.');
      }
    );
  }

  deleteProduct(productId: number, product: any) {
    if (
      window.confirm(
        'Are you sure you want to delete this product permanently? '
      )
    ) {
      var json = JSON.parse(JSON.stringify(product));
      json.whoIssuedRoute = sessionStorage.getItem('email');
      json.sessionToken = sessionStorage.getItem('cookie');
      this.productService.deleteProduct(productId, json).subscribe(
        result => {
          var loggingData = new LoggingJson(this.loggingService);
          loggingData.user = sessionStorage.getItem('email');
          loggingData.action = LoggingJson.Actions.DELETE;
          loggingData.comments = LoggingJson.Comments.DELETE_PRODUCT;
          loggingData.changes = {
            product_index_id: product.product_index_id,
            product_label: product.product_label,
          };
          loggingData.submit();

          s3Service.s3Util.deleteFile(
            'lidar-app-uploads',
            `lidar-files/${product.product_label}`
          );
          var metadataFileName = product.s3_path_metadata.substr(
            product.s3_path_metadata.lastIndexOf('/') + 1
          );
          s3Service.s3Util.deleteFile(
            'lidar-app-uploads',
            `lidar-files/${metadataFileName}`
          );
          alert('This product has been deleted sucessfully!');
          this.getProducts(this.siteId);
        },
        error => {
          console.log(error);
          alert('Error, cannot delete the proudct.');
        }
      );
    }
  }

  // Handy search function, not being used.
  findItem(id: number, array: any) {
    return array.find(item => item.id === id);
  }

  deleteLink(productId: number) {
    if (
      window.confirm(
        'Are you sure to remove the link between this product and its parent site?'
      )
    ) {
      let link = {
        site_id: this.siteId,
        product_id: productId,
        whoIssuedRoute: sessionStorage.getItem('email'),
        sessionToken: sessionStorage.getItem('cookie'),
      };

      this.linkSitesProductsService.deleteLink(link).subscribe(
        result => {
          var loggingData = new LoggingJson(this.loggingService);
          loggingData.user = sessionStorage.getItem('email');
          loggingData.action = LoggingJson.Actions.REMOVE;
          loggingData.comments = LoggingJson.Comments.REMOVE_PRODUCT;
          loggingData.changes = { site_id: this.siteId, product_id: productId };
          loggingData.submit();

          alert('The product has been removed from this site sucessfully!');
          this.getProducts(this.siteId);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  formValidation(siteForm: NgForm) {
    // Checking to make sure description is longer than 250
    if (siteForm.value.site_description.length < 10) {
      alert(
        'Cannot update site, site Description has to be longer than at least 10 characters.'
      );
      return false;
    }

    // Checking to make sure description isn't over 1000 characters
    if (siteForm.value.site_description.length > 1000) {
      alert(
        'Cannot update site, site Description cannot be longer than 1000 characters.'
      );
      return false;
    }

    // Checking to see if lat and long are correct
    if (
      siteForm.value.latitude < -90 ||
      siteForm.value.latitude > 90 ||
      siteForm.value.longitude < -180 ||
      siteForm.value.longitude > 180
    ) {
      alert(
        'Cannot update site, latitude or longitude are not valid coordinates.'
      );
      return false;
    }

    return true;
  }
}

// Product Table Dialog
@Component({
  selector: 'dialog-content-products-table',
  templateUrl: 'dialog-content-products-table.html',
  styleUrls: ['./site-product-table-editor.component.css'],
})
export class DialogContentProductsTable {
  existingSites: any;
  dataTypes: any;
  surveyTypes: any;

  // For the form
  surveyDate: string;
  siteIds: any;
  surveyType: any;
  dataType: any;
  productId: number;
  maxDate = new Date();
  productDescription: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // Populating the drop downs
    this.dataTypes = data.dataTypes;
    this.surveyTypes = data.surveyTypes;
    this.existingSites = data.exisitingSites;

    // Set form values
    this.siteIds = [this.data.product.site_id.toString()];
    this.surveyDate = data.product.product_survey_date;
    this.surveyType = data.product.survey_id.toString();
    this.dataType = data.product.data_id.toString();
    this.productId = data.product.product_index_id;
    this.productDescription = data.product.product_description;
  }
}
