import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

const customOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    ignoreLoadingBar: '',
  }),
};

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) {}

  getProducts() {
    return this.http.get('/api/data/products');
  }
  getAllProducts() {
    return this.http.get('/api/data/products/getAll');
  }
  getProduct(id) {
    //requires a json with key "productId"
    var test = this.http.post(`/api/data/product/`, id, customOptions);
    return test;
  }
  // getProductAndFiles(id)
  // {
  //   return this.http.post(`/api/data/products/files`, id)
  // }
  getFiles(id) {
    return this.http.post(`/api/data/products/files`, id, customOptions);
  }
  getAllFiles() {
    return this.http.get('/api/data/products/getAllFiles');
  }
  getProductAndUUIDFiles(id) {
    return this.http.post(`/api/data/products/uuidfiles`, id, customOptions);
  }

  getProductBytes(id): Observable<any> {
    return this.http.get(`/api/data/products/bytes/${id}`);
  }

  // createProductForSite(data): Observable<any> {
  //   return this.http.post(`/api/data/products/create/`, data);
  // }
  createProduct(data): Observable<any> {
    return this.http.post(`/api/data/products/create/`, data);
  }

  addPointsToProduct(productId, data) {
    return this.http.post(`/api/data/products/points/`, data, httpOptions);
  }

  updateProductCameraPosition(productId, data) {
    return this.http.post(
      `/api/data/products/update_camera/`,
      data,
      httpOptions
    );
  }

  updateProduct(product) {
    return this.http.post('/api/data/products/update/', product, httpOptions);
  }
  updateProductTotalSize(product) {
    return this.http.post(
      '/api/data/products/updateTotalSize/',
      product,
      httpOptions
    );
  }

  deleteProduct(productId, product) {
    //console.log(product);
    return this.http.post(`/api/data/products/delete/`, product, httpOptions);
  }
}
