import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteAutocompleteComponent } from './site-autocomplete.component';
import { MatAutocompleteModule, MatChipsModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [SiteAutocompleteComponent],
  exports: [SiteAutocompleteComponent]
})
export class SiteAutocompleteModule { }
