import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { SitesService } from '../../services/sites.service';
import { SurveyTypesService } from '../../services/surveytypes.service';
import { DataTypesService } from '../../services/datatypes.service';
import { ProductsService } from '../../services/products.service';
import { LinkSitesProductsService } from '../../services/link_sites_products.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSort,
} from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { NgForm } from '@angular/forms';
import * as uuid from 'uuid';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';

declare var require: any;
var s3Service = require('../../../../lib/s3Util');

@Component({
  selector: 'user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss'],
})
export class UserManagementComponent implements OnInit {
  displayedColumns = [
    'user_id',
    'user_fname',
    'user_lname',
    'user_email',
    'user_usertype',
    'user_isvalidated',
    'user_organization',
    'view',
  ];

  // For Add New Product Dialog
  surveyTypes: any;
  dataTypes: any;
  products: any;
  dataSource: any;
  USERS: any;
  s3Url = 'https://lidar-app-uploads.s3.amazonaws.com/';
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private sitesService: SitesService,
    public productDialog: MatDialog,
    private dataTypesService: DataTypesService,
    private surveyTypesService: SurveyTypesService,
    private linkSitesProductsService: LinkSitesProductsService,
    private productsService: ProductsService,
    private userService: UsersService,
    private router: Router
  ) {}

  ngOnInit() {
    //check if user has a valid session
    var json = {
      email: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };
    this.userService.getUserType(json).subscribe(result => {
      console.log(result);

      if (sessionStorage.getItem('email') === result[0].email) {
        //Session matches DB//Valid User

        if (result[0].usertype < 2) {
          //user must be admin.
          window.alert(
            'You do not have permission to access this page. \nPlease contact your administrator.'
          );
          this.router.navigate(['/']);
        }
      } else {
        window.alert(
          'We could not verify you as a valid user. \nPlease try logging in again or contact your administrator.'
        );
        this.router.navigate(['/login']);
      }
      this.getUsers(result);
    });
  }
  resendUserVerification(email: any) {
    let userJson = {
      email: email,
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };
    this.userService
      .resendUserVerificationKey(userJson)
      .subscribe(result => {});
  }
  deleteUser(email: any) {
    if (
      window.confirm('Are you sure you want to permanently delete this user?\n')
    ) {
      let userJson = {
        email: email,
        whoIssuedRoute: sessionStorage.getItem('email'),
        sessionToken: sessionStorage.getItem('cookie'),
      };
      this.userService.deleteUser(userJson).subscribe(result => {});
      window.location.reload();
    }
  }

  getUsers(user) {
    var json = {
      email: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };

    // Getting sites
    this.userService.getAllUsers(json).subscribe(
      users => {
        console.log(users);
        this.USERS = users;

        // Have to cast sites to any
        this.dataSource = new MatTableDataSource(this.USERS);
        //this.dataSource.sort = this.sort;
      },
      error => {
        console.log(error);
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
