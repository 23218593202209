import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FeedbackService } from '../../services/feedback.service';
import { take } from 'rxjs/operators';
import { using } from 'rxjs';
import { HttpClient } from 'selenium-webdriver/http';
var s3Service = require('../../../../lib/s3Util.js');
import * as uuid from 'uuid';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css'],
})
export class FeedbackDialogComponent implements OnInit {
  maxCharacters = 500;
  feedbackForm = new FormGroup({
    isBug: new FormControl('', [Validators.required]),
    pageLocation: new FormControl('', [Validators.required]),
    description: new FormControl('', [
      Validators.required,
      Validators.maxLength(this.maxCharacters),
    ]),
  });
  hasFormSubmitted = false;
  uploadedFile: any;
  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {}

  /**
   * (Used to close the feedback dialog)
   *
   * @memberof FeedbackDialogComponent
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * (Used to submit the feedback form)
   *
   * @returns
   * @memberof FeedbackDialogComponent
   */
  submitFeedback() {
    this.hasFormSubmitted = true;
    var json = {
      feedback_type: this.feedbackForm.value.isBug,
      interface: this.feedbackForm.value.pageLocation,
      description: this.feedbackForm.value.description,
      url: '',
    };

    let uniqueId = uuid.v4();
    var productUUID = uniqueId;
    let today = new Date();
    let date = today.toISOString().split('T')[0];
    if (this.uploadedFile == '' || this.uploadedFile == undefined) {
      json.url = 'NO SCREENSHOT PROVIDED';
    } else {
      let fileName = this.uploadedFile.name.substring(
        0,
        this.uploadedFile.name.length - 4
      );
      let remotePath = `${date}/${fileName}`;
      // let remotePathMetaData = `lidar-files/${this.productSurveyType}/${this.productDataType}/${fileName}/${this.metadataFileName}`;
      s3Service.s3Util.uploadFile(
        'lidar-app-bugs',
        remotePath,
        this.uploadedFile,
        null,
        uniqueId
      );
      json.url =
        'https://lidar-app-bugs.s3.amazonaws.com/' + date + '/' + fileName;
    }

    this.feedbackService
      .insertNewFeedback(json)
      .pipe(take(1))
      .subscribe(result => {
        this.dialogRef.close();
      });
    if (!this.feedbackForm.valid) {
      return;
    }
  }
  onFileChanged(event) {
    const file = event.target.files[0];
    console.log(file);
    this.uploadedFile = file;
  }
}
