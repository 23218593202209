import { Component, OnInit, Input } from '@angular/core';

//Global Declarations
declare let L;

@Component({
  selector: 'app-search-menu-geo-search',
  templateUrl: './search-menu-geo-search.component.html',
  styleUrls: ['./search-menu-geo-search.component.css'],
})
export class SearchMenuGeoSearchComponent implements OnInit {
  constructor() {}

  @Input() productList;
  @Input() _map;
  @Input() tab;
  @Input() layerTrackingDict;

  ngOnInit() {}
}
