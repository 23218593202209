import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LoggingJson } from '../../app/logging-json';
import { LoggingService } from '../../services/logging.service';
@Component({
  selector: 'logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.css'],
})
export class LogoutPageComponent {
  constructor(
    private Auth: AuthService,
    private loggingService: LoggingService
  ) {}
  goodbye: any;
  ngOnInit() {
    var loggingData = new LoggingJson(this.loggingService);
    loggingData.user = sessionStorage.getItem('email');
    loggingData.action = LoggingJson.Actions.LOGOUT;
    loggingData.comments = LoggingJson.Comments.LOGOUT;
    loggingData.submit();

    localStorage.clear();
    sessionStorage.clear();
    this.Auth.setLoggedIn(false);
    let array: string[] = [
      '“You have been my friend. That in itself is a tremendous thing.” -E.B. White (Charlotte’s Web)',
      '“Don’t be dismayed at goodbyes. A farewell is necessary before you can meet again. And meeting again, after moments or lifetimes, is certain for those who are friends.” -Richard Bach',
      '“How lucky I am to have something that makes saying goodbye so hard.” -A.A. Milne (Winnie-the-Pooh)',
      '“Never say goodbye because goodbye means going away and going away means forgetting.” -J.M. Barrie (Peter Pan)',
      '“Goodbyes are not forever, are not the end; it simply means I’ll miss you until we meet again.” -Unknown',
      '“So long as the memory of certain beloved friends lives in my heart, I shall say that life is good.” -Helen Keller',
      '“Yesterday brought the beginning, tomorrow brings the end, though somewhere in the middle we became the best of friends.” -Unknown',
      '“Good friends never say goodbye. They simply say “See you soon.” -Unknown',
      '“Though miles may lie between us, we are never far apart, for friendship doesn’t count miles, it’s measured by the heart.” -Unknown',
      '“You and I will meet again, When we’re least expecting it, One day in some far off place, I will recognize your face, I won’t say goodbye my friend, For you and I will meet again.” -Tom Petty',
      '“Nothing makes the earth seem so spacious as to have friends at a distance; they make the latitudes and longitudes.” -Henry David Thoreau',
      '“Great is the art of beginning, but greater is the art of ending.” -Henry Wadsworth Longfellow',
      '“They must often change, who would be constant in happiness or wisdom.” -Confucius',
      '“Satisfaction lies in the effort, not in the attainment, full effort is full victory.” -Mahatma Gandhi',
      '“No distance of place or lapse of time can lessen the friendship of those who are thoroughly persuaded of each other’s worth.” -Robert Southey',
      '“True friends don’t say good-bye, they just take extended leaves of absences from each other.” -Unknown',
      '“Life is a journey, not a destination.” -Ralph Waldo Emerson',
      '“Be well, do good work, and keep in touch.” -Garrison Keillor',
      '“Every new beginning comes from some other beginning’s end.” -Semisonic',
      '“This is the beginning of anything you want.” -Unknown',
      '“Farewell! God knows when we shall meet again.” -William Shakespeare',
      '“It’s time to say goodbye, but I think goodbyes are sad and I’d much rather say hello. Hello to a new adventure.” -Ernie Harwell',
      '“If you’re brave enough to say goodbye, life will reward you with a new hello.” -Paulo Coelho',
      '“All changes, even the most longed for, have their melancholy; for what we leave behind us is a part of ourselves; we must die to one life before we can enter another.” -Anatole France',
      '“Goodbyes make you think. They make you realize what you’ve had, what you’ve lost, and what you’ve taken for granted.” -Ritu Ghatourey',
      '“It’s sad, but sometimes moving on with the rest of your life, starts with goodbye.” -Carrie Underwood',
      '“I can’t remember all the times I told myself to hold on to these moments as they pass.” -Counting Crows',
      '“It’s not the days in life we remember, rather the moments.” -Walt Disney',
      '“Our memories of yesterday will last a lifetime. We’ll take the best, forget the rest, and someday will find that these are the best of times.” -Styx',
      '“Some people come into our lives and quickly go. Some stay for a while, leave footprints on our hearts, and we are never, ever the same.” -Flavia Weedn',
      '“The magic thing about home is that it feels good to leave, and it feels even better to come back.” -Wendy Wunder',
      '“You’ve changed me forever. And I’ll never forget you.” -Kiera Cass',
      '“This is not a goodbye, my darling, this is a thank you.” -Nicholas Sparks (Message in a Bottle)',
      '“I have learned along my journey that letting go doesn’t mean loving less.” -Alex Elle',
      '“Saying goodbye is the hardest solution of any problem. But sometimes it’s the only choice we have…” -Anurag Prakash Ray',
      '“At some point, you have to realize that some people can stay in your heart but not in your life.” -Unknown',
      '“Even if we can’t be together in the end, I’m glad that you were a part of my life.” -Unknown',
      '“Don’t cry because it’s over. Smile because it happened.” -Dr. Seuss',
      '“You had me at hello, goodbye and everything in between.” -Shannon L. Alder',
    ];

    this.goodbye = array[Math.floor(Math.random() * array.length)];
  }
}
