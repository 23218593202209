import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable()
export class FeedbackService {
  constructor(private http: HttpClient) {}
  insertNewFeedback(data) {
    return this.http.post(`/api/feedback/`, data, httpOptions);
  }
}
