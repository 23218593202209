import {
  Component,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
  SimpleChanges,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import * as _ from 'underscore';
import { FormControl, Validators } from '@angular/forms';
import { LinkSitesProductsService } from '../../services/link_sites_products.service';

interface SliderDetails {
  value: number;
  highValue: number;
  floor: number;
  ceil: number;
}

//Global Declarations
declare let L;

@Component({
  selector: 'app-search-menu-filter',
  templateUrl: './search-menu-filter.component.html',
  styleUrls: ['./search-menu-filter.component.css'],
})
export class SearchMenuFilterComponent implements OnInit {
  constructor(
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private dataService: DataService,
    private linkSitesProductsService: LinkSitesProductsService
  ) {}

  @Input() productList;
  @Input() _map;
  @Input() minYear;
  @Input() tab;
  @Input() layerTrackingDict;

  selectedProduct;
  hoverLayer: any;

  surveyOpenState = false;
  yearOpenState = false;
  dataOpenState = false;
  results = false;
  surveyTypes = [
    {
      key: 'survey_type',
      value: 'UAS SFM',
    },
    {
      key: 'survey_type',
      value: 'Terrestrial LiDAR',
    },
    {
      key: 'survey_type',
      value: 'UAS LiDAR',
    },
    {
      key: 'survey_type',
      value: 'Mobile LiDAR',
    },
    {
      key: 'survey_type',
      value: 'Other',
    },
  ];
  filterChecks = [];

  dataTypes = [
    {
      key: 'data_type',
      value: 'Point Cloud',
    },
    {
      key: 'data_type',
      value: 'DSM',
    },
    {
      key: 'data_type',
      value: 'DEM',
    },
    {
      key: 'data_type',
      value: 'Aerial Imagery',
    },
    {
      key: 'data_type',
      value: 'Other',
    },
  ];
  myArrayFiltered = [];

  value: number = 1970;

  selectFormControl = new FormControl('', Validators.required);

  years = [];
  currentYear = new Date().getFullYear();
  singleYear = false;
  theYear: any;
  filterLowValue: any;
  filterHighValue: any;
  yearsChanged = false;

  ngOnInit() {
    this.years = [];
    this.linkSitesProductsService.getAllSitesAndProducts().subscribe(sites => {
      this.populateYear();
      for (var x = this.currentYear; x >= this.minYear; x--) {
        this.years.push(x);
      }
    });
  }

  populateYear() {
    for (var i = 0; i < this.productList.length; i++) {
      var tempYear = new Date(this.productList[i].product_survey_date);
      if (this.minYear !== undefined && this.minYear > tempYear.getFullYear()) {
        this.minYear = tempYear.getFullYear();
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log('CHANGES: ', changes);
    // this.ngOnInit();
  }

  changeEnd(ev: any, startOrEnd: any) {
    console.log('CHANGE END: ', ev);
    this.yearsChanged = true;

    if (startOrEnd === 'START') {
      this.filterLowValue = ev;
    } else if (startOrEnd === 'END') {
      this.filterHighValue = ev;
    }
  }

  includeYearChecked(ev: any) {
    if (ev === true) {
      this.singleYear = true;
    } else {
      this.singleYear = false;
    }
  }

  formatLabel(value: number) {
    return value;
  }

  back() {
    this.results = false;
    this.singleYear = false;
    this.yearsChanged = false;
    this.value = 1970;
    this.filterChecks = [];
    this.myArrayFiltered = [];
    this.filterLowValue = undefined;
    this.filterHighValue = undefined;
    this.theYear = undefined;
  }

  showResults() {
    this.results = true;

    if (this.singleYear === true) {
      this.filterLowValue = this.theYear;
      this.filterHighValue = this.theYear;
    }

    for (var i = 0; i < this.productList.length; i++) {
      console.log(this.productList[i]);
      var productYear = new Date(this.productList[i].product_survey_date);
      //If the low + high values of search search have a value in them
      if (
        this.filterLowValue !== undefined &&
        this.filterHighValue !== undefined
      ) {
        if (
          productYear.getFullYear() >= this.filterLowValue &&
          productYear.getFullYear() <= this.filterHighValue
        ) {
          this.myArrayFiltered.push(this.productList[i]);
          continue;
        }
      }
      //loop through filterchecks
      console.log(this.filterChecks);
      for (var x = 0; x < this.filterChecks.length; x++) {
        if (this.filterChecks[x].key === 'survey_type') {
          console.log(this.productList[i].survey_type);
          console.log(this.filterChecks[x].value);
          if (this.productList[i].survey_type === this.filterChecks[x].value) {
            this.myArrayFiltered.push(this.productList[i]);
            break;
          }
        }
        if (this.filterChecks[x].key === 'data_type') {
          if (this.productList[i].data_type === this.filterChecks[x].value) {
            this.myArrayFiltered.push(this.productList[i]);
            break;
          }
        }
      }
    }

    // this.myArrayFiltered = _.filter(this.productList, (product) => {
    //   return _.every(this.filterChecks, (filter) => filter.value === product[filter.key]);
    // })

    console.log('NEWLIST: ', this.myArrayFiltered);
  }

  onCheck(ev: any, type: any): void {
    console.log('CHECKED: ', ev.checked, type);
    if (ev.checked === true) {
      this.filterChecks.push(type);
    } else {
      this.filterChecks.splice(this.filterChecks.indexOf(type), 1);
    }

    console.log('SURVERY CHECKS: ', this.filterChecks);
  }
}
