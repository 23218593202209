//import { s } from '@angular/core/src/render3';

import { object } from 'underscore';

let AWS = require('aws-sdk');
let Base64 = require('js-base64').Base64;

var _0x2c04 = [
  'dTJTWmNBanhSM09VUnRESThnTi9TQlhMSU1qVnNndjNWd1pFSWN5bg==',
  'QUtJQVhKVlQzUzRCTUlDWE8zRk8=',
  'dXMtZWFzdC0x',
  'decode',
  'config',
];
(function(_0x4e3c82, _0x849ca) {
  var _0x59c81f = function(_0x1a2798) {
    while (--_0x1a2798) {
      _0x4e3c82['push'](_0x4e3c82['shift']());
    }
  };
  _0x59c81f(++_0x849ca);
})(_0x2c04, 0x140);
var _0x187f = function(_0x4b315c, _0x2d152c) {
  _0x4b315c = _0x4b315c - 0x0;
  var _0x35a9d1 = _0x2c04[_0x4b315c];
  return _0x35a9d1;
};
const st = _0x187f('0x0');
const at = _0x187f('0x1');
const r = _0x187f('0x2');
const sdt = Base64[_0x187f('0x3')](st);
const adt = Base64[_0x187f('0x3')](at);
const rdt = Base64[_0x187f('0x3')](r);
AWS[_0x187f('0x4')]['update']({
  accessKeyId: adt,
  secretAccessKey: sdt,
  region: rdt,
  sslEnabled: !![],
});
let s3 = new AWS['S3']({
  accessKeyId: adt,
  secretAccessKey: sdt,
  region: rdt,
  sslEnabled: !![],
});

export const s3Util = {
  uploadFile: (bucket, remotePath, file, cb, UUID) => {
    var contentType = 'image/jpeg'; // default
    if (file.name.endsWith('.xml')) {
      contentType = 'text/xml';
    }
    console.log('S3Util: uploadFile:', file);
    const params = {
      Bucket: bucket,
      Key: remotePath,
      Metadata: [UUID],
      Body: file,
      ContentType: contentType,
    };
    return s3
      .upload(
        params,
        cb ||
          function(err, data) {
            if (err) {
              console.log('error', err);
            } else {
              console.log('Successful upload of file');
              resolve({ msg: 'foo' });
            }
          }
      )
      .promise();
  },

  uploadDirectoryOfFiles: (
    bucket,
    remotePath,
    fileArray,
    cb,
    UUID,
    UUIDARRAY
  ) => {
    console.log('BOO!');
    console.log('FILE ARRAY: ', fileArray, remotePath);
    var promises = [];
    var contentType = 'application/octet-stream'; //default
    for (var i = 0; i < fileArray.length; i++) {
      console.log(fileArray[i].name);
      if (fileArray[i].name.endsWith('.xml')) {
        contentType = 'text/xml';
      } else if (
        fileArray[i].name.toLowerCase().endsWith('.jpg') ||
        fileArray[i].name.toLowerCase().endsWith('.jpeg')
      ) {
        contentType = 'image/jpeg';
      } else {
        contentType = 'application/octet-stream';
      }
      const params = {
        Bucket: bucket,
        Key: remotePath + fileArray[i].name,
        Metadata: [UUIDARRAY[i], UUID],
        Body: fileArray[i],
        ContentType: contentType,
      };

      promises.push(
        s3
          .upload(
            params,
            cb ||
              function(err, data) {
                if (err) {
                  console.log('error', err);
                } else {
                  console.log('Successful upload of file');
                  resolve({ msg: 'foo' });
                }
              }
          )
          .promise()
      );
    }
    return Promise.all(promises);
  },
  downloadDirectoryOfFiles: async (bucket, remotePath, directory) => {
    var promises = [];
    const params = {
      //bucket is the bucket name
      //Prefix: is the path to the "directory" on S3
      Bucket: bucket,
      Prefix: remotePath,
    };
    var objects = new Promise((resolve, reject) => {
      s3.listObjectsV2(params, function(err, dataList) {
        if (err) {
          console.log(err);
          console.log('THREW ERROR!!!');
        } else {
          //Data contains the return from s3
          //data.Contents are all the files associated
          //  console.log(dataList);
          resolve(dataList);
        }
      });
    });
    //List of objects
    objects.then(
      result =>
        new Promise((resolve, reject) => {
          //loop through objects
          for (var i = 0; i < result.Contents.length; i++) {
            //console.log(result.Contents[i].Key);
            const downloadParams = {
              Bucket: bucket,
              Key: result.Contents[i].Key,
            };

            promises.push(
              new Promise((resolve, reject) => {
                s3.getObject(downloadParams, function(err, dataFile) {
                  if (err) {
                    console.log('error', err);
                  } else {
                    console.log('Successful download of file');
                    resolve(dataFile);
                  }
                });
              })
            );
            if (i == result.Contents.length - 1) {
              //ya
              resolve(Promise.all(promises));
            }
          }
        })
    );
    return objects;
  },

  downloadFile: (bucket, remotePath) => {
    const params = {
      Bucket: bucket,
      Key: remotePath,
    };

    return new Promise((resolve, reject) => {
      s3.getObject(params, function(err, data) {
        if (err) {
          console.log('error', err);
        } else {
          console.log('Successful upload of file');
          resolve(data);
        }
      });
    });
  },
  deleteAllObjects: async (bucket, remotePath) => {
    const params = {
      Bucket: bucket,
      Prefix: remotePath,
    };

    const listObjectsInDirectory = await s3.listObjectsV2(params).promise();
    if (listObjectsInDirectory.Contents.length === 0) return;

    const deleteParams = {
      Bucket: bucket,
      Delete: { Objects: [] },
    };

    listObjectsInDirectory.Contents.forEach(({ Key }) => {
      console.log(Key);
      deleteParams.Delete.Objects.push({ Key });
    });

    const objectsDeleted = await s3.deleteObjects(deleteParams).promise();

    //If there were no errors
    if (objectsDeleted.Errors.length === 0) {
      console.log(objectsDeleted.$response.httpResponse.statusCode);
      return objectsDeleted;
      // return resolve(objectsDeleted);
    } else {
      console.log('Error! Issue deleting objects');
      return objectsDeleted;
      //return resolve(objectsDeleted);
    }

    // .then(
    //   result =>
    //   {
    //     if(result.Contents.length === 0) return;

    //     const deleteParams = {
    //       Bucket: bucket,
    //       Delete: { Objects: [] }
    //     };

    //     console.log(result);
    //     result.Contents.forEach(({ Key }) =>
    //     {
    //       console.log(Key);
    //       deleteParams.Delete.Objects.push({ Key });
    //     })

    //     const objectsDeleted = s3.deleteObjects(deleteParams).promise().then( deleteResult =>
    //     {
    //       console.log(deleteResult);
    //       //If there were no errors
    //       if(deleteResult.Errors.length === 0)
    //       {
    //         console.log(deleteResult.$response.httpResponse.statusCode);
    //         return resolve(objectsDeleted);
    //       }
    //       else
    //       {
    //         console.log("Error! Issue deleting objects")
    //         return resolve(objectsDeleted);
    //       }
    //     });

    //   }
    // );
  },
  deleteFile: (bucket, remotePath) => {
    const params = {
      Bucket: bucket,
      Key: remotePath,
    };
    console.log(params);
    s3.deleteObject(params, function(err, data) {
      if (err) {
        console.log(err);
      } else {
        console.log('Object Deleted', data);
      }
    });
  },
  getObjectBytes: async (bucket, path) => {
    const head = await s3
      .headObject({
        Bucket: bucket,
        Key: path,
      })
      .promise();

    return head.ContentLength;
  },
  sizeOf: async (bucket, path) => {
    const params = {
      //bucket is the bucket name
      //Prefix: is the path to the "directory" on S3
      Bucket: bucket,
      Prefix: path,
    };
    // var objects = new Promise((resolve, reject) =>
    // {
    var objects = new Promise((resolve, reject) => {
      s3.listObjectsV2(params, function(err, dataList) {
        if (err) {
          console.log(err);
          console.log('THREW ERROR!!!');
        } else {
          var totalSize = 0;
          for (var i = 0; i < dataList.Contents.length; i++) {
            // console.log(dataList.Contents[i].Size);
            totalSize += dataList.Contents[i].Size;
          }
          //Data contains the return from s3
          //data.Contents are all the files associated
          //  console.log(dataList);
          resolve(totalSize);
        }
      });
    });
    return objects;
  },

  getSignedS3Url: (bucket, path, root = 'lidar-files') => {
    //maybe need root later?
    return s3.getSignedUrl('getObject', {
      Bucket: bucket,
      Key: path,
    });
  },

  invokeLambda: (iFilePathList, iDirectoryList, iEmail) => {
    var lambda = new AWS.Lambda();
    //iDirectoryList -> Space Separated UNIQUE directorys as shown in S3
    //iFilePathList -> Space Separated directory with appended filepath -> "foobar/foobar.dbf foobar/foobar.tif .."
    var PAYLOAD_AS_A_STRING = {
      inputDirectoryList: iDirectoryList,
      inputFilePathList: iFilePathList,
      email: iEmail,
    };

    var params = {
      FunctionName: 'lambda-spinup-ec2',
      Payload: JSON.stringify(PAYLOAD_AS_A_STRING),
    };
    lambda.invoke(params, function(err, data) {
      if (err) console.log(err);
      else console.log(data);
    });
  },
  invokeLambdaRaster2Tiles: async (iTIF, iTFW, iFILE, iS3PATH) => {
    var lambda = new AWS.Lambda();
    //iDirectoryList -> Space Separated UNIQUE directorys as shown in S3
    //iFilePathList -> Space Separated directory with appended filepath -> "foobar/foobar.dbf foobar/foobar.tif .."
    var PAYLOAD_AS_A_STRING = {
      INPUT_TIF: iTIF,
      INPUT_TFW: iTFW,
      FILE: iFILE,
      S3PATH: iS3PATH,
    };

    var params = {
      FunctionName: 'lambda-spinup-raster2pgsql',
      Payload: JSON.stringify(PAYLOAD_AS_A_STRING),
    };
    lambda.invoke(params, function(err, data) {
      if (err) console.log(err);
      else console.log(data);
    });
  },
  invokeLambdaGdalDem: async (iTIF, iFILE, iS3PATH) => {
    var lambda = new AWS.Lambda();
    //iDirectoryList -> Space Separated UNIQUE directorys as shown in S3
    //iFilePathList -> Space Separated directory with appended filepath -> "foobar/foobar.dbf foobar/foobar.tif .."
    var PAYLOAD_AS_A_STRING = {
      INPUT_TIF: iTIF,
      FILE: iFILE,
      S3PATH: iS3PATH,
    };

    var params = {
      FunctionName: 'lambda-spinup-gdaldem',
      Payload: JSON.stringify(PAYLOAD_AS_A_STRING),
    };
    lambda.invoke(params, function(err, data) {
      if (err) console.log(err);
      else console.log(data);
    });
  },
};
