import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DownloadProductsComponent } from '../download-products/download-products.component';
import { _ } from 'underscore';
import { TriggerService } from '../../services/trigger.service';
import { LandingPageComponent } from '../../landing-page/components/landing-page.component';

//Global Declarations
declare let L;

@Component({
  selector: 'app-search-menu-view-cart',
  templateUrl: './search-menu-view-cart.component.html',
  styleUrls: ['./search-menu-view-cart.component.css'],
})
export class SearchMenuViewCartComponent implements OnInit {
  constructor(
    private data: DataService,
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    private landingPage: LandingPageComponent,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private triggerService: TriggerService
  ) {}

  productList = [];
  @Input() _map;
  @Input() list;
  @Input() tab;

  message: any;
  selectedProduct;
  hoverLayer: any;

  ngOnInit() {
    this.triggerService.listenForDownloadProductsInstruction.subscribe(data => {
      if (data === 'downloadProducts') {
        this.downloadCart();
      }
    });
    // gets the selected products from the other tabs
    this.data.currentData.subscribe(newMessage => {
      //TEST ARRAY FROM product.ts here

      // Goes here initially
      if (this.message === undefined || newMessage.length === 0) {
        if (newMessage !== undefined) {
          this.message = newMessage;
          this.landingPage.lblCartCount = newMessage.length;
        }
      } else {
        // Goes here if items are already in the cart
        console.log(newMessage);
        console.log(this.message);
        console.log(this.landingPage.lblCartCount);
        // Iterates the necessary amount of products that were selected to be added
        for (var count = 0; count < newMessage.length; count++) {
          newMessage[count].isHidden = false;
          var productIndex = this.message.findIndex(
            index =>
              index.product_index_id === newMessage[count].product_index_id
          );
          //IF THE PRODUCT DOES NOT EXIST... ADD IT
          console.log(productIndex);
          if (productIndex == -1) {
            //If a product does NOT exist in the existing message
            this.message.push(newMessage[count]);
            console.log('Product does not exist, adding 1');
            if (this.landingPage.lblCartCount === undefined) {
              //Undefined hides the red circle and label but in order to add 1 we need to type cast to int and obviously start at 0
              //this only happens when the user removes all products from the dl cart
              this.landingPage.lblCartCount = 0; // changes type from undefined to int 0;
              this.landingPage.lblCartCount += 1; // can't increment without the type being int
            } else {
              this.landingPage.lblCartCount += 1;
            }
          } else {
            //Product found in the existing
            this.message[productIndex].files = _.union(
              this.message[productIndex].files,
              newMessage[count].files
            ).sort();

            // Message displays if the user trys to add duplicate products to their cart
            let snackBarRef = this.snackBar.open(
              'WARNING: Duplicates WERE NOT added to your cart!',
              'Dismiss',
              {
                duration: 5000,
                verticalPosition: 'top',
              }
            );
          }
        }
      }

      // console.log(this.message);
    });
  }

  downloadCart() {
    console.log(this.message);
    if (this.message === null || this.message.length === 0) {
      return;
    } else {
      const dialogRef = this.dialog.open(DownloadProductsComponent, {
        data: this.message,
      });
    }

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
  removeAll() {
    this.message = null;
    this.productList = null;
  }
  newMessage() {
    console.log(this.data.getData());
  }

  //When a product is selected (clicked) in the visible products
  //This function navigates to the product on the ALL-DATA page.
  onSelect(product): void {
    //selecting a product will take you to that product page
    this.selectedProduct = product;
    this.router
      .navigate(['/all-data', this.selectedProduct.product_index_id])
      .then(
        nav => {
          //  console.log(nav);
        },
        err => {
          console.log(err);
        }
      );
  }

  displayBoundingBox(product) {
    var json = JSON.parse(product.geom);
    var states = {
      type: 'Feature',
      properties: { party: 'Republican' },
      geometry: {
        type: 'Polygon',
        coordinates: json.coordinates,
      },
    };

    var myLayer = L.geoJSON(states, {
      coordsToLatLng: function(coords) {
        //                    latitude , longitude, altitude
        //return new L.LatLng(coords[1], coords[0], coords[2]); //Normal behavior
        return new L.LatLng(coords[1], coords[0]);
      },
    });
    this.hoverLayer = myLayer;
    this.hoverLayer.addTo(this._map);

    // var geometry = wkx.Geometry.parse(product.geom);
    // console.log(geometry);
    //   this.hoverPolygon = L.polygon([
    //     [51.509, -0.08],
    //     [51.503, -0.06],
    //     [51.51, -0.047]
    // ]);
    // this.hoverPolygon.addTo(this._map);
  }
  removeBoundingBox(product) {
    this._map.removeLayer(this.hoverLayer);
  }

  checkBoxChangeEvent(product) {
    this.message.splice(this.message.indexOf(product), 1);
    this.changeDetector.detectChanges();
    console.log('VIEWCART/PRODUCTLIST', this.message);
  }

  onCheck(product): void {
    //selecting a product will take you to that product page
    // console.log(this.tileLayerGroup);
    // this.tileLayerGroup.eachLayer(function(tlLayer) {
    //   tlLayer.eachLayer(function(feature) {
    //     if (feature.feature.geometry.properties.name == product.filename) {
    //       //names match
    //       console.log('Matched Name');
    //       console.log(product);
    //     }
    //   });
    // });
  }
}
