import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material';

import { LoginPageComponent } from './components/login-page.component';
import { UsersService } from '../services/users.service';
import { AuthService } from '../services/auth.service';
@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    LeafletModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
  ],
  providers: [UsersService, AuthService],
})
export class LoginPageModule {}
