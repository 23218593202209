import {
  Component,
  Inject,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatSort } from '@angular/material';

import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DataTypesService } from '../../services/datatypes.service';
import { SurveyTypesService } from '../../services/surveytypes.service';
import { UsersService } from '../../services/users.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { ExportXmlService } from '../../services/export-to-new-product.service';

declare var require: any;

const { create } = require('xmlbuilder2');
@Component({
  selector: 'xml-creator-page',
  templateUrl: './xml-creator-page.component.html',
  styleUrls: ['./xml-creator-page.component.scss'],
})
export class XmlCreatorComponent implements OnInit {
  // For Add New Product Dialog

  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  s3Url = 'https://lidar-app-uploads.s3.amazonaws.com/';

  @ViewChild('xmlForm') xmlForm: NgForm;
  @ViewChild('entryForm') entryForm: NgForm;
  siteTitle: 'SITE TITLE';
  productDescription: 'PRODUCT DESCRIPTION';
  surveyType: 'SURVEY TYPE';
  dataType: 'DATA TYPE';
  surveyDate: any;
  westbc: 'WEST BOUNDING COORD';
  eastbc: 'EAST BOUNDING COORD';
  northbc: 'NORTH BOUNDING COORD';
  southbc: 'SOUTH BOUNDING COORD';
  siteDescription: 'SITE DESCRIPTION';
  fileType: 'PRODUCT FILETYPE';
  surveyTypesDDL: any;
  dataTypesDDL: any;
  saveableXML: any;
  saveableOBJ: any;
  includeContactInformation: any;

  constructor(
    public productDialog: MatDialog,
    private userService: UsersService,
    private router: Router,
    private surveyTypesServices: SurveyTypesService,
    private dataTypesServices: DataTypesService,
    private datepipe: DatePipe,
    private changeDetector: ChangeDetectorRef,
    private exportToNewProduct: ExportXmlService
  ) {}

  ngOnInit() {
    this.surveyTypesServices
      .getSurveyTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.surveyTypesDDL = result;
        },
        error => console.log(error)
      );

    // Populating data types drop down
    this.dataTypesServices
      .getDataTypes()
      .pipe(take(1))
      .subscribe(
        result => {
          this.dataTypesDDL = result;
        },
        error => console.log(error)
      );
    //check if user has a valid session
    var json = {
      email: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
      whoIssuedRoute: sessionStorage.getItem('email'),
    };
    this.userService.getUserType(json).subscribe(result => {
      if (sessionStorage.getItem('email') === result[0].email) {
        //Session matches DB//Valid User

        if (result[0].usertype < 2) {
          //user must be admin.
          window.alert(
            'You do not have permission to access this page. \nPlease contact your administrator.'
          );
          this.router.navigate(['/']);
        }
      } else {
        window.alert(
          'We could not verify you as a valid user. \nPlease try logging in again or contact your administrator.'
        );
        this.router.navigate(['/login']);
      }
    });
    this.createxml();
  }
  includeContact(event) {
    console.log(event.checked);
    this.includeContactInformation = event.checked;
    this.createxml();
  }
  somethingChanged() {
    this.siteTitle = this.entryForm.controls['siteName'].value;
    this.siteDescription = this.entryForm.controls['siteDescription'].value;
    this.surveyType = this.entryForm.controls['surveyTypeDDL'].value;
    this.dataType = this.entryForm.controls['dataTypeDDL'].value;
    try {
      var date = new Date(this.entryForm.controls['surveyDatePicker'].value);
      let formattedDate = this.datepipe.transform(date, 'MM-dd-yyyy');
      this.surveyDate = formattedDate;
    } catch (error) {
      console.log('Date picker not yet selected.');
    }

    this.westbc = this.entryForm.controls['wbc'].value;
    this.eastbc = this.entryForm.controls['ebc'].value;
    this.northbc = this.entryForm.controls['nbc'].value;
    this.southbc = this.entryForm.controls['sbc'].value;

    //const isLatitude = num => isFinite(num) && Math.abs(num) <= 90;
    const isLongitudeW = num => isFinite(num) && Math.abs(num) <= 180;
    const isLongitudeE = num => isFinite(num) && Math.abs(num) <= 180;
    const isLatitudeN = num => isFinite(num) && Math.abs(num) <= 90;
    const isLatitudeS = num => isFinite(num) && Math.abs(num) <= 90;

    if (this.westbc != undefined && this.eastbc != undefined) {
      if (
        !isLongitudeW(Number(this.westbc)) ||
        !isLongitudeE(Number(this.eastbc))
      ) {
        //if both east and west are not valid long numbers alert the user
        alert(
          'Please check your east and west bounding coorindate. They are not within -180 to 180.'
        );
      }
    }
    if (this.northbc != undefined && this.southbc != undefined) {
      if (
        !isLatitudeN(Number(this.northbc)) ||
        !isLatitudeS(Number(this.southbc))
      ) {
        //if both east and west are not valid long numbers alert the user
        alert(
          'Please check your north and south bounding coorindate. They are not within -90 to 90.'
        );
      }
    }
    this.productDescription = this.entryForm.controls[
      'productDescription'
    ].value;
    this.fileType = this.entryForm.controls['fileTypeDDL'].value;

    //console.log(this.entryForm.controls['surveyDatePicker'].value);
    this.createxml();
  }
  exportXml() {
    var content = "What's up , hello world";
    // any kind of extension (.txt,.cpp,.cs,.bat)
    var filename = '_metadata.xml';

    var blob = new Blob([this.saveableXML], {
      type: 'text/xml',
    });

    saveAs(blob, filename);
  }
  exportXmlToNewProduct() {
    var xmlMetaData = this.exportToNewProduct.getXmlMetaData();
    var xmlMetaDataObj = this.exportToNewProduct.getXmlMetaDataObj();

    xmlMetaDataObj.next(this.saveableOBJ);
    xmlMetaData.next(this.saveableXML);

    this.router.navigate(['sites/exported']);
  }
  createxml() {
    const root = create({ version: '1.0' })
      .ele('metadata')
      .ele('idinfo')
      .ele('citation')
      .ele('citeinfo')
      .ele('title')
      .txt(this.siteTitle)
      .up()
      .up()
      .up()
      .ele('descript')
      .ele('abstract')
      .txt(this.productDescription)
      .up()
      .ele('supplinf')
      .txt(this.surveyType)
      .up()
      .up()
      .ele('timeperd')
      .ele('timeinfo')
      .ele('rngdates')
      .ele('begdate')
      .txt(this.surveyDate)
      .up()
      .up()
      .up()
      .up()
      .ele('spdom')
      .ele('bounding')
      .ele('westbc')
      .txt(this.westbc)
      .up()
      .ele('eastbc')
      .txt(this.eastbc)
      .up()
      .ele('northbc')
      .txt(this.northbc)
      .up()
      .ele('southbc')
      .txt(this.southbc)
      .up()
      .up()
      .up()
      .up()
      .ele('spdoinfo')
      .ele('indspref')
      .txt(this.siteDescription)
      .up()
      .ele('direct')
      .txt(this.dataType)
      .up()
      .up()
      .ele('distinfo')
      .ele('stdorder')
      .ele('digform')
      .ele('digtinfo')
      .ele('formname')
      .txt(this.fileType)
      .up()
      .up()
      .up()
      .up();
    const xml = root.end({ prettyPrint: true });
    const obj = root.end({ format: 'object' });

    if (this.includeContactInformation) {
      var a = xml;
      var b = `
              <distrib>
              <cntinfo>
                <cntorgp>
                  <cntorg>Texas A&amp;M University - Corpus Christi</cntorg>
                </cntorgp>
                <cntaddr>
                  <addrtype>mailing and physical</addrtype>
                  <address>6300 Ocean Drive</address>
                  <city>Corpus Christi</city>
                  <state>Texas</state>
                  <postal>78412</postal>
                  <country>USA</country>
                </cntaddr>
                <cntvoice>1-361-825-3978</cntvoice>
                <cntemail>Michael.Starek@tamucc.edu</cntemail>
              </cntinfo>
            </distrib>
          </distinfo>
        </metadata>`;
      var position = xml.indexOf('</stdorder>');
      var output = a.slice(0, position + 11) + b;
      const xmlExtra = create(output);
      const xmlExtraXml = xmlExtra.end({ prettyPrint: true });
      const xmlExtraObj = xmlExtra.end({ format: 'object' });
      this.saveableXML = xmlExtraXml;
      this.saveableOBJ = xmlExtraObj;
      setTimeout(() => {
        this.xmlForm.controls['xmlPreviewN'].setValue(xmlExtraXml);
      }, 0);
    } else {
      this.saveableXML = xml;
      this.saveableOBJ = obj;
      setTimeout(() => {
        this.xmlForm.controls['xmlPreviewN'].setValue(xml);
      }, 0);
    }

    // .ele('distrib')
    //  .ele('cntinfo')
    //    .ele('cntorgp')
    //      .ele('cntorg').txt("Texas A&amp;M University - Corpus Christi").up().up()
    //    .ele('cntaddr')
    //      .ele('addrtype').txt("mailing and physical").up()
    //      .ele('address').txt("6300 Ocean Drive").up()
    //      .ele('city').txt("Corpus Christi").up()
    //      .ele('state').txt("Texas").up()
    //      .ele('postal').txt("78412").up()
    //      .ele('country').txt("USA").up().up()
    //      .ele('cntvoice').txt("1-361-825-3978").up()
    //      .ele('cntemail').txt("Michael.Starek@tamucc.edu").up().up()
    // convert the XML tree to string

    //need to allow ng to update give small timeout
  }
}
