import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialogComponent } from './feedback-dialog.component';
import {
  MatDialogModule,
  MatFormFieldModule,
  MatButtonModule,
  MatRadioModule,
  MatInputModule,
  MatListModule,
} from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatListModule,
  ],
  declarations: [FeedbackDialogComponent],
  exports: [FeedbackDialogComponent],
})
export class FeedbackDialogModule {}
