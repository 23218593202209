import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {}

  updateUsernameAndPassword(user) {
    return this.http.post(`/api/data/users/update_user/`, user, httpOptions);
  }
  getUserOrganization(user) {
    return this.http.post(`api/data/users/getOrganization/`, user, httpOptions);
  }
  getUser(user) {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/`, user, httpOptions);
  }
  getUserForgotPW(user) {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/forgot-pw/`, user, httpOptions);
  }
  getUserLogin(user) {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/login`, user, httpOptions);
  }
  deleteUser(user) {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/delete/`, user, httpOptions);
  }
  getUserIsValidated(user) {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/isvalid/`, user, httpOptions);
  }
  getUserType(user) {
    // console.log(user);
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/usertype/`, user, httpOptions);
  }
  getUserByValidationKey(key) {
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/checkkey/`, key, httpOptions);
  }
  updateUserValidationKey(user) {
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/updatekey/`, user, httpOptions);
  }
  updateUserValidationKeyWithKey(user) {
    return this.http.post(`/api/data/users/updatekey-WKey/`, user, httpOptions);
  }
  changeUserPassword(user) {
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(`/api/data/users/changepass/`, user, httpOptions);
  }
  changeUserPasswordWithVKey(user) {
    //return this.http.get(`/api/data/users/${user}`,user);
    return this.http.post(
      `/api/data/users/changepass/VKey/`,
      user,
      httpOptions
    );
  }
  getAllUsers(user) {
    return this.http.post(`/api/data/users/getall/`, user, httpOptions);
  }
  resendUserVerificationKey(user) {
    return this.http.post(
      `/api/data/users/resendverificaiton/`,
      user,
      httpOptions
    );
  }
}
