import {
  Component,
  OnInit,
  AfterContentInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { expandableRowAnimation } from './expandable-row.animation';
import { tileLayer, latLng, Map } from 'leaflet';
import { MatTab } from '@angular/material';
import * as _ from 'lodash';
import { LinkSitesProductsService } from '../../services/link_sites_products.service';
import { SitesService } from '../../services/sites.service';
import { ProductsService } from '../../services/products.service';
import { templateJitUrl } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';
import { expand, take } from 'rxjs/operators';
import { detectChanges } from '@angular/core/src/render3';
import { UsersService } from '../../services/users.service';
import { text } from '@angular/core/src/render3/instructions';
import { AuthGuard } from '../../app/auth.guard';
declare var require: any;
var s3Service = require('../../../../lib/s3Util.js');
/**
 * @title Table with sorting
 */
declare let L;
@Component({
  selector: 'all-data.component',
  styleUrls: ['all-data.component.scss'],
  templateUrl: 'all-data.component.html',
  animations: [expandableRowAnimation],
})
export class AllDataComponent implements OnInit {
  displayedColumns: string[] = [
    'product_label',
    'product_file_extension',
    'survey_type',
    'data_type',
  ];

  displayedColumnsFiles: string[] = ['files', 'actions'];
  dataSource: any;
  fDataSource: any;
  expandedSymbol: string = '';
  products: any;
  productArrayOfObjects: any;
  productsLinks: any;
  showMap: boolean = false;
  showProgressBar: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  matTableDataSource = new MatTableDataSource();
  isUserAdmin: boolean;
  isTableReady: boolean = false;
  notVisible: boolean = false;

  constructor(
    private linkSitesProductsService: LinkSitesProductsService,
    private SiteService: SitesService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private ProductService: ProductsService,
    private cdRef: ChangeDetectorRef,
    private ngzone: NgZone,
    private userService: UsersService,
    private auth: AuthGuard
  ) {
    // Getting all the existing sites
  }

  ngOnInit() {
    this.setIsUserAdmin();
    this.getProducts();
  }

  ngAfterContentInit() {
    let string = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
    var num = +string;
    this.toggleExpandableSymbol(num);
    // console.log("Invoking Lambda!");
    // s3Service.s3Util.invokeLambda();
    // console.log("Invoked Lambda!");
  }

  /**
   * (This sets the user type and deterimes what columns are visible, which is based on user type)
   *
   * @memberof AllDataComponent
   */
  setIsUserAdmin() {
    const userEmail = sessionStorage.getItem('email');
    var json = {
      email: userEmail,
      whoIssuedRoute: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
    };
    // var currentTime = (new Date().getTime() / 1000);
    // var time = +sessionStorage.getItem('timer') / 1000; // cast to number

    if (userEmail != null) {
      this.userService
        .getUserType(json)
        .pipe(take(1))
        .subscribe(
          result => {
            if (result[0].usertype >= 2) {
              this.isUserAdmin = true;
              this.displayedColumns.push('created_at');
              this.displayedColumns.push('updated_at');
            } else {
              this.isUserAdmin = false;
            }
            this.isTableReady = true;
          },
          error => {
            this.isUserAdmin = false;
            this.isTableReady = true;
          }
        );
    } else {
      this.isUserAdmin = false;
      this.isTableReady = true;
    }
  }

  addLinks() {
    // Looping through products
    for (var i = 0; i < this.products.length; i++) {
      this.products[i].links = new Array();

      // Making sure product file extension isn't null, this breaks the page without if statement
      if (
        this.products[i].product_file_extension !== null &&
        this.products[i].product_file_extension !== 'undefined'
      ) {
        this.products[i].product_file_extension = this.products[
          i
        ].product_file_extension.toUpperCase();
      }

      // Looping through links
      for (var x = 0; x < this.productsLinks.length; x++) {
        if (
          this.products[i].product_index_id ===
          this.productsLinks[x].product_index_id
        ) {
          //IGNORE PRODUCTS WITH NO SITES
          if (this.productsLinks[x].site_id === null) {
            this.products.splice(i, 1);
            continue;
          }
          this.products[i].links.push(this.productsLinks[x]);
        }
      }
    }
    this.dataSource = new MatTableDataSource(this.products);
    this.dataSource.sort = this.sort;
  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  getLinks() {
    // Getting all products and links
    this.linkSitesProductsService.getAllSitesAndProducts().subscribe(links => {
      this.productsLinks = links;
      //console.log(this.products);
      this.addLinks();
    }),
      error => {
        console.log(error);
      };
  }

  // To prevent duplicate products showing, the tables datasource will just be products
  getProducts() {
    this.ProductService.getAllProducts().subscribe(products => {
      console.log(products);
      this.products = products;
      // console.log(this.products);
      for (var i = 0; i < this.products.length; i++) {
        this.products[i].filesWithoutExt = this.products[i].files.filter(
          function(file) {
            return file.indexOf('las', 'tif', 'TIF', 'LAS') !== -1;
          }
        );
      }
      console.log(this.products);

      // this.getLinks();
      this.dataSource = new MatTableDataSource(this.products);
      this.dataSource.sort = this.sort;
      this.getFiles(products);
    });
    // this.ProductService.getProducts()
    //   .pipe(take(1))
    //   .subscribe(
    //     products => {
    //       this.products = products;
    //       // console.log(this.products);
    //       for (var i = 0; i < this.products.length; i++) {
    //         this.products[i].filesWithoutExt = this.products[i].files.filter(
    //           function(file) {
    //             return file.indexOf('las', 'tif', 'TIF', 'LAS') !== -1;
    //           }
    //         );
    //       }
    //       console.log(this.products);

    //      // this.getLinks();
    //       this.dataSource = new MatTableDataSource(this.products);
    //       this.dataSource.sort = this.sort;
    //       this.getFiles(products);
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   );
  }
  getFiles(products) {
    this.productArrayOfObjects = [];
    this.ProductService.getAllFiles().subscribe(results => {
      for (var i = 0; i < products.length; i++) {
        products[i].filesWExt = [];
        for (var key in results) {
          var obj = results[key];
          var extPieces = obj.filename.split('.');
          var ext = extPieces[extPieces.length - 1];
          if (ext === 'las' || ext === 'LAS') {
            if (products[i].product_index_id === obj.product_id) {
              products[i].filesWExt.push(obj);
            }
          } else {
            this.notVisible = true;
          }
        }
      }
    });
    //loop through all products
    // for (var i = 0; i < products.length; i++)
    // {
    //   var productId = { productId: products[i].product_index_id };
    //   //query the files for each product
    //   this.ProductService.getFiles(productId)
    //     .pipe(take(1))
    //     .subscribe(
    //       results => {
    //         console.log(results);
    //         console.log(products[i]);
    //         console.log(productId);
    //         // for (var i = 0; i < products.length; i++)
    //         // {
    //         //   console.log(products[i]);
    //         //   products[i].filesWExt = [];
    //         //   for (var key in results) {
    //         //     var obj = results[key];
    //         //     console.log(obj.filename);
    //         //     var extPieces = obj.filename.split('.');
    //         //     var ext = extPieces[extPieces.length - 1];
    //         //     if (ext === 'las') {
    //         //       console.log(obj.filename);
    //         //       products[i].filesWExt.push(obj);
    //         //     } else {
    //         //       this.notVisible = true;
    //         //     }
    //         //   }
    //         // }
    //         console.log(this.products[0]);

    //         for (var i = 0; i < this.products.length; i++) {
    //           console.log(this.productArrayOfObjects);
    //           this.productArrayOfObjects.push(this.products[i]);
    //         }
    //         //this.productArrayOfObjects = this.products.filesWithoutExt;
    //         console.log(this.productArrayOfObjects);

    //         // this.products[i].filesWithoutExt = results[i].files.filter(function(file)
    //         // {
    //         //   return file.indexOf('las','tif') !== -1;
    //         // });
    //       },
    //       error => {
    //         console.log(error);
    //       }
    //     );
    // }
    console.log(this.products[0]);
  }

  navigateTo(row) {
    console.log(row);
    window.open('http://lidar.tamucc.edu/pv/' + row.file_UUID);
  }
  toggleExpandableSymbol(productId: any): void {
    this.expandedSymbol = this.expandedSymbol === productId ? '' : productId;
    this.showMap = true;
  }
  redirectToLogin() {
    this.router.navigate(['/login']);
    localStorage.clear();
    sessionStorage.clear();
  }
  copyProduct(product) {
    console.log(product);
    if (product[0].data_index_id == 1) {
      //If the product is a pointcloud navigate to lidar.tamucc
      var textArea = <HTMLInputElement>document.getElementById('hiddenText');
      textArea.style.visibility = 'visible';
      var shareableLink =
        'http://lidar.tamucc.edu/pv/' + product[0].product_uuid;

      textArea.value = shareableLink.toString();
      textArea.select();

      /* Copy the text inside the text field */
      document.execCommand('copy');
      textArea.style.visibility = 'hidden';
      /* Alert the copied text */
      alert('Copied to clipboard!');
    } else {
      alert('Visualization for this product type is not supported. Yet!');
    }
    // document.body.removeChild(textArea);
  }
  async downloadProduct(product) {
    console.log(product[0]);
    var test = s3Service.s3Util.invokeLambda(
      product[0].product_label,
      product[0].product_label,
      '*'
    );
    console.log(test);
    this.showProgressBar = true;

    if (product[0].data_type == 'Aerial Imagery') {
      //If this is an orthomosiac
      // var dFile = s3Service.s3Util.downloadFile('lidar-app-uploads', `lidar-files/${product[0].product_label}`);
      // dFile.then(result => {
      //   // this.downloadAll(result.Contents);
      // })
      // var thisbettereffingwork = s3Service.s3Util.downloadDirectoryOfFiles(
      //   'lidar-app-uploads',
      //   `lidar-files/${product[0].product_label}`,
      //   product[0].product_label
      // );
      // thisbettereffingwork.then(result => {
      //   console.log(result);
      //   console.log(result.Contents);
      // //  this.downloadAll(result.Contents);
      //   result = null;
      //   //   console.log("MADE IT HERE")
      //   //  this.showProgressBar = false;
      //   //    let blob = new Blob([result.Body], { type: result.ContentType });
      //   //  //  var link = document.createElement('a');
      //   //   link.href = window.URL.createObjectURL(blob);
      //   // // I need to rework this since this is for 1 file
      //   //   for(var i = 0; i < result.Contents.length; i++)
      //   //   {
      //   //     var link = document.createElement('a');
      //   //     link.download = result.Contents[i].Key;
      //   //  //   link.click();
      //   //   }
      //   //   link = null;
      //   console.log('Download Complete!');
      // });
    } else {
      s3Service.s3Util
        .downloadFile(
          'lidar-app-uploads',
          `lidar-files/${product[0].product_label}`
        )
        .then(result => {
          this.showProgressBar = false;
          let blob = new Blob([result.Body], { type: result.ContentType });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'lidar-files' + '/' + product[0].product_label;
          link.click();
          console.log('Download Complete!');
        });
    }
  }
  downloadAll(filesForDownload) {
    var interval = setInterval(download, 300, filesForDownload);

    function download(urls) {
      var url = urls.pop();
      console.log(url);
      console.log(typeof url);
      let blob = new Blob([url], { type: url.ContentType });
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = url.Key;
      a.setAttribute('target', '_blank');
      a.click();

      if (urls.length == 0) {
        clearInterval(interval);
      }
    }
  }
  downloadMetaData(product) {
    this.showProgressBar = true;
    var metadataFileName = product[0].s3_path_metadata.substr(
      product[0].s3_path_metadata.lastIndexOf('/') + 1
    );
    s3Service.s3Util
      .downloadFile('lidar-app-uploads', `lidar-files/${metadataFileName}`)
      .then(result => {
        this.showProgressBar = false;
        let blob = new Blob([result.Body], { type: result.ContentType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'lidar-files' + '/' + metadataFileName;
        link.click();
        console.log('Download Complete!');
      });
  }
  viewProduct(product) {
    console.log(product);
    if (product[0].data_index_id == 1) {
      //If the product is a pointcloud navigate to lidar.tamucc
      window.open('http://lidar.tamucc.edu/pv/' + product[0].product_uuid);
      //terrestrial lidar
    } else {
      alert('Visualization for this product type is not supported. Yet!');
      // this.router
      //   .navigate(['/ortho-view-download', product[0].product_index_id])
      //   .then(
      //     nav => {
      //       //  console.log(nav);
      //     },
      //     err => {
      //       console.log(err);
      //     }
      //   );
    }
  }
}
@Component({
  selector: 'loadable-map',
  templateUrl: 'loadable-map.component.html',
  styleUrls: ['./all-data.component.scss'],
})
export class LoadableMapComponent implements OnInit {
  //Local variables
  @Input() product: any;

  title = 'Blah';
  options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
    ],
    zoom: 6,
    center: latLng(26.72, -89.68),
  };
  selectedProduct;
  layers = [];
  tempLayers = [];
  jsonarray = [];
  markers;
  loaded: boolean = false;
  _map: Map;
  _container: any;
  _startLayerPoint: any;
  customMarker: any;

  ngOnInit() {}

  //Constructor
  constructor() {}

  onMapReady(map: L.Map) {
    this._map = map;
    map.removeControl(map.zoomControl);

    console.log(this.product);
    //define marker
    // for (let i = 0; i < this.product.links.length; i++)
    // {
    var myIcon = L.icon({
      iconUrl: '../../assets/img/marker-icon.png',
      iconSize: [24, 36],
      iconAnchor: [12, 36],
      popupAnchor: [-3, -76],
      shadowUrl: '../../assets/img/marker-shadow.png',
    });

    var dataCentroid = JSON.parse(this.product.centroid);
    console.log(dataCentroid);
    this.customMarker = L.marker(dataCentroid.coordinates, {
      draggable: false,
      icon: myIcon,
      clickable: false,
    });
    this.layers.push(this.customMarker);
    // }

    var markerBounds = [this.customMarker.getLatLng()];

    map.fitBounds(markerBounds);
    this._map.setZoom(8);
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }
}
