import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SuccessDownloadMessageComponent } from '../../components/success-download-message/success-download-message.component';
var s3Service = require('../../../../lib/s3Util.js');
@Component({
  selector: 'app-download-products',
  templateUrl: './download-products.component.html',
  styleUrls: ['./download-products.component.css'],
})
export class DownloadProductsComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  emailVariable: string;
  ngOnInit() {
    console.log('THE DATA PASSED BRO: ', this.data);
  }

  closeModal() {
    const dialogRef = this.dialog.closeAll();
  }

  submit() {
    const dialogRef = this.dialog.closeAll();
    console.log('THE DATA PASSED BRO: ', this.data);
    console.log(this.emailVariable);
    this.startProcess(this.data);
  }
  addUpBytesForTotal(sizeStrings) {
    //Create a size map that we can use to multiply the bytes by later
    const sizeMap = {
      BYTES: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024,
      TB: 1024 * 1024 * 1024 * 1024,
    };
    //Running total init
    let totalBytes = 0;
    //loop through array of string inputs [10 KB, 500 MB, 200 TB, 10 GB] ... etc
    for (let i = 0; i < sizeStrings.length; i++) {
      //split the input string into [0] = number of bytes, [1] the multiplier
      const sizeParts = sizeStrings[i].trim().split(' ');
      //convert string to a number
      let bytes = parseFloat(sizeParts[0]);

      if (sizeParts[1]) {
        //Find multipler in map.
        const multiplier = sizeMap[sizeParts[1].toUpperCase()];
        if (multiplier) {
          //Apply multiplier to bytes
          bytes *= multiplier;
        }
      }
      //Add number of bytes to running total
      totalBytes += bytes;
    }
    console.log(totalBytes);
    //Convert total bytes back to highest denomination
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (totalBytes >= 1024 && i < sizes.length - 1) {
      totalBytes /= 1024;
      i++;
    }
    return totalBytes.toFixed(2) + ' ' + sizes[i];
  }
  startProcess(DATA) {
    console.log(DATA);

    //DATA can contain FULL products
    //DATA can contain partial products
    var tempTotalSize = 0;
    var tempDirectory = '';
    var tempSurveyTypeList = '';
    var tempFilePathList = '';
    let totalSizeArray = [];
    //FOR THIS PRODUCT DO WE WANT THE WHOLE THING
    for (var i = 0; i < DATA.length; i++) {
      //LOOP THROUGH DATA (PRODUCTS)
      //STRING = product_label1 product_label2 product_label3
      //POINTCLOUD
      // if (DATA[i].data_index_id == 1)
      // {
      //   //POINT CLOUD
      //   //IE: Other/Point Cloud/20_07_30_Bob_Hall_Camp_Ground_##
      //   tempDirectory +=
      //     DATA[i].survey_type +
      //     '/' +
      //     DATA[i].data_type +
      //     '/' +
      //     DATA[i].product_label.substring(0, DATA[i].product_label.length - 4) +
      //     '##';
      //   //This is a point cloud (NO FILES)
      //   //ADD PRODUCT LAS
      //   tempFilePathList +=
      //     DATA[i].survey_type +
      //     '/' +
      //     DATA[i].data_type +
      //     '/' +
      //     DATA[i].product_label.substring(0, DATA[i].product_label.length - 4) +
      //     '/' +
      //     DATA[i].product_label +
      //     '##';

      //   tempFilePathList +=
      //     DATA[i].survey_type +
      //     '/' +
      //     DATA[i].data_type +
      //     '/' +
      //     DATA[i].product_label.substring(0, DATA[i].product_label.length - 4) +
      //     '/' +
      //     DATA[i].s3_path_metadata.replace(/^.*[\\\/]/, '') +
      //     '##'; // get last / to grab filename + '##';
      // } else

      //ORTHO MOSIAC
      tempDirectory +=
        DATA[i].survey_type +
        '/' +
        DATA[i].data_type +
        '/' +
        moment(DATA[i].product_survey_date).format('YYYY-MM-DD') +
        '/' +
        DATA[i].product_label +
        '##';
      for (var j = 0; j < DATA[i].files.length; j++) {
        //loop through all of the product gis files

        //STRING =/product_label1/file1.txt product_label1/file2.txt product_label2/file1.txt ... etc A REALLY REALLY LONG STRING
        tempFilePathList +=
          DATA[i].survey_type +
          '/' +
          DATA[i].data_type +
          '/' +
          moment(DATA[i].product_survey_date).format('YYYY-MM-DD') +
          '/' +
          DATA[i].product_label +
          '/' +
          DATA[i].files[j] +
          '##';
      }
      totalSizeArray.push(DATA[i].totalSize);
      console.log(tempDirectory);
      console.log(tempFilePathList);
    }

    console.log();
    //DIRECTORYLIST product1
    //FILEPATHLIST UAS//product1/file1.txt UAS/product1/file2.txt TERRESTRIAL/product1/file1
    var sendString = tempDirectory;
    var sendFPString = tempFilePathList;
    var test = s3Service.s3Util.invokeLambda(
      sendFPString,
      sendString,
      this.emailVariable
    );
    var tempJson = {
      email: this.emailVariable,
      tSize: this.addUpBytesForTotal(totalSizeArray),
    };
    const dialogRef = this.dialog.open(SuccessDownloadMessageComponent, {
      data: tempJson,
    });
  }
}
