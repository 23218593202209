import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteTableComponent } from './site-table.component';
import { MatTableModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule
  ],
  declarations: [SiteTableComponent],
  exports: [SiteTableComponent]
})
export class SiteTableModule { }
