import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  passed: any;

  private dataSource = new BehaviorSubject(this.passed); // establishing new variables to be observed
  currentData = this.dataSource.asObservable(); // asign as observable

  constructor() {}

  // Changes message to be passed to desired sibling
  changeMessage(message: object) {
    this.dataSource.next(message);
  }

  //CartData
  // Child sibling can obtain data
  getData() {
    return this.currentData;
  }
}
