import { Component, ChangeDetectorRef } from '@angular/core';
import { SitesService } from '../services/sites.service';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';
import { MatDialog } from '@angular/material';
import { FeedbackDialogComponent } from '../components/feedback-dialog/feedback-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'app';
  otherTheme: boolean = false;
  isLoggedIn = false;
  userIsAdmin = false;
  userIsContributor = false;
  constructor(
    private sitesService: SitesService,
    private Auth: AuthService,
    private userService: UsersService,
    private changeDetector: ChangeDetectorRef,
    public feedbackDialog: MatDialog
  ) {}

  ngOnInit() {
    //Check if user reloaded window but we still have a valid session
    let persistantUser = sessionStorage.getItem('email');
    if (persistantUser != null) {
      var json = {
        email: persistantUser,
        sessionToken: sessionStorage.getItem('cookie'),
        whoIssuedRoute: sessionStorage.getItem('email'),
      };
      this.isLoggedIn = true;
      this.Auth.setLoggedIn(true);
      // console.log("APP LEVEL CALLING USERTYPE"); //This is called at the app level, meaning every page
      this.userService.getUserType(json).subscribe(
        result => {
          if (result[0].usertype >= 2) {
            this.userIsAdmin = true;
          } else if (result[0].usertype == 1) {
            this.userIsContributor = true;
          } else {
            this.userIsAdmin = false;
            this.userIsContributor = false;
          }
        },
        error => console.log(error)
      );
    }

    //check if user has a valid session
    this.Auth.isUserLoggedIn.subscribe(value => {
      if (value) {
        this.isLoggedIn = true;
        //check if admin
        var json = {
          email: sessionStorage.getItem('email'),
          sessionToken: sessionStorage.getItem('cookie'),
          whoIssuedRoute: sessionStorage.getItem('email'),
        };
        this.userService.getUserType(json).subscribe(
          result => {
            if (result[0].usertype >= 2) {
              this.userIsAdmin = true;
            } else if (result[0].usertype == 1) {
              this.userIsContributor = true;
            } else {
              this.userIsAdmin = false;
              this.userIsContributor = false;
            }
          },
          error => console.log(error)
        );
      } else {
        this.isLoggedIn = false;
        this.userIsAdmin = false;
        this.userIsContributor = false;
      }
    });
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * (Used to open the feedback dialog)
   *
   * @memberof AppComponent
   */
  openFeedbackDialog() {
    const dialogRef = this.feedbackDialog.open(FeedbackDialogComponent, {
      width: '50%',
      data: {},
      hasBackdrop: true,
      position: { top: '0' },
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        // console.log('Feedback dialog has been closed.');
      });
  }

  createSite() {
    this.sitesService.createSite({ label: 'blah' });
  }

  changeTheme() {
    // Uncomment this line to get the theme switching
    // this.otherTheme = !this.otherTheme;
  }
}
