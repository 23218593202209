import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
} from '@angular/core';
import { latLng, tileLayer, Map, polygon, layerGroup } from 'leaflet';
import { Router } from '@angular/router';
import 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { CheckoutDialogComponent } from '../../components/checkout-dialog/checkout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material';
import { DataService } from '../../services/data.service';
import { LandingPageComponent } from '../../landing-page/components/landing-page.component';
import { ProductsService } from '../../services/products.service';
import { Observable, of } from 'rxjs';
import { ValueTransformer } from '@angular/compiler/src/util';
import { first } from 'rxjs/operators';
import { TriggerService } from '../../services/trigger.service';

//Global Declarations
declare let L;
var geoJsonWithProperties;

@Component({
  selector: 'app-download-cart',
  templateUrl: './download-cart.component.html',
  styleUrls: ['./download-cart.component.css'],
})
export class DownloadCartComponent implements OnInit {
  constructor(
    private router: Router, // private MAP: MapComponent
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private dataService: DataService,
    private landingPage: LandingPageComponent,
    private productservice: ProductsService,
    private triggerService: TriggerService
  ) {}

  @Input() productList;
  @Input() _map;
  @Input() viewingCart;
  @Input() tab;
  @Input() layerTrackingDict;

  selectedProduct;
  productList1: any;
  layers = [];
  dataLayers = [];
  jsonArray = [];
  jsonArray1 = [];
  markers;
  hoverLayer: any;
  hoverLayerGroup: any;
  rectangleViewPortLayer: any;
  loaded: boolean = false;
  _container: any;
  _startLayerPoint: any;
  selectedIndex: any; // Identifies the selected tab of the search menu
  drawBox: any; // Variable holds draw box icon to add and remove to map
  detailedView = {};
  checkBoxArray = [];
  zooming = false;

  tileLayerGroup = L.layerGroup();

  private _value: string;
  public get value(): string {
    return this._value;
  }
  public set value(v: string) {
    this._value = v;
  }

  productId: any;
  tileSubscription: any;
  thatLayer: any;
  fileCounter = 0;
  productCounter = 0;
  tempTab: any;
  testArray: any;
  isHidden: any;
  layerContainer = [];
  featureContainer = [];
  testChangesL: any;
  screenHeight: any;
  screenWidth: any;
  screenVH: any;

  ngOnInit() {
    this.testChangesL = this;
    if (this.productList === undefined) {
      this.productList = [];
    }
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.setScalingDimension(this.screenWidth, this.screenHeight);
  }
  setScalingDimension(screenWidth: number, screenHeight: number) {
    console.log(screenWidth);
    //Sets the scale of the map-container and map classes according to screen resolution
    if (screenWidth == 1600 || screenWidth == 1440) this.screenVH = '53.5vh';
    else if (screenWidth == 1680) this.screenVH = '60vh';
    else this.screenVH = '62vh';
  }
  // this.productList1 = this.productList;

  removeAll() {
    console.log(this.productList);
    this.productList = [];
    this.dataService.changeMessage(this.productList);
    // for (var i = 0; i < this.productList.length; i++) {

    //       this.productList.splice(i, 1);

    // }

    if (this.productList.length <= 0) {
      this.landingPage.lblCartCount = undefined;
    } else {
      this.landingPage.lblCartCount = this.productList.length;
    }
  }
  removeProductFromDownloadCart(product, file = null) {
    console.log(this.productList);
    //  console.log(this.productList1);
    console.log(this.jsonArray);
    console.log(file);
    //  console.log(this.jsonArray1);

    for (var i = 0; i < this.productList.length; i++) {
      if (this.productList[i].product_index_id == product.product_index_id) {
        if (file !== null) {
          console.log('here');
          //Get index of item in productList[i].files that matches the passed in file
          let fileIndex = this.productList[i].files.findIndex(i => i === file);
          this.productList[i].files.splice(fileIndex, 1);
        } else {
          console.log('here1');
          this.productList.splice(i, 1);
        }
      }
    }

    if (this.productList.length <= 0) {
      this.landingPage.lblCartCount = undefined;
    } else {
      this.landingPage.lblCartCount = this.productList.length;
    }
  }
  test() {
    this.changeDetector.detectChanges();
    this.detailedView = [];
    if (this.tempTab !== undefined && this.tileSubscription !== undefined) {
      if (this.tempTab !== this.tab) {
        this.resetAll();
      }
    }

    // re-initializing our users current tab selected on change
    this.tempTab = this.tab;
  }

  ngOnChanges(changes: SimpleChanges) {
    // if(this.jsonArray.length != 0)
    // {
    // console.log(changes);
    // console.log('BOOP');
    // console.log(this.productList);

    // // }
    //  var locallyDefinedArray = [];
    //  this.detailedView = [];
    // // console.log(this.productList.length);
    // if (this.productList !== undefined && this.productList.length > 0)
    // {
    //     this.jsonArray = [];
    //     // this.test();
    //     for (let i = 0; i < this.productList.length; i++) {
    //         let tempJson = { theProduct: null, files: [] };
    //         tempJson.theProduct = this.productList[i]; //isHidden isChecked isSelected

    //         //TODO IF FILES != null IF WE HAVE FILES
    //         if (this.productList[i].files !== null && this.productList[i].files !== undefined) {
    //             // console.log(this.productList[i].files);
    //             for (let x = 0; x < this.productList[i].files.length; x++) {
    //                 //default files to checked? no
    //                 //console.log(this.productList[i].files);
    //                 if (this.productList[i].files[x].includes('.tif') || this.productList[i].files[x].includes('.las')) {
    //                     if (this.productList[i].isHidden === true || this.productList[i].isChecked === false)
    //                         tempJson.files.push({
    //                             fileName: this.productList[i].files[x],
    //                             isChecked: false,
    //                             isSelected: false
    //                         });

    //                     if (this.productList[i].isChecked === true)
    //                         tempJson.files.push({
    //                             fileName: this.productList[i].files[x],
    //                             isChecked: true,
    //                             isSelected: true
    //                         });
    //                 }
    //             }
    //         }
    //         locallyDefinedArray.push(tempJson);
    //         this.detailedView[locallyDefinedArray.length - 1] = false;

    //         if(this.landingPage.selectedIndex == 4)
    //         {
    //             //The user has selected to view the cart
    //             this.productList[i].isBeingViewed = false;
    //         }

    //         console.log("SET DET AILED VIEW TO FALSE")
    //     }

    //     this.jsonArray = locallyDefinedArray;
    //     console.log(this.jsonArray);
    //     console.log(this.productList);
    // }
    // console.log('CHECKBOXARRAY', this.checkBoxArray);
    if (this.productList !== undefined && this.productList.length > 0) {
      for (let i = 0; i < this.productList.length; i++) {
        if (this.landingPage.selectedIndex == 1) {
          //The user has selected to view the cart
          this.productList[i].isBeingViewed = false;
          this.productList[i].isHidden = false;
        }
      }
    }
  }

  populateDownloadCart(json, prodCount = null) {
    //json is the product from the DB
    this.productservice.getFiles(json).subscribe(files => {
      // console.log(files);
      //Get Geometry for each tile of selected product
      if (this.viewingCart !== true) this.calculateBoundingBoxEachTile(files);

      for (var i = 0; i < Object.keys(files).length; i++) {
        if (files[i].file_centroid !== null) {
          var copyOfFileArrayForDisplayPurposes = files[i];
          copyOfFileArrayForDisplayPurposes.file_centroid =
            parseFloat(JSON.parse(files[i].file_centroid).coordinates[1])
              .toPrecision(8)
              .toString() +
            ',' +
            parseFloat(JSON.parse(files[i].file_centroid).coordinates[0])
              .toPrecision(8)
              .toString();

          //  this.productList.push(copyOfFileArrayForDisplayPurposes);
        }
      }
    });
  }

  calculateBoundingBoxEachTile(files) {
    if (this.tileLayerGroup.getLayers().length !== 0) {
      this.tileLayerGroup = L.layerGroup();
    }

    //Loop through file data sent from server
    for (var i = 0; i < files.length; i++) {
      //make sure each tile's geometry has been set
      if (files[i].file_geom !== null) {
        //build custom geojson
        geoJsonWithProperties = {
          type: JSON.parse(files[i].file_geom).type,
          coordinates: JSON.parse(files[i].file_geom).coordinates,
          properties: {
            name: files[i].filename,
            file: files[i],
          },
        };
        var THIS: DownloadCartComponent = this;
        //Create geoJSON layer using custom geojson from above
        var myGeoJSON = L.geoJSON(geoJsonWithProperties, {
          //Loop through each feature in the layer
          onEachFeature: function(feature, layer) {
            console.log(layer);
            //console.log('THE STUFF: ', feature, layer);

            //Bind a tooltip to each polygon and define a custom propertie hierarchy called "definedProperties"
            layer.bindTooltip(feature.properties.name, {
              closeButton: false,
            });

            var definedProperties = (layer.definedProperties =
              layer.definedProperties || {});
            definedProperties.type = definedProperties.type || 'Flag';
            definedProperties.name = definedProperties.name || 'polygonTile';
            var props = (definedProperties.properties =
              definedProperties.properties || {});

            //  console.log(THIS.jsonArray);
            if (THIS.jsonArray != undefined && THIS.jsonArray.length > 0) {
              for (var i = 0; i < THIS.jsonArray.length; i++) {
                for (var j = 0; j < THIS.jsonArray[i].files.length; j++) {
                  if (THIS.jsonArray[i].files[j].isChecked == false) {
                    // console.log("Not checked")
                    feature.properties.checked = false;
                  } else {
                    //  console.log("checked")
                    feature.properties.checked = true;
                  }
                  if (THIS.jsonArray[i].files[j].isSelected == false) {
                    //   console.log("Not selected")
                    layer.setStyle({
                      fillColor: '#3388ff',
                    });
                    feature.properties.selected = false;
                    props._selected = false;
                    layer.definedProperties.properties._selected = false;
                  } else {
                    // console.log("selected")
                    layer.setStyle({
                      fillColor: '#fff700',
                    });
                    props._selected = true;
                    feature.properties.selected = true;
                    layer.definedProperties.properties._selected = true;
                  }
                }
              }
            } else {
              // console.log("HEEEEEEEEE");
              //set definedProperties: property: _selected to false by default
              props._selected = false;
              layer.definedProperties.properties._selected = true;
              feature.properties.checked = true;
            }

            if (layer !== undefined) {
              THIS.layerContainer.push(layer);
              // THIS.layerContainer[i] = layer;
            }
            if (feature !== undefined) {
              THIS.featureContainer.push(feature);
            }

            // if (layer.definedProperties.properties._selected == true)
            //  {
            //     console.log("YAER!!" + layer);
            //     layer.setStyle({
            //         fillColor: '#fff700'
            //     });
            // }
            // if (layer.feature.properties.selected == true)
            //  {
            //     console.log("Setting Layer Style: " + layer);
            //     layer.setStyle({
            //         fillColor: '#fff700'
            //     });
            // }

            //Each polygon needs mouse events to display tooltip and highlight
            // layer.on('mouseover', function (e) {
            //   this.openPopup();
            //   //If the polygon is already selected keep it the selected color
            //   if (layer.definedProperties.properties._selected == true)
            //     this.setStyle({
            //       fillColor: '#fff700',
            //     });
            //   //If the polygon is not selected keep it the highlight color
            //   else if (layer.definedProperties.properties._selected != true)
            //     this.setStyle({
            //       fillColor: '#0000ff',
            //     });
            // });

            // //Mouse Out
            // layer.on('mouseout', function (e) {
            //   this.closePopup();
            //   //If the polygon is not selected set to default color
            //   if (layer.definedProperties.properties._selected != true)
            //     this.setStyle({
            //       fillColor: '#3388ff',
            //     });
            // });

            //Add/remove download cart on click
            layer.on('click', function() {
              // console.log("HJERE");
              //Loop through jsonArray ( SELECTED (CheckedFiles))
              for (
                var prodCount = 0;
                prodCount < THIS.jsonArray.length;
                prodCount++
              ) {
                for (
                  var fileCount = 0;
                  fileCount < THIS.jsonArray[prodCount].files.length;
                  fileCount++
                ) {
                  if (
                    layer._tooltip._content ===
                    THIS.jsonArray[prodCount].files[fileCount].fileName
                  ) {
                    console.log(
                      THIS.jsonArray[prodCount].files[fileCount].fileName
                    );
                    THIS.checkBoxChangeEvent(
                      THIS.productList[prodCount],
                      'file',
                      !THIS.jsonArray[prodCount].files[fileCount].isChecked,
                      prodCount,
                      fileCount,
                      THIS.jsonArray[prodCount].files[fileCount].fileName
                    );
                  }
                }
              }

              // console.log('LAYER: ', THIS.checked);

              // THIS.checked[0].files[i] = !THIS.checked[0].files[i];
              //Set color to selected
              // this.setStyle({
              //     fillColor: '#fff700'
              // });

              //Check if already exists in cart
              // if (layer.definedProperties.properties._selected == true) {
              //     //set to default color
              //     this.setStyle({
              //         fillColor: '#3388ff'
              //     });

              //     //unselect and remove from list
              //     layer.definedProperties.properties._selected = false;
              //     feature.properties.checked = false;
              //     // THIS.productList.splice(
              //     //   THIS.productList.indexOf(feature.properties),
              //     //   1
              //     // );
              //     // THIS.changeDetector.detectChanges();
              // } else {
              //     //add to list
              //     layer.definedProperties.properties._selected = true;
              //     feature.properties.checked = true;

              //     // THIS.productList.push(feature.properties);
              //     // THIS.changeDetector.detectChanges();
              // }
              THIS.changeDetector.detectChanges();
            });
          },
        });

        // console.log(myGeoJSON);
        // if (this.tileLayerGroup.getLayers().length !== 0)
        // {
        //   this.tileLayerGroup.eachLayer(function(layer)
        //   {
        //     layer.eachLayer(function(layerValues)
        //     {
        //       if(layerValues.definedProperties.properties._selected == false)
        //       {
        //         console.log("FALSE!");
        //       }
        //       console.log(layerValues.definedProperties.properties._selected);
        //     })

        //   })
        // }

        this.tileLayerGroup.addLayer(myGeoJSON);
      }
    }
    var customDict = {};
    this.layerTrackingDict['tileLayerGroup'] = this.tileLayerGroup;
    this._map.addLayer(this.tileLayerGroup);
    console.log(THIS.layerContainer);

    //this.watchThisBradThisIsMagic();
    //this._map.fitBounds(layerGroup.getBounds());
  }

  rectangleDrawEvent(map) {
    var drawnBounds;
    var THIS: DownloadCartComponent = this;

    //Set rectangle bounds on draw create
    map.on(L.Draw.Event.CREATED, function(e: any) {
      //Define the bounds of the rectangle drawn on the map

      //When we draw on the map
      if (THIS.rectangleViewPortLayer !== undefined)
        map.removeLayer(THIS.rectangleViewPortLayer);

      //Get bounds of drawn rectangle
      var topleft = e.layer.getBounds()._northEast;
      var bottomright = e.layer.getBounds()._southWest;
      //sets the rectangle bounds locally
      drawnBounds = new L.LatLngBounds(topleft, bottomright);
    });

    //Calculate once draw is complete
    map.on(L.Draw.Event.DRAWSTOP, function(e: any) {
      //The following section of code ADDS a visual rectangle to the map
      //************************************************************* */
      // this.rectangleViewPortLayer = new L.geoJSON();
      // var rectangle = L.rectangle(bounds, {
      //   color: '#ff7800',
      //   weight: 1,
      // }).addTo(this.rectangleViewPortLayer);
      // this.rectangleViewPortLayer.addTo(map);
      //************************************************************* */

      //Loop through each shapefile polygon layer
      THIS.tileLayerGroup.eachLayer(function(layer) {
        //Loop through each feature in the layer
        layer.eachLayer(function(feature) {
          //check if the drawn rectangle (calcualted above) intersects the visual polygon feature
          if (drawnBounds.intersects(layer.getBounds())) {
            //If the polygon (tile) has already been selected
            if (feature.definedProperties.properties._selected == true) {
              //  //remove from list
              //  layer.setStyle({
              //   'fillColor': '#3388ff'
              // });
              //  feature.definedProperties.properties._selected = false;
              //  THIS.productList.splice(THIS.productList.indexOf(feature.feature.geometry.properties.file),1);
              //  THIS.changeDetector.detectChanges();
            } else {
              //add to list
              feature.definedProperties.properties._selected = true;
              layer.setStyle({
                fillColor: '#fff700',
              });
              feature.feature.geometry.properties.checked = true;
              THIS.productList.push(feature.feature.geometry.properties);
              THIS.changeDetector.detectChanges();
            }
          }
        });
      });
    });
  }
  openInNewTab(router: Router, namedRoute) {
    let newRelativeUrl = namedRoute;
    let baseUrl = window.location.href.replace(router.url, '');
    newRelativeUrl = newRelativeUrl.toString().substring(1);
    console.log(baseUrl + newRelativeUrl);
    window.open('#' + newRelativeUrl, '_blank');
  }
  //When a product is selected (clicked) in the visible products
  //This function navigates to the product on the ALL-DATA page.
  onSelect(product): void {
    //selecting a product will take you to that product page
    this.selectedProduct = product;
    // this.router
    //   .navigate(['/all-data', this.selectedProduct.product_index_id])
    //   .then(
    //     nav => {
    //       //  console.log(nav);
    //       window.location.reload();
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    event.stopPropagation();

    //selecting a product will take you to that product page
    this.selectedProduct = product;
    this.openInNewTab(
      this.router,
      this.router.createUrlTree([
        'details/' + this.selectedProduct.product_index_id,
      ])
    );
  }

  displayBoundingBox(product) {
    this.triggerService.triggerProductHoverEvent(product);
    var json = JSON.parse(product.geom);

    var states = {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: json.coordinates,
      },
    };
    var exteriorStyle = {
      color: '#a1d99b',
      fillOpacity: 0.1,
    };
    var myLayer = L.geoJSON(
      states,
      { style: exteriorStyle },
      {
        coordsToLatLng: function(coords) {
          //  console.log(coords);

          //                    latitude , longitude, altitude
          return new L.LatLng(coords[1], coords[0]); //Normal behavior
        },
      }
    );

    this.hoverLayer = myLayer;
    this.hoverLayer.addTo(this._map);
    // var json = JSON.parse(product.geom);
    // console.log(json);
    // var states = {
    //   type: 'Feature',
    //   properties: {
    //     party: 'Republican',
    //   },
    //   geometry: {
    //     type: 'MultiPolygon',
    //     coordinates: json.coordinates,
    //   },
    // };

    // //console.log(states.geometry['coordinates']);
    // var myLayer = L.geoJSON(states, {
    //   coordsToLatLng: function(coords) {
    //     //  console.log(coords);

    //     //                    latitude , longitude, altitude
    //     return new L.LatLng(coords[1], coords[0]); //Normal behavior
    //   },
    // });

    // this.hoverLayer = myLayer;
    // this.hoverLayer.addTo(this._map);

    // // var geometry = wkx.Geometry.parse(product.geom);
    // // console.log(geometry);
    // //   this.hoverPolygon = L.polygon([
    // //     [51.509, -0.08],
    // //     [51.503, -0.06],
    // //     [51.51, -0.047]
    // // ]);
    // // this.hoverPolygon.addTo(this._map);
  }

  removeBoundingBox(product) {
    this._map.removeLayer(this.hoverLayer);
  }

  openDialog() {
    this.updateCart();

    // for (var count = 0; count < message.length; count++)
    // {
    //   message[count].isHidden = false;
    //   if (!this.message.some(item => item.product_id == message[count].product_id))
    //   {
    //     this.message.push(message[count]);
    //   } else {
    //     // Message displays if the user trys to add duplicate products to their cart
    //     let snackBarRef = this.snackBar.open(
    //       'WARNING: Dupplicates WERE NOT added to your cart!',
    //       'Dismiss',
    //       {
    //         duration: 5000,
    //         verticalPosition: 'top',
    //       }
    //     );
    //   }
    // }
    const dialogRef = this.dialog.open(CheckoutDialogComponent);

    console.log(this.testArray);
    console.log(this.productList);
    dialogRef.afterClosed().subscribe(() => {
      // modal closes
    });
    try {
      console.log(this.productList);
      console.log(this.testArray);
      this.dataService.changeMessage(this.testArray);
    } catch (Exception) {
      console.log(Exception);
    }
    // this.ngOnChanges(this.productList);
  }

  checkBoxChangeEvent(
    product,
    fileOrProduct,
    ischecked,
    prodCount,
    fileCount,
    productFileName
  ) {
    var THIS: DownloadCartComponent = this;
    console.log(product);
    console.log(fileOrProduct);
    console.log(ischecked);
    console.log(prodCount);
    console.log(fileCount);
    //console.log("BOOP");
    // console.log(product);
    if (fileOrProduct === 'product') {
      //this.checkBoxArray[prodCount].product = ischecked;
      product.isChecked = ischecked;
      product.isSelected = ischecked;

      // console.log(product);
      // console.log(ischecked);
      if (product.isChecked === false) {
        //console.log("hereee");
        //console.log(this.jsonArray[prodCount]);
        //console.log(this.productList[prodCount]);

        for (var i = 0; i < this.jsonArray[prodCount].files.length; i++) {
          this.jsonArray[prodCount].files[i].isChecked = ischecked;
          this.jsonArray[prodCount].files[i].isSelected = ischecked;
        }
        // console.log(this.jsonArray[prodCount].files)
        // this.jsonArray[prodCount].isChecked = ischecked;
      }
    }

    if (fileOrProduct === 'file') {
      //  console.log(ischecked);

      //  console.log(this.jsonArray[prodCount].files);
      console.log(this.jsonArray[prodCount].files[fileCount]);
      this.jsonArray[prodCount].files[fileCount].isChecked = ischecked;
      this.jsonArray[prodCount].files[fileCount].isSelected = ischecked;
      console.log(this.jsonArray[prodCount].files[fileCount]);
      //this.checkBoxArray[prodCount].files[fileCount].trueOrFalse = ischecked;
      //  console.log('filecount: ', fileCount);

      // console.log('CONTAINERS: ', THIS.layerContainer, THIS.featureContainer);

      //Loop through layer containers

      for (var i = 0; i < THIS.layerContainer.length; i++) {
        console.log(THIS.layerContainer[i]);
        if (THIS.layerContainer[i] != undefined) {
          //console.log("here!");

          if (THIS.layerContainer[i]._tooltip._content === productFileName) {
            console.log(i);
            THIS.layerContainer[
              i
            ].definedProperties.properties._selected = ischecked;
            THIS.featureContainer[i].properties.checked = ischecked;
            //If the polygon is already selected keep it the selected color
            console.log(
              'LayerContainer: ' +
                THIS.layerContainer[i].definedProperties.properties._selected,
              THIS.featureContainer[i].properties.checked
            );
            if (
              THIS.layerContainer[i].definedProperties.properties._selected ==
              true
            ) {
              //Polygons are yellow
              THIS.layerContainer[i].setStyle({ fillColor: '#fff700' });
              //Change it to not selected anymore
              THIS.layerContainer[
                i
              ].definedProperties.properties._selected = false;
              THIS.featureContainer[i].properties.checked = false;
            }
            //If the polygon is not selected set to default color
            else if (
              THIS.layerContainer[i].definedProperties.properties._selected !=
              true
            ) {
              THIS.layerContainer[i].setStyle({ fillColor: '#3388ff' });

              THIS.layerContainer[
                i
              ].definedProperties.properties._selected = true;
              THIS.featureContainer[i].properties.checked = true;
            }
          }
        }
      }
    }

    this.changeDetector.detectChanges();
    // console.log('PRODUCT: ', product, fileOrProduct, ischecked, prodCount, fileCount);
  }

  updateCart() {
    // console.log('checked: ', this.checked);
    var tempArray = [];
    var tmpArr = [];
    this.testArray = [];

    // console.log(this.checkBoxArray);
    //this.test();

    //TODO ADD LOGIC FOR FILE CHECKED/HIDDEN
    //TODO ADD PRODUCT/FILES TO tempArray

    //loop through all products
    for (let prodCount = 0; prodCount < this.jsonArray.length; prodCount++) {
      //Defined temp json to store data for passing
      var tempJson = {
        theProduct: null,
        files: [],
      };
      //If the product is checked and not hidden
      if (
        this.jsonArray[prodCount].theProduct.isChecked === true &&
        this.jsonArray[prodCount].theProduct.isHidden === false
      ) {
        //******** */
        //Check if the product has files
        if (this.jsonArray[prodCount].files !== null) {
          // console.log(this.jsonArray);
          //SEED tempArry with jsonArray
          tmpArr = this.jsonArray[prodCount].files;

          //loop through this product (x)'s files
          for (
            let fileCount = 0;
            fileCount < this.jsonArray[prodCount].files.length;
            fileCount++
          ) {
            //If the file is NOT checked */////////////////*

            // console.log("File to be checked: " , this.jsonArray[prodCount].files[fileCount]);
            if (!this.jsonArray[prodCount].files[fileCount].isChecked) {
              //********************* */
              // console.log("Before Filter: " , this.jsonArray[prodCount].files);
              tmpArr = tmpArr.filter(
                value =>
                  !value.fileName.includes(
                    this.jsonArray[prodCount].files[
                      fileCount
                    ].fileName.substring(
                      0,
                      this.jsonArray[prodCount].files[fileCount].fileName
                        .length - 4
                    )
                  )
              );
              // console.log("After Filter: " , tmpArr);
            } else {
              //dont add the file
              //flag file as hidden
            }
          }
          //  this.jsonArray[prodCount].theProduct.files = this.jsonArray[prodCount].files;
          console.log(this.jsonArray[prodCount].theProduct.files);
          this.jsonArray[prodCount].theProduct.files = [];
          // this.jsonArray[prodCount].files = []
          for (var i = 0; i < tmpArr.length; i++) {
            this.jsonArray[prodCount].theProduct.files[i] = tmpArr[i].fileName;
          }

          tempArray.push(this.jsonArray[prodCount].theProduct);
        } //LAS has no files
        else {
          tempJson.theProduct = this.jsonArray[prodCount].theProduct;
          tempJson.files = [];
          tempArray.push(tempJson.theProduct);
        }
        /*Add product to tempArray*/
      } else if (
        this.productList[prodCount].isChecked === true &&
        this.productList[prodCount].isHidden === true
      ) {
        // handle weird case where something is checked but hidden
        this.productList[prodCount].isHidden = false;
      } else if (
        this.productList[prodCount].isChecked === false &&
        this.productList[prodCount].isHidden === false
      ) {
        // handle case where item is not checked but still needs to be hidden
        this.productList[prodCount].isHidden = true;
      } //If isChecked is false && isHidden is true
      else {
      }
    }
    // Loop through tempArray to remove unchecked products from productList
    for (let y = 0; y < tempArray.length; y++) {
      // console.log(tempArray[y]);
      this.testArray.push(tempArray[y]);
    }

    this.changeDetector.detectChanges();

    // this.test();
  }

  zoomTo(product, count, eyeState, event) {
    event.stopPropagation();
    this.changeDetector.detectChanges();
    //this.resetAll(product);
    var centroid = JSON.parse(product.centroid);
    if (this._map.getZoom() > 15) {
      // if we are zoomed in futher than 15
      this._map.setView(centroid['coordinates'], this._map.getZoom());
    } else {
      this._map.setView(centroid['coordinates'], 15);
    }
    // //IF THE EYEBALL HAS BEEN SELECTED AND WE CAN SEE ALL OF THE FILES (OPEN)
    // if (eyeState == 0) {
    //   product.isBeingViewed = false;
    //   var THIS: DownloadCartComponent = this;
    //   // this.detailedView[count] = !this.detailedView[count];
    //   this._map.setView(
    //     [product.site_location.latitude, product.site_location.longitude],
    //     15
    //   );

    //   this.changeDetector.detectChanges();
    // } else {
    //   this.productId = { productId: product.product_index_id };
    //   this.zooming = true;
    //   product.isBeingViewed = true;
    //   this._map.setView(
    //     [product.site_location.latitude, product.site_location.longitude],
    //     15
    //   );

    //   this.tileSubscription = this.productservice
    //     .getProduct(this.productId)
    //     .pipe(first())
    //     .subscribe(product => {
    //       //Populate download cart
    //       this.populateDownloadCart(this.productId, count);

    //       this.changeDetector.detectChanges();
    //     });
    // }
    // //this.changeDetector.detectChanges();
    // this.zooming = false;
    // //100ms timeout for change detector
    // this.timedDetectChanges(100);
  }

  timedDetectChanges(time) {
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, time);
  }
  resetAll(product = undefined) {
    if (product != undefined) {
      for (var i = 0; i < this.productList.length; i++) {
        //If we find a product that is open and it doesn't match the passed product
        //close it
        if (
          this.productList[i].isBeingViewed == true &&
          this.productList[i] != product
        ) {
          this.productList[i].isBeingViewed = false;
        }
      }
    }

    if (this.tileSubscription !== undefined) {
      this.tileSubscription.unsubscribe();
      this.tileSubscription = undefined;
    }
    if (this.hoverLayer !== undefined) {
      this.hoverLayer.removeFrom(this._map);
    }

    this._map.removeLayer(this.tileLayerGroup);
    this.layerContainer = [];
    this.featureContainer = [];
    geoJsonWithProperties = undefined;
    this.changeDetector.detectChanges();
  }

  onCheck(product): void {
    //selecting a product will take you to that product page
    // console.log(this.tileLayerGroup);
    // this.tileLayerGroup.eachLayer(function(tlLayer) {
    //   tlLayer.eachLayer(function(feature) {
    //     if (feature.feature.geometry.properties.name == product.filename) {
    //       //names match
    //       console.log('Matched Name');
    //       console.log(product);
    //     }
    //   });
    // });
  }
  // displayAllboundingBoxes(product)
  // {
  //   console.log("display");
  //   var flag = true;
  //   if (this.hoverLayerGroup !== undefined)
  //     this._map.removeLayer(this.hoverLayerGroup);
  //   this.hoverLayerGroup = new L.layerGroup();

  //   flag = true;
  //   var geom = product.geom;
  //   var states = {
  //     type: 'Feature',
  //     properties: {},
  //     geometry: {
  //       type: 'Polygon',
  //       coordinates: JSON.parse(geom).coordinates,
  //     },
  //   };
  //   var myLayer = L.geoJSON(states, {
  //     coordsToLatLng: function(coords) {
  //       return new L.LatLng(coords[1], coords[0]);
  //     },
  //   });
  //   this.hoverLayer = myLayer;
  //   this.hoverLayer.addTo(this._map);
  //   //this._map.fitBounds(this.hoverLayer.getBounds());
  // }
}
