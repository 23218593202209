import { LoggingService } from '../services/logging.service';
import { take } from 'rxjs/operators';

export class LoggingJson {
  /**json.user must be passed an email, json.action must be defined with LoggingJson.Actions, json.comments must be defined with LoggingJson.Comments*/
  public user: string = '';
  /**Example Use: LoggingJson.Actions.UPDATE*/
  public action: LoggingJson.Actions;
  /**Example Use: LoggingJson.Comments.UPDATE_SITE*/
  public comments: LoggingJson.Comments;
  public changes: {};
  constructor(private loggingService: LoggingService) {}
  /**This function must be called to submit a log into the database. json.user, json.action, json.comments must be set. */
  submit() {
    if (this.user === null || this.user === undefined) {
      return 'Logging Failed: User must be defined.';
    }
    if (this.action === null || this.action === undefined) {
      return 'Logging Failed: An action must be defined.';
    }
    if (this.comments === null || this.comments === undefined) {
      return 'Logging Failed: A comment must be defined.';
    }

    let sendJson = {
      user: this.user,
      action: this.action,
      comments: this.comments,
      changes: this.changes,
    };
    this.loggingService
      .insertNewLog(JSON.stringify(sendJson))
      .pipe(take(1))
      .subscribe(resultlog => {});
  }
}
export namespace LoggingJson {
  export enum Actions {
    LOGIN = 'Login',
    REGISTER = 'Register',
    REMOVE = 'Remove',
    LOGOUT = 'Logout',
    UPDATE = 'Update',
    INSERT = 'Insert',
    DELETE = 'Delete',
  }
  export enum Comments {
    LOGIN = 'User has successfully logged into Gulf3D',
    REGISTER = 'User has successfully registered a new user',
    LOGOUT = 'User has successfully logged out of Gulf3D',
    UPDATE_SITE = 'User has successfully updated a site in the database.',
    UPDATE_PRODUCT = 'User has successfully update a product in the database.',
    INSERT_SITE = 'User has successfully inserted a new site into the database.',
    INSERT_PRODUCT = 'User has successfully inserted a new product into the database.',
    DELETE_SITE = 'User has successfully deleted a site from the database. ',
    DELETE_PRODUCT = 'User has successfully deleted a product from the database. ',
    REMOVE_PRODUCT = 'User has successfully removed a product from a site.',
  }
}
