import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
const fetch = require('node-fetch');
import { $ } from 'protractor';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
const httpOptionsFile = {
  headers: new HttpHeaders({
    'Content-Type': undefined,
  }),
};

@Injectable()
export class SitesService {
  constructor(private http: HttpClient) {}

  getSites() {
    return this.http.get('/api/data/sites');
  }

  getSitesBasedOnDistance(productGeometry) {
    return this.http.post(
      '/api/data/sites-distance',
      productGeometry,
      httpOptions
    );
  }

  getSite(id) {
    return this.http.post(`/api/data/sites/`, id, httpOptions);
  }
  // Get only sites, all of them
  getOnlySites() {
    return this.http.post('/api/data/all/sites', httpOptions);
  }
  // createSite(data): Observable<any> {
  //   console.log(data);
  //   return this.http.post(`/api/data/sites/${data}`, data);
  // }
  passShapeFileToServer(shapefile) {
    console.log(shapefile);

    //file
    var formData = new FormData();

    formData.append('file', shapefile, shapefile.name);

    //return this.http.post('/api/data/upload/shapefile/', formData, httpOptionsFile
    //);

    return 'Boop';
  }

  //  return this.http.post(`/api/data/upload/shapefile`, shapefile);
  createSite(data) {
    return this.http.post(`/api/data/sites/create`, data, httpOptions);
  }

  updateSite(data): Observable<any> {
    return this.http.put(`/api/data/sites/`, data, httpOptions);
  }

  addProductToSite(siteId, data): Observable<any> {
    return this.http.post(`/api/data/products/`, data, httpOptions);
  }

  // This is needed for the datatable on sites list
  getSiteObservable(): Observable<any> {
    return this.http.get('/api/data/sites');
  }

  deleteSite(siteId: number, site) {
    return this.http.post(`/api/data/sites/delete/`, site, httpOptions);
  }
}
