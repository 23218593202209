// grocery-store.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportXmlService {
  private xmlMetadata: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private xmlMetadataObj: BehaviorSubject<Object> = new BehaviorSubject<Object>(
    null
  );

  constructor() {}
  getXmlMetaData(): BehaviorSubject<string> {
    return this.xmlMetadata;
  }
  getXmlMetaDataObj(): BehaviorSubject<Object> {
    return this.xmlMetadataObj;
  }
}
