import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatAutocomplete,
} from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { SitesService } from '../../services/sites.service';

@Component({
  selector: 'app-site-autocomplete',
  templateUrl: './site-autocomplete.component.html',
  styleUrls: ['./site-autocomplete.component.css'],
})
export class SiteAutocompleteComponent implements OnInit {
  @Input() sites: any;
  @Output() emitSites = new EventEmitter<any>();
  selectedSites = new Array();
  isReady = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  siteCtrl = new FormControl();
  filteredSites: Observable<string[]>;

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private sitesService: SitesService) {}

  ngOnInit() {
    if (this.sites === null || this.sites === undefined) {
      this.getSites();
    } else {
      this.initializeAutoComplete();
    }
  }

  emitSelectedSites() {
    // console.log(this.selectedSites);
    this.emitSites.emit(this.selectedSites);
  }

  /**
   * (Used to filter the autocomplete field for sites)
   *
   * @param {string} val
   * @returns {string[]}
   * @memberof SiteEditorComponent
   */
  filter(val: any): string[] {
    return this.sites.filter(option => {
      if (typeof val === 'string') {
        return option.site_label.toLowerCase().includes(val.toLowerCase());
      } else if (val !== null && val !== undefined) {
        val = val.site_label;
        return option.site_label.toLowerCase().includes(val.toLowerCase());
      } else {
      }
    });
  }

  initializeAutoComplete() {
    // Initializing auto complete
    this.filteredSites = this.siteCtrl.valueChanges.pipe(
      startWith(''),
      map(val => this.filter(val))
    );
  }

  getSites() {
    this.sitesService
      .getOnlySites()
      .pipe(take(1))
      .subscribe(
        result => {
          this.sites = result;
          this.isReady = true;
          this.initializeAutoComplete();
        },
        error => {
          console.log(error);
        }
      );
  }

  /**
   * (Used in the html to filer what is displayed on the site search)
   *
   * @param {*} site
   * @returns
   * @memberof SiteEditorComponent
   */
  getDisplay(site: any) {
    return site.site_label;
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        //this.selectedSites.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.siteCtrl.setValue(null);
    }
  }

  remove(fruit: string): void {
    const index = this.selectedSites.indexOf(fruit);

    if (index >= 0) {
      this.selectedSites.splice(index, 1);
      this.emitSelectedSites();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.hasSiteAlready(event.option.value) !== -1) {
      alert('This site has already been added to the list!');
      return;
    }
    this.selectedSites.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.siteCtrl.setValue(null);
    this.emitSelectedSites();
  }

  hasSiteAlready(site: any) {
    return this.selectedSites.findIndex(
      exisitingSite => exisitingSite.site_index_id === site.site_index_id
    );
  }
}
