// httpSetHeaders.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { jsonpCallbackContext } from '@angular/common/http/src/module';

@Injectable()
export class httpSetHeaders implements HttpInterceptor {
  constructor(private router: Router, private Auth: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var isTokenInValid;
    var allowedRoutes = [
      '/api/data/users/login',
      '/api/data/users/isvalid/',
      '/api/data/logging/',
    ];
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    if (!allowedRoutes.includes(request.url)) {
      // console.log("HTTP INTERCEPTOR");
      // console.log(request.method);
      //reset sessionStorage

      //   var currentTime = (new Date().getTime() / 1000);
      //   var time = +sessionStorage.getItem('timer') / 1000; // cast to number
      //   console.log(currentTime);
      //   console.log(time);
      //   console.log(currentTime-time);
      //   if((currentTime - time) > 60) // time in seconds
      //   {
      //     console.log("its been 1 minute, removing from session");
      //   }
      //  var test = sessionStorage.getItem('cookie');
      if (request.method == 'POST') {
        // console.log('URL:');
        // console.log(request.url);
        // console.log('REQUEST BODY:');
        // console.log(request.body);
        // console.log(typeof request.body);
        var originalRequestBody = JSON.stringify(request.body);
        var tempBody = {};
        //console.log(JSON.parse(originalRequestBody));
        Object.assign(tempBody, JSON.parse(originalRequestBody), {
          sessionToken: sessionStorage.getItem('cookie'),
          whoIssuedRoute: sessionStorage.getItem('email'),
          oID: sessionStorage.getItem('oID'),
          //    // email: sessionStorage.getItem('email'),
        });
        request = request.clone({
          body: tempBody,
        });
        // console.log('MODIFIED BODY: ');
        // console.log(request.body);
      }
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('RESPONSE FROM SERVER: ', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        // let data = {};
        // data = {
        //     domain: error.name,
        //     message: error.message,
        //     reason: error.status
        // };
        if (error.error === 'Token Is Not Valid.') {
          isTokenInValid = true;
          if (isTokenInValid) {
            alert('Your session has expired. Please login again.');
            this.router.navigate(['/login']);
            localStorage.clear();
            sessionStorage.clear();
            this.Auth.setLoggedIn(false);
          }
        }
        return throwError(error);
      })
    );
  }
}
