export const fragmentShader = `
  uniform vec4 planes[6];
  uniform int do_plane_clipping;
  varying vec3 vColor;
 
  void main()
   {
    
    gl_FragColor = vec4( vColor, 1.0 );

  }
`;
