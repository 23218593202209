import { Routes } from '@angular/router';
import { LandingPageComponent } from '../landing-page/components/landing-page.component';
import { ExistingDataComponent } from '../sites/components/existing-data/existing-data.component';
import { NewDataComponent } from '../sites/components/new-data/new-data.component';
import { ExportedDataComponent } from '../sites/components/exported-data/exported-data.component';
import { ProductViewComponent } from '../products/components/product-view/product-view.component';
import { AboutPageComponent } from '../about-page/components/about-page.component';
import { ForgotPasswordComponent } from '../forgot-password-page/components/forgot-password-page.component';
import { ProfileComponent } from '../profile-page/components/profile-page.component';
import { LoginPageComponent } from '../login-page/components/login-page.component';
import { LogoutPageComponent } from '../logout-page/components/logout-page.component';
import { SiteTableEdit } from '../sites/components/site-product-table-editor/site-product-table-editor.component';
import { AllDataComponent } from '../all-data/components/all-data.component';
import { RegistrationComponent } from '../register/components/registration-page.component';
import { ChangePasswordComponent } from '../change-password-page/components/change-password-page.component';
import { XmlCreatorComponent } from '../xml-creator-page/components/xml-creator-page.component';
import { UserManagementComponent } from '../user-management-page/components/user-management-page.component';
import { AuthGuard } from './auth.guard';
import { OrthoViewDownloadComponent } from '../ortho-view-download-page/components/ortho-view-download-page.component';
import { DetailsPageComponent } from '../details-page/components/details-page.component';
import { EditProductPageComponent } from '../edit-product-page/components/edit-product-page.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'about',
    component: AboutPageComponent,
  },
  {
    path: 'sites',
    component: ExistingDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sites/new',
    component: NewDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sites/exported',
    component: ExportedDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sites/:id/edit',
    component: SiteTableEdit,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'all-data',
    component: AllDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'all-data/:id',
    component: AllDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'register',
    component: RegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'details/:id',
    component: DetailsPageComponent,
  },
  {
    path: 'edit-product/:id',
    component: EditProductPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'xml-creator',
    component: XmlCreatorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'ortho-view-download/:id',
    component: OrthoViewDownloadComponent,
  },
];
