import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatProgressBarModule,
} from '@angular/material';

import { MapComponent } from './map/map.component';
import { PointCloudViewerComponent } from './point-cloud-viewer/point-cloud-viewer.component';
import { Ng5SliderModule } from 'ng5-slider';

@NgModule({
  declarations: [MapComponent, PointCloudViewerComponent],
  imports: [
    CommonModule,
    Ng5SliderModule,
    MatButtonModule,
    MatProgressBarModule,
    MatGridListModule,
    MatListModule,
  ],
  exports: [MapComponent, PointCloudViewerComponent],
})
export class ComponentsModule {}
