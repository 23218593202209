import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import * as socketIo from 'socket.io-client';

const url = 'ws://api/data/product/points';

/*
websocket.onmessage = msg => console.log(msg)
websocket.addEventListener('open', () => {
  websocket.send(`{"action": "readpoints", "data": { "chunk": 2 } }`)
})
*/
@Injectable()
export class SocketService {
  private socket;

  constructor() {}

  public initSocket(): Observable<any> {
    this.socket = new WebSocket(`ws://localhost:3000/api/data/product/points`);

    return new Observable(observer => {
      console.log('blah');
      this.socket.addEventListener('open', () => {
        console.log('connection opened');
        observer.next(true);
      });
    });
  }

  public send(message): void {
    this.socket.send(message);
  }

  public onMessage(): Observable<any> {
    return new Observable(observer => {
      this.socket.addEventListener('message', data => observer.next(data));
    });
  }

  public onEvent(event): Observable<any> {
    return new Observable(observer => {
      this.socket.on(event, () => observer.next());
    });
  }
}
