import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { RouterModule } from '@angular/router';
import {
  MatButtonModule,
  MatGridListModule,
  MatListModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatDividerModule,
  MatProgressBarModule,
  MatIconModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatStepperModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatDialogModule,
  MatExpansionModule,
  MatTooltipModule,
  MatSortModule,
  MatRadioModule,
  MatChipsModule,
  MatCheckboxModule,
} from '@angular/material';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SitesService } from '../services/sites.service';
import { ProductsService } from '../services/products.service';
import {
  ExistingDataComponent,
  DialogAddNewProduct,
} from './components/existing-data/existing-data.component';
import { ExportedDataComponent } from './components/exported-data/exported-data.component';
import { NewDataComponent } from './components/new-data/new-data.component';
import {
  SiteTableEdit,
  DialogContentProductsTable,
} from './components/site-product-table-editor/site-product-table-editor.component';
import { ComponentsModule } from '../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SiteAutocompleteModule } from '../components/site-autocomplete/site-autocomplete.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DialogProviderTourOverview } from './components/new-data/new-data.component';
@NgModule({
  declarations: [
    ExistingDataComponent,
    NewDataComponent,
    ExportedDataComponent,
    SiteTableEdit,
    DialogContentProductsTable,
    DialogAddNewProduct,
    DialogProviderTourOverview,
  ],
  imports: [
    SweetAlert2Module,
    AutocompleteLibModule,
    CommonModule,
    RouterModule,
    ComponentsModule,
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    NgxFileDropModule,
    LeafletModule,
    MatGridListModule,
    MatListModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    NgxUploaderModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatStepperModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSortModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    SiteAutocompleteModule,
  ],
  providers: [SitesService, ProductsService],
  entryComponents: [
    DialogContentProductsTable,
    DialogAddNewProduct,
    DialogProviderTourOverview,
  ],
})
export class SitesModule {}
