import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private fileList: string[] = new Array<string>();
  private fileList$: Subject<string[]> = new Subject<string[]>();
  private displayLoader$: Subject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private http: HttpClient) {}

  public isLoading(): Observable<boolean> {
    return this.displayLoader$;
  }

  public upload(
    fileNameSHP: string,
    fileNameDBF: string,
    fileNamePRJ: string,
    fileContent: string
  ): any {
    this.displayLoader$.next(true);
    var foobar = this.http.put('/api/data/uploads', {
      nameSHP: fileNameSHP,
      nameDBF: fileNameDBF,
      namePRJ: fileNamePRJ,
      content: fileContent,
    });
    foobar.pipe(finalize(() => this.displayLoader$.next(false)));
    console.log(foobar);
    //.pipe(finalize(() => this.displayLoader$.next(false)));

    // foobar.subscribe(
    //   res => {
    //     console.log(fileNameSHP);
    //     this.fileList.push(fileNameSHP);
    //     this.fileList.push(fileNameDBF);
    //     this.fileList$.next(this.fileList);
    //     console.log(res);
    //     return res;
    //   },
    //   error => {
    //     this.displayLoader$.next(false);
    //   }
    // );

    return foobar;
  }

  public download(fileName: string): void {
    this.http
      .get('/api/data/uploads/${fileName}', { responseType: 'blob' })
      .subscribe(res => {
        window.open(window.URL.createObjectURL(res));
      });
  }

  public remove(fileName): void {
    this.http.delete('/api/data/uploads/${fileName}').subscribe(() => {
      this.fileList.splice(
        this.fileList.findIndex(name => name === fileName),
        1
      );
      this.fileList$.next(this.fileList);
    });
  }

  public list(): Observable<string[]> {
    return this.fileList$;
  }

  private addFileToList(fileName: string): void {
    this.fileList.push(fileName);
    this.fileList$.next(this.fileList);
  }
}
