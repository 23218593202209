import { Component } from '@angular/core';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { SocketService } from '../../services/socket.service';
import { RegistrationService } from '../../services/registration.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SurveyTypesService } from '../../services/surveytypes.service';
import { Validators } from '@angular/forms';
import { OrganizationsService } from '../../services/organizations.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css'],
})
//declare var require: any;
export class ProfileComponent {
  organizationTypes: any;
  comparisonNumber: any;
  param1: any;
  // siteForm: NgForm;
  siteForm = new FormGroup({
    ffname: new FormControl('', [Validators.required]),
    llname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
  });
  constructor(
    private socketService: SocketService,
    private registrationService: RegistrationService,
    private organizationsServices: OrganizationsService,
    private surveyTypesServices: SurveyTypesService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private usersService: UsersService
  ) {
    // Populating survey types drop down
    this.organizationsServices.getOrganizations().subscribe(
      result => {
        this.organizationTypes = result;
      },
      error => console.log(error)
    );
  }
  ngOnInit() {
    let json = {
      email: sessionStorage.getItem('email'),
      whoIssuedRoute: sessionStorage.getItem('email'),
      sessionToken: sessionStorage.getItem('cookie'),
    };
    this.usersService.getUser(json).subscribe(result => {
      //console.log(result);
      var json = result;
      this.siteForm.setValue({
        ffname: json[0].firstname,
        llname: json[0].lastname,
        email: json[0].email,
      });
    });
  }
  goToChangePassword(siteForm) {
    this.router.navigate([
      '/change-password',
      {
        firstname: siteForm.controls['ffname'].value,
        lastname: siteForm.controls['llname'].value,
      },
    ]);
  }
  goToForgotPassword(siteForm) {
    this.router.navigate(['/forgot-password']);
  }
}
