import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule } from '@angular/forms';
import { LandingPageComponent } from './components/landing-page.component';
import { CheckoutDialogComponent } from '../components/checkout-dialog/checkout-dialog.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { SearchMenuFilterComponent } from '../components/search-menu-filter/search-menu-filter.component';
import { SearchMenuSelectRegionComponent } from '../components/search-menu-select-region/search-menu-select-region.component';
import { SearchMenuGeoSearchComponent } from '../components/search-menu-geo-search/search-menu-geo-search.component';
import { SearchMenuViewCartComponent } from '../components/search-menu-view-cart/search-menu-view-cart.component';
import { SearchMenuSearchBarComponent } from '../components/search-menu-search-bar/search-menu-search-bar.component';
import { Ng5SliderModule } from 'ng5-slider';
import { Options } from 'ng5-slider';
import { DownloadProductsComponent } from '../components/download-products/download-products.component';
import { SuccessDownloadMessageComponent } from '../components/success-download-message/success-download-message.component';
import { ProductListComponent } from '../components/product-list/product-list.component';
import { DownloadCartComponent } from '../components/download-cart/download-cart.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { JoyrideModule, JoyrideStepComponent } from 'ngx-joyride';
import { DialogTourOverview } from './components/landing-page.component';
@NgModule({
  declarations: [
    DialogTourOverview,
    LandingPageComponent,
    SearchMenuFilterComponent,
    SearchMenuSelectRegionComponent,
    SearchMenuSearchBarComponent,
    SearchMenuViewCartComponent,
    CheckoutDialogComponent,
    DownloadProductsComponent,
    SearchMenuGeoSearchComponent,
    SuccessDownloadMessageComponent,
    ProductListComponent,
    DownloadCartComponent,
  ],
  imports: [
    JoyrideModule,
    CommonModule,
    LeafletModule,
    AngularMaterialModule,
    Ng5SliderModule,
    FormsModule,
    NgxSliderModule,
    //LeafletDrawModule.forRoot()
  ],
  entryComponents: [
    JoyrideStepComponent,
    DialogTourOverview,
    CheckoutDialogComponent,
    DownloadProductsComponent,
    SuccessDownloadMessageComponent,
  ],
})
export class LandingPageModule {}
